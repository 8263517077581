<script>
    import Loader from "./Loader.svelte";
    import {uploadSampleAttachment, uploadLabResultAttachment, getSamples} from "../../network/patient_match";
    import {selectedLabResults} from "../../store/patientMatch";
    export let onUpload;
    export let onCancel;
    export let patientUUID;
    export let sampleUUID;
    export let lrUUID;
    export let uploadType;
    let file;
    let loading = false;
    function handleFileChange(event) {
        file = event.target.files[0];
    }
    async function upload() {
        loading = true;
        let result;
        if (uploadType === 'labResult') {
            result = await uploadLabResultAttachment(file, lrUUID, patientUUID, sampleUUID);
        }
        else if (uploadType === 'sample') {
            result = await uploadSampleAttachment(file, lrUUID, patientUUID, sampleUUID);
        }
        const sampleData = await getSamples(patientUUID);
        selectedLabResults.set(sampleData);
        loading = false;
        onUpload(result);
    }
</script>
<div class="modal-backdrop" on:click={onCancel}></div>
<div class="modal">
    <div class="modal-content">
        {#if loading}
            <Loader backgroundColor="#fff"/>
        {:else}
            <div class="modal-input">
                {#if (uploadType === 'labResult' || uploadType === 'sample')}
                    <h3> Select PDF File to upload</h3>
                {/if}
                <input style="margin:5px" class="upload-attachment" type="file" on:change={handleFileChange}/>
            </div>
            <div class="modal-buttons">
                <button class="btn-cancel" on:click={onCancel}>Cancel</button>
                <button class="btn-confirm" on:click={upload}>Upload</button>
            </div>
        {/if}
    </div>
</div>
<style>
    .modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9;
    }
    .modal {
        width: 600px;
        height: 200px;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        padding: 20px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        z-index: 10;
    }
    .modal-content {
        text-align: center;
    }
    .modal-buttons {
        display: flex;
        justify-content: space-around;
        margin-top: 100px;
    }

    .modal-input {
        display: flex;
        justify-content: space-around;
    }
    .btn-confirm {
        background-color: #506ea8;
        color: white;
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
    }
    .btn-cancel {
        background-color: #a0aec0;
        color: white;
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
    }
</style>