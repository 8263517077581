import {callApi} from "./rest";

const ROOT_URL_CONSULT = '/consult';
const ROOT_URL_API = '/api';

export const getAssayMatch = async (patient_names, patient_mrns, patient_diagnoses) => {
    const url = `${ROOT_URL_CONSULT}/assays/match`;
    const requestBody = new FormData();

    for (let disease of patient_names){
        requestBody.append('patient_name', disease)
    }

    for (let mrn of patient_mrns){
        requestBody.append('patient_mrn', mrn)
    }

    for (let disease of patient_diagnoses){
        requestBody.append('patient_diagnosis', disease)
    }

    const response = await callApi(url, "POST", requestBody);

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return await response.json();
}


export const getDiseaseDriven = async (patient_diagnoses) => {
    const url = `${ROOT_URL_API}/pertinent_negative_groups/disease`;

    const queryParams = new URLSearchParams();
    patient_diagnoses.forEach(disease => queryParams.append('diseases', disease));

    const searchURL = `${url}?${queryParams.toString()}`
    console.log(searchURL)

    const response = await callApi(searchURL, "GET");

    if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
    }
    return await response.json();
}