<script>
    import {onMount} from 'svelte';
    import {searchQueries, totalPatients, changesDetected} from "../../store/patientSearchQueries";
    import {gatherFacetInfo, suggestDiagnosis} from "../../network/patient_search";
    import {getAssayMatch, getDiseaseDriven} from "../../network/assay_match";
    import {assayMatchResults, assayMatchSearchType} from "../../store/patientMatch";

    // Search type state
    let searchType;
    let isLoading = false;

    // Form states
    let diagnosis = '';
    let selectedDiagnoses = [];
    let diagnosisSuggestions = [];

    let names = '';
    let selectedNames = [];
    let patientNames = [];
    let filteredNames = patientNames;

    let mrns = '';
    let selectedMRNs = [];
    let patientMRNs = [];
    let filteredMRNOptions = patientMRNs;

    onMount(() => {
        assayMatchSearchType.set('');
        fetchPatientFacetInfo();
    });

    const fetchPatientFacetInfo = async () => {
        try {
            const facetInfoResponse = await gatherFacetInfo();

            console.log(facetInfoResponse);
            if (Array.isArray(facetInfoResponse['patient_mrns'])) {
                let total_patients = facetInfoResponse['patient_mrns'].length - 1
                totalPatients.update(storeValue => {
                    storeValue = total_patients.toString();
                    return storeValue;
                });
                patientMRNs = facetInfoResponse['patient_mrns'];
            }
            if (Array.isArray(facetInfoResponse['full_names'])) {
                patientNames = [...new Set(facetInfoResponse['full_names'].map((item) => item))];
            }
        } catch (error) {
            console.error("Error fetching patient facet info:", error);
        }
    };

    async function handleSearch() {
        changesDetected.set(true);
        isLoading = true;
        try {
            const assayMatchJSON = await getAssayMatch(selectedNames, selectedMRNs, selectedDiagnoses);
            if (assayMatchJSON != null) {
                assayMatchResults.set(assayMatchJSON);
            }
            if (selectedDiagnoses.length > 0){
                const pertinentAlts = await getDiseaseDriven(selectedDiagnoses);
                assayMatchResults.update(currentValue => ({
                    ...currentValue,
                    patient:{
                        pertinent_alterations: pertinentAlts
                    }
                }));
            }
        } catch (error) {
            console.error("Error during search:", error);
        } finally {
            isLoading = false;
        }
    }

    function resetFilters() {
        changesDetected.set(true);
        selectedNames = [];
        selectedMRNs = [];
        selectedDiagnoses = [];
        names = '';
        mrns = '';
        diagnosis = '';

    }

    function clearResults() {
        changesDetected.set(true);
        resetFilters();
        assayMatchResults.set('');
        searchQueries.update(storeValue => ({
            patient_mrn: [],
            full_name: [],
            diagnosis: []
        }));
    }

    function setSearchAndClearResults(){
        changesDetected.set(true);
        assayMatchSearchType.set(searchType);
        clearResults();
    }

    function handleMRNInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredMRNOptions = patientMRNs.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleMRNSelect(option) {
        selectedMRNs = [option];
        mrns = '';
        filteredMRNOptions = patientMRNs;
        searchQueries.update(storeValue => {
            storeValue.patient_mrn = selectedMRNs;
            return storeValue;
        });
    }

    function removeMRN(index) {
        selectedMRNs = selectedMRNs.slice(0, index).concat(selectedMRNs.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.patient_mrn = selectedMRNs;
            return storeValue;
        });
    }

    function handleMRNKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleMRNSelect(event.target.value);
        }
    }

    function handleNameInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredNames = patientNames.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleNameSelect(option) {
        selectedNames = [option];
        names = '';
        filteredNames = patientNames;
        searchQueries.update(storeValue => {
            storeValue.full_name = selectedNames;
            return storeValue;
        });
    }

    function removeName(index) {
        selectedNames = selectedNames.slice(0, index).concat(selectedNames.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.full_name = selectedNames;
            return storeValue;
        });
    }

    function handleNameKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleNameSelect(event.target.value);
        }
    }

    const removeDiagnosis = (index) => {
        selectedDiagnoses = selectedDiagnoses.filter((_, i) => i !== index);
        searchQueries.update(storeValue => {
            storeValue.diagnosis = selectedDiagnoses;
            return storeValue;
        });
    };

    const handleDiagnosisSelect = (suggestion) => {
        // Add the selected suggestion to 'selectedDiagnoses' array
        selectedDiagnoses = [suggestion];
        diagnosis = ""; // Clear the diagnosis input field
        diagnosisSuggestions = []; // Clear the diagnosis suggestions list
        searchQueries.update(storeValue => {
            storeValue.diagnosis = selectedDiagnoses;
            return storeValue;
        });
    };

    const fetchDiagnosisSuggestions = async () => {
        try {
            const suggestion_resp = await suggestDiagnosis(diagnosis); // Make the API request for diagnosis suggestions

            if (Array.isArray(suggestion_resp['results'])) {
                diagnosisSuggestions = [...new Set(suggestion_resp['results'].map((item) => item.text))];
                console.log(diagnosisSuggestions);
            } else {
                // Handle the case where the response is not an array, e.g., an object or an error message
                console.error("Invalid diagnosis suggestions response:", suggestion_resp);
                diagnosisSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching diagnosis suggestions:", error);
        }
    };

    const handleDiagnosisInput = async () => {
        await fetchDiagnosisSuggestions(); // Fetch diagnosis suggestions based on the current 'diagnosis'
    };
</script>

<style>
    .search-container {
        padding: 1rem;
    }

    .radio-group {
        margin-bottom: 1rem;
    }

    .radio-option {
        margin: 0.5rem 0;
    }

    .search-criteria {
        border: 1px solid #ddd;
        padding: 1rem;
        border-radius: 4px;
    }

    .criteria-header {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 1rem;
        color: #233C6B;
    }

    .button-group {
        display: flex;
        gap: 0.5rem;
        margin-bottom: 1rem;
    }

    .button-group button {
        padding: 0.5rem 1rem;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .button-group button:hover {
        background-color: #1a2d50;
    }

    .input-group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .input-field {
        padding: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 0.9rem;
    }

    .input-field:focus {
        outline: none;
        border-color: #233C6B;
    }

    button {
        width: 120px;
        height: 50px;
        font-size: 14px;
    }

    .search-button {
        /*margin-top: 10px;*/
        background-color: #9d9d9f;
        color: #fff;
    }

    .clear-button {
        background-color: #233C6B;
        color: #fff;
    }

    .reset-button {
        background-color: #d98b95;
        color: #fff;
    }

    .selected-values-container {
        display: flex;
        flex-wrap: wrap; /* Allow values to wrap to the next line */
        max-width: 100vw; /* Ensure it doesn't exceed its parent's width */
    }

    /* Style each selected diagnosis */
    .selected-value {
        background-color: #233C6B;
        color: white;
        padding: 5px 10px;
        border-radius: 20px;
        margin-right: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }

    /* Style the "x" button for removing selections */
    .remove-selection {
        margin-left: 5px;
        cursor: pointer;
    }

    /* Add hover effect for suggestions */
    .suggestions {
        display: none;
        z-index: 1;
        background-color: #f9f9f9;
        border: 1px solid #ccc;
        border-radius: 5px;
        max-height: 150px;
        overflow-y: auto;
    }

    .suggestion-item {
        padding: 8px;
        cursor: pointer;
    }

    /* Limit the width of the type-ahead container */
    .type-ahead {
        width: 100%; /* Set a maximum width for the type-ahead container */
    }

    .type-ahead:hover .suggestions {
        display: block;
    }

    .search-criteria {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        margin-top: 0;
        margin-left: 20px;
        /*height: calc(75vh - 50px);*/
        overflow-x: auto;
    }

    .input-label {
        font-weight: bold;
        margin-top: 15px;
    }

    .input-field {
        width: 100%;
        padding: 10px;
    }
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .loading-spinner {
        width: 50px;
        height: 50px;
        border: 5px solid #f3f3f3;
        border-top: 5px solid #233C6B;
        border-radius: 50%;
        animation: spin 1s linear infinite;
    }

    .loading-text {
        margin-top: 1rem;
        color: #233C6B;
        font-weight: 600;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

</style>

<div class="search-container">
    {#if isLoading}
        <div class="loading-overlay">
            <div class="loading-content">
                <div class="loading-spinner"></div>
                <div class="loading-text">Fetching Assay Match Results...</div>
            </div>
        </div>
    {/if}
    <div class="radio-group">
        <div class="radio-option">
            <input
                    type="radio"
                    id="disease"
                    name="searchType"
                    value="disease"
                    bind:group={searchType}
                    on:change={setSearchAndClearResults}
            >
            <label for="disease">Search by Disease</label>
        </div>

        <div class="radio-option">
            <input
                    type="radio"
                    id="patient"
                    name="searchType"
                    value="patient"
                    bind:group={searchType}
                    on:change={setSearchAndClearResults}
            >
            <label for="patient">Search by Existing Patient</label>
        </div>
    </div>

    {#if searchType === 'disease'}
        <div class="search-criteria">
            <div class="criteria-header">Search Criteria</div>

            <div class="button-group">
                <button class="search-button" on:click={handleSearch}>Search</button>
                <button class="clear-button" on:click={clearResults}>Clear Results</button>
                <button class="reset-button" on:click={resetFilters}>Reset Filters</button>
            </div>

            <div class="input-group">
                <div class="input-label">Diagnosis</div>
                <div class="type-ahead">
                    <div class="selected-values-container">
                        {#each selectedDiagnoses as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeDiagnosis(index)}>x</span>
                </span>
                        {/each}
                    </div>
                    <input
                            type="text"
                            class="input-field"
                            bind:value={diagnosis}
                            on:input={handleDiagnosisInput}
                            on:click={handleDiagnosisInput}
                            placeholder="Type to search..."
                    />
                    <div class="suggestions">
                        {#each diagnosisSuggestions as suggestion (suggestion)}
                            <div class="suggestion-item"
                                 on:click={() => handleDiagnosisSelect(suggestion)}>{suggestion}</div>
                        {/each}
                    </div>
                </div>
            </div>
        </div>
    {:else if searchType === 'patient'}
        <div class="search-criteria">
            <div class="criteria-header">Search Criteria</div>

            <div class="button-group">
                <button class="search-button" on:click={handleSearch}>Search</button>
                <button class="clear-button" on:click={clearResults}>Clear Results</button>
                <button class="reset-button" on:click={resetFilters}>Reset Filters</button>
            </div>

            <div class="input-group">
                <div class="input-label">Patient Name</div>
                <div class="type-ahead">
                    <div class="selected-values-container">
                        {#each selectedNames as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeName(index)}>x</span>
            </span>
                        {/each}
                    </div>
                    <input
                            type="text"
                            class="input-field"
                            bind:value={names}
                            on:input={handleNameInput}
                            on:click={handleNameInput}
                            on:keydown={handleNameKeyDown}
                            placeholder="Type to search..."
                    />
                    <div class="suggestions">
                        {#each filteredNames as option (option)}
                            <div class="suggestion-item" on:click={() => handleNameSelect(option)}>{option}</div>
                        {/each}
                    </div>
                </div>

                <div class="input-label">MRN</div>
                <div class="type-ahead">
                    <div class="selected-values-container">
                        {#each selectedMRNs as value, index (value)}
            <span class="selected-value">
                {value}
                <span class="remove-selection" on:click={() => removeMRN(index)}>x</span>
            </span>
                        {/each}
                    </div>
                    <input
                            type="text"
                            class="input-field"
                            bind:value={mrns}
                            on:input={handleMRNInput}
                            on:click={handleMRNInput}
                            on:keydown={handleMRNKeyDown}
                            placeholder="Type to search..."
                    />
                    <div class="suggestions">
                        {#each filteredMRNOptions as option (option)}
                            <div class="suggestion-item" on:click={() => handleMRNSelect(option)}>{option}</div>
                        {/each}
                    </div>
                </div>
            </div>
        </div>
    {/if}
</div>