import {callApi} from "./rest";

const ROOT_URL_API = '/api';
const ROOT_URL_CONSULT = '/consult';
const ROOT_URL_CURATION = '/curation';
const ROOT_URL_ANALYTICS = '/analytics';

export const getCase = async (case_uuid) =>{
    const url = `${ROOT_URL_CONSULT}/cases/${case_uuid}`;

    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return await response.json();
};

export const getCasePatientMRN = async (patient_mrn) =>{
    let url = `${ROOT_URL_CONSULT}/cases`;

    const queryParams = new URLSearchParams();

    queryParams.append('patient_mrn',patient_mrn)
    queryParams.append('fields', 'uuid')

    url = `${url}?${queryParams.toString()}`;


    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    const responseData = await response.json();

    return responseData.results || [];
};

export const getPatient = async (patient_uuid) =>{
    const url = `${ROOT_URL_CONSULT}/patients/${patient_uuid}`;

    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return await response.json();
};


export const getLabResults = async (patient_uuid) =>{
    const url = `${ROOT_URL_CONSULT}/patients/${patient_uuid}/lab-results`;

    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    const responseData = await response.json();

    return responseData.results || [];

};

export const getSamples = async (patient_uuid) =>{
    const url = `${ROOT_URL_CONSULT}/patients/${patient_uuid}/samples`;

    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    const responseData = await response.json();

    return responseData.results || [];

};

export const getAnnotationsTimeCourse = async (case_uuid) =>{
    const url = `${ROOT_URL_API}/cases/${case_uuid}/annotations-time-courses?page_size=1000`;

    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    const responseData = await response.json();

    return responseData.results || [];

};

export const getAnnotationsAnalytics = async () =>{
    const url = `${ROOT_URL_ANALYTICS}/queries/history?page_size=50`;

    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    const responseData = await response.json();

    return responseData.results || [];

};

export const getAlteration = async (mutation_hash) =>{
    const url = `${ROOT_URL_API}/alterations/${mutation_hash}`
    const response = await callApi(url, "GET")
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    const responseData = await response.json();

    return responseData || [];

}

export const getMarkers = async (case_uuid) =>{
    const url = `${ROOT_URL_CONSULT}/cases/${case_uuid}/markers`;

    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    const responseData = await response.json();

    return responseData.results || [];

};



export const getCaseMatches = async (case_uuid) =>{
    const url = `${ROOT_URL_CONSULT}/cases/${case_uuid}/subset/all`;

    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return await response.json();
};

export const therapies = async (data) => {
    const url = `${ROOT_URL_API}/therapies/therapies`;
    const requestBody = new FormData();

    // requestBody.append('assay', data.assay);
    for (let assay of data.assay){
        requestBody.append('assay', assay);
    }

    for (let alt of data.alterations){
        requestBody.append('alterations', alt);
    }
    for (let disease of data.diseases){
        requestBody.append('diseases', disease)
    }
    requestBody.append('only_consolidated_therapies', true);
    requestBody.append('include_descendant_diseases', true);

    const response = await callApi(url, "POST", requestBody);

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return await response.json();
}

export const evidenceTherapies = async (case_uuid, data) => {
    const url = `${ROOT_URL_API}/cases/${case_uuid}/therapies`;
    const requestBody = new FormData();

    requestBody.append('data_set', 'JAX-CKB');
    requestBody.append('data_set', 'MCG');
    requestBody.append('match_type', 'full');
    requestBody.append('include_other_tumor_type', true);

    const response = await callApi(url, "POST", requestBody);

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return await response.json();
}

export const getContentsMatch = async (data) => {
    const url = `${ROOT_URL_API}/contents/match`;
    const requestBody = new FormData();

    for (let alt of data.alterations){
        requestBody.append('alterations', alt);
    }
    for (let disease of data.diseases){
        requestBody.append('diseases', disease)
    }

    const response = await callApi(url, "POST", requestBody);

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return await response.json();
}

export const getPatientAssayMatch = async (uuid) => {
    const url = `${ROOT_URL_CONSULT}/patients/${uuid}/assay-match`;
    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch assay match data');
    }
    return await response.json();
}

export const trials = async (data) => {
    const url = `${ROOT_URL_API}/trials/matches`;
    const requestBody = new FormData();

    if (data.distance){
        requestBody.append('distance_miles', data.distance)
    }

    if (data.zipcode){
        requestBody.append('zip', data.zipcode)
    }

    for (let assay of data.assay){
        requestBody.append('assay', assay);
    }
    for (let alt of data.alterations){
        requestBody.append('alterations', alt)
    }
    for (let disease of data.diseases){
        requestBody.append('diseases', disease)
    }
    for (let ds of data.disease_state){
        requestBody.append('current_disease_state', ds)
    }

    let pi_strings = [];
    let pi_match = data.oncologic_treatments.length > 0

    for (let oncologic_treatment of data.oncologic_treatments) {
        let drugs = oncologic_treatment.drugs ? oncologic_treatment.drugs : '';
        let disease_states = Array.isArray(oncologic_treatment.disease_state) && oncologic_treatment.disease_state.length > 0
            ? oncologic_treatment.disease_state.join(',')
            : 'unspecified';
        let ther_context = Array.isArray(oncologic_treatment.therapeutic_context) && oncologic_treatment.therapeutic_context.length > 0
            ? oncologic_treatment.therapeutic_context.join(',')
            : 'unspecified';
        pi_strings.push(drugs + '::' + disease_states + '::' + ther_context);
    }

    // de-dupe prior intervention list
    pi_strings = [...new Set(pi_strings)];
    let prior_interventions = pi_strings.join("\n");


    requestBody.append('prior_interventions', prior_interventions);
    requestBody.append('prior_intervention_match', pi_match);
    requestBody.append('gender', data.gender);
    requestBody.append('date_of_birth', data.date_of_birth);
    requestBody.append('institutional_trials_only', data.institutional_trials_only);
    requestBody.append('biomarker_match_type', data.biomarker_match_type);
    requestBody.append('exclude_untested_partial_matches', true);
    requestBody.append('recruiting_status', 'Recruiting');
    requestBody.append('recruiting_status', 'Not yet recruiting');
    requestBody.append('match_type', 'all');
    requestBody.append('current_therapeutic_context', data.therapeutic_context);
    requestBody.append('include_descendant_diseases', true);
    for (let country of data.countries){
        requestBody.append('country', country)
    }

    // requestBody.append('prior_intervention_match', '');
    // requestBody.append('prior_interventions', '');


    const response = await callApi(url, "POST", requestBody);

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
}

export const trialDetails = async (nctID) => {
    const url = `${ROOT_URL_API}/trials/${nctID}`;

    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return await response.json();
};

// Drug

export const getDrugBridge = async (drugs) => {
    let url = `${ROOT_URL_API}/drugs/drug_bridge`;

    const requestBody = new FormData();
    drugs.forEach(drug => {
        requestBody.append('drugs',drug)
    })

    const response = await callApi(url, "POST", requestBody);

    if (response.ok) {
        return await response.json();
    } else {
        console.error("Failed to fetch bulk drug bridge");
    }
}

export const bulkDrugDetail = async (drugs) => {
    let url = `${ROOT_URL_API}/drugs/bulk_detail`;

    const queryParams = new URLSearchParams();
    drugs.forEach(drug => {
        queryParams.append('name', drug);
    });
    queryParams.append('fields','definition')

    url = `${url}?${queryParams.toString()}`;

    const response = await callApi(url, "GET");

    if (response.ok) {
        return await response.json();
    } else {
        console.error("Failed to fetch bulk drug details");
    }
}


export const getInstitutions = async () =>{
    const url = `${ROOT_URL_CONSULT}/institutions/`;

    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return await response.json();
};

//Biomarker & Annotation Details

// Annotations
export const getAnnotationMatch = async (alteration, build) =>{
    const url = `${ROOT_URL_API}/annotations/match`;

    const requestBody = new FormData();

    requestBody.append('batch', alteration);
    requestBody.append('build', build)

    const response = await callApi(url, "POST", requestBody);

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return await response.json();
};

// Protein Effects
export const getProteinEffects = async (alteration) =>{
    const formattedAlteration = alteration.replace(/ /g, '+');

    const url = `${ROOT_URL_API}/protein_effects?alterations=${formattedAlteration}`;

    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return await response.json();
};

// Case Alteration Analytics
export const getCaseAlterationAnalytics = async (alteration) =>{
    const url = `${ROOT_URL_ANALYTICS}/queries/cases/count`;

    const requestBody = {
        filters: [{
            core: "cases",
            display: `Alterations: ${alteration}`,
            field: "canonical_alterations",
            operator: "All",
            type: "term",
            values: [{
                value: alteration
            }]
        }],
        "facet_field":"specific_diseases"
    };

    const response = await callApi(url, "POST", requestBody);

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    const responseData = await response.json();

    return responseData;
};


export const updateMarker = async (marker_uuid, isUncertain) => {
    const url = `${ROOT_URL_CONSULT}/markers/${marker_uuid}`;
    const requestBody = new FormData();

    requestBody.append('is_variant_of_uncertain_significance', isUncertain);

    const response = await callApi(url, "PUT", requestBody);

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
}

export const getTherapeuticContextList = async () => {
    const url = `${ROOT_URL_API}/settings/therapeutic_context`;

    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }

    const data = await response.json();

    // Extract the names from the results array
    return data.results.map(item => item.name);

};


export const updateTreatment = async (patient_uuid, pk, updatedTreatment) => {
    const url = `${ROOT_URL_CONSULT}/patients/${patient_uuid}/oncologic_treatments/${pk}`

    const response = await callApi(url, "PUT", updatedTreatment);

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
}

export const createTreatment = async (patient_uuid, newTreatment) => {
    const url = `${ROOT_URL_CONSULT}/patients/${patient_uuid}/oncologic_treatments/`

    const response = await callApi(url, "POST", newTreatment);

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();}


export const removeTreatment = async (patient_uuid, pk) => {
    const url = `${ROOT_URL_CONSULT}/patients/${patient_uuid}/oncologic_treatments/${pk}`

    return await callApi(url, "DELETE");
}

export const updatePatient = async (patient_uuid, tc) => {
    const url = `${ROOT_URL_CONSULT}/patients/${patient_uuid}`
    const requestBody = new FormData();

    requestBody.append('therapeutic_context', tc);
    const response = await callApi(url, "PUT", requestBody);

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
}

export const updateCase = async (case_uuid, data) => {
    const url = `${ROOT_URL_CONSULT}/patients/${case_uuid}`

    const requestBody = new FormData();
    for (const [key, dataField] of Object.entries(data)) {
        if (Object.keys(dataField).length > 0) {
            if (key === 'patient_name') {
                requestBody.append('first_name', dataField.value.split(" ")[0])
                requestBody.append('last_name', dataField.value.split(" ")[1])
            } else if (key === 'patient_mrn') {
                requestBody.append('patient_mrn', dataField.value)
            }else if (key === 'gender') {
                requestBody.append('gender', dataField.value)
            } else if (key === 'date_of_birth') {
                requestBody.append('date_of_birth', dataField.value)
            }
        }
    }

    if ([...requestBody.entries()].length > 0) {
        const response = await callApi(url, "PUT", requestBody);

        // Parse response
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return response.json();
    }
    else {
        return true;
    }
}

export const updatePatientMeta = async (c, data) => {
    const url = `${ROOT_URL_CONSULT}/cases/${c.uuid}`

    const requestBody = new FormData();
    let metaDict = {}
    for (const [key, dataField] of Object.entries(data)) {
        if (Object.keys(dataField).length > 0) {
            if (key === 'patient_meta_data') {
                Object.keys(dataField).forEach((k) => {
                    if(k === 'ICD10'){
                        const ICD10_codes = [];
                        const ICD10_descriptions = [];
                        dataField[k].value.forEach(item => {
                            const [code, description] = item.split(" - ", 2);
                            ICD10_codes.push(code);
                            ICD10_descriptions.push(description ? description.trim() : '');
                        })
                        metaDict['ICD10__code']=ICD10_codes
                        metaDict['ICD10__description']=ICD10_descriptions
                    }
                    else{
                        metaDict[k] = dataField[k].value;
                    }
                });
            }
            if (key === 'cancer_icd10_codes'){
                metaDict['ICD10__Cancer__code'] = dataField.value;
            }
        }
    }

    if (c.meta){
        for (const [key, value] of Object.entries(c.meta)) {
            if (!metaDict.hasOwnProperty(key)){
                metaDict[key] = value;
            }
        }
    }

    requestBody.append('meta', JSON.stringify(metaDict))

    if (Object.entries(data).length > 0) {
        const response = await callApi(url, "PUT", requestBody);

        // Parse response
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return response.json();
    }
    else {
        return true;
    }
}


export const updatePatientDiagnosis = async (case_uuid, data) => {
    const url = `${ROOT_URL_CONSULT}/cases/${case_uuid}/load-entities`

    let requestBody = null

    for (const [key, dataField] of Object.entries(data)) {
        if (Object.keys(dataField).length > 0) {
            if (key === 'diagnoses') {
                requestBody = {diagnoses:[]}
                dataField.value.forEach(diag => {
                    let diagDict = {}
                    diagDict['disease'] = diag.diagnosis
                    if (diag.stage) {
                        diagDict['stage'] = diag.stage;
                    }
                    if (diag.date_of_diagnosis && diag.date_of_diagnosis !== 'N/A' ){
                        diagDict['date_of_diagnosis'] = diag.date_of_diagnosis;
                    }
                    requestBody['diagnoses'].push(diagDict)
                })
            }
        }
    }

    if(requestBody) {

        const response = await callApi(url, "PUT", requestBody);

        // Parse response
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        return response.json();
    }
    else {
        return true;
    }
}


export const getCaseAttachments = async (case_uuid) =>{
    const url = `${ROOT_URL_CONSULT}/cases/${case_uuid}/attachments`;

    const response = await callApi(url, "GET");

    // Parse response
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    const responseData = await response.json();

    return responseData.results || [];
};


export const uploadCaseAttachment = async (c, file) => {
    const url = `${ROOT_URL_CONSULT}/cases/${c.uuid}/attachments/upload`;

    const requestBody = new FormData();
    requestBody.append('file_url', file);
    requestBody.append('case_id', c.id);

    const response = await callApi(url, "POST", requestBody);
    if (!response.ok) {
        const errorMessage = await response.text();
        return { success: false, message: errorMessage };
    }
    return { success: true };
};


export const uploadLabResultAttachment = async (file, lrUUID, patientUUID, sampleUUID) => {
    const url = `${ROOT_URL_CONSULT}/patients/lab-result-attachments`;

    const requestBody = new FormData();
    requestBody.append('file_upload', file);

    requestBody.append('arguments', "patient_uuid=" + patientUUID + "|sample_uuid="+sampleUUID + "|lab_result_uuid="+lrUUID);


    const response = await callApi(url, "POST", requestBody);
    if (!response.ok) {
        const errorMessage = await response.text();
        return { success: false, message: errorMessage };
    }
    return { success: true };
};

export const uploadSampleAttachment = async (file, lrUUID, patientUUID, sampleUUID) => {
    const url = `${ROOT_URL_CONSULT}/patients/sample-attachments`;

    const requestBody = new FormData();
    requestBody.append('file_upload', file);

    requestBody.append('arguments', "sample_uuid="+sampleUUID);


    const response = await callApi(url, "POST", requestBody);
    if (!response.ok) {
        const errorMessage = await response.text();
        return { success: false, message: errorMessage };
    }
    return { success: true };
};


export const getSampleAttachment = async (attachmentID) => {
    const url = `${ROOT_URL_CONSULT}/patients/sample-attachments/${attachmentID}`;


    const response = await callApi(url, "GET");
    if (!response.ok) {
        const errorMessage = await response.text();
        return { success: false, message: errorMessage };
    }
    const blob = await response.blob();
    return URL.createObjectURL(blob);
};

export const getLabResultAttachment = async (attachmentID) => {
    const url = `${ROOT_URL_CONSULT}/patients/lab-result-attachments/${attachmentID}`;


    const response = await callApi(url, "GET");
    if (!response.ok) {
        const errorMessage = await response.text();
        return { success: false, message: errorMessage };
    }
    const blob = await response.blob();
    return URL.createObjectURL(blob);
};

