import { writable } from 'svelte/store';

const instTrialSearchQueries= {
    patient_name: '',
    patient_mrn: '',
    protocol_id: '',
    nct_id: '',
    eligibility_name: '',
    recruiting_status: '',
    neg_markers: '',
    drugs: '',
    genes: '',
    disease_states: '',
    diseases: '',
    alterations: ''
};

export let searchQueries = writable(instTrialSearchQueries);

export let totalTrials = writable('');

export let matchedTrials = writable('');

export let inFilterView = writable(false);

export let changesDetected = writable(false)