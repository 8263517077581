<script>
    import {onMount} from 'svelte';
    import {gatherFacetInfo} from "../../network/inst_trials";
    import {searchQueries, totalTrials, inFilterView} from '../../store/instTrialSearchQueries.js';

    export let fetchSearchTrials;
    export let fetchResetTrials;

    export let fetchClearFilters;

    let mrns = '';
    let patientMRNs = [];
    let selectedMRNs = [];
    let filteredMRNOptions = patientMRNs;
    let names = '';
    let patientNames = [];
    let selectedNames = [];
    let filteredNames = patientNames;
    let protocol_ids = '';
    let protocolIds = [];
    let selectedProtocolIds = [];
    let filteredProtocolIds = protocolIds;
    let nct_ids = '';
    let nctIds = [];
    let selectedNCTIds = [];
    let filteredNCTIds = nctIds;
    let elig_names = '';
    let eligNames = [];
    let selectedEligNames = [];
    let filteredEligNames = eligNames;
    let statuses = '';
    let recruiting_statuses = [];
    let selectedRecruitingStatus = [];
    let filteredRecruitingStatus = recruiting_statuses;
    let markers = '';
    let biomarkers = [];
    let selectedBiomarkers = [];
    let filteredBiomarkers = biomarkers;
    let dis = '';
    let diseases = [];
    let selectedDiseases = [];
    let filteredDiseases = diseases;
    let ds = '';
    let disease_states = [];
    let selectedDiseaseStates = [];
    let filteredDiseaseStates = disease_states;
    let drug = '';
    let drugs = [];
    let selectedDrugs = [];
    let filteredDrugs = drugs;
    let alts = '';
    let alterations = [];
    let selectedAlterations = [];
    let filteredAlterations = alterations;

    $: if ($inFilterView === false) {
        selectedNames = [];
        selectedMRNs = [];
        selectedBiomarkers = [];
        selectedAlterations = [];
        selectedDrugs = [];
        selectedDiseases = [];
        selectedDiseaseStates = [];
        selectedRecruitingStatus = [];
        selectedEligNames = [];
        selectedProtocolIds = [];
        selectedNCTIds = [];
    }

    // For example, you can call it when the component is initialized
    onMount(() => {
        fetchTrialFacetInfo();
    });

    const fetchTrialFacetInfo = async () => {
        try {
            const facetInfoResponse = await gatherFacetInfo();

            console.log(facetInfoResponse);
            totalTrials.update(storeValue => {
                storeValue = facetInfoResponse["total_trials"];
                return storeValue;
            });
            if (Array.isArray(facetInfoResponse['patient_mrns'])) {
                patientMRNs = facetInfoResponse['patient_mrns'];
            }
            if (Array.isArray(facetInfoResponse['patient_names'])) {
                patientNames = facetInfoResponse['patient_names'];
            }
            if (Array.isArray(facetInfoResponse['protocol_ids'])) {
                protocolIds = facetInfoResponse['protocol_ids'];
            }
            if (Array.isArray(facetInfoResponse['nct_ids'])) {
                nctIds = facetInfoResponse['nct_ids'];
            }
            if (Array.isArray(facetInfoResponse['eligibility_names'])) {
                eligNames = facetInfoResponse['eligibility_names'];
            }
            if (Array.isArray(facetInfoResponse['recruiting_statuses'])) {
                recruiting_statuses = facetInfoResponse['recruiting_statuses'];
            }
            if (Array.isArray(facetInfoResponse['biomarkers'])) {
                biomarkers = facetInfoResponse['biomarkers'];
            }
            if (Array.isArray(facetInfoResponse['diseases'])) {
                diseases = facetInfoResponse['diseases'];
            }
            if (Array.isArray(facetInfoResponse['disease_states'])) {
                disease_states = facetInfoResponse['disease_states'];
            }
            if (Array.isArray(facetInfoResponse['drugs'])) {
                drugs = facetInfoResponse['drugs'];
            }
            if (Array.isArray(facetInfoResponse['alterations'])) {
                alterations = facetInfoResponse['alterations'];
            }
        } catch (error) {
            console.error("Error fetching trial facet info:", error);
        }
    };

    const removeDisease = (index) => {
        selectedDiseases = selectedDiseases.slice(0, index).concat(selectedDiseases.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.diseases = selectedDiseases;
            return storeValue;
        });
    };

    const removeDiseaseState = (index) => {
        selectedDiseaseStates = selectedDiseaseStates.slice(0, index).concat(selectedDiseaseStates.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.disease_states = selectedDiseaseStates;
            return storeValue;
        });
    };

    const removeDrug = (index) => {
        selectedDrugs = selectedDrugs.slice(0, index).concat(selectedDrugs.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.drugs = selectedDrugs;
            return storeValue;
        });
    };

    const removeBiomarker = (index) => {
        selectedBiomarkers = selectedBiomarkers.slice(0, index).concat(selectedBiomarkers.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.genes = selectedBiomarkers;
            return storeValue;
        });
    };

    const removeAlteration = (index) => {
        selectedAlterations = selectedAlterations.slice(0, index).concat(selectedAlterations.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.alterations = selectedAlterations;
            return storeValue;
        });
    };

    function removeName(index) {
        selectedNames = selectedNames.slice(0, index).concat(selectedNames.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.patient_name = selectedNames;
            return storeValue;
        });
    }

    function removeMRN(index) {
        selectedMRNs = selectedMRNs.slice(0, index).concat(selectedMRNs.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.patient_mrn = selectedMRNs;
            return storeValue;
        });
    }

    function removeRecruitingStatus(index) {
        selectedRecruitingStatus = selectedRecruitingStatus.slice(0, index).concat(selectedRecruitingStatus.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.recruiting_status = selectedRecruitingStatus;
            return storeValue;
        });
    }

    function removeProtocolId(index) {
        selectedProtocolIds = selectedProtocolIds.slice(0, index).concat(selectedProtocolIds.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.protocol_id = selectedProtocolIds;
            return storeValue;
        });
    }

    function removeNctID(index) {
        selectedNCTIds = selectedNCTIds.slice(0, index).concat(selectedNCTIds.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.nct_id = selectedNCTIds;
            return storeValue;
        });
    }

    function removeEligName(index) {
        selectedEligNames = selectedEligNames.slice(0, index).concat(selectedEligNames.slice(index + 1));
        searchQueries.update(storeValue => {
            storeValue.eligibility_name = selectedEligNames;
            return storeValue;
        });
    }

    function handleDiseaseInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredDiseases = diseases.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleDiseaseStateInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredDiseaseStates = disease_states.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleDrugInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredDrugs = drugs.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleBiomarkerInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredBiomarkers = biomarkers.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleAlterationInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredAlterations = alterations.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleMRNInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredMRNOptions = patientMRNs.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleNameInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredNames = patientNames.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleProtocolIdInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredProtocolIds = protocolIds.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleNctIdInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredNCTIds = nctIds.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleRecruitingStatusInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredRecruitingStatus = recruiting_statuses.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleEligNameInput(event) {
        const inputText = event.target.value.toLowerCase();
        filteredEligNames = eligNames.filter(option =>
            option.toLowerCase().includes(inputText)
        );
    }

    function handleDiseaseSelect(option) {
        selectedDiseases = [...selectedDiseases, option];
        dis = '';
        filteredDiseases = diseases;
        searchQueries.update(storeValue => {
            storeValue.diseases = selectedDiseases;
            return storeValue;
        });
    }

    function handleDiseaseStateSelect(option) {
        selectedDiseaseStates = [...selectedDiseaseStates, option];
        ds = '';
        filteredDiseaseStates = disease_states;
        searchQueries.update(storeValue => {
            storeValue.disease_states = selectedDiseaseStates;
            return storeValue;
        });
    }

    function handleDrugSelect(option) {
        selectedDrugs = [...selectedDrugs, option];
        drug = '';
        filteredDrugs = drugs;
        searchQueries.update(storeValue => {
            storeValue.drugs = selectedDrugs;
            return storeValue;
        });
    }

    function handleBiomarkerSelect(option) {
        selectedBiomarkers = [...selectedBiomarkers, option];
        markers = '';
        filteredBiomarkers = biomarkers;
        searchQueries.update(storeValue => {
            storeValue.genes = selectedBiomarkers;
            return storeValue;
        });
    }

    function handleAlterationSelect(option) {
        selectedAlterations = [...selectedAlterations, option];
        alts = '';
        filteredAlterations = alterations;
        searchQueries.update(storeValue => {
            storeValue.alterations = selectedAlterations;
            return storeValue;
        });
    }

    function handleNameSelect(option) {
        selectedNames = [option];
        names = '';
        filteredNames = patientNames;
        searchQueries.update(storeValue => {
            storeValue.patient_name = selectedNames;
            return storeValue;
        });
    }

    function handleMRNSelect(option) {
        selectedMRNs = [option];
        mrns = '';
        filteredMRNOptions = patientMRNs;
        searchQueries.update(storeValue => {
            storeValue.patient_mrn = selectedMRNs;
            return storeValue;
        });
    }

    function handleRecruitingStatusSelect(option) {
        selectedRecruitingStatus = [option];
        statuses = '';
        filteredRecruitingStatus = recruiting_statuses;
        searchQueries.update(storeValue => {
            storeValue.recruiting_status = selectedRecruitingStatus;
            return storeValue;
        });
    }

    function handleProtocolIdSelect(option) {
        selectedProtocolIds = [option];
        protocol_ids = '';
        filteredProtocolIds = protocolIds;
        searchQueries.update(storeValue => {
            storeValue.protocol_id = selectedProtocolIds;
            return storeValue;
        });
    }

    function handleNctIdSelect(option) {
        selectedNCTIds = [option];
        nct_ids = '';
        filteredNCTIds = nctIds;
        searchQueries.update(storeValue => {
            storeValue.nct_id = selectedNCTIds;
            return storeValue;
        });
    }

    function handleEligNameSelect(option) {
        selectedEligNames = [option];
        elig_names = '';
        filteredEligNames = eligNames;
        searchQueries.update(storeValue => {
            storeValue.eligibility_name = selectedEligNames;
            return storeValue;
        });
    }

    function handleDiseaseKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleDiseaseSelect(event.target.value);
        }
    }

    function handleDiseaseStateKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleDiseaseStateSelect(event.target.value);
        }
    }

    function handleDrugKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleDrugSelect(event.target.value);
        }
    }

    function handleBiomarkerKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleBiomarkerSelect(event.target.value);
        }
    }

    function handleAlterationKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleAlterationSelect(event.target.value);
        }
    }

    function handleMRNKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleMRNSelect(event.target.value);
        }
    }

    function handleNameKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleNameSelect(event.target.value);
        }
    }

    function handleRecruitingStatusKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleRecruitingStatusSelect(event.target.value);
        }
    }

    function handleProtocolIdKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleProtocolIdSelect(event.target.value);
        }
    }

    function handleNctIdKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleNctIdSelect(event.target.value);
        }
    }

    function handleEligNameKeyDown(event) {
        if (event.key === 'Enter') {
            // The "Enter" key was pressed
            event.preventDefault(); // Prevent the default form submission (if applicable)
            handleEligNameSelect(event.target.value);
        }
    }

    function handleSearchSubmit() {
        fetchSearchTrials();
    }

    function handleSearchResetFilters() {
        selectedNames = [];
        selectedMRNs = [];
        selectedProtocolIds = [];
        selectedNCTIds = [];
        selectedEligNames = [];
        selectedDiseases = [];
        selectedBiomarkers = [];
        selectedAlterations = [];
        selectedDiseaseStates = [];
        selectedDrugs = [];
        selectedRecruitingStatus = [];
        fetchClearFilters();
    }

    function handleSearchClearResults() {
        selectedNames = [];
        selectedMRNs = [];
        selectedProtocolIds = [];
        selectedNCTIds = [];
        selectedEligNames = [];
        selectedDiseases = [];
        selectedBiomarkers = [];
        selectedAlterations = [];
        selectedDiseaseStates = [];
        selectedDrugs = [];
        selectedRecruitingStatus = [];
        fetchResetTrials();
    }

</script>

<style>
    button {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }

    .search-criteria {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        margin-top: 0;
        margin-left: 20px;
        height: calc(70vh - 50px);
        overflow-x: auto;
    }

    .input-label {
        font-weight: bold;
        margin-top:15px;
    }

    .input-field {
        width: 100%;
        padding: 10px;
    }

    .selected-values-container {
        display: flex;
        flex-wrap: wrap; /* Allow values to wrap to the next line */
        max-width: 100vw; /* Ensure it doesn't exceed its parent's width */
    }

    /* Style each selected diagnosis */
    .selected-value {
        background-color: #233C6B;
        color: white;
        padding: 5px 10px;
        border-radius: 20px;
        margin-right: 5px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
    }

    /* Style the "x" button for removing selections */
    .remove-selection {
        margin-left: 5px;
        cursor: pointer;
    }

    /* Add hover effect for suggestions */
    .suggestions {
        display: none;
        z-index: 1;
        background-color: #f9f9f9;
        border: 1px solid #ccc;
        border-radius: 5px;
        max-height: 150px;
        overflow-y: auto;
    }

    .suggestion-item {
        padding: 8px;
        cursor: pointer;
    }

    /* Limit the width of the type-ahead container */
    .type-ahead {
        width: 100%; /* Set a maximum width for the type-ahead container */
    }

    .type-ahead:hover .suggestions {
        display: block;
    }

    .search-button {
        /*margin-top: 10px;*/
        background-color: #9d9d9f;
        color: #fff;
    }

    .clear-button {
        background-color: #233C6B;
        color: #fff;
    }

    .reset-button {
        background-color: #d98b95;
        color: #fff;
    }

    .header {
        position: sticky;
        top: 0;
        background: white;
        z-index: 1;
        padding: 10px;
        border-bottom: 1px solid #ccc;
    }

    .scrollable-content {
        flex-grow: 1;
        overflow-y: auto;
        padding: 10px;
    }

</style>

<div class="search-criteria">
    <div class="header">
        <h2>Search Criteria</h2>

        <button class="search-button" on:click={handleSearchSubmit}>Search</button>
        <button class="clear-button" on:click={handleSearchClearResults}>Clear Results</button>
        <button class="reset-button" on:click={handleSearchResetFilters}>Reset Filters</button>
    </div>

    <div class="scrollable-content">
        <div class="input-label">Protocol ID</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedProtocolIds as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeProtocolId(index)}>x</span>
                </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={protocol_ids}
                    on:input={handleProtocolIdInput}
                    on:click={handleProtocolIdInput}
                    on:keydown={handleProtocolIdKeyDown}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each filteredProtocolIds as option (option)}
                    <div class="suggestion-item" on:click={() => handleProtocolIdSelect(option)}>{option}</div>
                {/each}
            </div>
        </div>

        <div class="input-label">NCT ID</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedNCTIds as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeNctID(index)}>x</span>
                </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={nct_ids}
                    on:input={handleNctIdInput}
                    on:click={handleNctIdInput}
                    on:keydown={handleNctIdKeyDown}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each filteredNCTIds as option (option)}
                    <div class="suggestion-item" on:click={() => handleNctIdSelect(option)}>{option}</div>
                {/each}
            </div>
        </div>

        <div class="input-label">Eligibility Name</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedEligNames as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeEligName(index)}>x</span>
                </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={elig_names}
                    on:input={handleEligNameInput}
                    on:click={handleEligNameInput}
                    on:keydown={handleEligNameKeyDown}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each filteredEligNames as option (option)}
                    <div class="suggestion-item" on:click={() => handleEligNameSelect(option)}>{option}</div>
                {/each}
            </div>
        </div>

        <div class="input-label">Recruiting Status</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedRecruitingStatus as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeRecruitingStatus(index)}>x</span>
                </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={statuses}
                    on:input={handleRecruitingStatusInput}
                    on:click={handleRecruitingStatusInput}
                    on:keydown={handleRecruitingStatusKeyDown}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each filteredRecruitingStatus as option (option)}
                    <div class="suggestion-item" on:click={() => handleRecruitingStatusSelect(option)}>{option}</div>
                {/each}
            </div>
        </div>

        <div class="input-label">Drugs</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedDrugs as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeDrug(index)}>x</span>
                </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={drug}
                    on:input={handleDrugInput}
                    on:click={handleDrugInput}
                    on:keydown={handleDrugKeyDown}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each filteredDrugs as option (option)}
                    <div class="suggestion-item" on:click={() => handleDrugSelect(option)}>{option}</div>
                {/each}
            </div>
        </div>

        <div class="input-label">Disease States</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedDiseaseStates as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeDiseaseState(index)}>x</span>
                </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={ds}
                    on:input={handleDiseaseStateInput}
                    on:click={handleDiseaseStateInput}
                    on:keydown={handleDiseaseStateKeyDown}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each filteredDiseaseStates as option (option)}
                    <div class="suggestion-item" on:click={() => handleDiseaseStateSelect(option)}>{option}</div>
                {/each}
            </div>
        </div>

        <div class="input-label">Diseases</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedDiseases as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeDisease(index)}>x</span>
                </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={dis}
                    on:input={handleDiseaseInput}
                    on:click={handleDiseaseInput}
                    on:keydown={handleDiseaseKeyDown}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each filteredDiseases as option (option)}
                    <div class="suggestion-item" on:click={() => handleDiseaseSelect(option)}>{option}</div>
                {/each}
            </div>
        </div>

        <div class="input-label">Genes</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedBiomarkers as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeBiomarker(index)}>x</span>
                </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={markers}
                    on:input={handleBiomarkerInput}
                    on:click={handleBiomarkerInput}
                    on:keydown={handleBiomarkerKeyDown}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each filteredBiomarkers as option (option)}
                    <div class="suggestion-item" on:click={() => handleBiomarkerSelect(option)}>{option}</div>
                {/each}
            </div>
        </div>

        <div class="input-label">Alterations</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedAlterations as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeAlteration(index)}>x</span>
                </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={alts}
                    on:input={handleAlterationInput}
                    on:click={handleAlterationInput}
                    on:keydown={handleAlterationKeyDown}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each filteredAlterations as option (option)}
                    <div class="suggestion-item" on:click={() => handleAlterationSelect(option)}>{option}</div>
                {/each}
            </div>
        </div>

        <div class="input-label">Patient Name</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedNames as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeName(index)}>x</span>
                </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={names}
                    on:input={handleNameInput}
                    on:click={handleNameInput}
                    on:keydown={handleNameKeyDown}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each filteredNames as option (option)}
                    <div class="suggestion-item" on:click={() => handleNameSelect(option)}>{option}</div>
                {/each}
            </div>
        </div>

        <div class="input-label">MRN</div>
        <div class="type-ahead">
            <div class="selected-values-container">
                {#each selectedMRNs as value, index (value)}
                <span class="selected-value">
                    {value}
                    <span class="remove-selection" on:click={() => removeMRN(index)}>x</span>
                </span>
                {/each}
            </div>
            <input
                    type="text"
                    class="input-field"
                    bind:value={mrns}
                    on:input={handleMRNInput}
                    on:click={handleMRNInput}
                    on:keydown={handleMRNKeyDown}
                    placeholder="Type to search..."
            />
            <div class="suggestions">
                {#each filteredMRNOptions as option (option)}
                    <div class="suggestion-item" on:click={() => handleMRNSelect(option)}>{option}</div>
                {/each}
            </div>
        </div>
    </div>
</div>
