<script>
    import Modal from "../Modal.svelte";
    import {
        createPatientTrackingStatusComment,
        getPatientTrackingStatuses,
        updatePatientTrackingStatus,
        bulkUpdatePatientTrackingStatus
    } from "../../network/patient_search";
    import {
        getAlertConfig
    } from "../../network/user";
    import {pushFail, pushSuccess} from "../../utils/toast";
    import {onMount} from "svelte";
    import {getUser} from "../../store/session";
    import TreatmentPlanTable from "./TreatmentPlanTable.svelte";
    import {faCircleXmark as faCircleXmarkRegular} from "@fortawesome/free-regular-svg-icons";
    import {Icon} from "svelte-awesome";

    let patientActionModal;
    let commentModal;
    let currentTrackingStatus;
    let alert_config = {};
    let patient_tracking_statuses = [];
    let filtered_tracking_statuses = [];
    let comments = [];
    let selectedOption = '';
    let selectedBulkOption = '';
    let wait = false;
    let candidate = false;
    let archive = false;
    let no_track = false;
    let all = false;
    let selected = false;
    let ordering = '';
    let commentValue = '';
    let checkedRows = new Map();
    let patientExportModal;
    let isBulkUpdate = false;

    export let page;
    export let refreshTable;
    export let config_id;
    export let isTrackView;
    export let filter;

    // Simulate fetching data from an API
    const fetchData = async () => {
        alert_config = await getAlertConfig(config_id);
        comments = alert_config.track_comments;
        let patient_tracking_statuses_summary = await getPatientTrackingStatuses(alert_config.name);
        patient_tracking_statuses = patient_tracking_statuses_summary.results;
        filterStatuses(patient_tracking_statuses);
        checkedRows = new Map(filtered_tracking_statuses.map(data => [data.id, false]));
    };

    function filterStatuses(patient_tracking_statuses) {
        if (filter === "tracked") {
            filtered_tracking_statuses = patient_tracking_statuses.filter(item => item.alert_config === alert_config.id && item.tracked && (item.is_waiting || item.needs_review));
        } else if (filter === "review") {
            filtered_tracking_statuses = patient_tracking_statuses.filter(item => item.alert_config === alert_config.id && item.tracked && item.needs_review);
        } else if (filter === "waiting") {
            filtered_tracking_statuses = patient_tracking_statuses.filter(item => item.alert_config === alert_config.id && item.tracked && item.is_waiting);
        } else if (filter === "candidate") {
            filtered_tracking_statuses = patient_tracking_statuses.filter(item => item.alert_config === alert_config.id && item.is_candidate && item.tracked);
        } else if (filter === "archived") {
            filtered_tracking_statuses = patient_tracking_statuses.filter(item => item.alert_config === alert_config.id && item.is_archived && item.tracked)
        } else if (filter === "not_tracked") {
            filtered_tracking_statuses = patient_tracking_statuses.filter(item => item.alert_config === alert_config.id && !item.tracked && !item.needs_more_review);
        }
    }

    function formatParameters(diseases, alts, genes) {
        let finalString = "";
        if (diseases && diseases.length !== 0) {
            finalString = finalString + "<b>Diseases:</b> " + diseases.join(", ");
        }
        if (alts && alts.length !== 0) {
            finalString = finalString + "<br><b>Alterations:</b> " + alts.join(", ");
        }
        if (genes && genes.length !== 0) {
            finalString = finalString + "<br><b>Genes:</b> " + genes.join(", ");
        }
        return finalString;
    }

    function formatParametersForExport(diseases, alts, genes) {
        let finalString = "";
        if (diseases && diseases.length !== 0) {
            finalString = finalString + "Diseases: " + diseases.join(", ");
        }
        if (alts && alts.length !== 0) {
            if (finalString !== "") {
                finalString = finalString + ", Alterations: " + alts.join(", ");
            } else {
                finalString = finalString + "Alterations: " + alts.join(", ");
            }
        }
        if (genes && genes.length !== 0) {
            if (finalString !== "") {
                finalString = finalString + ", Genes: " + genes.join(", ");
            } else {
                finalString = finalString + "Genes: " + genes.join(", ");
            }
        }
        return finalString;
    }

    function getStatus(row) {
        if (row.is_waiting) {
            return "Waiting";
        } else {
            return "Needs Review";
        }
    }

    function handlePatientActionModalOpen(track_status) {
        isBulkUpdate = false;
        currentTrackingStatus = track_status;
        patientActionModal.open();
    }

    function handleBulkPatientActionModalOpen() {
        isBulkUpdate = true;
        patientActionModal.open();
    }

    function handleCommentModalOpen(track_status) {
        currentTrackingStatus = track_status;
        commentModal.open();
    }

    function handleCheckboxChange(event, option) {
        selectedOption = option;
        if (option === 'wait') {
            wait = event.target.checked;
            candidate = false;
            archive = false;
            no_track = false;
        } else if (option === 'candidate') {
            wait = false;
            candidate = event.target.checked;
            archive = false;
            no_track = false;
        } else if (option === 'archive') {
            wait = false;
            candidate = false;
            archive = event.target.checked;
            no_track = false;
        } else if (option === 'no track') {
            wait = false;
            candidate = false;
            archive = false;
            no_track = event.target.checked;
        }
    }

    function handleBulkCheckboxChange(event, option) {
        selectedBulkOption = option;
        if (option === 'all') {
            all = event.target.checked;
            selected = false;
        } else if (option === 'selected') {
            all = false;
            selected = event.target.checked;
        }
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    }

    function formatTime(datetimeString) {
        let date = new Date(datetimeString);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    }

    const onPatientAction = async () => {
        if (isBulkUpdate) {
            if (selectedOption !== '' && selectedBulkOption !== '') {
                let track_ids;
                if (selectedBulkOption === "selected") {
                    track_ids = getCheckedRows();
                } else {
                    track_ids = filtered_tracking_statuses.map(obj => obj.id);
                }
                let updateFields = getUpdateFields();
                let body = [];
                track_ids.forEach((id) => {
                    let finalObject = {"id": id};
                    finalObject = {...finalObject, ...updateFields};
                    body = [...body, finalObject];
                });
                let response = await bulkUpdatePatientTrackingStatus(body);
                if (response.ok) {
                    patientActionModal.close();
                    refreshTable = !refreshTable;
                    wait = false;
                    candidate = false;
                    archive = false;
                    no_track = false;
                    all = false;
                    selected = false;
                    await fetchData();
                    pushSuccess("Successfully bulk updated patient tracking statuses.");
                } else {
                    patientActionModal.close();
                    pushFail("Failed to bulk update patient tracking statuses.");
                }
            } else {
                pushFail("Must select at least one bulk option and one decision.");
            }
        } else {
            if (selectedOption !== '') {
                let body = getUpdateFields();
                let response = await updatePatientTrackingStatus(currentTrackingStatus.id, body);
                if (response.ok) {
                    patientActionModal.close();
                    refreshTable = !refreshTable;
                    wait = false;
                    candidate = false;
                    archive = false;
                    no_track = false;
                    all = false;
                    selected = false;
                    await fetchData();
                    pushSuccess("Successfully updated patient tracking.");
                } else {
                    patientActionModal.close();
                    pushFail("Failed to update patient tracking.");
                }
            } else {
                pushFail("Must select at least one option.");
            }
        }
    }

    function getUpdateFields() {
        let body = {};
        if (selectedOption === 'wait') {
            body = {
                "tracked": true,
                "is_waiting": true,
                "is_candidate": false,
                "is_archived": false,
                "needs_review": false
            };
        } else if (selectedOption === 'candidate') {
            body = {
                "tracked": true,
                "is_waiting": false,
                "is_candidate": true,
                "is_archived": false,
                "needs_review": false
            };
        } else if (selectedOption === 'archive') {
            body = {
                "tracked": true,
                "is_waiting": false,
                "is_candidate": false,
                "is_archived": true,
                "needs_review": false
            };
        } else if (selectedOption === "not track") {
            body = {
                "tracked": false,
                "is_waiting": false,
                "is_candidate": false,
                "is_archived": false,
                "needs_review": false
            };
        }
        return body;
    }

    const onSaveComment = async () => {
        if (commentValue !== '') {
            const comment_body = {
                "comment": commentValue,
                "user_created": getUser().first_name + " " + getUser().last_name,
                "alert_config": currentTrackingStatus.alert_config,
                "patient": currentTrackingStatus.patient
            };
            let comment_response = await createPatientTrackingStatusComment(comment_body);
            if (comment_response.ok) {
                commentValue = '';
                commentModal.close();
                refreshTable = !refreshTable;
                await fetchData();
                pushSuccess("Successfully created tracked patient comment.");
            } else {
                commentValue = '';
                commentModal.close();
                pushFail("Failed to create tracked patient comment.");
            }
        }
    }

    function sortPatients(property) {
        console.log(property);
        if (property === ordering || `-${property}` === ordering) {
            if (ordering.startsWith('-')) {
                ordering = '';
            } else {
                ordering = `-${property}`;
            }
        } else {
            ordering = property;
        }
        if (ordering === 'name') {
            filtered_tracking_statuses = filtered_tracking_statuses.sort((a, b) => a.patient_name.localeCompare(b.patient_name));
        } else if (ordering === '-name') {
            filtered_tracking_statuses = filtered_tracking_statuses.sort((a, b) => b.patient_name.localeCompare(a.patient_name));
        } else if (ordering === 'last_data_update') {
            filtered_tracking_statuses = filtered_tracking_statuses.sort((a, b) => new Date(b.patient_cdw_associated_date) - new Date(a.patient_cdw_associated_date));
        } else if (ordering === '-last_data_update') {
            filtered_tracking_statuses = filtered_tracking_statuses.sort((a, b) => new Date(a.patient_cdw_associated_date) - new Date(b.patient_cdw_associated_date));
        } else if (ordering === 'num_tracked_days') {
            filtered_tracking_statuses = filtered_tracking_statuses.sort((a, b) => calculateDaysSince(b.created) -  calculateDaysSince(a.created));
        } else if (ordering === '-num_tracked_days') {
            filtered_tracking_statuses = filtered_tracking_statuses.sort((a, b) => calculateDaysSince(a.created) -  calculateDaysSince(b.created));
        }
    }

    const onPatientExport = async () => {
        await exportToCSV();
        patientExportModal.close();
    };

    async function exportToCSV() {
        const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(generateCSVContent())}`;
        const link = document.createElement("a");
        link.href = csvContent;
        link.download = filter + "_patients.csv";
        link.click();
    }

    function generateCSVContent() {
        // Define the headers for the CSV
        let headers = [];
        if (filter === "not_tracked") {
            headers = [
                "Alert Name",
                "Patient Name",
                "MRN",
                "Matching Parameters",
                "Last Data Update",
                "Not Tracked Details",
                "Comments"
            ];
        } else {
            headers = [
                "Alert Name",
                "Patient Name",
                "MRN",
                "Matching Parameters",
                "Last Data Update",
                "Number of Tracked Days",
                "Comments"
            ];
        }

        // Convert each patient's lab results into CSV rows
        const rows = [];
        filtered_tracking_statuses.forEach((s) => {
            let alert_name = alert_config.name;
            let patient_name = s.patient_name;
            let patient_mrn = s.patient_mrn;
            let matching_parameters = formatParametersForExport(s.matched_diseases, s.matched_alterations, s.matched_genes);
            let last_data_update = s.patient_cdw_associated_date;
            let not_tracked_reasons = s.tracked_user_name + " | " + formatDate(s.created) + " | " + s.not_tracking_reasons.replaceAll(",", ", ");
            let num_days_tracked = calculateDaysSince(s.created).toString();
            let comments = getCommentsForExport(s.patient);
            let row_info = [];
            if (filter === "not_tracked") {
                row_info = [
                    alert_name || "",
                    patient_name || "",
                    patient_mrn || "",
                    matching_parameters || "",
                    last_data_update || "",
                    not_tracked_reasons || "",
                    comments || ""];
            } else {
                row_info = [
                    alert_name || "",
                    patient_name || "",
                    patient_mrn || "",
                    matching_parameters || "",
                    last_data_update || "",
                    num_days_tracked || "",
                    comments || ""];
            }
            rows.push(row_info);
        });

        return [headers, ...rows].map((row) =>
            row.map(escapeCSVValue).join(",")
        ).join("\n");
    }

    function escapeCSVValue(value) {
        // Escape double quotes and wrap the value in double quotes if necessary
        if (value.includes(",")) {
            return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
    }

    function openExportModal() {
        if (filtered_tracking_statuses.length > 0) {
            patientExportModal.open();
        }
    }

    function getComments(id) {
        let patient_comments = comments.filter(comment => comment.patient === id);
        patient_comments = patient_comments.sort((a, b) => new Date(b.created) - new Date(a.created));
        let string = "";
        patient_comments.forEach((c) => {
            string = string + "<li>" + c.comment + " (" + c.user_created + " " + formatDate(c.created) + " " + formatTime(c.created) + ")" + "</li>";
        })
        return string;
    }

    function getCommentsForExport(id) {
        let patient_comments = comments.filter(comment => comment.patient === id);
        patient_comments = patient_comments.sort((a, b) => new Date(b.created) - new Date(a.created));
        let string = "";
        patient_comments.forEach((c) => {
            if (string !== "") {
                string = string + ", " + c.comment + " (" + c.user_created + " " + formatDate(c.created) + " " + formatTime(c.created) + ")";
            } else {
                string = string + c.comment + " (" + c.user_created + " " + formatDate(c.created) + " " + formatTime(c.created) + ")";
            }
        });
        return string;
    }

    function toggleDropdown(index) {
        filtered_tracking_statuses[index].showDropdown = !filtered_tracking_statuses[index].showDropdown;
    }

    function calculateDaysSince(createdDateStr) {
        let createdDate = new Date(createdDateStr);
        let currentDate = new Date();

        let differenceInTime = currentDate - createdDate;

        return Math.floor(differenceInTime / (1000 * 3600 * 24));
    }

    function toggleCheckbox(id) {
        checkedRows.set(id, !checkedRows.get(id));
        checkedRows = new Map(checkedRows);
    }

    function anyRowsChecked() {
        return Array.from(checkedRows.values()).some(checked => checked);
    }

    function getCheckedRows() {
        return Array.from(checkedRows.entries()).filter(([id, checked]) => checked).map(([id]) => id);
    }

    onMount(() => {
        fetchData();
    });

</script>
<style>
    .container {
        display: flex;
        height: calc(80vh - 151px);
    }
    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }
    thead th {
        position: sticky;
        top: 0;
    }
    th {
        background-color: #233C6B;
        color: #ffffff;
    }
    tr {
        background-color: white;
    }
    button {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }
    .checkbox{
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .big-textbox {
        width: 400px;
        height: 200px;
        resize: horizontal;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 15px;
    }
    .summary {
        height: 60vh;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 2em;
        margin-right: 2em;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1em;
        width: 94%;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .export-icon-png-large {
        width: 50px;
        cursor: pointer;
    }
    .title-info {
        margin-right: 20px;
    }
    .title-name {
        font-weight: bold;
        margin-right: 10px;
    }
    .scrollable-table {
        overflow-y: auto;
        max-height: calc(62vh - 4em);
        border-radius: 10px;
    }

</style>
<div class="container">
    <div class="summary">
        <div class="title">
            <div class="title-name">Alert Name:</div>
            <div class="title-info">{alert_config.name}</div>
            <div class="title-name">Latest Notification Date:</div>
            <div class="title-info">{formatDate(alert_config.last_run_date)}</div>
            <div class="title-name">Number of Patients:</div>
            <div class="title-info">{filtered_tracking_statuses.length}</div>
            <button on:click={() => handleBulkPatientActionModalOpen()} style="background-color: rgba(144, 195, 76, 1)"> Bulk Decision </button>
            <div style="margin-left: auto; display: flex; align-items: center;">
                <div on:click={() => isTrackView = true} aria-hidden="true" style="margin-right: 20px">
                        <span title="Close">
                            <Icon data={faCircleXmarkRegular} scale="2" style="color: #243c6a;"/>
                        </span>
                </div>
                <div on:click={() => openExportModal()} aria-hidden="true">
                    <div title="Export">
                        <img src="static/images/exportCurrentResultsIcon.png"
                             class="export-icon-png-large" alt=""></div>
                </div>
            </div>
        </div>
        <div class="scrollable-table">
            <table>
                <thead>
                <tr>
                    <th>Select</th>
                    <th on:click={() => sortPatients('name')}>
                        Patient Name
                        {#if ordering === 'name'}
                            &#9650; <!-- Upward arrow -->
                        {:else if ordering === '-name'}
                            &#9660; <!-- Downward arrow -->
                        {/if}
                    </th>
                    <th>MRN</th>
                    <th>Matching Parameters</th>
                    <th on:click={() => sortPatients('last_data_update')}>
                        Last Data Update
                        {#if ordering === 'last_data_update'}
                            &#9650; <!-- Upward arrow -->
                        {:else if ordering === '-last_data_update'}
                            &#9660; <!-- Downward arrow -->
                        {/if}
                    </th>
                    {#if page !== 'no track' }
                        <th on:click={() => sortPatients('num_tracked_days')}>
                            Number of Days Tracked
                            {#if ordering === 'num_tracked_days'}
                                &#9650; <!-- Upward arrow -->
                            {:else if ordering === '-num_tracked_days'}
                                &#9660; <!-- Downward arrow -->
                            {/if}
                        </th>
                    {/if}
                    {#if page === 'track' }
                        <th>Tracking Status</th>
                    {/if}
                    {#if page === 'no track' }
                        <th>Not Tracked Details</th>
                    {/if}
                    <th>Comments</th>
                    <th>Action</th>
                    <th>Treatment Plan</th>
                </tr>
                </thead>
                <tbody>
                    {#each filtered_tracking_statuses as row, i}
                    <tr>
                        <td>
                            <!-- Bind the checked state to checkedRows map -->
                            <input type="checkbox" checked={checkedRows.get(row.id) || false} on:change={() => toggleCheckbox(row.id)}>
                        </td>
                        <td>{row.patient_name}</td>
                        <td>{row.patient_mrn || "N/A"}</td>
                        <td>{@html formatParameters(row.matched_diseases, row.matched_alterations, row.matched_genes)}</td>
                        <td>{row.patient_cdw_associated_date}</td>
                        {#if page !== 'no track' }
                            <td>{calculateDaysSince(row.created)}</td>
                        {/if}
                        {#if page === 'track' }
                            <td>{getStatus(row)}</td>
                        {/if}
                        {#if page === 'no track' }
                            <td>
                                <div>
                                    {row.tracked_user_name}
                                </div>
                                <div>
                                    {formatDate(row.created)}
                                </div>
                                <div>
                                    {row.not_tracking_reasons.replaceAll(",", ", ")}
                                </div>
                            </td>
                        {/if}
                        <td>
                            {@html getComments(row.patient)}
                            <button on:click={() => handleCommentModalOpen(row)} style="background-color: rgba(144, 195, 76, 1); margin-top: 10px"> Add New Comment </button>
                        </td>
                        <td>
                            {#if page === 'track' }
                                <button on:click={() => handlePatientActionModalOpen(row)} style="background-color: rgba(144, 195, 76, 1)"> Patient Decision </button>
                            {:else}
                                <button on:click={() => handlePatientActionModalOpen(row)} style="background-color: rgba(144, 195, 76, 1)"> Edit Decision </button>
                            {/if}
                        </td>
                        <td>
                            {#if row.patient_case_meta && row.patient_case_meta.care_plan_category}
                                <button on:click={() => toggleDropdown(i)}>
                                    {filtered_tracking_statuses[i].showDropdown ? 'Close' : 'View'}
                                </button>
                            {/if}
                        </td>
                    </tr>
                        {#if filtered_tracking_statuses[i].showDropdown}
                            <tr>
                                <td colspan="10">
                                    <div class="table">
                                        <TreatmentPlanTable {filtered_tracking_statuses} {i}/>
                                    </div>
                                </td>
                            </tr>
                        {/if}
                    {/each}
                </tbody>
            </table>
        </div>
    </div>
</div>

<!--Patient Action Modal-->
<form on:submit|preventDefault={onPatientAction}>
    <Modal bind:modalFunc={patientActionModal}>
        <div slot="header">
            <div style="font-size: 15px">
                <h1>What is the decision on this patient?</h1>
            </div>
        </div>
        <div slot="content" style="display: flex">
            {#if isBulkUpdate}
                <div style="flex: 6">
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={all} on:change={(event) => handleBulkCheckboxChange(event, 'all')} />
                        Apply Decision to All
                    </label>
                    {#if anyRowsChecked()}
                        <label class="checkbox">
                            <input type="checkbox" bind:checked={selected} on:change={(event) => handleBulkCheckboxChange(event, 'selected')} />
                            Apply Decision to Selected
                        </label>
                    {/if}
                </div>
            {/if}
            <div style="flex: 7">
                {#if page === 'track'}
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={wait} on:change={(event) => handleCheckboxChange(event, 'wait')} />
                        Keep Waiting
                    </label>
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={candidate} on:change={(event) => handleCheckboxChange(event, 'candidate')} />
                        Mark as Candidate
                    </label>
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={archive} on:change={(event) => handleCheckboxChange(event, 'archive')} />
                        Archive
                    </label>
                {:else if page === 'candidate'}
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={candidate} on:change={(event) => handleCheckboxChange(event, 'candidate')} />
                        Keep as Candidate
                    </label>
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={wait} on:change={(event) => handleCheckboxChange(event, 'wait')} />
                        Send Back to Waiting
                    </label>
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={archive} on:change={(event) => handleCheckboxChange(event, 'archive')} />
                        Archive
                    </label>
                {:else if page === 'archive'}
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={archive} on:change={(event) => handleCheckboxChange(event, 'archive')} />
                        Keep as Archived
                    </label>
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={wait} on:change={(event) => handleCheckboxChange(event, 'wait')} />
                        Send Back to Waiting
                    </label>
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={candidate} on:change={(event) => handleCheckboxChange(event, 'candidate')} />
                        Mark as Candidate
                    </label>
                {:else if page === 'no track'}
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={wait} on:change={(event) => handleCheckboxChange(event, 'wait')} />
                        Track
                    </label>
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={archive} on:change={(event) => handleCheckboxChange(event, 'archive')} />
                        Archive
                    </label>
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={candidate} on:change={(event) => handleCheckboxChange(event, 'candidate')} />
                        Mark as Candidate
                    </label>
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={no_track} on:change={(event) => handleCheckboxChange(event, 'not track')} />
                        Keep as Untracked
                    </label>
                {/if}
            </div>
        </div>
        <div slot="footer">
            <div class="modal-footer-row">
                <button class="modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Submit</button>
                <button class="modal-button" on:click={() => patientActionModal.close()}>Cancel</button>
            </div>
        </div>
    </Modal>
</form>

<!--Comment Modal-->
<form on:submit|preventDefault={onSaveComment}>
    <Modal bind:modalFunc={commentModal}>
        <div slot="content">
            <textarea bind:value={commentValue} class="big-textbox"></textarea>
        </div>
        <div slot="footer">
            <div class="modal-footer-row">
                <button class="modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Add Comment</button>
                <button class="modal-button" style="background-color: lightcoral" on:click={() => commentModal.close()}>Close</button>
            </div>
        </div>
    </Modal>
</form>

<!--Patient Export Modal-->
<form on:submit|preventDefault={onPatientExport}>
    <Modal bind:modalFunc={patientExportModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to export patients?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="alert-modal-first-row">
                <button on:click={() => patientExportModal.close()}>Cancel</button>
                <button style="background-color: rgba(144, 195, 76, 1)" type="submit">Export</button>
            </div>
        </div>
    </Modal>
</form>