<script>
    import {onMount} from 'svelte';
    import {getAlertConfigs, deleteAlertConfig, patchAlertConfig} from "../../network/user";
    import Modal from "../Modal.svelte";
    import Loader from "../shared/Loader.svelte";
    import {getUser} from "../../store/session";
    import {Icon} from "svelte-awesome";
    import {faTrash, faEdit, faPause, faArchive} from '@fortawesome/free-solid-svg-icons';
    import {pushFail, pushSuccess} from "../../utils/toast";
    import {alertPage, alertID} from "../../store/navigation";

    let alerts_summary = {};
    let alerts = [];
    let selectedPageSize = 10;
    let currentPage = 1;
    let allCount = '';
    let isLoading = false;
    let deleteAlertModal;
    let pauseAlertModal;
    let archiveAlertModal;
    let alertIdToDelete;
    let alertIdToPause;
    let alertIdToArchive;
    let ordering = '';
    let isViewAll = false;
    let previousPageSize = 10;

    async function fetchAlerts() {
        isLoading = true;
        alerts_summary = await getAlertConfigs(getUser().id, currentPage, selectedPageSize, "active", ordering);
        allCount = alerts_summary.hits;
        alerts = alerts_summary.results;
        isLoading = false;
    }

    async function changePageSize(event) {
        selectedPageSize = event.target.value;
        currentPage = 1;
        await fetchAlerts();
    }

    async function handleViewAllChange() {
        if (isViewAll) {
            previousPageSize = selectedPageSize;
            selectedPageSize = allCount;
        } else {
            selectedPageSize = previousPageSize;
        }
        currentPage = 1;
        await fetchAlerts();
    }

    async function changePage(page) {
        currentPage = page;
        await fetchAlerts();
    }

    function formatParameters(parameters) {
        let params = [];
        let filters = parameters.filters;
        filters.forEach(filter => {
            if ("range" === filter.type) {
                let param = `${filter.operator} of ${filter.field} ${filter.values[0].value} to ${filter.values[1].value}`;
                params = [...params, param];
            } else {
                filter.values.forEach(value => {
                    let param = `${filter.operator} of ${value.value}`;
                    params = [...params, param];
                });
            }
        });
        return params.toString().replaceAll(",", ", ");
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    }

    function handleOpenDeleteAlertModal(id) {
        alertIdToDelete = id;
        deleteAlertModal.open();
    }

    function handleOpenPauseAlertModal(id) {
        alertIdToPause = id;
        pauseAlertModal.open();
    }

    function handleOpenArchiveAlertModal(id) {
        alertIdToArchive = id;
        archiveAlertModal.open();
    }

    function handleEditAlert(id) {
        alertPage.set("create");
        alertID.set(id);
    }

    const onDeleteAlert = async () => {
        let response = await deleteAlertConfig(alertIdToDelete);
        if (response.ok) {
            deleteAlertModal.close();
            await fetchAlerts();
            pushSuccess("Successfully deleted alert.");
        } else {
            deleteAlertModal.close();
            pushFail("Failed to delete alert.");
        }
    };

    const onPauseAlert = async () => {
        let body = {
            "is_paused": true
        };
        let response = await patchAlertConfig(alertIdToPause, body, false);
        if (response.ok) {
            pauseAlertModal.close();
            await fetchAlerts();
            pushSuccess("Successfully paused alert.");
        } else {
            pauseAlertModal.close();
            pushFail("Failed to pause alert.");
        }
    };

    const onArchiveAlert = async () => {
        let body = {
            "is_archived": true
        };
        let response = await patchAlertConfig(alertIdToArchive, body, false);
        if (response.ok) {
            archiveAlertModal.close();
            await fetchAlerts();
            pushSuccess("Successfully archived alert.");
        } else {
            archiveAlertModal.close();
            pushFail("Failed to archive alert.");
        }
    };

    function sortAlerts(property) {
        console.log(property);
        if (property === ordering || `-${property}` === ordering) {
            if (ordering.startsWith('-')) {
                ordering = '';
            } else {
                ordering = `-${property}`;
            }
        } else {
            ordering = property;
        }
        fetchAlerts();
    }

    function formatTime(timeString) {
        if (timeString !== null) {
            const parsedTime = new Date(`1970-01-01T${timeString}`);
            return parsedTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
        } else {
            return "N/A";
        }

    }

    onMount(() => {
        fetchAlerts();
    });

    $: totalPages = Math.ceil(allCount / selectedPageSize);
</script>

<style>
    .container {
        display: flex;
        height: calc(74vh - 51px);
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    thead th {
        position: sticky;
        top: 0;
    }

    th {
        background-color: #233C6B;
        color: #ffffff;
    }

    tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    button {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }

    .summary {
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 2em;
        margin-right: 2em;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1em;
        width: 98%;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .next-button {
        background-color: rgba(7, 7, 7, 0.19);
        color: #fff;
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 5px;
    }

    .params {
        width: 30%;
    }

    .pagination button {
        margin-left: 0.5em;
    }

    .pages-span {
        margin-left: 0.5em;
        margin-right: 0.2em;
    }

    .scrollable-table {
        overflow-y: auto;
        max-height: calc(64vh - 4em);
        border-radius: 10px;
    }

</style>

<div class="container">
    <div class="summary">
        <div class="pagination">
            <label style="margin-right: 25px">
                <input type="checkbox" bind:checked={isViewAll} on:change={() => handleViewAllChange()}/>
                View All
            </label>
            <label for="page-size">Page Size:</label>
            <select id="page-size" on:change={changePageSize}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </select>
            {#if !isLoading && alerts.length > 0}
                <div class="pagination">
                    {#if alerts_summary.pagination.previous}
                        <button class="next-button" on:click={() => changePage(currentPage - 1)}>Previous</button>
                    {/if}
                    <span class="pages-span">
                    Page {currentPage} of {totalPages}
                </span>
                    {#if alerts_summary.pagination.next}
                        <button class="next-button" on:click={() => changePage(currentPage + 1)}>Next</button>
                    {/if}
                </div>
            {/if}
        </div>
        <div class="scrollable-table">
            {#if isLoading}
                <Loader/>
            {:else}
                <table>
                    <thead>
                    <tr>
                        <th on:click={() => sortAlerts('name')}>
                            Name
                            {#if ordering === 'name'}
                                &#9650; <!-- Upward arrow -->
                            {:else if ordering === '-name'}
                                &#9660; <!-- Downward arrow -->
                            {/if}
                        </th>
                        <th>Parameters</th>
                        <th>Shared Users</th>
                        <th style="width: 18%" on:click={() => sortAlerts('cadence')}>
                            Frequency
                            {#if ordering === 'cadence'}
                                &#9650; <!-- Upward arrow -->
                            {:else if ordering === '-cadence'}
                                &#9660; <!-- Downward arrow -->
                            {/if}
                        </th>
                        <th on:click={() => sortAlerts('last_run_date')}>
                            Last Run Date
                            {#if ordering === 'last_run_date'}
                                &#9650; <!-- Upward arrow -->
                            {:else if ordering === '-last_run_date'}
                                &#9660; <!-- Downward arrow -->
                            {/if}
                        </th>
                        <th>Edit</th>
                        <th>Pause</th>
                        <th>Archive</th>
                        <th>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {#each alerts as alert, i}
                        <tr class:even={i % 2 === 1}>
                            <td>{alert.name}</td>
                            <td class="params">{formatParameters(alert.alert_parameters)}</td>
                            {#if alert.shared_users !== null}
                                <td>{alert.shared_users.replaceAll(",", ", ")}</td>
                            {:else}
                                <td>{""}</td>
                            {/if}
                            {#if alert.cadence_day}
                                {#if alert.time}
                                    <td>Weekly - {alert.cadence_day} - {formatTime(alert.time)}</td>
                                {:else}
                                    <td>Weekly - {alert.cadence_day}</td>
                                {/if}
                            {:else if alert.cadence === 0}
                                <td>Instant</td>
                            {:else if alert.cadence === 1}
                                {#if alert.time}
                                    <td>Daily - {formatTime(alert.time)}</td>
                                {:else}
                                    <td>Daily</td>
                                {/if}
                            {:else}
                                {#if alert.time}
                                    <td>Every {alert.cadence} Days - {formatTime(alert.time)}</td>
                                {:else}
                                    <td>Every {alert.cadence} Days</td>
                                {/if}
                            {/if}
                            <td>{formatDate(alert.last_run_date)}</td>
                            <td>
                                <div on:click={() => handleEditAlert(alert.id)} aria-hidden="true">
                                    <Icon data={faEdit}/>
                                </div>
                            </td>
                            <td>
                                <div on:click={() => handleOpenPauseAlertModal(alert.id)} aria-hidden="true">
                                    <Icon data={faPause}/>
                                </div>
                            </td>
                            <td>
                                <div on:click={() => handleOpenArchiveAlertModal(alert.id)} aria-hidden="true">
                                    <Icon data={faArchive}/>
                                </div>
                            </td>
                            <td>
                                <div on:click={() => handleOpenDeleteAlertModal(alert.id)} aria-hidden="true">
                                    <Icon data={faTrash}/>
                                </div>
                            </td>
                        </tr>
                    {/each}
                    </tbody>
                </table>
            {/if}
        </div>
    </div>
</div>

<!--Delete Alert Modal-->
<form on:submit|preventDefault={onDeleteAlert}>
    <Modal bind:modalFunc={deleteAlertModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to delete this alert?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="alert-modal-first-row">
                <button class="alert-modal-button" on:click={() => deleteAlertModal.close()}>Cancel</button>
                <button class="alert-modal-button" style="background-color: lightcoral" type="submit">Delete</button>
            </div>
        </div>
    </Modal>
</form>

<!--Pause Alert Modal-->
<form on:submit|preventDefault={onPauseAlert}>
    <Modal bind:modalFunc={pauseAlertModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to pause this alert?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="alert-modal-first-row">
                <button class="alert-modal-button" on:click={() => pauseAlertModal.close()}>Cancel</button>
                <button class="alert-modal-button" style="background-color: #98B249" type="submit">Pause</button>
            </div>
        </div>
    </Modal>
</form>

<!--Archive Alert Modal-->
<form on:submit|preventDefault={onArchiveAlert}>
    <Modal bind:modalFunc={archiveAlertModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to archive this alert?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="alert-modal-first-row">
                <button class="alert-modal-button" on:click={() => archiveAlertModal.close()}>Cancel</button>
                <button class="alert-modal-button" style="background-color: #d98b95" type="submit">Archive</button>
            </div>
        </div>
    </Modal>
</form>