<script>
  import {pane,} from "../../store/instInsights";
  import InsitutionalTrialsDashboard from "./InsitutionalTrialsDashboard.svelte";
  import InsitutionalTrialsList from "./InsitutionalTrialsList.svelte";
  import InsitutionalTrialsAnalytics from "./InsitutionalTrialsAnalytics.svelte";
  import InsitutionalTrialsPriorityList from "./InsitutionalTrialsPriorityList.svelte";
  import InsitutionalTrialsEligibility from "./InsitutionalTrialsEligibility.svelte";
  import InsitutionalTrialsPatient from "./InsitutionalTrialsPatient.svelte";
  import {onMount} from "svelte";

  onMount(() => {
      $pane = 'backToSummary';
  });
</script>

<style>

    .container {
        display: flex;
    }

    .nav-header {
        z-index: 999;
        display: flex;
        justify-content: space-between;
        height: 75px;
    }


    .header-nav-button:hover {
        cursor: pointer;
    }

    .header-nav-button {
        position: relative;
        cursor: pointer;
        flex: 1;
        padding: 10px;
        background-color: #f2f2f2;
        border: 1px solid #ccc;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        margin-left: 1em;
        margin-right: 1em;
        margin-top: 1em;
        font-size: 16px;
        font-weight: bold;
    }

    .match-button {
        cursor: pointer;
        flex: 1;
        padding: 10px;
        background-color: #6cc250;
        border: 1px solid #ccc;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        margin-left: 1em;
        margin-right: 1em;
        margin-top: 0;
        font-size: 14px;
        font-weight: bold;
        height: 35px;
    }

    .unsaved-changes-dot {
        position: absolute;
        right: -5px;
        top: -5px;
        width: 12px;
        height: 12px;
        background-color: red;
        border-radius: 50%;
        z-index: 999;
    }

</style>
<div class="nav-header">
    <h3 style="align-content: center; margin-left: 10px;">Details: </h3>
    <p class="header-nav-button" on:click={() => pane.set("trial_list")}
       style="color: {$pane === 'trial_list' ? '#233C6B' : 'black'};"> Trial List
    </p>
    <p class="header-nav-button" on:click={() => pane.set("trial_analytics")}
       style="color: {$pane === 'trial_analytics' ? '#233C6B' : 'black'};">Trial Analytics
    </p>
    <p class="header-nav-button" on:click={() => pane.set("priority_list")}
       style="color: {$pane === 'priority_list' ? '#233C6B' : 'black'};">Priority List Management
    </p>
</div>
{#if ($pane === "main")}
    <div class="nav-header">

    </div>
{/if}
<hr style="margin: 0; border: none; border-top: 4px solid #AAAAAA;">

<div class="container" style="height: {$pane === 'main' ? 'calc(60vh - 10px)' : 'calc(70vh - 30px)'};">
  {#if ($pane === 'main' || $pane === 'backToSummary')}
    <svelte:component this={InsitutionalTrialsDashboard}/>
  {:else if ($pane === 'trial_list')}
    <svelte:component this={InsitutionalTrialsList}/>
  {:else if ($pane === 'trial_analytics')}
    <svelte:component this="{InsitutionalTrialsAnalytics}"/>
  {:else if ($pane === 'priority_list')}
    <svelte:component this="{InsitutionalTrialsPriorityList}"/>
  {:else if ($pane === 'eligibility_list')}
    <svelte:component this="{InsitutionalTrialsEligibility}"/>
  {:else if ($pane === 'patient_list')}
      <svelte:component this="{InsitutionalTrialsPatient}"/>
  {/if}
</div>