<script>
    import {HSplitPane} from "svelte-split-pane";
    import AssayMatchSearch from "./AssayMatchSearch.svelte";
    import TestRecommendationsTable from "../patient_match/TestRecommendationsTable.svelte";
    import {assayMatchSearchType} from "../../store/patientMatch";

    let leftPaneInfoWidth = "100%";
    let rightPaneInfoWidth = "0%";
    let leftPaneWidth = "21%";
    let rightPaneWidth = "79%";
    let hideLeftNav = false;

</script>

<style>
    .container {
        display: flex;
        height: calc(80vh - 51px);
        overflow: scroll;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    thead th {
        position: sticky;
        top: 0;
    }

    th {
        background-color: #233C6B;
        color: #ffffff;
    }

    tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    button {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }

    ul {
        list-style-type: none;
        color: grey;
    }

    li {
        margin-left: 20px;
    }

    .pagination button {
        margin-left: 0.5em;
    }

    /* Add the following styles for the "Markers" column */
    td:nth-child(4) {
        word-break: break-word;
        overflow-wrap: break-word;
        max-width: 800px;
    }

    .side-bar {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
</style>

<div class="container">
    <HSplitPane bind:leftPaneSize={leftPaneWidth} bind:rightPaneSize={rightPaneWidth} minLeftPaneSize="20%">
        <left slot="left">
            {#if !hideLeftNav}
                <div class="side-bar">
                    <AssayMatchSearch/>
                </div>
            {/if}
        </left>
        <right slot="right">
            {#if $assayMatchSearchType}
                <TestRecommendationsTable caseMatch={false}/>
            {:else}
                <h2>Choose option to find test recommendation information</h2>
            {/if}

        </right>
    </HSplitPane>
</div>