import {callApi} from "./rest";

const ROOT_URL_API = '/api';

export async function fetchTrialDetails(uuid) {
    const url = `${ROOT_URL_API}/trials/detail/${uuid}`;
    const response = await callApi(url, "GET");

    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return await response.json();
}

export const getTrialSummary = async (currentPage, selectedPageSize, searchQueries, ordering) => {
    let url = `${ROOT_URL_API}/trials/summary?page=${currentPage}&page_size=${selectedPageSize}`;

    const searchParams = Object.entries(searchQueries)
        .map(([field, value]) => value && `${field}=${encodeURIComponent(value)}`)
        .filter(Boolean)
        .join('&');

    if (searchParams) {
        url = `${url}&${searchParams}`;
    }

    if (ordering) {
        url = `${url}&sort_by=${ordering}`;
    }

    const response = await callApi(url, "GET");

    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get trial summary");
    }
};

export const gatherFacetInfo = async () => {
    const url = `${ROOT_URL_API}/trials/facet-info`;
    const response = await callApi(url, "GET");
    if (response.ok) {
        return await response.json();
    } else {
        console.error("Couldn't get inst trial facet info");
    }
};