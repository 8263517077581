// Import Svelte writable store
import { writable } from 'svelte/store';

// Patient Details
export let additionalPatientDetails = writable([]);
export let selectedCase = writable('');
export let selectedPatient = writable('');
export let selectedLabResults = writable('');
export let selectedMarkers = writable('');
export let selectedCaseUUID = writable('');
export let patientIsLoaded = writable(false);

// Trials
export let instNonBioTrials = writable('');
export let instBioTrials = writable('');
export let outsideTrials = writable('');
export let generateTrialsMatches = writable(true);
export let openMatchResults = writable(false);

// Trial Filters
export let allTrialfilters = writable({"NCTID":[],"ProtocolID":[],"Biomarkers":[],"Diseases":[],"Phase":[],"RecruitingStatus":[],"Drugs":[], "DrugCategory":[], "PriorTreatment":[], "TherapeuticContext":[], "DiseaseState":[], "Distance":[]});
export let instTrialfilters = writable({"NCTID":[],"ProtocolID":[],"Biomarkers":[],"Diseases":[],"Phase":[],"RecruitingStatus":[],"Drugs":[], "DrugCategory":[], "PriorTreatment":[], "TherapeuticContext":[], "DiseaseState":[], "Distance":[]});

// Therapy Filters
export let therapyFilters = writable({"Biomarkers":[]});

// Markers
export let markerMatchesMap = writable('');
export let sigMarkers = writable({});
export let vusMarkers = writable({});
export let negMarkers = writable({});
export let rnaMarkers = writable({});

// Navigation
export let pane = writable('backToSummary');
export let matchTableSetting = writable('');
export let viewUnsavedChanges = writable('closed')

// Therapy Lists
export let senFTTTherapies = writable([]);
export let senOTTTherapies = writable([]);
export let resTherapies = writable([]);
export let senEvidenceMCGTherapies = writable([])
export let senEvidenceCTTherapies = writable([])
export let senEvidenceCSTherapies = writable([])
export let senEvidenceCRCSTherapies = writable([])
export let senEvidencePCTherapies = writable([])
export let resEvidenceMCGTherapies = writable([])
export let resEvidenceCTTherapies = writable([])
export let resEvidenceCSTherapies = writable([])
export let resEvidenceCRCSTherapies = writable([])
export let resEvidencePCTherapies = writable([])

//annotations & contents
export let contentsMatches = writable({})

// Trial Filtering Pane
export let leftFilterPaneState = writable('closed')

export let historical_match_params = writable({})

export let recalculate_match = writable(false)

// Match Result Counts
export let instCount = writable(0)
export let outsideCount  = writable(0)
export let fdaNccnCount = writable(0)
export let evidenceCount = writable(0)
export let diagnosticCount = writable(0)
export let prognosticCount  = writable(0)

// Assay Match
export let assayMatchResults = writable('')
export let assayMatchSearchType = writable('')

// DS TC
export let tcList = writable([])
export let dsList = writable(["Primary","Stage I", "Stage II", "Advanced", "Locally Advanced", "Stage III", "Metastatic", "Metastatic Recurrence", "Stage IV", "Unspecified"])

// Distance Zipcode
export let distanceParam = writable('');
export let zipcodeParam = writable('');

// Side Nav
export let sideNavWidth = writable(0);

// PDF Attachments
export let caseAttachments = writable([])
export let openTab = writable('')
export let selectedFileID = writable('')
export let openAssayExplorer = writable(false)