<script>
    import {Icon} from "svelte-awesome";
    import FileUpload from "../shared/FileUpload.svelte";
    import {
        faPenToSquare,
        faCircleXmark,
        faXmark,
        faUpload,
        faFilePdf
    } from "@fortawesome/free-solid-svg-icons";
    import {
        pane,
        selectedLabResults,
        selectedCase,
        selectedPatient,
        openTab,
        selectedFileID,
        openAssayExplorer
    } from "../../store/patientMatch";
    import {HSplitPane} from "svelte-split-pane";
    import AssayExplorer from "../shared/AssayExplorer.svelte";


    let editPage = false;
    $: toggleLR = {};
    $: filteredSamples = [];

    let openFileUpload = false;
    let selectedLRUUID = '';
    let selectedSampleUUID = '';
    let selectedUploadType=''

    function toggleEditMode() {
        editPage = !editPage;
    }

    function toggleCollapseItem(lrID) {
        toggleLR[lrID] = !toggleLR[lrID];
    }

    $: {
        filteredSamples = $selectedLabResults.filter(sample => sample.lab_results && sample.lab_results.length > 0);

        if (filteredSamples){
            filteredSamples.forEach(sample => {
                sample.lab_results.forEach(lr => {
                    const key = `${sample.id}-${lr.id}`;
                    toggleLR[key] = false;
                });
            });
        }
    }

</script>
{#if ($openAssayExplorer)}
    <HSplitPane rightPaneSize="30%" leftPaneSize="70%" minLeftPaneSize="60%" minRightPaneSize="550px">
        <left slot="left">
            <div style="display: flex; flex-direction: column; width: 100%;">
                <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 25px; width: 97%;">
                    <div></div>
                    <div style="display: flex; align-items: center; justify-content: flex-start">
                        <h1 style="margin: 0;">Test Information</h1>
                        <div style="cursor: pointer;" on:click={toggleEditMode} aria-hidden="true">
                            {#if editPage}
                                <Icon data="{faCircleXmark}" scale="1.75"
                                      style="margin-left: 15px; color:#c5c5c7; cursor:pointer;"/>
                            {:else}
                                <Icon data="{faPenToSquare}" scale="1.75"
                                      style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                            {/if}
                            <span on:click={() => {openAssayExplorer.set(!$openAssayExplorer);}}
                                  style="color:#233C6B;" aria-hidden="true"><img
                                    src="static/images/exploreMoreIcon.png" class="icon-png"
                                    alt=""> Explore Assays</span>
                        </div>
                    </div>
                    <div on:click={() => pane.set("backToSummary")} aria-hidden="true">
                        <Icon data="{faXmark}" scale="1.75" style="margin-left: 15px; color:black; cursor:pointer;"/>
                    </div>
                </div>
                <div style="height: 55vh; overflow-x: scroll;">
                    {#each $selectedLabResults as sample, sampleIndex}
                        {#each sample.lab_results as lr, lrIndex}
                            <table style="width:100%; border-collapse: collapse; cursor:pointer;">
                                <tr>
                                    <td style="padding: 10px 10px 10px 5vw; text-align: left;">
                                            <span style="font-size: 16px; font-weight: normal">
                                                <span on:click={() => toggleCollapseItem(`${sample.id}-${lr.id}`)}
                                                      aria-hidden="true">
                                                    <strong>Test {sampleIndex * sample.lab_results.length + lrIndex + 1}
                                                        :</strong>
                                                    {lr.assay_display}, {lr.report_id}, {lr.reported}:
                                                </span>
                                                <span on:click={()=> {openFileUpload = true; selectedLRUUID=lr.uuid; selectedSampleUUID=sample.uuid; selectedUploadType='labResult'}} aria-hidden="true">
                                                    <Icon data="{faUpload}" style="margin-left: 25px;"/>
                                                </span>
                                                {#each lr.attachment_files as lrAttachment}
                                                    <span on:click={()=> {selectedFileID.set('lab_result-'+lrAttachment); openTab.set('view');}} aria-hidden="true">
                                                        <Icon data="{faFilePdf}" style="margin-left: 25px;"/>
                                                    </span>
                                                {/each}
                                                <!--{#each lr.data_files as lrDataFile}-->
                                                <!--    <Icon data="{faPaperclip}" style="margin-left: 25px;"/>-->
                                                <!--{/each}-->
                                            </span>
                                        {#if (toggleLR[`${sample.id}-${lr.id}`])}
                                            <div style="margin-left: 25px;">
                                                <p style="font-weight: normal"><strong>Assay
                                                    Name: </strong>{lr.assay_display}</p>
                                                <p style="font-weight: normal">
                                                    <strong>OrderID: </strong>{$selectedCase.order_identifier}</p>
                                                <p style="font-weight: normal"><strong>Reported
                                                    Date: </strong>{lr.reported}</p>
                                                <p style="font-weight: normal"><strong>Report
                                                    Status: </strong>{lr.report_status}</p>
                                                <p style="font-weight: normal"><strong>Sample
                                                    Name: </strong>{lr.sample_display}
                                                    <span on:click={()=> {openFileUpload = true; selectedLRUUID=lr.uuid; selectedSampleUUID=sample.uuid; selectedUploadType='sample'}}
                                                          aria-hidden="true">
                                                            <Icon data="{faUpload}" style="margin-left: 25px;"/>
                                                        </span>
                                                    {#each sample.attachment_files as sampleAttachment}
                                                        <span on:click={()=> {selectedFileID.set('sample-'+sampleAttachment); openTab.set('view');}} aria-hidden="true">
                                                        <Icon data="{faFilePdf}" style="margin-left: 25px;"/>
                                                    </span>
                                                    {/each}
                                                </p>
                                                <p style="font-weight: normal"><strong>Sample Collected
                                                    Date: </strong>{lr.sample_taken}</p>
                                            </div>
                                        {/if}
                                    </td>
                                </tr>
                            </table>
                        {/each}
                    {/each}
                </div>
            </div>
        </left>
        <right slot="right">
            <AssayExplorer filteredSamples="{filteredSamples}"/>
        </right>
    </HSplitPane>
{:else}
    <div style="display: flex; flex-direction: column; width: 100%;">
        <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 25px; width: 97%;">
            <div></div>
            <div style="display: flex; align-items: center; justify-content: flex-start">
                <h1 style="margin: 0;">Test Information</h1>
                <div style="cursor: pointer;" on:click={toggleEditMode} aria-hidden="true">
                    {#if editPage}
                        <Icon data="{faCircleXmark}" scale="1.75"
                              style="margin-left: 15px; color:#c5c5c7; cursor:pointer;"/>
                    {:else}
                        <Icon data="{faPenToSquare}" scale="1.75"
                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                    {/if}
                    <span on:click={() => {openAssayExplorer.set(!$openAssayExplorer);}}
                          style="color:#233C6B;" aria-hidden="true"><img
                            src="static/images/exploreMoreIcon.png" class="icon-png"
                            alt=""> Explore Assays</span>
                </div>
            </div>
            <div on:click={() => pane.set("backToSummary")} aria-hidden="true">
                <Icon data="{faXmark}" scale="1.75" style="margin-left: 15px; color:black; cursor:pointer;"/>
            </div>
        </div>
        <div style="height: 55vh; overflow-x: scroll;">
            {#each $selectedLabResults as sample, sampleIndex}
                {#each sample.lab_results as lr, lrIndex}
                    <table style="width:100%; border-collapse: collapse; cursor:pointer;">
                        <tr>
                            <td style="padding: 10px 10px 10px 5vw; text-align: left;">
                                            <span style="font-size: 16px; font-weight: normal">
                                                <span on:click={() => toggleCollapseItem(`${sample.id}-${lr.id}`)}
                                                      aria-hidden="true">
                                                    <strong>Test {sampleIndex * sample.lab_results.length + lrIndex + 1}
                                                        :</strong>
                                                    {lr.assay_display}, {lr.report_id}, {lr.reported}:
                                                </span>
                                                <span on:click={()=> {openFileUpload = true; selectedLRUUID=lr.uuid; selectedSampleUUID=sample.uuid; selectedUploadType='labResult'}} aria-hidden="true">
                                                    <Icon data="{faUpload}" style="margin-left: 25px;"/>
                                                </span>
                                                {#each lr.attachment_files as lrAttachment}
                                                    <span on:click={()=> {selectedFileID.set('lab_result-'+lrAttachment); openTab.set('view');}} aria-hidden="true">
                                                        <Icon data="{faFilePdf}" style="margin-left: 25px;"/>
                                                    </span>
                                                {/each}
                                                <!--{#each lr.data_files as lrDataFile}-->
                                                <!--    <Icon data="{faPaperclip}" style="margin-left: 25px;"/>-->
                                                <!--{/each}-->
                                            </span>
                                {#if (toggleLR[`${sample.id}-${lr.id}`])}
                                    <div style="margin-left: 25px;">
                                        <p style="font-weight: normal"><strong>Assay
                                            Name: </strong>{lr.assay_display}</p>
                                        <p style="font-weight: normal">
                                            <strong>OrderID: </strong>{$selectedCase.order_identifier}</p>
                                        <p style="font-weight: normal"><strong>Reported
                                            Date: </strong>{lr.reported}</p>
                                        <p style="font-weight: normal"><strong>Report
                                            Status: </strong>{lr.report_status}</p>
                                        <p style="font-weight: normal"><strong>Sample
                                            Name: </strong>{lr.sample_display}
                                            <span on:click={()=> {openFileUpload = true; selectedLRUUID=lr.uuid; selectedSampleUUID=sample.uuid; selectedUploadType='sample'}}
                                                  aria-hidden="true">
                                                            <Icon data="{faUpload}" style="margin-left: 25px;"/>
                                                        </span>
                                            {#each sample.attachment_files as sampleAttachment}
                                                <span on:click={()=> {selectedFileID.set('sample-'+sampleAttachment); openTab.set('view');}} aria-hidden="true">
                                                        <Icon data="{faFilePdf}" style="margin-left: 25px;"/>
                                                    </span>
                                            {/each}
                                        </p>
                                        <p style="font-weight: normal"><strong>Sample Collected
                                            Date: </strong>{lr.sample_taken}</p>
                                    </div>
                                {/if}
                            </td>
                        </tr>
                    </table>
                {/each}
            {/each}
        </div>
    </div>
{/if}

{#if openFileUpload}
    <FileUpload onUpload={() => {openFileUpload=false}} onCancel={() => {openFileUpload=false}} patientUUID="{$selectedPatient.uuid}" lrUUID="{selectedLRUUID}" sampleUUID="{selectedSampleUUID}" uploadType="{selectedUploadType}"/>
{/if}

<style>
    p{
        font-weight: bold;
        font-size: 14px;
        margin-left: 2em;
    }

    .icon-png {
        margin-left: 20px;
        width: 20px;
        cursor: pointer;
        color: #233C6B;
    }

</style>

