<script>
    import AppLayout from "../components/shared/AppLayout.svelte";
    import PageHeader from "../components/shared/MainHeader.svelte";
    import AssayMatchHeader from "../components/assay_match/AssayMatchHeader.svelte";
    import AssayMatchTable from "../components/assay_match/AssayMatchTable.svelte";
</script>

<div>
    <PageHeader/>
    <AppLayout subHeader="{AssayMatchHeader}" mainContent={AssayMatchTable}/>
</div>


