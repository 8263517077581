<script>
    export let top = '0';
    export let left = '0';
    export let backdrop = "true";
    let isOpen = false;

    // this is a readonly property, a warning will be logged if this is accidentally overridden
    export const modalFunc = {
        open() {
            isOpen = true;
            document.addEventListener('keydown', handleKeyPress);
        },
        close() {
            isOpen = false;
            document.removeEventListener('keydown', handleKeyPress);
        }
    };

    function handleKeyPress(event) {
        if (event.key === 'Escape') {
            modalFunc.close();
        }
    }
</script>

<style>
    div.modal {
        position: fixed;
        width: 100%;
        height: 100vh;

        display: flex;
        justify-content: center;
        align-items: center;

        z-index: 100;
    }

    div.backdrop {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }

    div.content-wrapper {
        z-index: 10;
        max-width: 70vw;
        border-radius: 0.3rem;
        border: 1px solid #7b7b7b;
        background-color: white;
        overflow: hidden;
        padding: 20px;
    }

    div.content {
        max-height: 50vh;
        overflow: auto;
    }
</style>

{#if isOpen}
    <div class="modal" aria-hidden="{!isOpen}" role="dialog" aria-labelledby="modalHeader" aria-modal="true" style="top: {top}; left: {left};">
        {#if backdrop === "true"}
            <div class="backdrop" on:click={modalFunc.close} aria-hidden="true"></div>
        {/if}

        <div class="content-wrapper">
            <slot name="header"/>

            <div class="content">
                <slot name="content"/>
            </div>

            <slot name="footer"/>
        </div>
    </div>
{/if}