<script>
    import {Icon} from "svelte-awesome";
    import {
        faDownLeftAndUpRightToCenter,
        faUpRightAndDownLeftFromCenter,
        faXmark,
        faClock
    } from "@fortawesome/free-solid-svg-icons";
    import {fromPDFUpload, getInProcessCases} from "../../network/cases";
    import {onMount} from "svelte";


    let isModalOpen = false;
    let isFullScreen = false;
    let file = null;
    let caseUploaded = false;
    $: inProcessCases = [];
    let showWarning = false;
    $: orderIdentifierIsEmpty = orderIdentifier === '';
    let orderIdentifier = '';
    let patientMRN = '';

    function toggleUploadCase() {
        isModalOpen = !isModalOpen;
        isFullScreen = false;
    }

    function toggleFullScreen() {
        isFullScreen = !isFullScreen;
    }

    function handleFileChange(event) {
        file = event.target.files[0];
    }

    function handleAdd() {
        if (file) {
            toggleUploadCase();
            caseUploaded = true;
        }
    }

    function truncateFileName(name, maxLength = 50) {
        if (name.length <= maxLength) return name;
        const start = name.slice(0, 25);
        const end = name.slice(-25);
        return `${start}...${end}`;
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
        return date.toLocaleDateString('en-US', options);
    }

    async function submitCase() {
        if (orderIdentifier !== '') {
            let response = await fromPDFUpload(file, orderIdentifier, patientMRN);
            // reset values
            resetFields();
            // reload table
            await populateTable();
        } else {
            showWarning = true;
        }
    }

    function resetFields() {
        caseUploaded = false;
        file = null;
        showWarning = false;
        orderIdentifier = '';
        patientMRN = '';
    }

    async function populateTable() {
        let response = await getInProcessCases();
        inProcessCases = response.results;
    }

    onMount(async () => {
        await populateTable();
    });

    let pageSize = '10';
    let currentPage = 1;

    function goToNextPage() {
        if (currentPage < totalPages) {
            currentPage++;
        }
    }

    function goToPreviousPage() {
        if (currentPage > 1) {
            currentPage--;
        }
    }

    $: totalPages = Math.ceil(inProcessCases.length / pageSize);
</script>


<div style="display: flex; justify-content: space-between; align-items: start; margin: 25px; width: 95%;">
    {#if (caseUploaded)}
        <div>
            <button style="background-color: #34a1ff; width:300px" on:click={submitCase}>ADD TO QUEUE</button>
            <button style="background-color: #9d9d9f" on:click={resetFields}>CANCEL</button>
            <p style="text-align:left; padding-top:25px">{truncateFileName(file.name)}</p>
            <div class="input-row">
                <div class="input-field">
                    <label style="text-align:left">Order Identifier*</label>
                    <input type="text" bind:value={orderIdentifier}/>
                    {#if showWarning && orderIdentifierIsEmpty}<label style="color:red">This field may not be
                        blank.</label>{/if}
                </div>
                <div class="input-field">
                    <label style="text-align:left">Patient MRN</label>
                    <input type="text" bind:value={patientMRN}/>
                </div>
            </div>
        </div>
    {:else}
        <div>
            <button on:click={toggleUploadCase}>
                SELECT FILE
            </button>
        </div>
    {/if}
    <div></div>
    <div style="width: 575px; text-align:left">
        <span style="font-style:italic;">When a case is added, it will be added to the list of in-progress cases.
            The turnaround time for a case to be "Ready for Analysis" will be about <strong>3 days</strong>.
            When the case is ready, users will be able to view the case analysis by selecting the
            "Select a Case" button on the patient match page</span>
    </div>
</div>

{#if isModalOpen}
    <div class="overlay" on:click={toggleUploadCase}></div>
    <div class={`modal ${isFullScreen ? 'fullscreen' : ''}`}>
        <div class="modal-header">
            <div>
                <p style="color:white; margin-left:10px;">
                    Upload
                </p>
            </div>
            <div class="button-container">
                <button on:click={toggleFullScreen}>
                    <Icon data={isFullScreen ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter}/>
                </button>
                <button on:click={toggleUploadCase}>
                    <Icon data="{faXmark}"/>
                </button>
            </div>
        </div>

        <div class="modal-body">
            <label>
                <input class="upload-case" type="file" on:change={handleFileChange}/>
            </label>
        </div>

        <div class="modal-footer">
            <button class="button cancel" on:click={toggleUploadCase}>Cancel</button>
            <button class="button add" on:click={handleAdd} disabled={!file}>Add</button>
        </div>
    </div>
{/if}
<h3 style="text-align:left; margin-left:25px;">Cases In Progress</h3>
<div style="max-height:{caseUploaded ? '45vh' : '60vh'}; overflow: scroll">
    <div style="margin-left:25px; width: 70%; padding-bottom: 10px;">
        <div style="display:flex; justify-content: space-between; align-items: center; width: 350px; margin-left: calc(100% - 350px)">
            <label for="pageSize">Page Size:</label>
            <select id="pageSize" bind:value={pageSize} on:change={() => { currentPage = 1; }}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="{inProcessCases.length}">All</option>
            </select>
            <button style="height:35px; width: 60px;" on:click={goToPreviousPage} disabled={currentPage === 1}>Back
            </button>
            <span>Page {currentPage} of {totalPages}</span>
            <button style="height:35px; width: 60px;" on:click={goToNextPage} disabled={currentPage === totalPages}>
                Next
            </button>
        </div>
    </div>
    <table>
        <thead>
        <tr>
            <th style="width: 50px;">Status</th>
            <th>Patient MRN</th>
            <th>Report Identifier</th>
            <th>Date Uploaded</th>
        </tr>
        </thead>
        <tbody>
        {#each inProcessCases.slice((currentPage - 1) * pageSize, currentPage * pageSize) as c}
            <tr>
                <td>
                    <Icon style="color:#FFC107" data="{faClock}"/>
                </td>
                <td>{c.patient_mrn || "N/A"}</td>
                <td>{c.order_identifier}</td>
                <td>{c.pdf_report ? formatDate(c.pdf_report.upload_date) : "N/A"}</td>
            </tr>
        {/each}
        </tbody>
    </table>
</div>
<style>

    button {
        width: 150px;
        height: 50px;
        background-color: #233C6B;
        color: #FFFFFF;
        border-radius: 8px;
    }

    table {
        margin-left: 25px;
        width: 70%;
        border-collapse: collapse;
        table-layout: fixed;
    }

    th, td {
        border: 1px solid black;
        padding: 8px;
        text-align: center;
    }

    thead th {
        background-color: #f9f9f9;
    }

    .modal {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border-radius: 8px;
        width: 800px;
        z-index: 1000;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }

    .modal.fullscreen {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transform: none;
        border-radius: 0;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: start;
        background-color: #233C6B;

    }

    .button-container {
        display: flex;
        margin-right: 10px;
        width: 100px;
    }

    .modal-header h2 {
        margin: 0;
    }

    .modal-header button {
        background: none;
        border: none;
        font-size: 16px;
        cursor: pointer;
    }

    .modal-body {
        display: flex;
        justify-content: flex-start;
        padding: 50px 20px 50px;
    }

    .modal-footer {
        display: flex;
        justify-content: space-between;
        padding: 0 10px 75px;
        width: 375px;
        align-items: start;
    }

    .button {
        padding: 8px 16px;
        border: none;
        cursor: pointer;
    }

    .button.cancel {
        background-color: #9d9d9f;
        color: white;
    }

    .button.add {
        background-color: #9FCE67;
        color: white;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
    }

    .upload-case {
        width: 750px;
        padding: 8px;
        border: none;
        outline: none;
        font-size: 16px;
        color: #333;
        border-radius: 4px;
    }

    .input-row {
        padding-top: 25px;
        display: flex;
        gap: 20px;
    }

    .input-field {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

</style>