// Import Svelte writable store
import { writable } from 'svelte/store';
export let pane = writable('backToSummary');

export let leftFilterPaneState = writable('closed')

export let selectedNCTID = writable(null);
export let selectedEligibility = writable(null);
export let selectedProtocolID = writable(null);
export let selectedEligID = writable(null);
export let selectedEligName = writable(null);
export let patientLevel = writable(null);