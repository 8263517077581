<script>
    export let logicSet;
    export let selectedDetailType;

    function getOperatorStyles(operator) {
        switch (operator?.toLowerCase()) {
            case 'any':
                return 'operator-any';
            case 'none':
                return 'operator-none';
            default:
                return 'text-gray-700';
        }
    }

    const getDetailsNames = details =>
        details?.map(detail => detail?.name || detail).join(', ') || '';
</script>

<div class="logic-set">
    <div class="logic-set-content">
        <span class={getOperatorStyles(logicSet.operator)}>
            {logicSet.operator}:
        </span>
        {#if selectedDetailType == 'alteration_criteria' }
            <span class="details">{getDetailsNames(logicSet.alt_details)}</span>
        {:else if selectedDetailType == 'disease_criteria'}
            <span class="details">{getDetailsNames(logicSet.concepts)}</span>
        {/if}
    </div>

    {#if logicSet.logic_sets?.length > 0}
        <div class="nested-logic-set">
            {#each logicSet.logic_sets as nestedSet}
                <svelte:self logicSet={nestedSet} selectedDetailType={selectedDetailType}/>
            {/each}
        </div>
    {/if}
</div>

<style>
    .logic-set {
        margin-bottom: 0.5em;
        padding: 0.5em;
        width: 100%;
    }

    .logic-set-content {
        display: flex;
        gap: 0.5em;
        word-break: break-word;
    }

    .nested-logic-set {
        margin-left: 1.5em;
        border-left: 2px solid #eee;
        padding-left: 1em;
        width: calc(100% - 1.5em);
    }

    :global(.operator-any) {
        color: #16a34a;
        font-weight: 700;
        white-space: nowrap;
    }

    :global(.operator-none) {
        color: #dc2626;
        font-weight: 700;
        white-space: nowrap;
    }
</style>