<script>
    import {
        pane,
        patientLevel,
        selectedEligibility,
        selectedEligName,
        selectedEligID,
        selectedProtocolID,
        selectedNCTID
    } from "../../store/instInsights";
    import {Icon} from "svelte-awesome";
    import {faAsterisk, faBell, faCircleXmark, faUserCheck} from "@fortawesome/free-solid-svg-icons";
    import {ChevronRight, ChevronLeft} from 'lucide-svelte';
    import LogicSetDisplay from "./components/LogicSetDisplay.svelte";
    import {createAlertConfig, getUsers} from "../../network/user";
    import {pushFail, pushSuccess} from "../../utils/toast";
    import {getUser} from "../../store/session";
    import {onMount} from "svelte";
    import Modal from "../Modal.svelte";
    import {queryCases} from "../../network/analytics/queries";
    import {faCircleXmark as faCircleXmarkRegular} from "@fortawesome/free-regular-svg-icons";

    let selectedDetails = null;
    let selectedDetailType = null;
    let isPanelVisible = false;
    let eligibilityName = null;
    let eligibilityID = null;
    let shareAlertOption = false;
    let setAlertName = false;
    let alertName = '';
    let alertFrequency = '';
    let alertTime = '';
    let notificationFrequency = '';
    let selectedDay = "";
    let numOfDays = "";
    let alertFrequencyDay = "";
    let shareAlertModal;
    let matchedPatientCounts = {};

    function getOperatorStyles(operator) {
        switch (operator?.toLowerCase()) {
            case 'any':
                return 'operator-any';
            case 'none':
                return 'operator-none';
            case 'all':
                return 'operator-all';
            default:
                return 'text-gray-700';
        }
    }

    const getDetailsNames = details =>
        details?.map(detail => detail?.name || detail).join(', ') || '';

    function hasNestedLogicSets(eligibility, criteriaType) {
        return eligibility?.[criteriaType]?.logic_sets?.some(set =>
            set.logic_sets?.length > 0
        );
    }

    let rows = [];
    async function retrieveUsers() {
        const response = await getUsers();
        if (response.ok) {
            const body = await response.json();
            rows = body.results;
        }
    }

    function handleAlertCreation(name, id, has_alert) {
        if (!has_alert) {
            eligibilityName = name;
            eligibilityID = id;
            selectedDetails = null;
            isPanelVisible = true;
        }
    }

    async function viewPatients(name, id) {
        selectedEligName.set(name);
        selectedEligID.set(id);
        patientLevel.set("eligibility");
        pane.set("patient_list");
    }

    async function getNumMatchedPatients(id) {
        let filters = [
            {
                "core": "recommendations",
                "type": "term",
                "field": "eligibility_id",
                "values": [{"value": id}],
                "display": "Eligibility ID: " + id,
                "operator": "Any"
            }
        ];
        let response = await queryCases(filters, true, 1, 1);
        if (response.ok) {
            const results = await response.json();
            matchedPatientCounts[id] = results.hits;
        } else {
            pushFail('Unable to fetch query');
            matchedPatientCounts[id] = "";
        }
    }

    function loadMatchedPatientCount(elig) {
        getNumMatchedPatients(elig.id);
    }

    function determineFrequencyInfo() {
        if (notificationFrequency === "instantaneous") {
            alertFrequency = 0;
            alertFrequencyDay = null;
        } else if (notificationFrequency === "daily") {
            alertFrequency = 1;
            alertFrequencyDay = null;
        } else if (notificationFrequency === "everyNumOfDays") {
            alertFrequency = numOfDays;
            alertFrequencyDay = null;
        } else if (notificationFrequency === "dayOfWeek") {
            alertFrequency = 7;
            alertFrequencyDay = selectedDay;
        }
    }

    function handleClear() {
        shareAlertOption = false;
        setAlertName = false;
        alertName = '';
        alertFrequency = '';
        notificationFrequency = '';
        alertTime = '';
        selectedDay = '';
        numOfDays = '';
        selectedItems = new Set();
    }

    let selectedItems = new Set();
    function handleCheckboxChange(item) {
        if (selectedItems.has(item)) {
            selectedItems.delete(item);
        } else {
            selectedItems.add(item);
        }
    }

    const onSaveAlert = async () => {
        if (alertName === '' && !setAlertName) {
            pushFail("Must add an alert name.");
        } else if (notificationFrequency === '') {
            pushFail("Must add a notification frequency.");
        } else if (notificationFrequency === "everyNumOfDays" && numOfDays === "") {
            pushFail("Must add a value for Every __ Days entry.");
        } else if (notificationFrequency === "everyNumOfDays" && numOfDays <= 1) {
            pushFail("Must add a value for Every __ Days entry above 1.")
        } else if (notificationFrequency === "dayOfWeek" && selectedDay === "") {
            pushFail("Must choose a day of the week.");
        } else if (shareAlertOption) {
            shareAlertModal.open();
        } else {
            determineFrequencyInfo();
            if (setAlertName) {
                alertName = eligibilityName
            }
            let body = {
                "name": alertName,
                "cadence": alertFrequency,
                "alert_parameters": {
                    "filters": [
                        {
                            "core": "recommendations",
                            "type": "term",
                            "field": "eligibility_id",
                            "values": [{"value": eligibilityID}],
                            "display": "Eligibility ID: " + eligibilityID,
                            "operator": "Any"
                        }
                    ]
                }
            };
            if (alertFrequencyDay !== "") {
                body["cadence_day"] = alertFrequencyDay;
            }
            if (alertTime !== "") {
                body["time"] = alertTime;
            }
            let response = await createAlertConfig(getUser().id, body);

            if (response.ok) {
                pushSuccess("Alert has been created.");
                shareAlertOption = false;
                handleClear();
                eligibilityName = null;
                pane.set("trial_list");
            } else {
                const body = await response.json();
                if (body.name) {
                    pushFail('This alert name is already in use.');
                } else {
                    pushFail('There was a problem creating the alert.');
                }
            }
        }
    };

    const onSaveShareAlert = async () => {
        determineFrequencyInfo();
        if (setAlertName) {
            alertName = eligibilityName
        }
        let body = {
            "name": alertName,
            "cadence": alertFrequency,
            "shared_users": Array.from(selectedItems).map(item => item.username).join(','),
            "alert_parameters": {
                "filters": [
                    {
                        "core": "recommendations",
                        "type": "term",
                        "field": "eligibility_id",
                        "values": [{"value": eligibilityID}],
                        "display": "Eligibility ID: " + eligibilityID,
                        "operator": "Any"
                    }
                ]
            }
        };
        if (alertFrequencyDay !== "") {
            body["cadence_day"] = alertFrequencyDay;
        }
        if (alertTime !== "") {
            body["time"] = alertTime;
        }
        let response = await createAlertConfig(getUser().id, body);
        if (response.ok) {
            pushSuccess("Alert has been created.");
            shareAlertModal.close();
            shareAlertOption = false;
            handleClear();
            eligibilityName = null;
            pane.set("trial_list");
        } else {
            const body = await response.json();
            if (body.name) {
                pushFail('This alert name is already in use.');
            } else {
                pushFail('There was a problem creating the alert.');
            }
        }
    };

    function toggleAlertPanel() {
        if (isPanelVisible) {
            eligibilityName = null;
            eligibilityID = null;
            handleClear();
        }
        isPanelVisible = !isPanelVisible;
    }

    function handleViewDetails(eligibility, type) {
        selectedDetailType = type;
        selectedDetails = eligibility;
        eligibilityName = null;
        eligibilityID = null;
        isPanelVisible = true;
    }

    function toggleSeeMorePanel() {
        if (isPanelVisible) {
            selectedDetails = null;
        }
        isPanelVisible = !isPanelVisible;
    }

    function exitPage() {
        pane.set("trial_list");
    }

    onMount(() => {
        for (const e of $selectedEligibility) {
            loadMatchedPatientCount(e);
        }
        retrieveUsers();
    });

</script>

<style>
    .container {
        display: flex;
        height: calc(74vh - 70px);
        width: 100%;
        gap: 1em;
        overflow: hidden; /* Add this */
    }

    .summary {
        margin: 1em 1em;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1em;
        flex: 1; /* Changed from flex-grow: 1 */
        transition: width 0.3s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        min-width: 0; /* Add this */
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    thead th {
        position: sticky;
        top: 0;
    }

    th {
        background-color: #233C6B;
        color: #ffffff;
    }

    tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    .scrollable-table {
        overflow-y: auto;
        max-height: calc(64vh - 4em);
        border-radius: 10px;
    }

    .icon-action {
        cursor: pointer;
        color: #233C6B;
    }

    .icon-action:hover {
        opacity: 0.8;
    }

    .see-more {
        color: #233C6B;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 0.5em;
    }

    .see-more:hover {
        opacity: 0.8;
    }

    .toggle-see-more-panel-button {
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
        background: #233C6B;
        color: white;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 10;
    }

    /* When hidden */
    .hidden {
        flex: 0;
        width: 0;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    .see-more-details-pane-container {
        flex: 0 0 35%; /* Add this - don't grow, don't shrink, start at 30% */
        transition: all 0.3s ease;
        position: relative;
    }

    .see-more-details-pane {
        height: calc(74vh - 135px); /* Match container height */
        margin: 1em 1em 1em 0;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1em;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        overflow: hidden; /* Add this */
    }

    .logic-sets {
        flex: 1;
        overflow-y: auto;
        padding-right: 1em;
    }

    /* Add styles for the main operator displays in the table */
    :global(.operator-any) {
        color: #16a34a;
        font-weight: 700;
    }

    :global(.operator-none) {
        color: #dc2626;
        font-weight: 700;
    }

    :global(.operator-all) {
        color: #002aff;
        font-weight: 700;
    }

    .alert-details-pane-container {
        flex: 0 0 25%; /* Add this - don't grow, don't shrink, start at 30% */
        transition: all 0.3s ease;
        position: relative;
        width: 0;
    }

    .alert-details-pane {
        height: calc(74vh - 135px); /* Match container height */
        margin: 1em 1em 1em 0;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1em;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        overflow: hidden; /* Add this */
    }

    .alert-field-first{
        margin-top: 10px;
    }
    .alert-field-second{
        margin-top: 20px;
        display: flex;
    }
    .alert-input-first{
        width: 20vw;
    }
    .alert-input-second{
        float: left;
        width: 8vw;
    }
    .alert-modal-button{
        margin-right: 10px;
        padding: 8px 16px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    .alert-modal-checkbox{
        margin-top: 10px;
        margin-left: 10px;
    }
    .alert-modal-first-row{
        margin-top: 10px;
        display: flex;
    }

    .footer {
        margin-left: 10px;
        margin-right: 10px;
    }

    .content {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 15px;
    }
    .toggle-alert-panel-button {
        margin-left: 25px;
    }
    .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .icon-container {
        position: relative;
        display: inline-block;
        cursor: pointer;
    }

    .checkmark-overlay {
        position: absolute;
        bottom: 12px;
        left: 10px;
        background-color: yellowgreen;
        color: white;
        font-size: 0.8rem;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid white;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    }

    .icon-container:hover::after {
        content: attr(data-tooltip);
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        background-color: black;
        color: white;
        padding: 4px 8px;
        font-size: 0.75rem;
        border-radius: 4px;
        white-space: nowrap;
        z-index: 10;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    .icon-container:hover::before {
        content: '';
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent black transparent;
        z-index: 9;
    }
</style>

<div class="container">
    <div class="summary">
        <div class="title">
            <div>
                <b>Currently Exploring:</b> {$selectedProtocolID} / {$selectedNCTID}
            </div>
            <div style="margin-left: auto; display: flex; align-items: center;">
                <div on:click={() => exitPage()} aria-hidden="true" style="margin-bottom: 10px">
                        <span title="Close">
                            <Icon data={faCircleXmarkRegular} scale="2" style="color: #243c6a;"/>
                        </span>
                </div>
            </div>
        </div>
        <div class="scrollable-table">
            <table>
                <thead>
                <tr>
                    <th>Eligibility</th>
                    <th style="width: 15%">Biomarker Criteria</th>
                    <th>Disease Criteria</th>
                    <th>Treatments</th>
                    <th>Disease State Requirements</th>
                    <th style="width: 25%">Prior Treatment Requirements</th>
                    <th>Create Alerts</th>
                    <th>View Matched Patients</th>
                </tr>
                </thead>
                <tbody>
                {#each $selectedEligibility as elig, i}
                    <tr class:even={i % 2 === 1} style="background-color:
                                   {(selectedDetails && selectedDetails.id === elig.id) || (eligibilityID && eligibilityID === elig.id) ? 'lightyellow' : ''}">
                        <td>{elig.name}</td>
                        <td>
                            <div>
                                {#if elig?.alteration_criteria?.logic_sets?.length > 0}
                                    {#each elig?.alteration_criteria?.logic_sets || [] as logicSet}
                                        <div>
                                            <span class={getOperatorStyles(logicSet.operator)}>
                                                {logicSet.operator}:
                                            </span>
                                            <span>{getDetailsNames(logicSet.alt_details)}</span>
                                        </div>
                                    {/each}
                                    {#if hasNestedLogicSets(elig, 'alteration_criteria')}
                                        <span
                                                class="see-more"
                                                on:click={() => handleViewDetails(elig, 'alteration_criteria')}
                                        >
                                            see more
                                        </span>
                                    {/if}
                                {:else}
                                    <div>
                                        <b><i>*No Biomarker Based Eligibility</i></b>
                                    </div>
                                {/if}
                            </div>
                        </td>
                        <td>
                            <div>
                                {#each elig?.disease_criteria?.logic_sets || [] as logicSet}
                                    <div>
                                        <span class={getOperatorStyles(logicSet.operator)}>
                                            {logicSet.operator}:
                                        </span>
                                        <span>{getDetailsNames(logicSet.concepts)}</span>
                                    </div>
                                {/each}
                                {#if hasNestedLogicSets(elig, 'disease_criteria')}
                                    <span
                                            class="see-more"
                                            on:click={() => handleViewDetails(elig, 'disease_criteria')}
                                    >
                                        see more
                                    </span>
                                {/if}
                            </div>
                        </td>
                        <td>
                            <div>
                                {#each elig?.treatment_contexts as context}
                                    <div>
                                        <div><strong>Therapy:</strong> {context.drugs.map(drug => drug.name).join(', ')}
                                        </div>
                                        <div><strong>Treatment
                                            Settings:</strong> {context.therapeutic_context.join(', ')}</div>
                                    </div>
                                {/each}
                            </div>
                        </td>
                        <td>
                            <div>
                                {elig?.disease_criteria?.disease_states.map(ds => ds).join(', ')}
                            </div>
                        </td>
                        <td>
                            <div>
                                {#each elig?.treatment_contexts as context}
                                    {#each context?.prior_interventions as prior_intervention}
                                        <div>
                                            <div><strong>Any:</strong>{prior_intervention.earliestAlphabeticalConcept}</div>
                                            <div><strong>Disease
                                                State:</strong> {prior_intervention.disease_states.join(', ')}
                                            </div>
                                            <div><strong>Treatment
                                                Setting:</strong> {prior_intervention.therapeutic_contexts.join(', ')}
                                            </div>
                                        </div>
                                    {/each}
                                {/each}
                            </div>
                        </td>
                        <td>
                            <div class="icon-action" on:click={() => handleAlertCreation(elig.name, elig.id, elig.has_alert)} aria-hidden="true">
                                {#if elig.has_alert}
                                    <div class="icon-container" data-tooltip="Alert has already been created">
                                        <Icon data={faBell} style="scale: 2"/>
                                        <div class="checkmark-overlay">✔</div>
                                    </div>
                                {:else}
                                    <Icon data={faBell} style="scale: 2"/>
                                {/if}
                            </div>
                        </td>
                        <td>
                            <div class="icon-action" on:click={() => viewPatients(elig.name, elig.id)} aria-hidden="true">
                                <div style="display: flex">
                                    <Icon data={faUserCheck} style="scale: 2"/>
                                    <div style="margin-left: 20px">{matchedPatientCounts[elig.id]}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                {/each}
                </tbody>
            </table>
        </div>
    </div>

    {#if selectedDetails}
        <div class="see-more-details-pane-container" class:hidden={!isPanelVisible}>
            <div class="see-more-details-pane">
                <div class="toggle-see-more-panel-button" on:click={toggleSeeMorePanel} aria-hidden="true">
                    {#if isPanelVisible}
                        <ChevronRight class="w-4 h-4"/>
                    {:else}
                        <ChevronLeft class="w-4 h-4"/>
                    {/if}
                </div>
                <h3 class="text-xl font-bold mb-4">{selectedDetails.name}</h3>
                <div class="logic-sets">
                    {#if selectedDetailType}
                        {#each selectedDetails[`${selectedDetailType}`]?.logic_sets || [] as logicSet}
                            <LogicSetDisplay {logicSet} {selectedDetailType}/>
                        {/each}
                    {/if}
                </div>
            </div>
        </div>
    {/if}

    {#if eligibilityName}
        <div class="alert-details-pane-container" class:hidden={!isPanelVisible}>
            <div class="alert-details-pane">
                <div style="display:flex; justify-content:center; align-items: center;">
                    <h3 class="text-xl font-bold mb-4">Create Alert: {eligibilityName}</h3>
                    <div class="toggle-alert-panel-button" on:click={toggleAlertPanel} aria-hidden="true">
                        <div>
                            <Icon data="{faCircleXmark}" style="scale: 1.25"/>
                        </div>
                    </div>
                </div>
                <div>
                    This will create an alert that will send a notification when a patient matches to this curated eligibility of the clinical trial
                </div>
                <div class="content">
                    <div class="alert-field-first">
                        <div style="display: block">
                            <div style="display: flex">
                                <label style="float: left;">Name</label>
                                <Icon data ="{faAsterisk}" scale="1" style="float: left; margin-left: 5px; color: #d98b95"/>
                            </div>
                            <input class="alert-input-first" type="text" bind:value={alertName} name="alertName" placeholder="Alert Name" required>
                        </div>
                    </div>
                    <div style="display:flex; justify-content: flex-start;">
                        <label>
                            <input class="alert-modal-checkbox" type="checkbox" style="width: 15px; height: 15px;" bind:checked={setAlertName}>
                            Use Eligibility Name
                        </label>
                    </div>
                    <div class="alert-field-second">
                        <div style="margin-right: 50px;">
                            <div style="margin-bottom: 10px">
                                <label style="float: left;">Notification Frequency</label>
                                <Icon data ="{faAsterisk}" scale="1" style="float: left; margin-left: 5px; color: #d98b95"/>
                            </div>
                            <div style="text-align: left; margin-top: 10px;">
                                <label>
                                    <input class="frequency-checkbox" type="radio" name="notificationFrequency" value="instantaneous" bind:group={notificationFrequency}>
                                    Instantaneous
                                </label>
                                <label>
                                    <input class="frequency-checkbox" type="radio" name="notificationFrequency" value="daily" bind:group={notificationFrequency}>
                                    Daily
                                </label>
                                <label>
                                    <input class="frequency-checkbox" type="radio" name="notificationFrequency" value="everyNumOfDays" bind:group={notificationFrequency}>
                                    Every __ Days
                                </label>
                                {#if notificationFrequency === "everyNumOfDays"}
                                    <input type="text" bind:value={numOfDays}>
                                {/if}
                                <label>
                                    <input class="frequency-checkbox" type="radio" name="notificationFrequency" value="dayOfWeek" bind:group={notificationFrequency}>
                                    Weekly
                                </label>
                                {#if notificationFrequency === "dayOfWeek"}
                                    <select bind:value={selectedDay}>
                                        <option value="">Select a day</option>
                                        <option value="Monday">Monday</option>
                                        <option value="Tuesday">Tuesday</option>
                                        <option value="Wednesday">Wednesday</option>
                                        <option value="Thursday">Thursday</option>
                                        <option value="Friday">Friday</option>
                                        <option value="Saturday">Saturday</option>
                                        <option value="Sunday">Sunday</option>
                                    </select>
                                {/if}
                            </div>
                        </div>
                        <div>
                            {#if notificationFrequency !== "instantaneous"}
                                <label style="text-align: left">Notification Time</label>
                                <input class="alert-input-second" type="time" bind:value={alertTime}/>
                            {/if}
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <div class="alert-modal-first-row">
                        <button class="alert-modal-button" style="background-color: lightcoral" on:click={() => handleClear()}>Clear</button>
                        <button class="alert-modal-button" style="background-color: #9FCE67" on:click={() => onSaveAlert()}>Create</button>
                        <label>
                            <input class="alert-modal-checkbox" type="checkbox" style="width: 15px; height: 15px;" bind:checked={shareAlertOption}>
                            Share Alert
                        </label>
                    </div>
                </div>
            </div>
        </div>
    {/if}
</div>

<!--Share Alert Modal-->
<form on:submit|preventDefault={onSaveShareAlert}>
    <Modal bind:modalFunc={shareAlertModal}>
        <div slot="header">
            <div>
                <h1>Please select which users to share notifications with.</h1>
            </div>
        </div>
        <div slot="content">
            <table class="table">
                <thead>
                <tr>
                    <th></th>
                    <th>Username</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                </tr>
                </thead>
                <tbody>
                {#each rows as item}
                    <tr>
                        <td><input type="checkbox" checked={selectedItems.has(item)} on:change={() => handleCheckboxChange(item)} /></td>
                        <td>{item.username}</td>
                        <td>{item.first_name}</td>
                        <td>{item.last_name}</td>
                        <td>{item.email}</td>
                    </tr>
                {/each}
                </tbody>
            </table>
        </div>
        <div slot="footer">
            <div class="alert-modal-footer-row">
                <button class="alert-modal-button" style="background-color: lightcoral" on:click={() => shareAlertModal.close()}>Cancel</button>
                <button class="alert-modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Create</button>
            </div>
        </div>
    </Modal>
</form>