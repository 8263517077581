<script>
    import {Icon} from "svelte-awesome";
    import {
        faPenToSquare,
        faXmark,
        faTriangleExclamation
    } from "@fortawesome/free-solid-svg-icons";
    import {faCircleXmark, faCircleRight, faCircleLeft,} from "@fortawesome/free-regular-svg-icons";
    import {
        pane,
        selectedCase,
        dsList,
        additionalPatientDetails,
        historical_match_params,
        selectedPatient
    } from "../../store/patientMatch";
    import {HSplitPane} from "svelte-split-pane";
    import PatientInfoLeftNav from "./PatientInfoLeftNav.svelte";
    import {afterUpdate, createEventDispatcher, onMount, tick} from 'svelte';
    import {suggestDiagnosis} from "../../network/patient_search";
    import {
        createTreatment,
        getPatient,
        updateCase,
        updatePatient, updatePatientDiagnosis,
        updatePatientMeta,
        updateTreatment
    } from "../../network/patient_match";
    import {toast} from "@zerodevx/svelte-toast";
    import {getPdModOverrideVar} from "../../store/session";

    const dispatch = createEventDispatcher();

    let showLabel = '';

    let diagnosisSuggestions = [];
    let diagnosis = '';
    let diagnosisDisplay = 'none';
    let diagInput;

    let cancerICD10Code = '';

    let editPage = false;
    let viewAddlFieldsPane = false;

    let activeDiv;
    let isResizing = false;

    let resizableDivs = [];
    let mainDiv;

    let openStageDropdown = -1;

    let mrn, patientName, date_of_birth, gender, selectedDiagnoses, selectedCancerICD10Codes, metaData;

    $: if ($historical_match_params && $historical_match_params.patient_fields) {
        mrn = $historical_match_params.patient_fields.patient_mrn;
        patientName = $historical_match_params.patient_fields.patient_name;
        date_of_birth = $historical_match_params.patient_fields.date_of_birth;
        gender = $historical_match_params.patient_fields.gender;
        selectedDiagnoses = $historical_match_params.patient_fields.diagnoses;
        selectedCancerICD10Codes = $historical_match_params.patient_fields.cancer_icd10_codes;
        metaData = $historical_match_params.patient_fields.patient_meta_data
    }

    $: changesDetected = $historical_match_params?.unsaved_updated_fields?.patient_fields ?? false;

    $: maxICD10Height = selectedCancerICD10Codes && selectedCancerICD10Codes.length > 0
        ? `calc(${selectedCancerICD10Codes.length} * 32px)`
        : '32px';

    let metaFields = []

    $: {
        metaFields = [];
        for (let i = 0; i < $additionalPatientDetails.length; i += 3) {
            metaFields.push($additionalPatientDetails.slice(i, i + 3));
        }
    }

    function getCurrentTime() {
        return new Date().toLocaleTimeString();
    }

    function addResizableDiv(div) {
        if (div && !resizableDivs.includes(div)) {
            resizableDivs.push(div);
            div.addEventListener('mousedown', (e) => onMouseDown(e, div));
        }
    }

    function onMouseMove(event) {
        if (isResizing && activeDiv) {
            const newHeight = event.clientY - activeDiv.getBoundingClientRect().top;
            activeDiv.style.height = `${newHeight}px`;

            const combinedHeight = resizableDivs.reduce((maxHeight, div) => {
                return Math.max(maxHeight, div.scrollHeight);
            }, 0);

            const mainDivMaxHeight = parseInt(getComputedStyle(mainDiv).maxHeight);

            if (combinedHeight > mainDivMaxHeight) {
                mainDiv.style.height = `${combinedHeight}px`;
                mainDiv.scrollTop = mainDiv.scrollHeight;
            } else {
                mainDiv.style.height = `${Math.max(combinedHeight, mainDivMaxHeight)}px`;
            }
        }
    }

    function onMouseUp() {
        isResizing = false;
        activeDiv = null;
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
    }

    function onMouseDown(event, div) {
        isResizing = true;
        activeDiv = div;
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    }

    function handleClickOutside(event) {
        if (diagInput && !diagInput.contains(event.target)) {
            diagnosisDisplay = 'none';
        }
    }

    afterUpdate(async () => {
        await tick(); // Wait for the DOM to update
        resizableDivs.forEach(div => {
            div.addEventListener('mousedown', (e) => onMouseDown(e, div));
        });
    });

    onMount(() => {
        document.addEventListener('click', handleClickOutside);

    });

    function toggleEditMode() {
        editPage = !editPage;
        // Whenever Edit Page is closed make an update to the historical params store
        if (!editPage){
            Object.keys($historical_match_params.patient_fields).forEach(field => {
                if (field !== 'patient_meta_data'){
                    const currentValue = $historical_match_params.patient_fields[field];
                    const historicalValue = $historical_match_params.historical_fields.patient_fields[field];


                    updateStore(field, currentValue);
                }
            })
        }
    }

    function handleCancerICD10KeyUp() {
        if (event.key === 'Enter') {
            handleCancerICD10CodeSelect(cancerICD10Code);
        }
    }

    function updateStoreMetaField(metaField, updatedMeta) {
        historical_match_params.update(params => {
            const historical_patient_fields = params.historical_fields.patient_fields
            const historical_meta = historical_patient_fields['patient_meta_data']

            const patient_fields = params.patient_fields;
            const meta = patient_fields['patient_meta_data']

            const updated_fields = {...params.updated_fields};
            const unsaved_updated_fields = {...params.unsaved_updated_fields};

            if (!updated_fields.patient_fields){
                updated_fields.patient_fields = {
                    'patient_name': {},
                    'patient_mrn': {},
                    'patient_meta_data': {},
                    'gender': {},
                    'date_of_birth': {},
                    'cancer_icd10_codes': {},
                    'diagnoses': {},
                }
            }

            meta[metaField] = updatedMeta
            if (JSON.stringify(historical_meta[metaField]) !== JSON.stringify(meta[metaField])) {
                updated_fields.patient_fields.patient_meta_data[metaField] = {
                    field: metaField,
                    value: updatedMeta,
                    timeStamp: getCurrentTime(),
                    saved: false
                };
            }
            else {
                delete updated_fields.patient_fields.patient_meta_data[metaField];

                const patientFieldsEmpty = Object.values(updated_fields.patient_fields).every(field => {
                    return Object.keys(field).length === 0;
                });

                if (patientFieldsEmpty){
                    delete updated_fields.patient_fields
                }
            }

            const recalculate_match = Object.values(updated_fields).length > 0;

            unsaved_updated_fields['patient_fields'] = Object.keys(updated_fields).length > 0;
            return {
                ...params,
                patient_fields,
                updated_fields,
                unsaved_updated_fields,
                recalculate_match};
        });
    }

    function updateStore(field, updatedValue) {
        historical_match_params.update(params => {
            const historical_patient_fields = params.historical_fields.patient_fields

            const patient_fields = params.patient_fields;

            const updated_fields = {...params.updated_fields};
            const unsaved_updated_fields = {...params.unsaved_updated_fields};

            if (!updated_fields.patient_fields){
                updated_fields.patient_fields = {
                    'patient_name': {},
                    'patient_mrn': {},
                    'patient_meta_data': {},
                    'gender': {},
                    'date_of_birth': {},
                    'cancer_icd10_codes': {},
                    'diagnoses': {},
                }
            }

            patient_fields[field] = updatedValue;

            if (JSON.stringify(historical_patient_fields[field]) !== JSON.stringify(patient_fields[field])) {
                updated_fields.patient_fields[field] = {
                    field: field,
                    value: updatedValue,
                    timeStamp: getCurrentTime(),
                    saved: false
                };
            }
            else {
                updated_fields.patient_fields[field] = {};

                const patientFieldsEmpty = Object.values(updated_fields.patient_fields).every(field => {
                    return Object.keys(field).length === 0;
                });

                if (patientFieldsEmpty){
                    delete updated_fields.patient_fields
                }
            }

            // Filter out fields where `saved === true`
            const filtered_updated_fields = Object.entries(updated_fields.patient_fields || {}).filter(
                ([, value]) => value && !value.saved
            );
            const has_unsaved_fields = filtered_updated_fields.length > 0;

            // Update unsaved_updated_fields based on the filtered list
            unsaved_updated_fields['patient_fields'] = has_unsaved_fields;

            const recalculate_match = Object.values(updated_fields).length > 0;

            return {
                ...params,
                patient_fields,
                updated_fields,
                unsaved_updated_fields,
                recalculate_match};
        });
    }

    const removeMetaField = (metaField, index) => {
        const updatedMeta = metaData[metaField].filter((_, i) => i !== index);
        updateStoreMetaField(metaField, updatedMeta);

    }

    const addMetaField = (event, metaField) => {
        if (event.key === "Enter"){
            const value = event.target.value
            const updatedMeta = [value, ...metaData[metaField]];
            updateStoreMetaField(metaField, updatedMeta);
        }
    }

    const removeCancerICD10Code = (index) => {
        selectedCancerICD10Codes = selectedCancerICD10Codes.filter((_, i) => i !== index);
        historical_match_params.update(params => {
            const patient_fields = params.patient_fields;
            patient_fields['cancer_icd10_codes'] = selectedCancerICD10Codes

            return {
                ...params,
                patient_fields
            };
        });
    };

    const handleCancerICD10CodeSelect = (input) => {
        selectedCancerICD10Codes = [input, ...selectedCancerICD10Codes];
        historical_match_params.update(params => {
            const patient_fields = params.patient_fields;
            patient_fields['cancer_icd10_codes'] = selectedCancerICD10Codes

            return {
                ...params,
                patient_fields
            };
        });
        cancerICD10Code = "";
    };

    const removeDiagnosis = (index) => {
        selectedDiagnoses = selectedDiagnoses.filter((_, i) => i !== index);
        historical_match_params.update(params => {
            const patient_fields = params.patient_fields;
            patient_fields['diagnoses'] = selectedDiagnoses

            return {
                ...params,
                patient_fields
            };
        });
    };

    const updateDiagnosis = (index, value) => {
        selectedDiagnoses = selectedDiagnoses.map((diagnosis, i) =>
            i === index ? { ...diagnosis, stage: selectedDS } : diagnosis
        );

        historical_match_params.update(params => {
            const patient_fields = params.patient_fields;
            patient_fields['diagnoses'] = selectedDiagnoses

            return {
                ...params,
                patient_fields
            };
        });
    };

    const fetchDiagnosisSuggestions = async () => {
        try {
            const suggestion_resp = await suggestDiagnosis(diagnosis);

            if (Array.isArray(suggestion_resp['results'])) {
                diagnosisSuggestions = [...new Set(suggestion_resp['results'].map((item) => item.text))];
            } else {
                console.error("Invalid diagnosis suggestions response:", suggestion_resp);
                diagnosisSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching diagnosis suggestions:", error);
        }
    };

    const handleDiagnosisInput = async () => {
        diagnosisDisplay = 'block';
        await fetchDiagnosisSuggestions();
    };

    const handleDiagnosisSelect = (suggestion) => {
        const diagEntry = {
            "diagnosis": suggestion,
            "date_of_diagnosis": "N/A",
            "stage": "N/A"
        }
        selectedDiagnoses = [diagEntry, ...selectedDiagnoses];
        historical_match_params.update(params => {
            const patient_fields = params.patient_fields;
            patient_fields['diagnoses'] = selectedDiagnoses

            return {
                ...params,
                patient_fields
            };
        });
        diagnosis = "";
        diagnosisSuggestions = [];
    };

    let selectedDS = '';
    const handleSingleCheckboxChangeDS = (e) => {
        if (e.target.checked) {
            selectedDS = e.target.value;
        } else {
            selectedDS = '';
        }
    };

    function selectStage(index, value){
        updateDiagnosis(index, value);
        selectedDS = '';
        openStageDropdown = -1;
    }

    async function saveCase() {
        pushSuccess('Saving ...');
        await Promise.resolve(updateCase($selectedPatient.uuid, $historical_match_params.updated_fields.patient_fields))
        await Promise.resolve(updatePatientMeta($selectedCase, $historical_match_params.updated_fields.patient_fields))
        await Promise.resolve(updatePatientDiagnosis($selectedCase.uuid, $historical_match_params.updated_fields.patient_fields))

        historical_match_params.update(params => {
            const updated_fields = Object.entries(params.updated_fields.patient_fields).map(([key, value]) => {
                return [key, { ...value, saved: true }];
            });

            const updatedFieldsObj = Object.fromEntries(updated_fields);

            let unsaved_updated_fields = { ...params.unsaved_updated_fields };
            unsaved_updated_fields['patient_fields'] = false;

            let recalculate_match = Object.values(updated_fields).length > 0;

            let updated_historical_fields = {
                ...params.historical_fields,
                patient_fields: { ...params.patient_fields }
            };

            return {
                ...params,
                updated_fields: updatedFieldsObj,
                unsaved_updated_fields,
                recalculate_match,
                historical_fields: updated_historical_fields
            };
        });


        removeToast();
    }


    let toastId;

    function pushSuccess(message) {
        toastId = toast.push(message, {
            theme: {
                '--toastBackground': '#fff',
                '--toastProgressBackground': '#233C6B',
                '--toastColor': '#000',
            },
            duration: 10000
        });
    }

    function removeToast() {
        if (toastId) {
            toast.pop(toastId);
        }
    }

</script>
<div style="width: 100%;">
    {#if (viewAddlFieldsPane)}
        <HSplitPane rightPaneSize={viewAddlFieldsPane ? "80%" : "100%"} leftPaneSize={viewAddlFieldsPane ? "20%" : "0%"}
                    minRightPaneSize=80% minLeftPaneSize={viewAddlFieldsPane ? "20%" : "0%"}>
            <left slot="left">
                {#if (viewAddlFieldsPane)}
                    <svelte:component this={PatientInfoLeftNav}/>
                {/if}
            </left>
            <right slot="right">
                <div>
                    <div class="main-header">
                        <table>
                            <thead>
                            <tr>
                                <th colSpan="2">
                                    <div class="header-content">
                                        <div class="adtl-btn" on:click={() => {viewAddlFieldsPane = !viewAddlFieldsPane}}
                                             aria-hidden="true" style="visibility: {$selectedCase.meta ? 'visible' : 'hidden'}">
                                            {#if !viewAddlFieldsPane}
                                                <Icon data="{faCircleRight}" scale="1.5" style="color: #233C6B;"/>
                                                <p class="header-title">Open Additional Fields Pane</p>
                                            {:else}
                                                <Icon data="{faCircleLeft}" scale="1.5" style="color: #c5c5c7;"/>
                                                <p class="header-title">Close Additional Fields Pane</p>
                                            {/if}
                                        </div>
                                        <div class="page-title-btn">
                                            <h1 style="margin: 0;">Patient Information</h1>
                                            <div style="cursor: pointer;" on:click={toggleEditMode} aria-hidden="true">
                                                {#if editPage}
                                                    <div class="tooltip-container" aria-hidden="true">
                                                        <Icon data="{faCircleXmark}" scale="1.75"
                                                              style="margin-left: 15px; color:#c5c5c7; cursor:pointer;"/>
                                                        <span class="tooltip-text">Close Edit Mode</span>
                                                    </div>
                                                {:else}
                                                    <div class="tooltip-container" aria-hidden="true">
                                                        <Icon data="{faPenToSquare}" scale="1.75"
                                                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                                        <span class="tooltip-text">Edit Details</span>
                                                    </div>
                                                    <button class='save-button' style="visibility: {changesDetected ? 'visible' : 'hidden'}" on:click={() => saveCase()} aria-hidden="true">
                                                        Save Changes
                                                    </button>
                                                {/if}
                                            </div>

                                        </div>
                                        <div on:click={() => pane.set("backToSummary")} aria-hidden="true">
                                            <Icon data="{faXmark}" scale="1.75" class="icon-color-black"/>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="main-content-container" bind:this={mainDiv}>
                        <table class="main-content-table">
                            <tr>
                                <td class="table-content">
                                    <div class="patient-details-row">
                                        {#if editPage}
                                            <!-- Edit mode -->
                                            <div class="input-container">
                                                <h3>Patient Name:</h3>
                                                <input type="text" class="input-field" bind:value={$historical_match_params.patient_fields.patient_name}>
                                            </div>
                                            <div class="input-container">
                                                <h3>Patient MRN:</h3>
                                                <input type="text" class="input-field" bind:value={$historical_match_params.patient_fields.patient_mrn}>
                                            </div>
                                            <div class="input-container">
                                                <h3>Date of Birth:</h3>
                                                <input type="date" class="input-field" bind:value={$historical_match_params.patient_fields.date_of_birth}>
                                            </div>
                                            <div class="input-container">
                                                <h3>Gender:</h3>
                                                <select class="select-field"
                                                        bind:value={$historical_match_params.patient_fields.gender}>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                    <option value="Unknown">Unknown</option>
                                                </select>
                                            </div>
                                        {:else}
                                            <!-- View mode -->
                                            <div class="input-container">
                                                <h3>Patient Name:</h3>
                                                <p>{patientName || 'N/A'}</p>
                                            </div>
                                            <div class="input-container">
                                                <h3>Patient MRN:</h3>
                                                <p>{mrn || 'N/A'}</p>
                                            </div>
                                            <div class="input-container">
                                                <h3>Date of Birth:</h3>
                                                <p>{date_of_birth || 'N/A'}</p>
                                            </div>
                                            <div class="input-container">
                                                <h3>Gender:</h3>
                                                <p>{gender || 'N/A'}</p>
                                            </div>
                                        {/if}
                                    </div>
                                </td>
                                <td class="table-content">
                                    <div class="patient-details-row">
                                        {#if editPage}
                                            <!-- Edit mode -->
                                            {#each selectedDiagnoses as value, index (value)}
                                                <div class="diagnosis-input">
                                                    <span class="diagnosis-input-remove" on:click={() => removeDiagnosis(index)} aria-hidden="true">x</span>
                                                    <div class="input-container">
                                                        <h3>Stage:</h3>
                                                        <span class="dropdown">
                                        <span class="dropdown-container" on:click={() => {openStageDropdown = openStageDropdown === index ? -1 : index}} aria-hidden="true">
                                            {value.stage}
                                        </span>
                                                            {#if (openStageDropdown === index)}
                                            <span class="dropdown-menu">
                                                    {#each $dsList as ds, index}
                                                        <label class={index === 0 || index === 3 || index === 6 ? '' : 'indent'}>
                                                            <input type="checkbox"
                                                                   value={ds}
                                                                   checked={selectedDS !== '' ? selectedDS === ds : value.stage === ds}
                                                                   on:change={handleSingleCheckboxChangeDS}>
                                                            {ds}
                                                        </label>
                                                    {/each}
                                                <span class="dropdown-footer">
                                                        <button on:click={()=> selectStage(index, value)}
                                                                style="background-color:#9FCE67; padding: 5px;">Select</button>
                                                        <button on:click={() => {openStageDropdown = -1;}}
                                                                style="background-color:#d98b95; padding: 5px;">Cancel</button>
                                                    </span>
                                                </span>
                                            {/if}
                                        </span>
                                                    </div>
                                                    <div class="input-container">
                                                        <h3>Diagnosis:</h3>
                                                        <p>{value.diagnosis}</p>
                                                    </div>
                                                    <div class="input-container" style="margin-bottom: 15px;">
                                                        <h3>Date of Diagnosis:</h3>
                                                        <input type="date" class="input-field" bind:value={value.date_of_diagnosis}>
                                                    </div>
                                                </div>
                                            {/each}
                                            <div class="input-container" bind:this={diagInput}>
                                                <h3>Add Diagnosis: </h3>
                                                <div class="type-ahead" on:click={() => diagnosisDisplay = diagnosisDisplay === 'none' ? 'block' : 'none'} aria-hidden="true">
                                                    <input
                                                            type="text"
                                                            class="input-field"
                                                            bind:value={diagnosis}
                                                            on:input={handleDiagnosisInput}
                                                            on:click={handleDiagnosisInput}
                                                    />
                                                    <div class="suggestions" style="display:{diagnosisDisplay}">
                                                        {#each diagnosisSuggestions as diag}
                                                            <div class="suggestion-item" on:click={() => handleDiagnosisSelect(diag)} aria-hidden="true">{diag}</div>
                                                        {/each}
                                                    </div>
                                                </div>
                                            </div>
                                        {:else}
                                            <!-- View mode -->
                                            {#each selectedDiagnoses as diagnosis}
                                                <div style="border: 2px solid #ccc; border-radius: 20px; padding: 5px; width: 300px; margin: 5px;">
                                                    <div class="input-container">
                                                        <h3>Stage: </h3>
                                                        <p>{diagnosis.stage}</p>
                                                    </div>
                                                    <div class="input-container">
                                                        <h3>Diagnosis:</h3>
                                                        {#if (getPdModOverrideVar().includes('pd_display_reported_diseases'))}
                                                        {#if $selectedCase.invalid_diseases.includes(diagnosis.diagnosis)}
                                                            <div class="icon-button" on:mouseover={() => {showLabel=diagnosis.diagnosis}}
                                                                 on:mouseleave={() => {showLabel=''}} aria-hidden="true" on:focus>
                                                                <Icon data="{faTriangleExclamation}" style="margin-left: 15px; margin-top: 15px; color:red; cursor:pointer;" />
                                                            </div>
                                                            <p class="tooltip" style="visibility: {showLabel===diagnosis.diagnosis ? 'visible' : 'hidden'}">Invalid Disease</p>
                                                        {/if}
                                                    {/if}<p>{diagnosis.diagnosis}</p>
                                                    </div>
                                                    <div class="input-container">
                                                        <h3>Date of Diagnosis:</h3>
                                                        <p>{diagnosis.date_of_diagnosis}</p>
                                                    </div>
                                                </div>
                                            {/each}
                                        {/if}
                                    </div>
                                </td>
                                <td class="table-content">
                                    <div class="patient-details-row">
                                        {#if editPage}
                                            <!-- Edit mode -->
                                            <div style="margin-top: 5px; max-height: 200px;overflow: scroll; border: 2px solid #ccc; border-radius: 20px; padding: 5px;">
                                                {#each selectedCancerICD10Codes as value, index (value)}
                                                    <div class="selected-values-container">
                                            <span class="selected-value">
                                                {value}
                                                <span class="remove-selection"
                                                      on:click={() => removeCancerICD10Code(index)}
                                                      aria-hidden="true">x</span>
                                            </span>
                                                    </div>
                                                {/each}
                                            </div>
                                            <div class="input-container">
                                                <h3>Cancer ICD10 Codes:</h3>
                                                <input
                                                        type="text"
                                                        class="input-field"
                                                        bind:value={cancerICD10Code}
                                                        on:keyup={handleCancerICD10KeyUp}
                                                />
                                                <br/>
                                            </div>
                                        {:else}
                                            <!-- View mode -->
                                            <div class="input-container">
                                                <h3>Cancer ICD10 Codes: </h3>
                                                <div style="display:flex;
                                            flex-direction: column;
                                            align-items: flex-start;
                                            margin-top: 15px;
                                            margin-left: 5px;
                                            overflow:scroll;
                                            resize: vertical;
                                            transition: height 0.1s ease-in;
                                            border: 1px solid #ccc;
                                            min-height: 50px;
                                            max-height: {maxICD10Height}"
                                                     use:addResizableDiv>

                                                    {#if selectedCancerICD10Codes.length > 0}
                                                        {#each selectedCancerICD10Codes as code, index}
                                                            <p style="margin:0">{code}</p>
                                                        {/each}
                                                    {:else}
                                                        <p class="table-body">N/A</p>
                                                    {/if}
                                                </div>
                                            </div>
                                        {/if}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <h2 style="visibility: {$additionalPatientDetails.length > 0 ? 'visible' : 'hidden'}">
                                        Additional Fields:
                                    </h2>
                                </td>
                            </tr>
                            {#if editPage}
                                {#each metaFields as metaRow}
                                    <tr>
                                        {#each metaRow as metaCol}
                                            <td class="table-content">
                                                {#if Array.isArray(metaData[metaCol])}
                                                    <div style="margin-top: 5px; max-height: 200px;overflow: scroll; border: 2px solid #ccc; border-radius: 20px; padding: 5px;">
                                                        {#each metaData[metaCol] as metaField, index}
                                                            <div class="selected-values-container">
                                                    <span class="selected-value">
                                                        {metaField}
                                                        <span class="remove-selection" on:click={() => removeMetaField(metaCol, index)} aria-hidden="true">x</span>
                                                    </span>
                                                            </div>
                                                        {/each}
                                                    </div>
                                                    <div class="input-container">
                                                        <h3>{metaCol.toString().replaceAll("_", " ")}:</h3>
                                                        <input
                                                                type="text"
                                                                class="input-field"
                                                                on:keydown={(event) => addMetaField(event, metaCol)}
                                                        />
                                                        <br/>
                                                    </div>
                                                {:else}
                                                    <div class="input-container">
                                                        <h3>{metaCol.toString().replaceAll("_", " ")}:</h3>
                                                        <input
                                                                type="text"
                                                                class="input-field"
                                                        />
                                                        <br/>
                                                    </div>
                                                {/if}
                                            </td>
                                        {/each}
                                    </tr>
                                {/each}
                            {:else}
                                {#each metaFields as metaRow}
                                    <tr>
                                        {#each metaRow as metaCol}
                                            <td class="table-content">
                                                <div class="input-container">
                                                    <h3 style="width:125px">{metaCol.toString().replaceAll("_", " ")}:</h3>
                                                    {#if Array.isArray(metaData[metaCol])}
                                        <span style="max-height: 150px;
                                                    overflow: scroll;
                                                    border: 2px solid #ccc;
                                                    border-radius: 20px;
                                                    padding: 5px;
                                                    width: 15vw;
                                                    display:flex;
                                                    flex-direction: column;
                                                    align-items: flex-start;
                                                    margin-top: 15px;
                                                    margin-left: 5px;">
                                            {#each metaData[metaCol] as metaField}
                                                <p>{metaField}</p>
                                            {/each}
                                        </span>
                                                    {:else}
                                                        <p>{metaData[metaCol] || 'N/A'}</p>
                                                    {/if}
                                                </div>
                                            </td>
                                        {/each}
                                    </tr>
                                {/each}
                            {/if}

                        </table>
                    </div>
                </div>
            </right>
        </HSplitPane>
    {:else}
        <div style="display: flex; flex-direction: column;">
            <div class="main-header">
                <table>
                    <thead>
                    <tr>
                        <th colSpan="2">
                            <div class="header-content">
                                <div class="adtl-btn" on:click={() => {viewAddlFieldsPane = !viewAddlFieldsPane}}
                                     aria-hidden="true" style="visibility: {$selectedCase.meta ? 'visible' : 'hidden'}">
                                    {#if !viewAddlFieldsPane}
                                        <Icon data="{faCircleRight}" scale="1.5" style="color: #233C6B;"/>
                                        <p class="header-title">Open Additional Fields Pane</p>
                                    {:else}
                                        <Icon data="{faCircleLeft}" scale="1.5" style="color: #c5c5c7;"/>
                                        <p class="header-title">Close Additional Fields Pane</p>
                                    {/if}
                                </div>
                                <div class="page-title-btn">
                                    <h1 style="margin: 0;">Patient Information</h1>
                                    <div style="cursor: pointer;" on:click={toggleEditMode} aria-hidden="true">
                                        {#if editPage}
                                            <div class="tooltip-container" aria-hidden="true">
                                                <Icon data="{faCircleXmark}" scale="1.75"
                                                      style="margin-left: 15px; color:#c5c5c7; cursor:pointer;"/>
                                                <span class="tooltip-text">Close Edit Mode</span>
                                            </div>
                                        {:else}
                                            <div class="tooltip-container" aria-hidden="true">
                                                <Icon data="{faPenToSquare}" scale="1.75"
                                                      style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                                                <span class="tooltip-text">Edit Details</span>
                                            </div>
                                            <button class='save-button' style="visibility: {changesDetected ? 'visible' : 'hidden'}" on:click={() => saveCase()} aria-hidden="true">
                                                Save Changes
                                            </button>
                                        {/if}
                                    </div>
                                </div>
                                <div on:click={() => pane.set("backToSummary")} aria-hidden="true">
                                    <Icon data="{faXmark}" scale="1.75" class="icon-color-black"/>
                                </div>
                            </div>
                        </th>
                    </tr>
                    </thead>
                </table>
            </div>
            <div class="main-content-container" bind:this={mainDiv}>
                <table class="main-content-table">
                    <tr>
                        <td class="table-content">
                            <div class="patient-details-row">
                                {#if editPage}
                                    <!-- Edit mode -->
                                    <div class="input-container">
                                        <h3>Patient Name:</h3>
                                        <input type="text" class="input-field" bind:value={$historical_match_params.patient_fields.patient_name}>
                                    </div>
                                    <div class="input-container">
                                        <h3>Patient MRN:</h3>
                                        <input type="text" class="input-field" bind:value={$historical_match_params.patient_fields.patient_mrn}>
                                    </div>
                                    <div class="input-container">
                                        <h3>Date of Birth:</h3>
                                        <input type="date" class="input-field" bind:value={$historical_match_params.patient_fields.date_of_birth}>
                                    </div>
                                    <div class="input-container">
                                        <h3>Gender:</h3>
                                        <select class="select-field"
                                                bind:value={$historical_match_params.patient_fields.gender}>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                            <option value="Unknown">Unknown</option>
                                        </select>
                                    </div>
                                {:else}
                                    <!-- View mode -->
                                    <div class="input-container">
                                        <h3>Patient Name:</h3>
                                        <p>{patientName || 'N/A'}</p>
                                    </div>
                                    <div class="input-container">
                                        <h3>Patient MRN:</h3>
                                        <p>{mrn || 'N/A'}</p>
                                    </div>
                                    <div class="input-container">
                                        <h3>Date of Birth:</h3>
                                        <p>{date_of_birth || 'N/A'}</p>
                                    </div>
                                    <div class="input-container">
                                        <h3>Gender:</h3>
                                        <p>{gender || 'N/A'}</p>
                                    </div>
                                {/if}
                            </div>
                        </td>
                        <td class="table-content">
                            <div class="patient-details-row">
                                {#if editPage}
                                    <!-- Edit mode -->
                                    {#each selectedDiagnoses as value, index (value)}
                                        <div class="diagnosis-input">
                                            <span class="diagnosis-input-remove" on:click={() => removeDiagnosis(index)} aria-hidden="true">x</span>
                                            <div class="input-container">
                                                <h3>Stage:</h3>
                                                <span class="dropdown">
                                        <span class="dropdown-container" on:click={() => {openStageDropdown = openStageDropdown === index ? -1 : index}} aria-hidden="true">
                                            {value.stage}
                                        </span>
                                                    {#if (openStageDropdown === index)}
                                            <span class="dropdown-menu">
                                                    {#each $dsList as ds, index}
                                                        <label class={index === 0 || index === 3 || index === 6 ? '' : 'indent'}>
                                                            <input type="checkbox"
                                                                   value={ds}
                                                                   checked={selectedDS !== '' ? selectedDS === ds : value.stage === ds}
                                                                   on:change={handleSingleCheckboxChangeDS}>
                                                            {ds}
                                                        </label>
                                                    {/each}
                                                <span class="dropdown-footer">
                                                        <button on:click={()=> selectStage(index, value)}
                                                                style="background-color:#9FCE67; padding: 5px;">Select</button>
                                                        <button on:click={() => {openStageDropdown = -1;}}
                                                                style="background-color:#d98b95; padding: 5px;">Cancel</button>
                                                    </span>
                                                </span>
                                            {/if}
                                        </span>
                                            </div>
                                            <div class="input-container">
                                                <h3>Diagnosis:</h3>
                                                <p>{value.diagnosis}</p>
                                            </div>
                                            <div class="input-container" style="margin-bottom: 15px;">
                                                <h3>Date of Diagnosis:</h3>
                                                <input type="date" class="input-field" bind:value={value.date_of_diagnosis}>
                                            </div>
                                        </div>
                                    {/each}
                                    <div class="input-container" bind:this={diagInput}>
                                        <h3>Add Diagnosis: </h3>
                                        <div class="type-ahead" on:click={() => diagnosisDisplay = diagnosisDisplay === 'none' ? 'block' : 'none'} aria-hidden="true">
                                            <input
                                                    type="text"
                                                    class="input-field"
                                                    bind:value={diagnosis}
                                                    on:input={handleDiagnosisInput}
                                                    on:click={handleDiagnosisInput}
                                            />
                                            <div class="suggestions" style="display:{diagnosisDisplay}">
                                                {#each diagnosisSuggestions as diag}
                                                    <div class="suggestion-item" on:click={() => handleDiagnosisSelect(diag)} aria-hidden="true">{diag}</div>
                                                {/each}
                                            </div>
                                        </div>
                                    </div>
                                {:else}
                                    <!-- View mode -->
                                    {#each selectedDiagnoses as diagnosis}
                                        <div style="border: 2px solid #ccc; border-radius: 20px; padding: 5px; width: 300px; margin: 5px;">
                                            <div class="input-container">
                                                <h3>Stage: </h3>
                                                <p>{diagnosis.stage}</p>
                                            </div>
                                            <div class="input-container">
                                                <h3>Diagnosis:</h3>
                                                {#if (getPdModOverrideVar().includes('pd_display_reported_diseases'))}
                                                {#if $selectedCase.invalid_diseases.includes(diagnosis.diagnosis)}
                                                    <div class="icon-button" on:mouseover={() => {showLabel=diagnosis.diagnosis}}
                                                         on:mouseleave={() => {showLabel=''}} aria-hidden="true" on:focus>
                                                        <Icon data="{faTriangleExclamation}" style="margin-left: 15px; margin-top: 15px; color:red; cursor:pointer;" />
                                                    </div>
                                                    <p class="tooltip" style="visibility: {showLabel===diagnosis.diagnosis ? 'visible' : 'hidden'}">Invalid Disease</p>
                                                {/if}
                                            {/if}<p>{diagnosis.diagnosis}</p>
                                            </div>
                                            <div class="input-container">
                                                <h3>Date of Diagnosis:</h3>
                                                <p>{diagnosis.date_of_diagnosis}</p>
                                            </div>
                                        </div>
                                    {/each}
                                {/if}
                            </div>
                        </td>
                        <td class="table-content">
                            <div class="patient-details-row">
                                {#if editPage}
                                    <!-- Edit mode -->
                                    <div style="margin-top: 5px; max-height: 200px;overflow: scroll; border: 2px solid #ccc; border-radius: 20px; padding: 5px;">
                                        {#each selectedCancerICD10Codes as value, index (value)}
                                            <div class="selected-values-container">
                                            <span class="selected-value">
                                                {value}
                                                <span class="remove-selection"
                                                      on:click={() => removeCancerICD10Code(index)}
                                                      aria-hidden="true">x</span>
                                            </span>
                                            </div>
                                        {/each}
                                    </div>
                                    <div class="input-container">
                                        <h3>Cancer ICD10 Codes:</h3>
                                        <input
                                                type="text"
                                                class="input-field"
                                                bind:value={cancerICD10Code}
                                                on:keyup={handleCancerICD10KeyUp}
                                        />
                                        <br/>
                                    </div>
                                {:else}
                                    <!-- View mode -->
                                    <div class="input-container">
                                        <h3>Cancer ICD10 Codes: </h3>
                                        <div style="display:flex;
                                            flex-direction: column;
                                            align-items: flex-start;
                                            margin-top: 15px;
                                            margin-left: 5px;
                                            overflow:scroll;
                                            resize: vertical;
                                            transition: height 0.1s ease-in;
                                            border: 1px solid #ccc;
                                            min-height: 50px;
                                            max-height: {maxICD10Height}"
                                             use:addResizableDiv>

                                            {#if selectedCancerICD10Codes.length > 0}
                                                {#each selectedCancerICD10Codes as code, index}
                                                    <p style="margin:0">{code}</p>
                                                {/each}
                                            {:else}
                                                <p class="table-body">N/A</p>
                                            {/if}
                                        </div>
                                    </div>
                                {/if}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3">
                            <h2 style="visibility: {$additionalPatientDetails.length > 0 ? 'visible' : 'hidden'}">
                                Additional Fields:
                            </h2>
                        </td>
                    </tr>
                    {#if editPage}
                        {#each metaFields as metaRow}
                            <tr>
                                {#each metaRow as metaCol}
                                    <td class="table-content">
                                        {#if Array.isArray(metaData[metaCol])}
                                            <div style="margin-top: 5px; max-height: 200px;overflow: scroll; border: 2px solid #ccc; border-radius: 20px; padding: 5px;">
                                                {#each metaData[metaCol] as metaField, index}
                                                    <div class="selected-values-container">
                                                    <span class="selected-value">
                                                        {metaField}
                                                        <span class="remove-selection" on:click={() => removeMetaField(metaCol, index)} aria-hidden="true">x</span>
                                                    </span>
                                                    </div>
                                                {/each}
                                            </div>
                                            <div class="input-container">
                                                <h3>{metaCol.toString().replaceAll("_", " ")}:</h3>
                                                <input
                                                        type="text"
                                                        class="input-field"
                                                        on:keydown={(event) => addMetaField(event, metaCol)}
                                                />
                                                <br/>
                                            </div>
                                        {:else}
                                            <div class="input-container">
                                                <h3>{metaCol.toString().replaceAll("_", " ")}:</h3>
                                                <input
                                                        type="text"
                                                        class="input-field"
                                                />
                                                <br/>
                                            </div>
                                        {/if}
                                    </td>
                                {/each}
                            </tr>
                        {/each}
                    {:else}
                        {#each metaFields as metaRow}
                            <tr>
                                {#each metaRow as metaCol}
                                    <td class="table-content">
                                        <div class="input-container">
                                            <h3 style="width:125px">{metaCol.toString().replaceAll("_", " ")}:</h3>
                                            {#if Array.isArray(metaData[metaCol])}
                                        <span style="max-height: 150px;
                                                    overflow: scroll;
                                                    border: 2px solid #ccc;
                                                    border-radius: 20px;
                                                    padding: 5px;
                                                    width: 15vw;
                                                    display:flex;
                                                    flex-direction: column;
                                                    align-items: flex-start;
                                                    margin-top: 15px;
                                                    margin-left: 5px;">
                                            {#each metaData[metaCol] as metaField}
                                                <p>{metaField}</p>
                                            {/each}
                                        </span>
                                            {:else}
                                                <p>{metaData[metaCol] || 'N/A'}</p>
                                            {/if}
                                        </div>
                                    </td>
                                {/each}
                            </tr>
                        {/each}
                    {/if}

                </table>
            </div>
        </div>
    {/if}
</div>


<style>

    .tooltip-container {
        position: relative;
        display: inline-block;
    }

    .tooltip-text {
        visibility: hidden;
        width: 100px;
        background-color: #f2f2f2;
        color: #000000;
        text-align: center;
        border-radius: 5px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        top: 125%;
        left: 50%;
        margin-left: -40px;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .tooltip-container:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }

    .tooltip-container:hover .tooltip-icon {
        color: #e79a45;
    }

    .main-header {
        width: 95%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
    }

    .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .adtl-btn {
        cursor: pointer;
        display: flex;
        justify-content: start;
    }

    .header-title {
        font-weight: normal;
        position: absolute;
        top: -2.5px;
        left: 30px;
        z-index: 999;
    }

    .page-title-btn {
        display: flex;
        align-items: center;
        justify-content: flex-start
    }

    .main-content-container {
        max-height: 61vh;
        overflow-y: auto;
    }

    .table-content {
        vertical-align: top;
        width: 25vw;
    }

    .table-body {
        cursor: default;
    }

    .save-button {
        margin-left: 25px;
        background-color: white;
        padding: 10px 40px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .main-content-table {
        margin-left: 45px;
    }

    h3 {
        font-size: 14px;
        text-align: left;
    }

    p {
        font-size: 12px;
        padding: 4px;
        text-align: left;
    }

    .select-field {
        padding-top: 0;
        padding-bottom: 0;
        font-size: 12px;
        margin-top: 15px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .input-field {
        font-size: 12px;
        margin-top: 15px;
        margin-left: 5px;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

    .input-container {
        display: flex;
    }

    .selected-values-container {
        display: flex;
        flex-wrap: wrap;
        width: 25vw;
        height:auto;
    }

    .selected-value {
        display: flex;
        align-items: center;
        background-color: #233C6B;
        color: white;
        padding: 5px 10px;
        border-radius: 20px;
        margin-bottom: 5px;
        font-size: 12px;
        gap: 5px;
    }

    .diagnosis-input {
        position: relative;
        width: 60%;
        background-color: #233C6B;
        color:white;
        border-radius: 20px;
        padding: 25px;
        margin-bottom: 5px;
    }

    .diagnosis-input-remove{
        position: absolute;
        top: 25px;
        right: 25px;
        transform: translateY(-50%);
        cursor: pointer;
    }

    .remove-selection {
        margin-left: auto;
        cursor: pointer;
    }

    .patient-details-row {
        margin-left: 10px;
    }

    .suggestions {
        z-index: 1;
        background-color: #f9f9f9;
        border-radius: 5px;
        max-height: 150px;
        overflow-y: auto;
        width: 200px;
    }

    .suggestion-item {
        padding: 8px;
        cursor: pointer;
    }

    .type-ahead {
        width: 100px;
    }

    .indent {
        margin-left: 20px;
    }

    .dropdown {
        position: relative;
        display: inline-block;
        cursor: pointer;
        text-align: left;
    }

    .dropdown-container {
        border: 1px solid #ccc;
        border-radius:  4px;
        position: absolute;
        bottom: 20%;
        width: 125px;
        margin-left: 5px;
    }

    .dropdown-menu {
        color:black;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 999;
        border: 1px solid #ccc;
        border-top: none;
        border-radius: 0 0 4px 4px;
        background-color: #fff;
        padding: 5px;
        margin: 0;
        width: 300px;
    }

    .dropdown-footer {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
    }

    .tooltip {
        position: absolute;
        z-index: 9999;
        background-color: rgba(48, 78, 130, 0.83);
        color: #fff;
        padding: 8px;
        border-radius: 4px;
        font-size: 16px;
        margin-left: 50px;
        margin-top: 50px;
    }

</style>

