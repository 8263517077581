<script>
    import {onMount} from 'svelte';
    import {Icon} from "svelte-awesome";
    import {
        faFileText,
        faSearch,
        faBell,
        faUserCheck,
        faAsterisk,
        faCircleXmark, faFilter, faCircleLeft
    } from '@fortawesome/free-solid-svg-icons';
    import {
        pane,
        selectedNCTID,
        selectedEligibility,
        patientLevel,
        selectedProtocolID
    } from "../../store/instInsights";
    import Loader from "../shared/Loader.svelte";
    import {getUser} from "../../store/session";
    import {fetchTrialDetails, getTrialSummary} from "../../network/inst_trials";
    import Modal from "../Modal.svelte";
    import {pushFail, pushSuccess} from "../../utils/toast";
    import {createAlertConfig, getUsers} from "../../network/user";
    import {queryCases} from "../../network/analytics/queries";
    import {matchedTrials, searchQueries, inFilterView} from "../../store/instTrialSearchQueries";
    import {HSplitPane} from "svelte-split-pane";
    import InstitutionalTrialsListSidebar from "./InstitutionalTrialsListSidebar.svelte";

    let trials = [];
    let isLoading = true;
    let error = null;
    let selectedPageSize = 10;
    let currentPage = 1;
    let allCount = 0;
    let ordering = '';
    let protocolID = null;
    let isPanelVisible = false;
    let shareAlertOption = false;
    let setAlertName = false;
    let alertName = '';
    let alertFrequency = '';
    let alertTime = '';
    let notificationFrequency = '';
    let selectedDay = "";
    let numOfDays = "";
    let alertFrequencyDay = "";
    let shareAlertModal;
    let matchedPatientCounts = {};
    let trialDetails = null;
    let leftPaneWidth = "0%";
    let hideLeftNav = true;
    let rightPaneWidth = "98%";

    $: if ($inFilterView === false) {
        gatherTrials();
    }

    function clearSearch() {
        searchQueries.update(storeValue => {
            storeValue.patient_name = '';
            storeValue.patient_mrn = '';
            storeValue.diseases = '';
            storeValue.disease_states = '';
            storeValue.drugs = '';
            storeValue.genes = '';
            storeValue.alterations = '';
            storeValue.recruiting_status = '';
            storeValue.protocol_id = '';
            storeValue.nct_id = '';
            storeValue.eligibility_name = '';
            return storeValue;
        });
        allCount = '';
        inFilterView.update(storeValue => {
            storeValue = false;
            return storeValue;
        });
        matchedTrials.update(storeValue => {
            storeValue = allCount;
            return storeValue;
        });
        resetPageSize();
    }

    function ResetSearch() {
        searchQueries.update(storeValue => {
            storeValue.patient_name = '';
            storeValue.patient_mrn = '';
            storeValue.diseases = '';
            storeValue.disease_states = '';
            storeValue.drugs = '';
            storeValue.genes = '';
            storeValue.alterations = '';
            storeValue.recruiting_status = '';
            storeValue.protocol_id = '';
            storeValue.nct_id = '';
            storeValue.eligibility_name = '';
            return storeValue;
        });
        resetPageSize();
    }

    function resetPageSize() {
        selectedPageSize = 10;
        const pageSizeDropdown = document.getElementById("page-size");
        if (pageSizeDropdown) {
            pageSizeDropdown.selectedIndex = 0;
        }
    }

    async function fetchResetTrials() {
        clearSearch();
        currentPage = 1;
        await gatherTrials();
    }

    async function fetchClearFilters() {
        ResetSearch();
        currentPage = 1;
    }

    async function fetchSearchTrials() {
        allCount = '';
        currentPage = 1;
        await gatherTrials();
    }

    function parseEligibilities(eligibilityList) {
        if (!eligibilityList || !Array.isArray(eligibilityList)) return [];

        try {
            return eligibilityList.map(eligObj => {
                return eligObj.name;
            });
        } catch (err) {
            console.error('Error parsing eligibilities:', err);
            return [];
        }
    }

    async function getNumMatchedPatients(id) {
        let protocolID = id.split(" ")[0];
        let filters = [
                {
                    "core": "recommendations",
                    "type": "term",
                    "field": "inst_protocol_ids",
                    "values": [{"value": protocolID}],
                    "display": "Protocol ID: " + protocolID,
                    "operator": "Any"
                }
        ];
        let response = await queryCases(filters, true, 1, 1);
        if (response.ok) {
            const results = await response.json();
            matchedPatientCounts[id] = results.hits;
        } else {
            pushFail('Unable to fetch query');
            matchedPatientCounts[id] = "";
        }
    }

    async function gatherTrials() {
        isLoading = true;
        let trials_json = await getTrialSummary(currentPage, selectedPageSize, $searchQueries, ordering);
        if (!Object.values($searchQueries).every(value => value === '')) {
            inFilterView.update(storeValue => {
                storeValue = true;
                return storeValue;
            });
        }
        trials = trials_json.results.map(trial => ({
            ...trial,
            eligibilities: parseEligibilities(trial.eligibility),
            inst_protocol_ids_joined: trial.inst_protocol_ids[0].includes('[]') ? trial.inst_protocol_ids[0].replace('[]', '') : (trial.inst_protocol_ids[0].toString() + " / ")
        }));
        for (const t of trials) {
            loadMatchedPatientCount(t);
        }
        allCount = trials_json.count;
        matchedTrials.update(storeValue => {
            storeValue = allCount;
            return storeValue;
        });
        isLoading = false;
    }

    async function changePageSize(event) {
        selectedPageSize = event.target.value;
        currentPage = 1;
        await gatherTrials();
    }

    async function changePage(page) {
        currentPage = page;
        await gatherTrials();
    }

    function viewEligibility(nct_id, eligibility, protocol_id) {
        pane.set("eligibility_list");
        selectedNCTID.set(nct_id);
        let protocolID = protocol_id.split(" ")[0];
        selectedProtocolID.set(protocolID);
        if (!Array.isArray(eligibility)) {
            eligibility = [eligibility];
        }
        selectedEligibility.set(eligibility);
    }

    let rows = [];
    async function retrieveUsers() {
        const response = await getUsers();
        if (response.ok) {
            const body = await response.json();
            rows = body.results;
        }
    }

    function handleAlertCreation(id, has_alert) {
        if (!has_alert) {
            protocolID = id.split(" ")[0];
            trialDetails = null;
            isPanelVisible = true;
        }
    }

    async function viewTrialDetails(uuid) {
        trialDetails = await fetchTrialDetails(uuid);
        protocolID = null;
        isPanelVisible = true;
    }

    async function viewPatients(id) {
        let protocol_id = id.split(" ")[0];
        if (matchedPatientCounts[protocol_id] > 0) {
            selectedProtocolID.set(protocol_id);
            patientLevel.set("trial");
            pane.set("patient_list");
        }
    }

    function determineFrequencyInfo() {
        if (notificationFrequency === "instantaneous") {
            alertFrequency = 0;
            alertFrequencyDay = null;
        } else if (notificationFrequency === "daily") {
            alertFrequency = 1;
            alertFrequencyDay = null;
        } else if (notificationFrequency === "everyNumOfDays") {
            alertFrequency = numOfDays;
            alertFrequencyDay = null;
        } else if (notificationFrequency === "dayOfWeek") {
            alertFrequency = 7;
            alertFrequencyDay = selectedDay;
        }
    }

    function handleClear() {
        shareAlertOption = false;
        setAlertName = false;
        alertName = '';
        alertFrequency = '';
        notificationFrequency = '';
        alertTime = '';
        selectedDay = '';
        numOfDays = '';
        selectedItems = new Set();
    }

    let selectedItems = new Set();
    function handleCheckboxChange(item) {
        if (selectedItems.has(item)) {
            selectedItems.delete(item);
        } else {
            selectedItems.add(item);
        }
    }

    const onSaveAlert = async () => {
        if (alertName === '' && !setAlertName) {
            pushFail("Must add an alert name.");
        } else if (notificationFrequency === '') {
            pushFail("Must add a notification frequency.");
        } else if (notificationFrequency === "everyNumOfDays" && numOfDays === "") {
            pushFail("Must add a value for Every __ Days entry.");
        } else if (notificationFrequency === "everyNumOfDays" && numOfDays <= 1) {
            pushFail("Must add a value for Every __ Days entry above 1.")
        } else if (notificationFrequency === "dayOfWeek" && selectedDay === "") {
            pushFail("Must choose a day of the week.");
        } else if (shareAlertOption) {
            shareAlertModal.open();
        } else {
            determineFrequencyInfo();
            if (setAlertName) {
                alertName = protocolID
            }
            let body = {
                "name": alertName,
                "cadence": alertFrequency,
                "alert_parameters": {
                    "filters": [
                        {
                            "core": "recommendations",
                            "type": "term",
                            "field": "inst_protocol_ids",
                            "values": [{"value": protocolID}],
                            "display": "Protocol ID: " + protocolID,
                            "operator": "Any"
                        }
                    ]
                }
            };
            if (alertFrequencyDay !== "") {
                body["cadence_day"] = alertFrequencyDay;
            }
            if (alertTime !== "") {
                body["time"] = alertTime;
            }
            let response = await createAlertConfig(getUser().id, body);

            if (response.ok) {
                pushSuccess("Alert has been created.");
                shareAlertOption = false;
                handleClear();
                protocolID = null;
                await gatherTrials();
            } else {
                const body = await response.json();
                if (body.name) {
                    pushFail('This alert name is already in use.');
                } else {
                    pushFail('There was a problem creating the alert.');
                }
            }
        }
    };

    const onSaveShareAlert = async () => {
        determineFrequencyInfo();
        if (setAlertName) {
            alertName = protocolID
        }
        let body = {
            "name": alertName,
            "cadence": alertFrequency,
            "shared_users": Array.from(selectedItems).map(item => item.username).join(','),
            "alert_parameters": {
                "filters": [
                    {
                        "core": "recommendations",
                        "type": "term",
                        "field": "inst_protocol_ids",
                        "values": [{"value": protocolID}],
                        "display": "Protocol ID: " + protocolID,
                        "operator": "Any"
                    }
                ]
            }
        };
        if (alertFrequencyDay !== "") {
            body["cadence_day"] = alertFrequencyDay;
        }
        if (alertTime !== "") {
            body["time"] = alertTime;
        }
        let response = await createAlertConfig(getUser().id, body);
        if (response.ok) {
            pushSuccess("Alert has been created.");
            shareAlertModal.close();
            shareAlertOption = false;
            handleClear();
            protocolID = null;
            await gatherTrials();
        } else {
            const body = await response.json();
            if (body.name) {
                pushFail('This alert name is already in use.');
            } else {
                pushFail('There was a problem creating the alert.');
            }
        }
    };

    function togglePanel() {
        if (isPanelVisible) {
            trialDetails = null;
            protocolID = null;
            handleClear();
        }
        isPanelVisible = !isPanelVisible;
    }

    function sortTrials(property) {
        if (property === ordering || `-${property}` === ordering) {
            if (ordering.startsWith('-')) {
                ordering = '';
            } else {
                ordering = `-${property}`;
            }
        } else {
            ordering = property;
        }
        gatherTrials();
    }

    function loadMatchedPatientCount(trial) {
        let protocolID = trial.inst_protocol_ids_joined.split(" ")[0];
        getNumMatchedPatients(protocolID);
    }

    function handleLeftNav() {
        hideLeftNav = !hideLeftNav;
        leftPaneWidth = hideLeftNav ? "0%" : "22%";
        rightPaneWidth = hideLeftNav ? "98%" : "76%";
    }

    onMount(() => {
        clearSearch();
        gatherTrials();
        retrieveUsers();
    });

    $: totalPages = Math.ceil(allCount / selectedPageSize);
</script>

<style>
    .container {
        display: flex;
        height: calc(74vh - 70px);
        width: 100%;
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    thead th {
        position: sticky;
        top: 0;
        z-index: 1;
    }

    th {
        background-color: #233C6B;
        color: #ffffff;
    }

    tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    .summary {
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 2em;
        margin-right: 2em;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1em;
        width: 98%;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .next-button {
        background-color: rgba(7, 7, 7, 0.19);
        color: #fff;
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 5px;
    }

    .pagination button {
        margin-left: 0.5em;
    }

    .pages-span {
        margin-left: 0.5em;
        margin-right: 0.2em;
    }

    .scrollable-table {
        overflow-y: auto;
        max-height: calc(68vh - 8.3em);
        border-radius: 10px;
    }

    .status-badge {
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 0.875rem;
    }

    .icon-action {
        cursor: pointer;
        color: #233C6B;
    }

    .icon-action:hover {
        opacity: 0.8;
    }

    .eligibilities-list {
        list-style-type: decimal;
        padding-left: 1.5em;
        margin: 0;
        margin-top: 5px;
    }

    .eligibilities-list li {
        margin-bottom: 0.25em;
    }

    .link-style {
        color: #0000EE;  /* Traditional link color */
        text-decoration: none;
        cursor: pointer;
    }

    .link-style:hover {
       color: #551A8B;  /* Traditional visited link color */
       text-decoration: none;
    }

    .details-alert-pane-container {
        flex: 0 0 25%; /* Add this - don't grow, don't shrink, start at 30% */
        transition: all 0.3s ease;
        position: relative;
        width: 0;
        margin-left: 30px;
    }

    .details-trial-info-pane-container {
        flex: 0 0 30%; /* Add this - don't grow, don't shrink, start at 30% */
        transition: all 0.3s ease;
        position: relative;
        width: 0;
        margin-left: 30px;
    }

    .details-pane {
        height: calc(74vh - 135px); /* Match container height */
        margin: 1em 1em 1em 0;
        border: 1px solid #ccc;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        overflow: auto;
        max-height: 100vh;
        padding: 1rem;
    }
    .alert-field-first{
        margin-top: 10px;
    }
    .alert-field-second{
        margin-top: 20px;
        display: flex;
    }
    .alert-input-first{
        width: 20vw;
    }
    .alert-input-second{
        float: left;
        width: 8vw;
    }
    .alert-modal-button{
        margin-right: 10px;
        padding: 8px 16px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
    .alert-modal-checkbox{
        margin-top: 10px;
        margin-left: 10px;
    }
    .alert-modal-first-row{
        margin-top: 10px;
        display: flex;
    }

    .footer {
        margin-left: 10px;
        margin-right: 10px;
    }

    .content {
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 15px;
    }
    .toggle-panel-button {
        margin-left: 25px;
    }
    .table-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .icon-container {
        position: relative;
        display: inline-block;
        cursor: pointer;
    }

    .checkmark-overlay {
        position: absolute;
        bottom: 12px;
        left: 10px;
        background-color: yellowgreen;
        color: white;
        font-size: 0.8rem;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 2px solid white;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
    }

    .icon-container:hover::after {
        content: attr(data-tooltip);
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        background-color: black;
        color: white;
        padding: 4px 8px;
        font-size: 0.75rem;
        border-radius: 4px;
        white-space: nowrap;
        z-index: 10;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }

    .icon-container:hover::before {
        content: '';
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent black transparent;
        z-index: 9;
    }
</style>

<div class="container">
    <HSplitPane bind:leftPaneSize={leftPaneWidth} bind:rightPaneSize={rightPaneWidth} minLeftPaneSize= "0%">
        <left slot="left">
            {#if !hideLeftNav}
                <div class="side-bar">
                    <InstitutionalTrialsListSidebar {fetchSearchTrials} {fetchResetTrials} {fetchClearFilters}/>
                </div>
            {/if}
        </left>
        <right slot="right">
            <div class="summary">
                <div class="table-top">
                    <div class="icon-button" on:click={handleLeftNav} aria-hidden="true">
                        {#if hideLeftNav}
                            <p style="margin-bottom: 0">Apply Filters</p>
                            <Icon data="{faFilter}" scale="1.5" style="color: #304e82; margin-top: 0"/>
                        {:else}
                            <p style="margin-bottom: 0">Close Filter Pane</p>
                            <Icon data="{faCircleLeft}" scale="1.5" style="color: #c5c5c7; margin-top: 0"/>
                        {/if}
                    </div>

                    <!-- Pagination -->
                    <div class="pagination">
                        <label for="page-size">Page Size:</label>
                        <select id="page-size" on:change={changePageSize}>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value={allCount}>All</option>
                        </select>

                        {#if !isLoading && trials.length > 0}
                            <div class="pagination">
                                {#if currentPage > 1}
                                    <button class="next-button" on:click={() => changePage(currentPage - 1)}>Previous</button>
                                {/if}
                                <span class="pages-span">
                        Page {currentPage} of {totalPages}
                      </span>
                                {#if currentPage < totalPages}
                                    <button class="next-button" on:click={() => changePage(currentPage + 1)}>Next</button>
                                {/if}
                            </div>
                        {/if}
                    </div>
                </div>

                <!-- Table -->
                <div class="scrollable-table">
                    {#if isLoading}
                        <Loader/>
                    {:else if error}
                        <div class="text-red-600 text-center py-8">{error}</div>
                    {:else}
                        <table>
                            <thead>
                            <tr>
                                <th on:click={() => sortTrials('protocol_id')}>
                                    Protocol No / NCT ID
                                    {#if ordering === 'protocol_id'}
                                        &#9650;
                                    {:else if ordering === '-protocol_id'}
                                        &#9660;
                                    {/if}
                                </th>
                                <th>Curated Eligibilities</th>
                                <th>View Trial Details</th>
                                <th on:click={() => sortTrials('recruiting_status')}>
                                    Current Status
                                    {#if ordering === 'recruiting_status'}
                                        &#9650;
                                    {:else if ordering === '-recruiting_status'}
                                        &#9660;
                                    {/if}
                                </th>
                                <th>Explore Eligibilities</th>
                                <th>Create Alerts</th>
                                <th on:click={() => sortTrials('matched_patients')}>
                                    View Matched Patients
                                    {#if ordering === 'matched_patients'}
                                        &#9650;
                                    {:else if ordering === '-matched_patients'}
                                        &#9660;
                                    {/if}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each trials as trial, i}
                                <tr class:even={i % 2 === 1} style="background-color: {(protocolID && trial.inst_protocol_ids_joined.includes(protocolID)) ||
                                 (trialDetails && trial.uuid === trialDetails.uuid) ? 'lightyellow' : ''}">
                                    <td>{trial.inst_protocol_ids_joined}{trial.nct_id}</td>
                                    <td class="table-cell-content">
                                        {#if trial.eligibilities && trial.eligibilities.length > 0}
                                            <span style="font-weight: bold">{trial.eligibilities.length} Eligibility Arms</span>
                                            <ol class="eligibilities-list">
                                                {#each trial.eligibility as eligibility}
                                                    <li class="link-style" on:click={() => viewEligibility(trial.nct_id, eligibility, trial.inst_protocol_ids_joined)}>{eligibility.name}</li>
                                                {/each}
                                            </ol>
                                        {:else}
                                            No eligibilities defined
                                        {/if}
                                    </td>
                                    <td>
                                        <div class="icon-action" on:click={() => viewTrialDetails(trial.uuid)} aria-hidden="true">
                                            <Icon data={faFileText} style="scale: 2"/>
                                        </div>
                                    </td>
                                    <td>
                                      <span class="status-badge">
                                        {trial.inst_statuses && trial.inst_statuses.length > 0 ? trial.inst_statuses[0] : ''} <br/> {trial.inst_status_dates && trial.inst_status_dates.length > 0 ? trial.inst_status_dates[0] : ''}
                                      </span>
                                    </td>
                                    <td>
                                        <div class="icon-action" on:click={() => viewEligibility(trial.nct_id, trial.eligibility, trial.inst_protocol_ids_joined)} aria-hidden="true">
                                            <Icon data={faSearch} style="scale: 2"/>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="icon-action" on:click={() => handleAlertCreation(trial.inst_protocol_ids_joined, trial.has_alert)} aria-hidden="true">
                                            {#if trial.has_alert}
                                                <div class="icon-container" data-tooltip="Alert has already been created">
                                                    <Icon data={faBell} style="scale: 2"/>
                                                    <div class="checkmark-overlay">✔</div>
                                                </div>
                                            {:else}
                                                <Icon data={faBell} style="scale: 2"/>
                                            {/if}
                                        </div>
                                    </td>
                                    <td>
                                        <div class="icon-action" on:click={() => viewPatients(trial.inst_protocol_ids_joined)} aria-hidden="true">
                                            <div style="display: flex">
                                                <Icon data={faUserCheck} style="scale: 2"/>
                                                <div style="margin-left: 20px">{matchedPatientCounts[trial.inst_protocol_ids_joined.split(" ")[0]]}</div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                    {/if}
                </div>
            </div>
        </right>
    </HSplitPane>

    {#if protocolID}
        <div class="details-alert-pane-container" class:hidden={!isPanelVisible}>
            <div class="details-pane">
                <div style="display:flex; justify-content:center; align-items: center;">
                    <h3 class="text-xl font-bold mb-4">Create Alert: {protocolID}</h3>
                    <div class="toggle-panel-button" on:click={togglePanel} aria-hidden="true">
                        <div>
                            <Icon data="{faCircleXmark}" style="scale: 1.25"/>
                        </div>
                    </div>
                </div>
                <div>
                    This will create an alert that will send a notification when a patient matches to ANY of the curated eligibilities of this trial
                </div>
                <div class="content">
                    <div class="alert-field-first">
                        <div style="display: block">
                            <div style="display: flex">
                                <label style="float: left;">Name</label>
                                <Icon data ="{faAsterisk}" scale="1" style="float: left; margin-left: 5px; color: #d98b95"/>
                            </div>
                            <input class="alert-input-first" type="text" bind:value={alertName} name="alertName" placeholder="Alert Name" required>
                        </div>
                    </div>
                    <div style="display:flex; justify-content: flex-start;">
                        <label>
                            <input class="alert-modal-checkbox" type="checkbox" style="width: 15px; height: 15px;" bind:checked={setAlertName}>
                            Use Protocol ID as name
                        </label>
                    </div>
                    <div class="alert-field-second">
                        <div style="margin-right: 50px;">
                            <div style="margin-bottom: 10px">
                                <label style="float: left;">Notification Frequency</label>
                                <Icon data ="{faAsterisk}" scale="1" style="float: left; margin-left: 5px; color: #d98b95"/>
                            </div>
                            <div style="text-align: left; margin-top: 10px;">
                                <label>
                                    <input class="frequency-checkbox" type="radio" name="notificationFrequency" value="instantaneous" bind:group={notificationFrequency}>
                                    Instantaneous
                                </label>
                                <label>
                                    <input class="frequency-checkbox" type="radio" name="notificationFrequency" value="daily" bind:group={notificationFrequency}>
                                    Daily
                                </label>
                                <label>
                                    <input class="frequency-checkbox" type="radio" name="notificationFrequency" value="everyNumOfDays" bind:group={notificationFrequency}>
                                    Every __ Days
                                </label>
                                {#if notificationFrequency === "everyNumOfDays"}
                                    <input type="text" bind:value={numOfDays}>
                                {/if}
                                <label>
                                    <input class="frequency-checkbox" type="radio" name="notificationFrequency" value="dayOfWeek" bind:group={notificationFrequency}>
                                    Weekly
                                </label>
                                {#if notificationFrequency === "dayOfWeek"}
                                    <select bind:value={selectedDay}>
                                        <option value="">Select a day</option>
                                        <option value="Monday">Monday</option>
                                        <option value="Tuesday">Tuesday</option>
                                        <option value="Wednesday">Wednesday</option>
                                        <option value="Thursday">Thursday</option>
                                        <option value="Friday">Friday</option>
                                        <option value="Saturday">Saturday</option>
                                        <option value="Sunday">Sunday</option>
                                    </select>
                                {/if}
                            </div>
                        </div>
                        <div>
                            {#if notificationFrequency !== "instantaneous"}
                                <label style="text-align: left">Notification Time</label>
                                <input class="alert-input-second" type="time" bind:value={alertTime}/>
                            {/if}
                        </div>
                    </div>
                </div>
                <div class="footer">
                    <div class="alert-modal-first-row">
                        <button class="alert-modal-button" style="background-color: lightcoral" on:click={() => handleClear()}>Clear</button>
                        <button class="alert-modal-button" style="background-color: #9FCE67" on:click={() => onSaveAlert()}>Create</button>
                        <label>
                            <input class="alert-modal-checkbox" type="checkbox" style="width: 15px; height: 15px;" bind:checked={shareAlertOption}>
                            Share Alert
                        </label>
                    </div>
                </div>
            </div>
        </div>
    {/if}

    {#if trialDetails}
        <div class="details-trial-info-pane-container" class:hidden={!isPanelVisible}>
            <div class="details-pane">
                <div style="display:flex; justify-content:center; align-items: center;">
                    <h3 class="text-xl font-bold mb-4" style="font-size: 25px">{trialDetails.inst_protocol_ids} / {trialDetails.nct_id}</h3>
                    <div class="toggle-panel-button" on:click={togglePanel} aria-hidden="true">
                        <div>
                            <Icon data="{faCircleXmark}" style="scale: 1.25"/>
                        </div>
                    </div>
                </div>
                <div class="text-xl font-bold mb-4 center-heading" style="margin-top: 20px; margin-bottom: 10px; font-size: 20px;"><b>OncOre Details</b></div>
                <div style="display: flex; flex-direction: column; align-items: flex-start; text-align: left;">
                    <div style="margin-bottom: 10px"><b>Status Update Date:</b> {trialDetails.inst_status_dates && trialDetails.inst_status_dates.length > 0 ? trialDetails.inst_status_dates : "-"}</div>
                    <div style="margin-bottom: 10px"><b>Additional Protocol Numbers:</b> {trialDetails.inst_additional_protocol_numbers && trialDetails.inst_additional_protocol_numbers.length > 0 ?
                        trialDetails.inst_additional_protocol_numbers : "-"}</div>
                    <div style="margin-bottom: 10px"><b>Principal Investigator:</b> {trialDetails.lead_sponsor || "-"}</div>
                    <div style="margin-bottom: 10px"><b>Organizational Unit:</b> - </div>
                    <div style="margin-bottom: 10px"><b>Sponsor Type:</b> {trialDetails.sponsor_type || "-"}</div>
                    <div style="margin-bottom: 10px"><b>Department:</b> - </div>
                </div>
                <div class="text-xl font-bold mb-4 center-heading" style="margin-top: 20px; margin-bottom: 10px; font-size: 20px;"><b>CT.gov Details</b></div>
                <div style="display: flex; flex-direction: column; align-items: flex-start; text-align: left;">
                    <div style="margin-bottom: 10px"><b>Title:</b> {trialDetails.title || "-"}</div>
                    <div style="margin-bottom: 10px"><b>NCT ID:</b> {trialDetails.nct_id || "-"}</div>
                    <div style="margin-bottom: 10px"><b>Phase:</b> {trialDetails.phase || "-"}</div>
                    <div style="margin-bottom: 10px"><b>Recruiting Status:</b> {trialDetails.recruiting_status || "-"}</div>
                    <div style="margin-bottom: 10px"><b>Lead Sponsor:</b> {trialDetails.lead_sponsor || "-"}</div>
                    {#if trialDetails.overall_contact}
                        <div style="margin-bottom: 10px"><strong>Contact Information:</strong></div>
                        <div style="margin-bottom: 10px; margin-left: 15px"><strong>Name:</strong> {trialDetails.overall_contact.last_name}</div>
                        <div style="margin-bottom: 10px; margin-left: 15px"><strong>Email:</strong> {trialDetails.overall_contact.email}</div>
                        <div style="margin-bottom: 10px; margin-left: 15px">
                            <strong>Phone:</strong> {trialDetails.overall_contact.phone}{trialDetails.overall_contact.phone_ext ? ` ext: ${trialDetails.overall_contact.phone_ext}` : ''}
                        </div>
                    {:else}
                        <div style="margin-bottom: 10px"><strong>Contact Information:</strong> -</div>
                    {/if}
                    <div style="margin-bottom: 10px"><b>Age Range (yrs):</b> {trialDetails.min_age_months / 12} to {trialDetails.max_age_months / 12}</div>
                    <div style="margin-bottom: 10px"><b>Gender:</b> {trialDetails.gender || "-"}</div>
                    <div style="margin-bottom: 10px"><b>Summary:</b> {trialDetails.summary || "-"}</div>
                </div>
            </div>
        </div>
    {/if}
</div>

<!--Share Alert Modal-->
<form on:submit|preventDefault={onSaveShareAlert}>
    <Modal bind:modalFunc={shareAlertModal}>
        <div slot="header">
            <div>
                <h1>Please select which users to share notifications with.</h1>
            </div>
        </div>
        <div slot="content">
            <table class="table">
                <thead>
                <tr>
                    <th></th>
                    <th>Username</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                </tr>
                </thead>
                <tbody>
                {#each rows as item}
                    <tr>
                        <td><input type="checkbox" checked={selectedItems.has(item)} on:change={() => handleCheckboxChange(item)} /></td>
                        <td>{item.username}</td>
                        <td>{item.first_name}</td>
                        <td>{item.last_name}</td>
                        <td>{item.email}</td>
                    </tr>
                {/each}
                </tbody>
            </table>
        </div>
        <div slot="footer">
            <div class="alert-modal-footer-row">
                <button class="alert-modal-button" style="background-color: lightcoral" on:click={() => shareAlertModal.close()}>Cancel</button>
                <button class="alert-modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Create</button>
            </div>
        </div>
    </Modal>
</form>