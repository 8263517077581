<script>
    import {Icon} from "svelte-awesome";
    import {
        faChevronDown,
        faChevronUp,
        faCirclePlus,
        faInfo,
        faPenToSquare,
        faPlusCircle,
        faTrash,
        faTriangleExclamation,
        faXmark
    } from "@fortawesome/free-solid-svg-icons";
    import {faCircleXmark, faComment} from "@fortawesome/free-regular-svg-icons";
    import {dsList, historical_match_params, pane, selectedPatient, tcList} from "../../store/patientMatch";
    import {createEventDispatcher, onMount} from 'svelte';
    import {suggestDrugs} from "../../network/patient_search";
    import {
        createTreatment,
        getPatient,
        removeTreatment,
        updatePatient,
        updateTreatment
    } from "../../network/patient_match";
    import {getDefaultInstitution} from "../../store/session";
    import {toast} from "@zerodevx/svelte-toast";


    const dispatch = createEventDispatcher();

    let editPage = false;

    function toggleEditMode() {
        editPage = !editPage;
    }

    let updatedDrugs = '';

    let uniqueDrugs = new Set();
    let systemic = [];
    let radiation = [];
    let surgery = [];
    let displaySystemic = false;
    let displayRadiation = false;
    let displaySurgery = false;
    let dedupedOncologicTreatments = [];
    let addTreatment = false;

    let SurgeryDrugsInput = '';
    let SurgeryDrug = '';
    let SurgeryDrugSuggestions = [];

    let SurgeryStartDateInput = '';
    let SurgeryEndDateInput = '';

    let SystemicDrugsInput = '';
    let SystemicDrug = '';
    let SystemicDrugSuggestions = [];

    let SystemicStartDateInput = '';
    let SystemicEndDateInput = '';

    let newDrugsInput = '';
    let newDrug = '';
    let newDrugSuggestions = [];
    let newDrugStartDateInput = '';
    let newDrugEndDateInput = '';
    let newDrugType = 'systemic';

    let RadiationDrugsInput = '';
    let RadiationDrug = '';
    let RadiationDrugSuggestions = [];

    let RadiationStartDateInput = '';
    let RadiationEndDateInput = '';

    let showToolTip = false;
    let openTopTCDropdown = false;

    let openAddDrugDropdown = false;
    let openAddTCDropdown = false;
    let openAddDSDropdown = false;

    let openSystemicDrugDropdownIndex = -1;
    let openSystemicDSDropdownIndex = -1;
    let openSystemicTCDropdownIndex = -1;

    let openRadiationDrugDropdownIndex = -1;
    let openRadiationDSDropdownIndex = -1;
    let openRadiationTCDropdownIndex = -1;


    let openSurgeryDrugDropdownIndex = -1;
    let openSurgeryDSDropdownIndex = -1;
    let openSurgeryTCDropdownIndex = -1;

    let openRadiationTCDropdown = false;
    let openSurgeryTCDropdown = false;

    let addDrugDS = '';
    let addDrugTC = '';
    let checkedAddDrugDS = '';
    let checkedAddDrugTC = '';

    let orderedTCList = [
        "Subsequent Line",
        "Second line",
        "Third line",
        "Fourth line",
        "Fifth line",
        "Sixth line"
    ];

    let sortedTCList = [];

    let toastId;

    function gatherTreatments() {
        dedupedOncologicTreatments = $selectedPatient.oncologic_treatments.filter(item => {
            if (uniqueDrugs.has(`${item.drugs}-${item.type_of_therapy}-${item.start_date}-${item.end_date}`)) {
                return false;
            } else {
                uniqueDrugs.add(`${item.drugs}-${item.type_of_therapy}-${item.start_date}-${item.end_date}`);
                return true;
            }
        });
    }

    function sortTCList() {
        let specialItems = $tcList.filter(item => orderedTCList.includes(item));
        let otherItems = $tcList.filter(item => (!orderedTCList.includes(item) && item !== "Unspecified"));

        specialItems.sort((a, b) => orderedTCList.indexOf(a) - orderedTCList.indexOf(b));

        sortedTCList = [...otherItems, ...specialItems, "Unspecified"];


    }

    function toggleVisibility(type) {
        if (type === 'systemic') {
            displaySystemic = !displaySystemic;
        } else if (type === 'radiation') {
            displayRadiation = !displayRadiation;
        } else if (type === 'surgery') {
            displaySurgery = !displaySurgery;
        }
    }

    onMount(() => {
        gatherTreatments();

        sortTCList();
    });

    $:{
        // reset lists if dedeupedOT list changes
        systemic = [];
        radiation = [];
        surgery = [];

        // Add temporarily added treatments to display
        for (let [key, param] of Object.entries($historical_match_params.updated_fields)
            .filter(([key, param]) => param.saved === false)
            .filter(([key, param]) => param.field === "prior_interventions")
            .filter(([key, param]) => param.newTreatment === true)) {

            let therapy;
            if (key.includes("Rad")) {
                therapy = 'Radiation';
            } else if (key.includes('Sys')) {
                therapy = "Systemic Therapy";
            } else if (key.includes('Sur')) {
                therapy = "Surgery";
            }
            const newTreatment = {
                id: key,
                type_of_therapy: therapy,
                disease_state: [param.updatedTreatment.disease_state.value],
                therapeutic_context: [param.updatedTreatment.therapeutic_context.value],
                drugs: param.updatedTreatment.drugs.value,
                start_date: param.updatedTreatment.start_date.value,
                end_date: param.updatedTreatment.end_date.value,
            };
            if (!dedupedOncologicTreatments.some(treatment => treatment.id === key)) {
                dedupedOncologicTreatments = [...dedupedOncologicTreatments, newTreatment];
            }

        }

        // Remove temporarily deleted treatments from display
        for (let [key, param] of Object.entries($historical_match_params.updated_fields)
            .filter(([key, param]) => param.saved === false)
            .filter(([key, param]) => param.field === "prior_interventions")
            .filter(([key, param]) => param.deleteTreatment === true)) {

            let drugForDeletion = key.split("_")[1]
            let dateForDeletion = key.split("_")[2]

            dedupedOncologicTreatments = dedupedOncologicTreatments.filter(treatment => {
                return !(treatment.drugs === drugForDeletion &&
                    treatment.start_date === dateForDeletion)
            })
        }

        for (let oc of dedupedOncologicTreatments) {
            if (oc.type_of_therapy === 'Systemic Therapy') {
                systemic = [...systemic, oc];
            } else if (oc.type_of_therapy === 'Radiation') {
                radiation = [...radiation, oc];
            } else if (oc.type_of_therapy === 'Surgery') {
                surgery = [...surgery, oc];
            }
        }
    }

    $: updatedFields = $historical_match_params.updated_fields;
    $: changesDetected = $historical_match_params.unsaved_updated_fields.prior_interventions || $historical_match_params.unsaved_updated_fields.therapeutic_context;
    $: dynamicPatientTC = $historical_match_params.updated_fields['Patient_TC'] ? $historical_match_params.updated_fields['Patient_TC'].value : $selectedPatient.therapeutic_context;

    function formatDate(dateString) {
        if (!dateString) return '';
        const d = new Date(dateString);
        const year = d.getFullYear();
        const month = ('0' + (d.getMonth() + 1)).slice(-2);
        const day = ('0' + d.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }

    function getCurrentTime() {
        return new Date().toLocaleTimeString();
    }


    const handleChange = (updatedValue, field, treatment) => {
        if (treatment.drugs === "new") return;

        const category = treatment.type_of_therapy;
        const updateTreatmentField = (key, value, isUpdated) => {
            historical_match_params.update(params => {
                const updated_fields = {...params.updated_fields};
                const unsaved_updated_fields = {...params.unsaved_updated_fields};

                if (!updated_fields[treatment.id]) {
                    updated_fields[treatment.id] = {
                        drug: treatment.drugs,
                        field: "prior_interventions",
                        newTreatment: false,
                        deleteTreatment:false,
                        updatedTreatment: {
                            drugs: {value: treatment.drugs, updated: false},
                            therapeutic_context: {
                                value: treatment.therapeutic_context.length > 0 ? treatment.therapeutic_context[0] : 'Unspecified',
                                updated: false
                            },
                            disease_state: {
                                value: treatment.disease_state.length > 0 ? treatment.disease_state[0] : 'Unspecified',
                                updated: false
                            },
                            start_date: {value: treatment.start_date, updated: false},
                            end_date: {value: treatment.end_date, updated: false}
                        },
                        timeStamp: getCurrentTime(),
                        saved: false
                    };
                }

                const existingTreatment = updated_fields[treatment.id].updatedTreatment;
                updated_fields[treatment.id].updatedTreatment[key] = {value, updated: isUpdated};
                updated_fields[treatment.id].timeStamp = getCurrentTime();
                updated_fields[treatment.id].saved = false;

                // Remove entry if all fields are not updated
                const allFieldsNotUpdated = Object.values(updated_fields[treatment.id].updatedTreatment)
                    .every(field => field.updated === false);
                if (allFieldsNotUpdated) {
                    delete updated_fields[treatment.id];
                }

                unsaved_updated_fields['prior_interventions'] = Object.keys(updated_fields).length > 0;

                const recalculate_match = Object.values(updated_fields).length > 0;
                return {...params, updated_fields, unsaved_updated_fields, recalculate_match};
            });
        };
        openSystemicDrugDropdownIndex = -1;
        openSystemicDSDropdownIndex = -1;
        openSystemicTCDropdownIndex = -1;
        openRadiationDrugDropdownIndex = -1;
        openRadiationDSDropdownIndex = -1;
        openRadiationTCDropdownIndex = -1;
        openSurgeryDrugDropdownIndex = -1;
        openSurgeryDSDropdownIndex = -1;
        openSurgeryTCDropdownIndex = -1;

        const handleCategorySpecificLogic = () => {

            if (category === 'Systemic Therapy') {
                SystemicDrugsInput = updatedValue;
                SystemicDrug = updatedValue;
                SystemicDrugSuggestions = [];
            } else if (category === 'Radiation') {
                RadiationDrugsInput = updatedValue;
                RadiationDrug = updatedValue;
                RadiationDrugSuggestions = [];

            } else if (category === 'Surgery') {
                SurgeryDrugsInput = updatedValue;
                SurgeryDrug = updatedValue;
                SurgeryDrugSuggestions = [];

            }
        };

        // Handle field-specific updates
        switch (field) {
        case 'drugs':
            const isDrugUpdated = updatedValue !== treatment.drugs;
            updateTreatmentField('drugs', updatedValue, isDrugUpdated);
            handleCategorySpecificLogic();
            break;

        case 'TC':
            const currentTC = treatment.therapeutic_context.length > 0 ? treatment.therapeutic_context[0] : 'Unspecified';
            const isTCUpdated = updatedValue !== currentTC;
            updateTreatmentField('therapeutic_context', updatedValue, isTCUpdated);
            selectedTC = '';
            break;

        case 'DS':
            const currentDS = treatment.disease_state.length > 0 ? treatment.disease_state[0] : "Unspecified";
            const isDSUpdated = updatedValue !== currentDS;
            updateTreatmentField('disease_state', updatedValue, isDSUpdated);
            selectedTC = '';
            break;

        case 'start_date':
            const currentStart = treatment.start_date;
            const isStartUpdated = updatedValue !== currentStart;
            updateTreatmentField('start_date', updatedValue, isStartUpdated);
            selectedTC = '';
            break;

        case 'end_date':
            const currentEnd = treatment.end_date;
            const isEndUpdated = updatedValue !== currentEnd;
            updateTreatmentField('end_date', updatedValue, isEndUpdated);
            selectedTC = '';
            break;

        default:
            break;
        }
    };


    const handleNewDrugsSelect = (drug, type) => {
        newDrug = drug;
        newDrugsInput = drug;
    }


    const handleSystemicDrugsInput = async (event) => {
        const inputText = event.target.value;
        await fetchSystemicDrugsSuggestions(inputText);
    };

    const fetchSystemicDrugsSuggestions = async (drugInput) => {
        try {
            const suggestion_resp = await suggestDrugs(drugInput);

            if (Array.isArray(suggestion_resp['results'])) {
                SystemicDrugSuggestions = [...new Set(suggestion_resp['results'].map((item) => item.text))];
            } else {
                console.error("Invalid drug suggestions response:", suggestion_resp);
                SystemicDrugSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching drug suggestions:", error);
        }
    };


    const handleRadiationDrugsInput = async (event) => {
        const inputText = event.target.value;
        await fetchRadiationDrugsSuggestions(inputText);
    };

    const fetchRadiationDrugsSuggestions = async (drugInput) => {
        try {
            const suggestion_resp = await suggestDrugs(drugInput);

            if (Array.isArray(suggestion_resp['results'])) {
                RadiationDrugSuggestions = [...new Set(suggestion_resp['results'].map((item) => item.text))];
            } else {
                console.error("Invalid drug suggestions response:", suggestion_resp);
                RadiationDrugSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching drug suggestions:", error);
        }
    };

    const handleSurgeryDrugsInput = async (event) => {
        const inputText = event.target.value;
        await fetchSurgeryDrugsSuggestions(inputText);
    };

    const fetchSurgeryDrugsSuggestions = async (drugInput) => {
        try {
            const suggestion_resp = await suggestDrugs(drugInput);

            if (Array.isArray(suggestion_resp['results'])) {
                SurgeryDrugSuggestions = [...new Set(suggestion_resp['results'].map((item) => item.text))];
            } else {
                console.error("Invalid drug suggestions response:", suggestion_resp);
                SurgeryDrugSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching drug suggestions:", error);
        }
    };

    async function handleNewDrugsInput(event)  {
        const drugInput = event.target.value;
        try {
            const suggestion_resp = await suggestDrugs(drugInput);

            if (Array.isArray(suggestion_resp['results'])) {
                newDrugSuggestions = [...new Set(suggestion_resp['results'].map((item) => item.text))];
            } else {
                console.error("Invalid drug suggestions response:", suggestion_resp);
                newDrugSuggestions = [];
            }
        } catch (error) {
            console.error("Error fetching drug suggestions:", error);
        }
    }


    function deleteDrug(drugName) {
        dedupedOncologicTreatments = dedupedOncologicTreatments.filter(ot => ot.drugs !== drugName);
    }

    async function createDrug(type) {
        let key = '';
        let typeOfTherapy = '';
        if (type === 'systemic') {
            key = 'newSys' + newDrugsInput;
            typeOfTherapy = 'Systemic Therapy';
        } else if (type === 'radiation') {
            key = 'newRad' + newDrugsInput;
            typeOfTherapy = 'Radiation';
        } else if (type === 'surgery') {
            key = 'newSur' + newDrugsInput;
            typeOfTherapy = 'Surgery';
        }

        const newDrugEntry= {
            drugs: newDrugsInput,
            start_date: newDrugStartDateInput,
            end_date: newDrugEndDateInput,
            therapeutic_context: [addDrugTC || 'Unspecified'],
            disease_state: [addDrugDS || 'Unspecified'],
            type_of_therapy: typeOfTherapy,
            patient: $selectedPatient.uuid
        };
        historical_match_params.update(params => {
            const updated_fields = {...params.updated_fields};
            const unsaved_updated_fields = {...params.unsaved_updated_fields};

            updated_fields[key] = {
                drug: newDrugsInput,
                newTreatment: true,
                deleteTreatment:false,
                field: "prior_interventions",
                updatedTreatment:
                    {
                        drugs: { value: newDrugEntry.drugs, updated: true },
                        therapeutic_context: { value: newDrugEntry.therapeutic_context.length > 0 ? newDrugEntry.therapeutic_context[0] : 'Unspecified', updated: true },
                        disease_state: { value: newDrugEntry.disease_state.length > 0 ? newDrugEntry.disease_state[0] : 'Unspecified', updated: true },
                        start_date: { value: newDrugEntry.start_date, updated: true },
                        end_date: { value: newDrugEntry.end_date, updated: true }
                    },

                timeStamp: getCurrentTime(),
                saved: false
            };

            unsaved_updated_fields['prior_interventions'] = true;

            const recalculate_match = Object.values(updated_fields).length > 0;

            return {
                ...params,
                updated_fields,
                unsaved_updated_fields,
                recalculate_match
            };
        });
        // reset values
        newDrugsInput = '';
        newDrugStartDateInput = '';
        newDrugEndDateInput = '';
        addDrugTC = '';
        addDrugDS = ''
        checkedAddDrugDS = '';
        checkedAddDrugTC = '';
        newDrugType = 'systemic';
        newDrug = '';
    }

    let selectedTC = '';
    const handleSingleCheckboxChangeTC = (e) => {
        if (e.target.checked) {
            selectedTC = e.target.value;
        } else {
            selectedTC = '';
        }
    };

    let selectedDS = '';
    const handleSingleCheckboxChangeDS = (e) => {
        if (e.target.checked) {
            selectedDS = e.target.value;
        } else {
            selectedDS = '';
        }
    };

    function toggleDropdown(category, index, value) {
        selectedTC = '';
        selectedDS = '';
        if (value === "DS") {
            openSystemicTCDropdownIndex = -1;
            openRadiationTCDropdownIndex = -1;
            openSurgeryTCDropdownIndex = -1;
            openSystemicDrugDropdownIndex = -1;
            openRadiationDrugDropdownIndex = -1;
            openSurgeryDrugDropdownIndex = -1;
            if (category === 'systemic') {
                openSystemicDSDropdownIndex = openSystemicDSDropdownIndex === index ? -1 : index;
                openRadiationDSDropdownIndex = -1;
                openSurgeryDSDropdownIndex = -1;
            } else if (category === 'radiation') {
                openSystemicDSDropdownIndex = -1;
                openRadiationDSDropdownIndex = openRadiationDSDropdownIndex === index ? -1 : index;
                openSurgeryDSDropdownIndex = -1;
            } else if (category === 'surgery') {
                openSystemicDSDropdownIndex = -1;
                openRadiationDSDropdownIndex = -1;
                openSurgeryDSDropdownIndex = openSurgeryDSDropdownIndex === index ? -1 : index;
            }
        } else if (value === "TC") {
            openSystemicDSDropdownIndex = -1;
            openRadiationDSDropdownIndex = -1;
            openSurgeryDSDropdownIndex = -1;
            openSystemicDrugDropdownIndex = -1;
            openRadiationDrugDropdownIndex = -1;
            openSurgeryDrugDropdownIndex = -1;
            if (category === 'systemic') {
                openSystemicTCDropdownIndex = openSystemicTCDropdownIndex === index ? -1 : index;
                openRadiationTCDropdownIndex = -1;
                openSurgeryTCDropdownIndex = -1;
            } else if (category === 'radiation') {
                openSystemicTCDropdownIndex = -1;
                openRadiationTCDropdownIndex = openRadiationTCDropdownIndex === index ? -1 : index;
                openSurgeryTCDropdownIndex = -1;
            } else if (category === 'surgery') {
                openSystemicTCDropdownIndex = -1;
                openRadiationTCDropdownIndex = -1;
                openSurgeryTCDropdownIndex = openSurgeryTCDropdownIndex === index ? -1 : index;
            }
        } else if (value === "drug") {
            openSystemicDSDropdownIndex = -1;
            openRadiationDSDropdownIndex = -1;
            openSurgeryDSDropdownIndex = -1;
            openSystemicTCDropdownIndex = -1;
            openRadiationTCDropdownIndex = -1;
            openSurgeryTCDropdownIndex = -1;
            if (category === 'systemic') {
                openSystemicDrugDropdownIndex = openSystemicDrugDropdownIndex === index ? -1 : index;
                openRadiationDrugDropdownIndex = -1;
                openSurgeryDrugDropdownIndex = -1;
            } else if (category === 'radiation') {
                openSystemicDrugDropdownIndex = -1;
                openRadiationDrugDropdownIndex = openRadiationDrugDropdownIndex === index ? -1 : index;
                openSurgeryDrugDropdownIndex = -1;
            } else if (category === 'surgery') {
                openSystemicDrugDropdownIndex = -1;
                openRadiationDrugDropdownIndex = -1;
                openSurgeryDrugDropdownIndex = openSurgeryDrugDropdownIndex === index ? -1 : index;
            }
        }

    }

    async function handleSaveOT() {
        pushSuccess('Saving ...');
        const groupedByDrug = Object.entries($historical_match_params.updated_fields)
            .filter(([key, param]) => param.saved === false)
            .filter(([key, param]) => param.newTreatment === false)
            .filter(([key, param]) => param.field === "prior_interventions")
            .reduce((drugDict, [key, param]) => {
                const drug = param.drug;
                if (!drugDict[drug]) {
                    drugDict[drug] = [];
                }
                if (param.deleteTreatment) {
                    drugDict[drug].push({updatedField: key, value:'deleteTreatment'})
                }
                Object.entries(param.updatedTreatment).map(([key, value]) => {
                    if (value.updated) {
                        drugDict[drug].push({updatedField: key, value: value.value});
                    }
                });
                return drugDict;
            }, {});

        for (const [drug, updates] of Object.entries(groupedByDrug)) {
            let treatmentIDs = [];
            let updatedOTData;

            $selectedPatient.oncologic_treatments.forEach(ot => {
                if (ot.drugs === drug) {
                    treatmentIDs = [...treatmentIDs, ot.id];

                    updatedOTData = {
                        'drugs': ot.drugs,
                        'disease_state': ot.disease_state,
                        'therapeutic_context': ot.therapeutic_context,
                        'start_date': ot.start_date,
                        'end_date': ot.end_date,

                    };
                }
            });

            let callDelete = false;

            updates.forEach(update => {
                if (update.value === 'deleteTreatment'){
                    callDelete=true
                } else if (update.updatedField === 'drugs'){
                    updatedOTData['drugs'] = update.value;
                } else if (update.updatedField === 'therapeutic_context') {
                    updatedOTData['therapeutic_context'] = [update.value];
                } else if (update.updatedField === 'disease_state') {
                    updatedOTData['disease_state'] = [update.value];
                } else if (update.updatedField === 'start_date') {
                    updatedOTData['start_date'] = update.value;
                } else if (update.updatedField === 'end_date') {
                    updatedOTData['end_date'] = update.value;
                }
            });

            if (callDelete) {
                let deletePromises = [];
                treatmentIDs.forEach(id => {
                    deletePromises.push(removeTreatment($selectedPatient.uuid, id));
                });
                await Promise.all(deletePromises);
            }
            else {
                let promises = [];
                treatmentIDs.forEach(id => {
                    promises.push(updateTreatment($selectedPatient.uuid, id, updatedOTData));
                });
                await Promise.all(promises);
            }

        }

        if (Object.entries($historical_match_params.updated_fields).length > 0) {
            if ($historical_match_params.updated_fields.Patient_TC) {
                await updatePatient($selectedPatient.uuid, $historical_match_params.updated_fields.Patient_TC.value);
            }
        }



        for (let [key, param] of Object.entries($historical_match_params.updated_fields)
            .filter(([key, param]) => param.saved === false)
            .filter(([key, param]) => param.field === "prior_interventions")
            .filter(([key, param]) => param.newTreatment === true)) {

            let therapy;
            if (key.includes("Rad")) {
                therapy = 'Radiation';
            } else if (key.includes('Sys')) {
                therapy = "Systemic Therapy";
            } else if (key.includes('Sur')) {
                therapy = "Surgery";
            }
            const newTreatment = {
                patient: $selectedPatient.uuid,
                type_of_therapy: therapy,
                disease_state: [param.updatedTreatment.disease_state.value],
                therapeutic_context: [param.updatedTreatment.therapeutic_context.value],
                drugs: param.updatedTreatment.drugs.value,
                start_date: param.updatedTreatment.start_date.value,
                end_date: param.updatedTreatment.end_date.value,
            };
            await createTreatment($selectedPatient.uuid, newTreatment);
        }

        const patientData = await getPatient($selectedPatient.uuid);

        selectedPatient.set(patientData);
        uniqueDrugs = new Set();
        gatherTreatments();


        historical_match_params.update(params => {
            const updated_fields = Object.entries(params.updated_fields).map(([key, value]) => {
                if (value.field === "prior_interventions") {
                    return [key, {...value, saved: true}];
                }
                return [key, value];
            });

            const updatedFieldsObj = Object.fromEntries(updated_fields);

            let unsaved_updated_fields = {...params.unsaved_updated_fields};
            unsaved_updated_fields['prior_interventions'] = false;

            let recalculate_match = Object.values(updated_fields).length > 0;

            return {
                ...params,
                updated_fields: updatedFieldsObj,
                unsaved_updated_fields,
                recalculate_match
            };
        });

        historical_match_params.update(params => {
            const updated_fields = Object.entries(params.updated_fields).map(([key, value]) => {
                if (value.field === "therapeutic_context") {
                    return [key, {...value, saved: true}];
                }
                return [key, value];
            });

            const updatedFieldsObj = Object.fromEntries(updated_fields);

            let unsaved_updated_fields = {...params.unsaved_updated_fields};
            unsaved_updated_fields['therapeutic_context'] = false;

            let recalculate_match = Object.values(updated_fields).length > 0;

            return {
                ...params,
                updated_fields: updatedFieldsObj,
                unsaved_updated_fields,
                recalculate_match
            };
        });
        removeToast();
    }

    function handleSelectPatientTC() {
        openTopTCDropdown = false;
        historical_match_params.update(params => {
            const updated_fields = {...params.updated_fields};
            const unsaved_updated_fields = {...params.unsaved_updated_fields};

            updated_fields['Patient_TC'] = {
                field: "therapeutic_context",
                value: selectedTC,
                originalValue: $selectedPatient.therapeutic_context.length > 0 ? $selectedPatient.therapeutic_context[0] : "Unspecified",
                timeStamp: getCurrentTime(),
                saved: false
            };

            unsaved_updated_fields['therapeutic_context'] = true;

            const recalculate_match = Object.values(updated_fields).length > 0;

            if (updated_fields['Patient_TC'].value === updated_fields['Patient_TC'].originalValue) {
                delete updated_fields['Patient_TC'];
                unsaved_updated_fields['therapeutic_context'] = false;
                const recalculate_match = Object.values(updated_fields).length > 0;

            }

            return {
                ...params,
                updated_fields,
                unsaved_updated_fields,
                recalculate_match
            };
        });
        selectedTC = '';
    }

    function handleDeletePatientTC() {
        historical_match_params.update(params => {
            const updated_fields = {...params.updated_fields};
            const unsaved_updated_fields = {...params.unsaved_updated_fields};

            updated_fields['Patient_TC'] = {
                field: "therapeutic_context",
                value: '',
                originalValue: $selectedPatient.therapeutic_context.length > 0 ? $selectedPatient.therapeutic_context[0] : "Unspecified",
                timeStamp: getCurrentTime(),
                saved: false
            };

            unsaved_updated_fields['therapeutic_context'] = true;

            const recalculate_match = Object.values(updated_fields).length > 0;

            if (updated_fields['Patient_TC'].value === updated_fields['Patient_TC'].originalValue) {
                delete updated_fields['Patient_TC'];
                unsaved_updated_fields['therapeutic_context'] = false;
                const recalculate_match = Object.values(updated_fields).length > 0;

            }

            return {
                ...params,
                updated_fields,
                unsaved_updated_fields,
                recalculate_match
            };
        });
    }

    function pushSuccess(message) {
        toastId = toast.push(message, {
            theme: {
                '--toastBackground': '#fff',
                '--toastProgressBackground': '#233C6B',
                '--toastColor': '#000',
            },
            duration: 10000
        });
    }

    function removeToast() {
        if (toastId) {
            toast.pop(toastId);
        }
    }

    function deleteTreatment(treatment){
        historical_match_params.update(params => {
            const deleteKey = `delete_${treatment.drugs}_${treatment.start_date}`;

            const updated_fields = { ...params.updated_fields };
            const unsaved_updated_fields = { ...params.unsaved_updated_fields };

            updated_fields[deleteKey] = {
                drug: treatment.drugs,
                newTreatment: false,
                deleteTreatment: true,
                field: "prior_interventions",
                updatedTreatment: {},
                timeStamp: getCurrentTime(),
                saved: false
            };

            unsaved_updated_fields['prior_interventions'] = true;

            for (let [key, param] of Object.entries(updated_fields)
                .filter(([key, param]) => param.saved === false)
                .filter(([key, param]) => param.field === "prior_interventions")
                .filter(([key, param]) => param.newTreatment === true)) {

                if (param.updatedTreatment.drugs.value === treatment.drugs) {
                    delete updated_fields[key];
                }
            }

            const recalculate_match = Object.values(updated_fields).length > 0;

            return {
                ...params,
                updated_fields,
                unsaved_updated_fields,
                recalculate_match
            };
        });
    }
</script>

<div style="display: flex; flex-direction: column; width: 100%;">
    <div style="display: flex; justify-content: space-between; align-items: center; margin: 25px;">
        <div style="display: flex; align-items: center; justify-content: flex-start; cursor:pointer;">
            <div style="position: relative; display: inline-block;" on:click={() =>showToolTip = !showToolTip}
                 aria-hidden="true">
                <span style="display: inline-block;">
                <Icon data="{faComment}" scale="2.5" style="color:#233C6B;"/>
            </span>
                <span style="position: absolute; top: 45%; left: 50%; transform: translate(-45%, -50%);">
                <Icon data="{faInfo}" style="color:#233C6B;"/>
            </span>
            </div>
            <span style="text-align: left; margin-left: 15px; font-size: 14px; visibility: {showToolTip ? 'visible' : 'hidden'}">This information comes from a<br/>{getDefaultInstitution()}
                data feed and was<br/>last updated {$selectedPatient.cdw_associated_date || 'N/A'}</span>
        </div>
        <div style="display: flex; align-items: center; justify-content: flex-start; padding-right:150px;">
            <h1 style="margin: 0;">Prior Treatment History</h1>
            <div style="cursor: pointer;" on:click={toggleEditMode} aria-hidden="true">
                {#if editPage}
                    <div class="tooltip-container" aria-hidden="true">
                        <Icon data="{faCircleXmark}" scale="1.75"
                              style="margin-left: 15px; color:#c5c5c7; cursor:pointer;"/>
                        <span class="tooltip-text">Close Edit Mode</span>
                    </div>

                {:else}
                    <div class="tooltip-container" aria-hidden="true">
                        <Icon data="{faPenToSquare}" scale="1.75"
                              style="margin-left: 15px; color:#233C6B; cursor:pointer;"/>
                        <span class="tooltip-text">Edit Details</span>
                    </div>

                {/if}
            </div>
            <button class='save-button' style="visibility: {changesDetected ? 'visible' : 'hidden'}"
                    on:click={()=>{handleSaveOT()}}>Save Changes
            </button>
        </div>
        <div on:click={() => pane.set("backToSummary")} aria-hidden="true">
            <Icon data="{faXmark}" scale="1.75" style="margin-left: 15px; color:black; cursor:pointer;"/>
        </div>
    </div>

    <div style="height: 55vh; margin-left:10px; overflow-x: scroll; padding-right: 50px;">
        <div style="display: flex; align-items: center; text-align: left">
            <h2 style="margin-right: 10px;">Current Treatment Context:</h2>
            {#if (dynamicPatientTC && dynamicPatientTC.length > 0)}
                <span on:click={() => {handleDeletePatientTC()}}>
                    <Icon data="{faCircleXmark}" scale="1.25"
                          style="margin-left: 10px; margin-right: 10px; color: #ccc;"/>
                </span>
            {/if}
            <strong>{dynamicPatientTC}</strong>
            {#if (editPage)}
                <span class="dropdown">
                    <span on:click={() => {openTopTCDropdown = !openTopTCDropdown; selectedTC = ''}} aria-hidden="true">
                        <Icon data="{faPlusCircle}" scale="1.25"
                              style="margin-left: 10px; margin-right: 10px; color: #9FCE67;"/>
                        <strong>Add New Entry</strong>
                    </span>
                    {#if (openTopTCDropdown)}
                        <span class="dropdown-menu">
                            {#each sortedTCList as tc, index}
                                <label class={orderedTCList.includes(tc)? 'indent' : ''}>
                                    <input type="checkbox" value={tc} on:change={handleSingleCheckboxChangeTC}
                                           checked={selectedTC === tc}/>
                                    {tc}
                                </label>
                            {/each}
                            <span class="dropdown-footer">
                                <button on:click={() => {handleSelectPatientTC()}}
                                        style="background-color:#9FCE67; padding: 5px;">Select</button>
                                <button on:click={() => {openTopTCDropdown=false; selectedTC='';}}
                                        style="background-color:#d98b95; padding: 5px;">Cancel</button>
                            </span>
                        </span>
                    {/if}
                </span>
            {/if}
        </div>
        {#if (addTreatment)}
            <p style="font-size: 16px; text-align: left; margin: 25px; display: flex; align-items: center;">
                <strong style="margin-right: 10px;">Drug Name:</strong>
                <span class="dropdown"
                      on:click={() => {openAddDrugDropdown = !openAddDrugDropdown}}>
                            <input
                                    type="text"
                                    class="input-field"
                                    bind:value={newDrug}
                                    on:input={handleNewDrugsInput}
                                    on:click={handleNewDrugsInput}
                            />
                    {#if (openAddDrugDropdown && newDrugSuggestions.length > 0)}
                                <span class="dropdown-menu">
                                      {#each newDrugSuggestions as drug}
                                          <p on:click={() => handleNewDrugsSelect(drug, 'systemic')}>{drug}</p>
                                      {/each}
                                </span>
                            {/if}
                        </span>
                <strong style="margin-right: 10px;">Drug Type:</strong>
                <select bind:value={newDrugType}>
                    <option value="systemic">Systemic</option>
                    <option value="radiation">Radiation</option>
                    <option value="surgery">Surgery</option>
                </select>
                <strong style="margin-right: 10px; margin-left: 10px;">Disease State</strong>
                <span class="dropdown">
                            <span style="border-bottom: 1px solid #000; padding-right: 150px;"
                                  on:click={() => {openAddDSDropdown = !openAddDSDropdown}}>{addDrugDS}</span>
                    {#if (openAddDSDropdown)}
                                <span class="dropdown-menu" style="margin-top: 10px;">
                                    {#each $dsList as ds, index}
                                        <label class={index === 0 || index === 3 || index === 6 ? '' : 'indent'}>
                                            <input type="checkbox" value={ds} on:change={(e) => checkedAddDrugDS = e.target.checked ? ds : 'Unspecified'}/>
                                            {ds}
                                        </label>
                                    {/each}
                                    <span class="dropdown-footer">
                                        <button on:click={() => {openAddDSDropdown = false; addDrugDS=checkedAddDrugDS}}
                                                style="background-color:#9FCE67; padding: 5px;">Select</button>
                                        <button on:click={() => {openAddDSDropdown = false}}
                                                style="background-color:#d98b95; padding: 5px;">Cancel</button>
                                    </span>
                                </span>
                            {/if}
                        </span>
                <strong style="margin-right: 10px; margin-left: 10px;">Therapeutic Context</strong>
                <span class="dropdown">
                            <span style="border-bottom: 1px solid #000; padding-right: 150px;"
                                  on:click={() => {openAddTCDropdown = !openAddTCDropdown}}>
                                {addDrugTC}
                            </span>
                    {#if (openAddTCDropdown)}
                                <span class="dropdown-menu" style="margin-top: 10px;">
                                    {#each sortedTCList as tc, index}
                                                <label class={orderedTCList.includes(tc)? 'indent' : ''}>
                                            <input type="checkbox" value={tc} on:change={(e) => checkedAddDrugTC = e.target.checked ? tc : 'Unspecified'}/>
                                                    {tc}
                                        </label>
                                    {/each}
                                    <span class="dropdown-footer">
                                        <button on:click={() => {openAddTCDropdown = false; addDrugTC=checkedAddDrugTC}}
                                                style="background-color:#9FCE67; padding: 5px;">Select</button>
                                        <button on:click={() => {openAddTCDropdown = false}}
                                                style="background-color:#d98b95; padding: 5px;">Cancel</button>
                                    </span>
                                </span>
                            {/if}
                        </span>
                <strong style="margin-right: 10px; margin-left: 10px;">Start Date:</strong> <input type="date"
                                                                                                   bind:value={newDrugStartDateInput}
                                                                                                   style="border-radius: 0">
                <strong style="margin-right: 10px; margin-left: 10px;">End Date:</strong> <input type="date"
                                                                                                 bind:value={newDrugEndDateInput}
                                                                                                 style="border-radius: 0">
                <span style="margin-left: 10px; cursor: pointer; display: flex;">
                            <span style="text-align: center; margin-right: 20px;">
                                <span on:click={() => { createDrug(newDrugType); addTreatment = false; }}>
                                    <Icon data="{faCirclePlus}" style="color: #9FCE67;"/>
                                </span>
                                <div>Add Entry</div>
                            </span>
                            <span style="text-align: center;">
                                <span on:click={() => {
                                    addTreatment = false;
                                    newDrugsInput = '';
                                    newDrugStartDateInput = '';
                                    newDrugEndDateInput = '';
                                    newDrug = '';
                                    addDrugTC = '';
                                    addDrugDS = ''
                                    checkedAddDrugDS = '';
                                    checkedAddDrugTC = '';
                                    newDrugType = 'systemic';
                                }}>
                                    <Icon data="{faCircleXmark}" style="color: #d98b95;"/>
                                </span>
                                <div>Cancel</div>
                            </span>
                        </span>
            </p>
        {/if}
        {#if (editPage)}
            <div class="add-entry-container" on:click={()=>{addTreatment=true}}>
                <Icon data="{faPlusCircle}" scale="1.25" style="margin-right: 10px; color: #9FCE67;"/>
                <strong>Add New Entry</strong>
            </div>
        {/if}
        <div class="ot-title" on:click={() => {toggleVisibility('systemic')}}>
            <span></span>
            Systemic Therapy Count ({systemic.length})
            {#if (displaySystemic)}
                <Icon data="{faChevronUp}"/>
            {:else}
                <Icon data="{faChevronDown}"/>
            {/if}
        </div>
        {#if (displaySystemic)}
            <div style="white-space: nowrap;">
                {#each systemic as ot, index}
                    <div style="display: flex; flex-direction: row; align-items: center;">
                        {#if (changesDetected && Object.entries($historical_match_params.updated_fields).filter(([key, param]) => !param.saved).some(([key, param]) => key.includes(`${ot.id}`)))}
                            <div class="tooltip-container">
                                <Icon class="tooltip-icon" data="{faTriangleExclamation}"
                                      style="margin-left: 15px; margin-right: 15px; color:#e79a45;"/>
                                <span class="tooltip-text">Unsaved</span>
                            </div>
                        {/if}
                        <p style="font-size: 16px; text-align: left; margin: 25px;">
                            {#if (editPage)}
                                <p style="font-size: 16px; text-align: left; display: flex; align-items: center;">
                                    <strong style="margin-right: 10px;">Drug Name:</strong>
                                    <span class="dropdown" on:click={() => {toggleDropdown('systemic', index, 'drug')}}
                                          aria-hidden="true">
                                    <input
                                            type="text"
                                            class="input-field"
                                            value="{updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.drugs.value || ot.drugs : ot.drugs}"
                                            on:input={handleSystemicDrugsInput}
                                            on:click={handleSystemicDrugsInput}
                                    />
                                        {#if ((openSystemicDrugDropdownIndex === index) && (SystemicDrugSuggestions.length > 0))}
                                        <span class="dropdown-menu">
                                            {#each SystemicDrugSuggestions as drug}
                                                <p on:click={() => handleChange(drug, 'drugs', ot)}
                                                   aria-hidden="true">{drug}</p>
                                            {/each}
                                        </span>
                                    {/if}
                                </span>
                                    <strong style="margin-right: 10px; margin-left: 10px;">Disease State</strong>
                                    <span class="dropdown">
                                    <p style="border-bottom: 1px solid #000; width: 150px;"
                                       on:click={() => {toggleDropdown('systemic', index, 'DS')}}
                                       aria-hidden="true">{updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.disease_state.value : ot.disease_state.length > 0 ? ot.disease_state[0] : 'Unspecified'}</p>
                                        {#if (openSystemicDSDropdownIndex === index)}
                                        <span class="dropdown-menu">
                                            {#each $dsList as ds, index}
                                                <label class={index === 0 || index === 3 || index === 6 ? '' : 'indent'}>
                                                    <input type="checkbox"
                                                           value={ds}
                                                           checked={selectedDS !== '' ? selectedDS === ds : updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.disease_state.value === ds : ot.disease_state.length > 0 ? ot.disease_state[0] === ds : 'Unspecified' === ds}
                                                           on:change={handleSingleCheckboxChangeDS}/>
                                                    {ds}
                                                </label>
                                            {/each}
                                            <span class="dropdown-footer">
                                                <button on:click={() => handleChange(selectedDS, "DS", ot)}
                                                        style="background-color:#9FCE67; padding: 5px;">Select</button>
                                                <button on:click={() => {openSystemicDSDropdownIndex = -1; selectedDS = ''}}
                                                        style="background-color:#d98b95; padding: 5px;">Cancel</button>
                                            </span>
                                        </span>
                                    {/if}
                                </span>
                                    <strong style="margin-right: 10px; margin-left: 10px;">Therapeutic Context</strong>
                                    <span class="dropdown">
                                    <p style="border-bottom: 1px solid #000; width: 150px;"
                                       on:click={() => {toggleDropdown('systemic', index, 'TC')}}
                                       aria-hidden="true">{updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.therapeutic_context.value : ot.therapeutic_context.length > 0 ? ot.therapeutic_context[0] : 'Unspecified'}</p>
                                        {#if (openSystemicTCDropdownIndex === index)}
                                        <span class="dropdown-menu">
                                            {#each sortedTCList as tc, index}
                                                <label class={orderedTCList.includes(tc)? 'indent' : ''}>
                                                    <input type="checkbox"
                                                           value={tc}
                                                           checked={selectedTC !== '' ? selectedTC === tc : updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.therapeutic_context.value === tc : ot.therapeutic_context.length > 0 ? ot.therapeutic_context[0] === tc : 'Unspecified' === tc}
                                                           on:change={handleSingleCheckboxChangeTC}/>
                                                    {tc}
                                                </label>
                                            {/each}
                                            <span class="dropdown-footer">
                                                <button on:click={() => handleChange(selectedTC, "TC", ot)}
                                                        style="background-color:#9FCE67; padding: 5px;">Select</button>
                                                <button on:click={() => {openSystemicTCDropdownIndex = -1; selectedTC = ''}}
                                                        style="background-color:#d98b95; padding: 5px;">Cancel</button>
                                            </span>
                                        </span>
                                    {/if}
                                </span>
                                    <strong style="margin-right: 10px; margin-left: 10px;">Start Date:</strong> <input
                                        type="date" value={formatDate(ot.start_date)}
                                        on:change={(e) => {handleChange(e.target.value, 'start_date', ot)}}
                                        style="border-radius: 0">
                                    <strong style="margin-right: 10px; margin-left: 10px;">End Date:</strong> <input
                                        type="date" value={formatDate(ot.end_date)}
                                        on:change={(e) => {handleChange(e.target.value, 'end_date', ot)}}
                                        style="border-radius: 0">
                                </p>
                            {:else}
                                <strong>Drug
                                    Name:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.drugs.value || ot.drugs : ot.drugs}
                                <strong>Disease
                                    State:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.disease_state.value : ot.disease_state.length > 0 ? ot.disease_state[0] : 'Unspecified'}
                                <strong>Therapeutic
                                    Context:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.therapeutic_context.value : ot.therapeutic_context.length > 0 ? ot.therapeutic_context[0] : 'Unspecified'}
                                <strong>Start
                                    Date:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.start_date.value : ot.start_date}
                                <strong>End
                                    Date:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.end_date.value : ot.end_date}
                            {/if}
                        </p>
                        <div class="tooltip-container" on:click={deleteTreatment(ot)}>
                            <Icon class="tooltip-icon" data="{faTrash}"
                                  style="margin-left: 15px; margin-right: 15px; color:#d98b95; display: {editPage ? 'block' : 'none'}"/>
                            <span class="tooltip-text" style="width: 175px;">Delete Treatment</span>
                        </div>
                    </div>
                {/each}
            </div>
        {/if}
        <div class="ot-title" on:click={() => {toggleVisibility('radiation')}} aria-hidden="true">
            <span></span>
            Radiation Count ({radiation.length})
            {#if (displayRadiation)}
                <Icon data="{faChevronUp}"/>
            {:else}
                <Icon data="{faChevronDown}"/>
            {/if}
        </div>
        {#if (displayRadiation)}
            <div style="white-space: nowrap;">
                {#each radiation as ot, index}
                    <div style="display: flex; flex-direction: row; align-items: center;">
                        {#if (changesDetected && Object.entries($historical_match_params.updated_fields).filter(([key, param]) => !param.saved).some(([key, param]) => key.includes(`${ot.id}`)))}
                            <div class="tooltip-container">
                                <Icon class="tooltip-icon" data="{faTriangleExclamation}"
                                      style="margin-left: 15px; margin-right: 15px; color:#e79a45;"/>
                                <span class="tooltip-text">Unsaved</span>
                            </div>
                        {/if}
                        <p style="font-size: 16px; text-align: left; margin: 25px;">
                            {#if (editPage)}
                                <p style="font-size: 16px; text-align: left; display: flex; align-items: center;">
                                    <strong style="margin-right: 10px;">Drug Name:</strong>
                                    <span class="dropdown"
                                          on:click={() => {toggleDropdown('radiation', index, 'drug')}}
                                          aria-hidden="true">
                                    <input
                                            type="text"
                                            class="input-field"
                                            value="{updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.drugs.value || ot.drugs : ot.drugs}"
                                            on:input={handleRadiationDrugsInput}
                                            on:click={handleRadiationDrugsInput}
                                    />
                                        {#if ((openRadiationDrugDropdownIndex === index) && (RadiationDrugSuggestions.length > 0))}
                                        <span class="dropdown-menu">
                                            {#each RadiationDrugSuggestions as drug}
                                                <p on:click={() => handleChange(drug, 'drugs', ot)}
                                                   aria-hidden="true">{drug}</p>
                                            {/each}
                                        </span>
                                    {/if}
                                </span>
                                    <strong style="margin-right: 10px; margin-left: 10px;">Disease State</strong>
                                    <span class="dropdown">
                                    <p style="border-bottom: 1px solid #000; width: 150px;"
                                       on:click={() => {toggleDropdown('radiation', index, 'DS')}}
                                       aria-hidden="true">{updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.disease_state.value : ot.disease_state.length > 0 ? ot.disease_state[0] : 'Unspecified'}</p>
                                        {#if (openRadiationDSDropdownIndex === index)}
                                        <span class="dropdown-menu">
                                            {#each $dsList as ds, index}
                                                <label class={index === 0 || index === 3 || index === 6 ? '' : 'indent'}>
                                                    <input type="checkbox"
                                                           value={ds}
                                                           checked={selectedDS !== '' ? selectedDS === ds : updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.disease_state.value === ds : ot.disease_state.length > 0 ? ot.disease_state[0] === ds : 'Unspecified' === ds}
                                                           on:change={handleSingleCheckboxChangeDS}/>
                                                    {ds}
                                                </label>
                                            {/each}
                                            <span class="dropdown-footer">
                                                <button on:click={() => handleChange(selectedDS, "DS", ot)}
                                                        style="background-color:#9FCE67; padding: 5px;">Select</button>
                                                <button on:click={() => {openRadiationDSDropdownIndex = -1; selectedDS = ''}}
                                                        style="background-color:#d98b95; padding: 5px;">Cancel</button>
                                            </span>
                                        </span>
                                    {/if}
                                </span>
                                    <strong style="margin-right: 10px; margin-left: 10px;">Therapeutic Context</strong>
                                    <span class="dropdown">
                                    <p style="border-bottom: 1px solid #000; width: 150px;"
                                       on:click={() => {toggleDropdown('radiation', index, 'TC')}}
                                       aria-hidden="true">{updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.therapeutic_context.value : ot.therapeutic_context.length > 0 ? ot.therapeutic_context[0] : 'Unspecified'}</p>
                                        {#if (openRadiationTCDropdownIndex === index)}
                                        <span class="dropdown-menu">
                                            {#each sortedTCList as tc, index}
                                                <label class={orderedTCList.includes(tc)? 'indent' : ''}>
                                                    <input type="checkbox"
                                                           value={tc}
                                                           checked={selectedTC !== '' ? selectedTC === tc : updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.therapeutic_context.value === tc : ot.therapeutic_context.length > 0 ? ot.therapeutic_context[0] === tc : 'Unspecified' === tc}
                                                           on:change={handleSingleCheckboxChangeTC}/>
                                                    {tc}
                                                </label>
                                            {/each}
                                            <span class="dropdown-footer">
                                                <button on:click={() => handleChange(selectedTC, "TC", ot)}
                                                        style="background-color:#9FCE67; padding: 5px;">Select</button>
                                                <button on:click={() => {openRadiationTCDropdownIndex = -1; selectedTC = ''}}
                                                        style="background-color:#d98b95; padding: 5px;">Cancel</button>
                                            </span>
                                        </span>
                                    {/if}
                                </span>
                                    <strong style="margin-right: 10px; margin-left: 10px;">Start Date:</strong> <input
                                        type="date" value={formatDate(ot.start_date)}
                                        on:change={(e) => {handleChange(e.target.value, 'start_date', ot)}}
                                        style="border-radius: 0">
                                    <strong style="margin-right: 10px; margin-left: 10px;">End Date:</strong> <input
                                        type="date" value={formatDate(ot.end_date)}
                                        on:change={(e) => {handleChange(e.target.value, 'end_date', ot)}}
                                        style="border-radius: 0">
                                </p>
                            {:else}
                                <strong>Drug
                                    Name:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.drugs.value || ot.drugs : ot.drugs}
                                <strong>Disease
                                    State:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.disease_state.value : ot.disease_state.length > 0 ? ot.disease_state[0] : 'Unspecified'}
                                <strong>Therapeutic
                                    Context:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.therapeutic_context.value : ot.therapeutic_context.length > 0 ? ot.therapeutic_context[0] : 'Unspecified'}
                                <strong>Start
                                    Date:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.start_date.value : ot.start_date}
                                <strong>End
                                    Date:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.end_date.value : ot.end_date}
                            {/if}
                        </p>
                        <div class="tooltip-container" on:click={deleteTreatment(ot)}>
                            <Icon class="tooltip-icon" data="{faTrash}"
                                  style="margin-left: 15px; margin-right: 15px; color:#d98b95; display: {editPage ? 'block' : 'none'}"/>
                            <span class="tooltip-text" style="width: 175px;">Delete Treatment</span>
                        </div>
                    </div>
                {/each}
            </div>
        {/if}
        <div class="ot-title" on:click={() => {toggleVisibility('surgery')}} aria-hidden="true">
            <span></span>
            Surgery Count ({surgery.length})
            {#if (displaySurgery)}
                <Icon data="{faChevronUp}"/>
            {:else}
                <Icon data="{faChevronDown}"/>
            {/if}
        </div>
        {#if (displaySurgery)}
            <div style="white-space: nowrap;">
                {#each surgery as ot, index}
                    <div style="display: flex; flex-direction: row; align-items: center;">
                        {#if (changesDetected && Object.entries($historical_match_params.updated_fields).filter(([key, param]) => !param.saved).some(([key, param]) => key.includes(`${ot.id}`)))}
                            <div class="tooltip-container">
                                <Icon class="tooltip-icon" data="{faTriangleExclamation}"
                                      style="margin-left: 15px; margin-right: 15px; color:#e79a45;"/>
                                <span class="tooltip-text">Unsaved</span>
                            </div>
                        {/if}
                        <p style="font-size: 16px; text-align: left; margin: 25px;">
                            {#if (editPage)}
                                <p style="font-size: 16px; text-align: left; display: flex; align-items: center;">
                                    <strong style="margin-right: 10px;">Drug Name:</strong>
                                    <span class="dropdown" on:click={() => {toggleDropdown('surgery', index, 'drug')}}
                                          aria-hidden="true">
                                    <input
                                            type="text"
                                            class="input-field"
                                            value="{updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.drugs.value || ot.drugs : ot.drugs}"
                                            on:input={handleSurgeryDrugsInput}
                                            on:click={handleSurgeryDrugsInput}
                                    />
                                        {#if ((openSurgeryDrugDropdownIndex === index) && (SurgeryDrugSuggestions.length > 0))}
                                        <span class="dropdown-menu">
                                            {#each SurgeryDrugSuggestions as drug}
                                                <p on:click={() => handleChange(drug, 'drugs', ot)}
                                                   aria-hidden="true">{drug}</p>
                                            {/each}
                                        </span>
                                    {/if}
                                </span>
                                    <strong style="margin-right: 10px; margin-left: 10px;">Disease State</strong>
                                    <span class="dropdown">
                                    <p style="border-bottom: 1px solid #000; width: 150px;"
                                       on:click={() => {toggleDropdown('surgery', index, 'DS')}}
                                       aria-hidden="true">{updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.disease_state.value : ot.disease_state.length > 0 ? ot.disease_state[0] : 'Unspecified'}</p>
                                        {#if (openSurgeryDSDropdownIndex === index)}
                                        <span class="dropdown-menu">
                                            {#each $dsList as ds, index}
                                                <label class={index === 0 || index === 3 || index === 6 ? '' : 'indent'}>
                                                    <input type="checkbox"
                                                           value={ds}
                                                           checked={selectedDS !== '' ? selectedDS === ds : updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.disease_state.value === ds : ot.disease_state.length > 0 ? ot.disease_state[0] === ds : 'Unspecified' === ds}
                                                           on:change={handleSingleCheckboxChangeDS}/>
                                                    {ds}
                                                </label>
                                            {/each}
                                            <span class="dropdown-footer">
                                                <button on:click={() => handleChange(selectedDS, "DS", ot)}
                                                        style="background-color:#9FCE67; padding: 5px;">Select</button>
                                                <button on:click={() => {openSurgeryDSDropdownIndex = -1; selectedDS = ''}}
                                                        style="background-color:#d98b95; padding: 5px;">Cancel</button>
                                            </span>
                                        </span>
                                    {/if}
                                </span>
                                    <strong style="margin-right: 10px; margin-left: 10px;">Therapeutic Context</strong>
                                    <span class="dropdown">
                                    <p style="border-bottom: 1px solid #000; width: 150px;"
                                       on:click={() => {toggleDropdown('surgery', index, 'TC')}}
                                       aria-hidden="true">{updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.therapeutic_context.value : ot.therapeutic_context.length > 0 ? ot.therapeutic_context[0] : 'Unspecified'}</p>
                                        {#if (openSurgeryTCDropdownIndex === index)}
                                        <span class="dropdown-menu">
                                            {#each sortedTCList as tc, index}
                                                <label class={orderedTCList.includes(tc)? 'indent' : ''}>
                                                    <input type="checkbox"
                                                           value={tc}
                                                           checked={selectedTC !== '' ? selectedTC === tc : updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.therapeutic_context.value === tc : ot.therapeutic_context.length > 0 ? ot.therapeutic_context[0] === tc : 'Unspecified' === tc}
                                                           on:change={handleSingleCheckboxChangeTC}/>
                                                    {tc}
                                                </label>
                                            {/each}
                                            <span class="dropdown-footer">
                                                <button on:click={() => handleChange(selectedTC, "TC", ot)}
                                                        style="background-color:#9FCE67; padding: 5px;">Select</button>
                                                <button on:click={() => {openSurgeryTCDropdownIndex = -1; selectedTC = ''}}
                                                        style="background-color:#d98b95; padding: 5px;">Cancel</button>
                                            </span>
                                        </span>
                                    {/if}
                                </span>
                                    <strong style="margin-right: 10px; margin-left: 10px;">Start Date:</strong> <input
                                        type="date" value={formatDate(ot.start_date)}
                                        on:change={(e) => {handleChange(e.target.value, 'start_date', ot)}}
                                        style="border-radius: 0">
                                    <strong style="margin-right: 10px; margin-left: 10px;">End Date:</strong> <input
                                        type="date" value={formatDate(ot.end_date)}
                                        on:change={(e) => {handleChange(e.target.value, 'end_date', ot)}}
                                        style="border-radius: 0">
                                </p>
                            {:else}
                                <strong>Drug
                                    Name:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.drugs.value || ot.drugs : ot.drugs}
                                <strong>Disease
                                    State:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.disease_state.value : ot.disease_state.length > 0 ? ot.disease_state[0] : 'Unspecified'}
                                <strong>Therapeutic
                                    Context:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.therapeutic_context.value : ot.therapeutic_context.length > 0 ? ot.therapeutic_context[0] : 'Unspecified'}
                                <strong>Start
                                    Date:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.start_date.value : ot.start_date}
                                <strong>End
                                    Date:</strong> {updatedFields[ot.id] ? updatedFields[ot.id].updatedTreatment.end_date.value : ot.end_date}
                            {/if}
                        </p>
                        <div class="tooltip-container" on:click={deleteTreatment(ot)}>
                            <Icon class="tooltip-icon" data="{faTrash}"
                                  style="margin-left: 15px; margin-right: 15px; color:#d98b95; display: {editPage ? 'block' : 'none'}"/>
                            <span class="tooltip-text" style="width: 175px;">Delete Treatment</span>
                        </div>
                    </div>
                {/each}
            </div>
        {/if}
    </div>
</div>

<style>

    .add-entry-container {
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 16px;
        text-align: left;
        margin: 25px;
    }

    input {
        border: none;
        border-bottom: 1px solid black;
        background-color: transparent;
        outline: none;
    }

    .save-button {
        margin-left: 25px;
        background-color: white;
        padding: 10px 40px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    h3 {
        font-size: 16px;
        width: 150px;
        text-align: right;
    }

    .ot-title {
        padding: 8px 12px;
        border: 1px solid #ccc;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        width: 45vw;
        background-color: #f2f2f2;
        margin: 10px;
        display: flex;
        justify-content: space-between;
        align-content: center;
        cursor: pointer;
    }

    .drug-entry {
        margin-left: 10px;
        border: 1px solid #ccc;
        border-radius: 20px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 10px;
    }

    .tooltip-container {
        position: relative;
        display: inline-block;
    }

    .tooltip-text {
        visibility: hidden;
        width: 100px;
        background-color: #f2f2f2;
        color: #000000;
        text-align: center;
        border-radius: 5px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        top: 125%;
        left: 50%;
        margin-left: -40px;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .tooltip-container:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }

    .tooltip-container:hover .tooltip-icon {
        color: #e79a45;
    }


    .indent {
        margin-left: 20px;
    }

    .dropdown {
        position: relative;
        display: inline-block;
        cursor: pointer;
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 999;
        border: 1px solid #ccc;
        border-top: none;
        border-radius: 0 0 4px 4px;
        background-color: #fff;
        padding: 5px;
        margin: 0;
        width: 300px;
    }

    .dropdown-footer {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
    }

    .input-field {
        width: 300px;
        border-radius: 0;
    }

</style>

