<script>
    import {onMount} from 'svelte';
    import {CASE_FIELDS} from "../../utils/constants";
    import {getCasesWithUuids} from "../../network/cases";
    import Loader from "../shared/Loader.svelte";
    import {Icon} from "svelte-awesome";
    import {faTimesCircle, faCheckCircle, faEdit} from '@fortawesome/free-solid-svg-icons';
    import {faCircleXmark as faCircleXmarkRegular} from "@fortawesome/free-regular-svg-icons";
    import Modal from "../Modal.svelte";
    import {pushFail, pushSuccess} from "../../utils/toast";
    import {
        getPatientTrackingStatuses,
        createPatientTrackingStatus,
        deletePatientTrackingStatus,
        createPatientTrackingStatusComment,
        getPatientTrackingStatusComments
    } from "../../network/patient_search";
    import {getUser, getPdModOverrideVar} from "../../store/session";
    import AdditionalPatientInfoTable from "./AdditionalPatientInfoTable.svelte";
    import {HSplitPane} from "svelte-split-pane";
    import AdditionalPatientInfoSideBar from "./AdditionalPatientInfoSideBar.svelte";
    import {patientInfoOptions} from '../../store/patientInfoOptions.js';

    let cases_summary = {};
    let cases = [];
    let selectedPageSize = 10;
    let currentPage = 1;
    let allCount = '';
    let isLoading = false;
    let trackPatientModal;
    let notTrackPatientModal;
    let needsMoreReviewPatientModal;
    let editTrackPatientModal;
    let commentModal;
    let noTrackReason = '';
    let selectedNonTrackOptions = [];
    let currentCase;
    let trackedPatientId;
    let trackedPatientNeedsMoreReview;
    let patientAlreadyTrackedIds = [];
    let patient_tracking_summary = {};
    let hideLeftNav = false;
    let leftPaneWidth = "17%";
    let rightPaneWidth = "83%";
    let commentValue = '';
    let comments = [];
    let caseExportModal;
    let exportData = [];
    let additionalPatientInfoCase;
    let ordering = '';
    let trackStatusSort = '';
    const parameters_limit = 100;
    let viewParametersModal;

    export let uuids;
    export let name;
    export let date;
    export let id;
    export let isNotificationView;
    export let uuid_to_alert_info_dict;
    export let queue_status;
    export let parameters;

    const presetNonTrackOptions = [
        {value: 'age', label: 'Age'},
        {value: 'biomarker ineligibility', label: 'Biomarker Ineligibility'},
        {value: 'cancer type', label: 'Cancer Type'},
        {value: 'cardiac', label: 'Cardiac'},
        {value: 'medical condition', label: 'Medical Condition'},
        {value: 'medical history', label: 'Medical History'},
        {value: 'number of prior therapies', label: 'Number of Prior Therapies'},
        {value: 'performance status inadequate', label: 'Performance Status Inadequate'},
        {value: 'stage of disease', label: 'Stage of Disease'}
    ];

    async function fetchCases() {
        isLoading = true;
        let patient_tracking_response = await getPatientTrackingStatuses(name, true);
        patient_tracking_summary = patient_tracking_response.results;
        patientAlreadyTrackedIds = patient_tracking_response.patient_ids;
        cases_summary = await getCasesWithUuids(CASE_FIELDS, uuids, currentPage, selectedPageSize);
        allCount = cases_summary.hits;
        cases = cases_summary.results;
        sortAllCases();
        let comment_summary = await getPatientTrackingStatusComments(name);
        comments = comment_summary.results;
        isLoading = false;
    }

    patientInfoOptions.subscribe(value => {
        exportData = value;
    });

    async function changePageSize(event) {
        selectedPageSize = event.target.value;
        currentPage = 1;
        await fetchCases();
    }

    async function changePage(page) {
        currentPage = page;
        await fetchCases();
    }

    function handleCheckboxChange(event, optionValue) {
        if (event.target.checked) {
            selectedNonTrackOptions = [...selectedNonTrackOptions, optionValue];
        } else {
            selectedNonTrackOptions = selectedNonTrackOptions.filter(value => value !== optionValue);
        }
    }

    function sortCases(property) {
        if (property === ordering || `-${property}` === ordering) {
            if (ordering.startsWith('-')) {
                ordering = '';
            } else {
                ordering = `-${property}`;
            }
        } else {
            ordering = property;
        }
        fetchCases();
    }

    function getTrackValue(c, status) {
        if (patientAlreadyTrackedIds.includes(c.patient_id)) {
            if (status === "tracked") {
                return patient_tracking_summary[c.patient_id].tracked
            } else if (status === "not_tracked") {
                return !patient_tracking_summary[c.patient_id].tracked && !patient_tracking_summary[c.patient_id].needs_more_review
            } else if (status === "needs_more_review") {
                return patient_tracking_summary[c.patient_id].needs_more_review
            }
        }
        return false;
    }

    function sortAllCases() {
        if (ordering !== '') {
            if (ordering === 'name') {
                cases.sort((a, b) => a.patient_name.localeCompare(b.patient_name));
            } else if (ordering === '-name') {
                cases.sort((a, b) => b.patient_name.localeCompare(a.patient_name));
            } else if (ordering === 'physician') {
                cases.sort((a, b) => b.patient_information[0].ordering_physician.localeCompare(a.patient_information[0].ordering_physician));
            } else if (ordering === '-physician') {
                cases.sort((a, b) => a.patient_information[0].ordering_physician.localeCompare(b.patient_information[0].ordering_physician));
            } else if (ordering === 'date') {
                cases.sort((a, b) => getMostRecentDate(a.lab_reported_dates) - getMostRecentDate(b.lab_reported_dates));
            } else if (ordering === '-date') {
                cases.sort((a, b) => getMostRecentDate(b.lab_reported_dates) - getMostRecentDate(a.lab_reported_dates));
            }
        } else if (trackStatusSort !== '') {
            if (trackStatusSort === "tracked") {
                cases.sort((a, b) => getTrackValue(b, "tracked") - getTrackValue(a, "tracked"));
            } else if (trackStatusSort === "not_tracked") {
                cases.sort((a, b) => getTrackValue(b, "not_tracked") - getTrackValue(a, "not_tracked"));
            } else if (trackStatusSort === "needs_more_review") {
                cases.sort((a, b) => getTrackValue(b, "needs_more_review") - getTrackValue(a, "needs_more_review"));
            }
        }
    }

    function formatParameters(parameters) {
        let params = [];
        let filters = parameters.filters;
        filters.forEach(filter => {
            if ("range" === filter.type) {
                let param = `${filter.operator} of ${filter.field} ${filter.values[0].value} to ${filter.values[1].value}`;
                params = [...params, param];
            } else {
                filter.values.forEach(value => {
                    let param = `${filter.operator} of ${value.value}`;
                    params = [...params, param];
                });
            }
        });
        return params.toString().replaceAll(",", ", ");
    }

    function sortCasesByTrackingStatus() {
        ordering = '';
        if (trackStatusSort === '') {
            trackStatusSort = "tracked";
        } else if (trackStatusSort === "tracked") {
            trackStatusSort = "not_tracked";
        } else if (trackStatusSort === "not_tracked") {
            trackStatusSort = "needs_more_review";
        } else {
            trackStatusSort = '';
        }
        fetchCases();
    }

    function getMostRecentDate(dates) {
        return new Date(Math.max(...dates.map(date => new Date(date))));
    }

    function handleOpenTrackPatientModal(c) {
        currentCase = c;
        trackPatientModal.open()
    }

    function handleOpenNotTrackPatientModal(c) {
        currentCase = c;
        notTrackPatientModal.open()
    }

    function handleOpenNeedsMoreReviewPatientModal(c) {
        currentCase = c;
        needsMoreReviewPatientModal.open()
    }

    function handleCommentModalOpen(c) {
        currentCase = c;
        commentModal.open()
    }

    const onTrackPatient = async () => {
        let alert_id;
        if (id !== '') {
            alert_id = id;
        } else {
            alert_id = uuid_to_alert_info_dict[String(currentCase.uuid)].alert_id;
        }

        let trigger = currentCase.alert_triggers.find(item => item.alert_id === alert_id);
        let diseases = [];
        let alts = [];
        let genes = [];
        if (trigger !== undefined) {
            if (trigger.disease_triggers !== "[]") {
                diseases = trigger.disease_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "").split(", ");
            }
            if (trigger.alteration_triggers !== "[]") {
                alts = trigger.alteration_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "").split(", ");
            }
            if (trigger.gene_triggers !== "[]") {
                genes = trigger.gene_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "").split(", ");
            }
        }
        const body = {
            "alert_config_name": name,
            "patient_uuid": currentCase.patient_uuid,
            "tracked": true,
            "is_waiting": true,
            "tracked_user_name": getUser().first_name + " " + getUser().last_name,
            "matched_alterations": alts,
            "matched_genes": genes,
            "matched_diseases": diseases
        };
        let response = await createPatientTrackingStatus(body);
        if (response.ok) {
            trackPatientModal.close();
            if (queue_status !== '') {
                queue_status.total_statuses_decided = queue_status.total_statuses_decided + 1;
            }
            await fetchCases();
            pushSuccess("Successfully tracking patient.");
        } else {
            trackPatientModal.close();
            pushFail("Failed to track patient.");
        }
    }

    const onNotTrackPatient = async () => {
        let not_tracking_reasons = selectedNonTrackOptions.join(",");
        if (noTrackReason.trim().length > 0) {
            if (not_tracking_reasons !== '') {
                not_tracking_reasons = not_tracking_reasons + "," + noTrackReason;
            } else {
                not_tracking_reasons = noTrackReason;
            }
        }
        if (not_tracking_reasons !== '') {
            const body = {
                "alert_config_name": name,
                "patient_uuid": currentCase.patient_uuid,
                "not_tracking_reasons": not_tracking_reasons,
                "tracked_user_name": getUser().first_name + " " + getUser().last_name
            };
            let response = await createPatientTrackingStatus(body);
            if (response.ok) {
                noTrackReason = '';
                selectedNonTrackOptions = [];
                notTrackPatientModal.close();
                if (queue_status !== '') {
                    queue_status.total_statuses_decided = queue_status.total_statuses_decided + 1;
                }
                await fetchCases();
                pushSuccess("Successfully not tracking patient.");
            } else {
                notTrackPatientModal.close();
                pushFail("Failed to save not tracking for patient.");
            }
        } else {
            pushFail("Must add a reason for not tracking.");
        }

    }

    const onNeedsMoreReviewPatient = async () => {
        const body = {
            "alert_config_name": name,
            "patient_uuid": currentCase.patient_uuid,
            "needs_more_review": true,
            "tracked_user_name": getUser().first_name + " " + getUser().last_name
        };
        let response = await createPatientTrackingStatus(body);
        if (response.ok) {
            needsMoreReviewPatientModal.close();
            if (queue_status !== '') {
                queue_status.total_statuses_needs_more_review = queue_status.total_statuses_needs_more_review + 1;
            }
            await fetchCases();
            pushSuccess("Successfully designated patient as needs more review.");
        } else {
            notTrackPatientModal.close();
            pushFail("Failed to designate patient as needs more review.");
        }
    }

    const onSaveComment = async () => {
        if (commentValue !== '') {
            const comment_body = {
                "comment": commentValue,
                "user_created": getUser().first_name + " " + getUser().last_name,
                "alert_config_name": name,
                "patient": currentCase.patient_id
            };
            let comment_response = await createPatientTrackingStatusComment(comment_body);
            if (comment_response.ok) {
                commentValue = '';
                commentModal.close();
                await fetchCases();
                pushSuccess("Successfully created tracked patient comment.");
            } else {
                commentValue = '';
                commentModal.close();
                pushFail("Failed to create tracked patient comment.");
            }
        }
    }

    function handleNotTrackModalClose() {
        noTrackReason = '';
        notTrackPatientModal.close();
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = {year: 'numeric', month: '2-digit', day: '2-digit'};
        return date.toLocaleDateString('en-US', options);
    }

    function formatTime(datetimeString) {
        let date = new Date(datetimeString);
        return date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true});
    }

    function toggleDropdown(index, c) {
        cases[index].showDropdown = !cases[index].showDropdown;
        additionalPatientInfoCase = c;
    }

    function handleOpenEditTrackingModal(id) {
        trackedPatientId = patient_tracking_summary[id].id;
        trackedPatientNeedsMoreReview = patient_tracking_summary[id].needs_more_review
        editTrackPatientModal.open()
    }

    const onEditTrackPatient = async () => {
        let response = await deletePatientTrackingStatus(trackedPatientId);
        if (response.ok) {
            editTrackPatientModal.close();
            if (queue_status !== '') {
                if (trackedPatientNeedsMoreReview) {
                    queue_status.total_statuses_needs_more_review = queue_status.total_statuses_needs_more_review - 1;
                } else {
                    queue_status.total_statuses_decided = queue_status.total_statuses_decided - 1;
                }
            }
            await fetchCases();
            pushSuccess("Successfully updated tracking status.");
        } else {
            editTrackPatientModal.close();
            pushFail("Failed to update tracking status.");
        }
    };

    function getMatchingParameters(c) {
        let alert_id;
        if (id !== '') {
            alert_id = id;
        } else {
            alert_id = uuid_to_alert_info_dict[String(c.uuid)].alert_id;
        }

        if (c.alert_triggers.length !== 0) {
            let trigger = c.alert_triggers.find(item => item.alert_id === alert_id);
            if (trigger !== undefined) {
                let finalString = "";
                if (trigger.disease_triggers !== "[]") {
                    finalString = finalString + "<b>Diseases:</b> " +
                        trigger.disease_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "");
                }
                if (trigger.alteration_triggers !== "[]") {
                    finalString = finalString + "<br><b>Alterations:</b> " +
                        trigger.alteration_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "");
                }
                if (trigger.gene_triggers !== "[]") {
                    finalString = finalString + "<br><b>Genes:</b> " +
                        trigger.gene_triggers.replaceAll("'", "").replaceAll("[", "").replaceAll("]", "");
                }
                return finalString;
            }
        }
        return "";
    }

    function getInfoFromCase(c, field) {
        let value = 'N/A';
        if (field in c) {
            value = c[field];
        } else if (c['meta'] !== null && field in c['meta']) {
            value = c['meta'][field];
        } else if (c['patient_information'] !== null && field in c['patient_information'][0]) {
            value = c['patient_information'][0][field];
        }

        if (Array.isArray(value)) {
            if (value.every(value => value === null)) {
                return "N/A";
            } else {
                return Array.from(new Set(value)).join(", ");
            }
        } else {
            if (value === null) {
                return "N/A";
            } else {
                return value;
            }
        }
    }

    const onCaseExport = async () => {
        let response = await getCasesWithUuids(CASE_FIELDS, uuids, 1, 1000);
        let results = response.results;
        await exportToCSV(results);
        caseExportModal.close();
    };

    function generateCSVContent(data) {
        // Define the headers for the CSV
        let headers = [
            "Full Name",
            "Patient MRN",
            "Ordering Physician",
            "Assay Names",
            "Lab Reported Dates",
            "Matching Parameters"
        ];
        exportData.forEach((option) => {
            if (option.visible) {
                headers = [...headers, option.name];
            }
        });

        // Convert each patient's lab results into CSV rows
        const rows = [];
        data.forEach((c) => {
            let full_name = c.patient_name;
            let patient_mrn = c.patient_mrn;
            let ordering_physician = c.patient_information[0].ordering_physician;
            let assay_names = c.assay_names.toString().replace("[", "").replace("]", "").replaceAll(",", ", ");
            let reported_dates = Array.from(new Set(c.lab_reported_dates)).toString().replace("[", "").replace("]", "").replaceAll(",", ", ");
            let matching_parameters = getMatchingParameters(c);
            let row_info = [
                full_name || "",
                patient_mrn || "",
                ordering_physician || "",
                assay_names || "",
                reported_dates || "",
                matching_parameters || ""];
            exportData.forEach((option) => {
                if (option.visible) {
                    row_info = [...row_info, getInfoFromCase(c, option.field)];
                }
            });
            rows.push(row_info);
        });

        return [headers, ...rows].map((row) =>
            row.map(escapeCSVValue).join(",")
        ).join("\n");
    }

    function escapeCSVValue(value) {
        // Escape double quotes and wrap the value in double quotes if necessary
        if (value.includes(",")) {
            return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
    }

    async function exportToCSV(data) {
        const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(generateCSVContent(data))}`;
        const link = document.createElement("a");
        link.href = csvContent;
        link.download = "cases.csv";
        link.click();
    }

    function handleLeftNav() {
        hideLeftNav = !hideLeftNav;
        leftPaneWidth = hideLeftNav ? "0%" : "17%";
        rightPaneWidth = hideLeftNav ? "100%" : "83%";
    }

    function getComments(id) {
        let patient_comments = comments.filter(comment => comment.patient === id);
        patient_comments = patient_comments.sort((a, b) => new Date(b.created) - new Date(a.created));
        let string = "";
        patient_comments.forEach((c) => {
            string = string + "<li>" + c.comment + " (" + c.user_created + " " + formatDate(c.created) + " " + formatTime(c.created) + ")" + "</li>";
        })
        return string;
    }

    function checkIfNeedsMoreReviewRow(c) {
        if (getPdModOverrideVar().includes('patient_tracking')) {
            if (patientAlreadyTrackedIds.includes(c.patient_id)) {
                if (patient_tracking_summary[c.patient_id].needs_more_review) {
                    return true;
                }
            }
        }
        return false;
    }

    onMount(() => {
        fetchCases();
    });

    $: totalPages = Math.ceil(allCount / selectedPageSize);
</script>
<style>
    .container {
        display: flex;
        height: calc(80vh - 51px);
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    thead th {
        position: sticky;
        top: 0;
    }

    th {
        background-color: #233C6B;
        color: #ffffff;
    }

    tr {
        background-color: white;
    }

    button {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }

    .summary {
        height: 65vh;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 2em;
        margin-right: 2em;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1em;
        width: 94%;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .next-button {
        background-color: rgba(7, 7, 7, 0.19);
        color: #fff;
    }

    .hide-additional-fields {
        background-color: rgba(7, 7, 7, 0.19);
        color: black;
        margin-right: 25px;
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 5px;
    }

    .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .title-info {
        margin-right: 20px;
    }

    .title-name {
        font-weight: bold;
        margin-right: 10px;
    }

    .pagination button {
        margin-left: 0.5em;
    }

    .pages-span {
        margin-left: 0.5em;
        margin-right: 0.2em;
    }

    .icon-container {
        display: inline-flex;
        align-items: center;
        /*background-color: white;*/
    }

    .modal-button {
        margin-right: 10px;
        padding: 8px 16px;
        font-size: 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .modal-footer-row {
        margin-top: 20px;
        display: flex;
    }

    .checkbox {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .no-track-reason-input {
        float: left;
        margin-top: 10px;
        width: 80%;
    }

    .container-decision {
        display: flex;
        flex-direction: column; /* Stacks items vertically */
        margin-right: 10px;
    }

    .item {
        margin-bottom: 5px;
        font-size: 12px;
    }

    .decision-item {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: bold;
    }

    .scrollable-table {
        overflow-y: auto;
        max-height: calc(64vh - 4em);
        border-radius: 10px;
    }

    .big-textbox {
        width: 400px;
        height: 200px;
        resize: horizontal;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 15px;
    }

    .export-icon-png-large {
        width: 50px;
        cursor: pointer;
    }

    .more-review-icon-png {
        width: 35px;
        cursor: pointer;
    }
    .queue-status {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
    }
    .highlight {
        background-color: #fff9c4; /* Example highlight color */
    }
    .see-more {
        color: blue;
        cursor: pointer;
        text-decoration: underline;
    }

</style>
<div class="container">
    <HSplitPane bind:leftPaneSize={leftPaneWidth} bind:rightPaneSize={rightPaneWidth}>
        <left slot="left">
            {#if !hideLeftNav}
                <div>
                    <AdditionalPatientInfoSideBar/>
                </div>
            {/if}
        </left>
        <right slot="right">
            <div class="summary">
                <div class="title">
                    <button class="hide-additional-fields" on:click={() => handleLeftNav()}>
                        {hideLeftNav ? 'View Additional Info' : 'Hide Additional Info'}
                    </button>
                    {#if name !== ''}
                        <div class="title-name">Alert Name:</div>
                        <div class="title-info">{name}</div>
                    {/if}
                    {#if date !== ''}
                        <div class="title-name">Notification Date:</div>
                        <div class="title-info">{date}</div>
                    {/if}
                    {#if queue_status !== ''}
                        <div class="title-name">Queue Status:</div>
                        <div class="queue-status">
                            <div>{queue_status.total_statuses_decided}/{queue_status.total_statuses} Reviewed</div>
                            {#if queue_status.total_statuses_needs_more_review !== 0}
                                <div>{queue_status.total_statuses_needs_more_review} Needs More Review</div>
                            {/if}
                        </div>
                    {/if}
                    {#if parameters !== ''}
                        <div class="title-name">Parameters:</div>
                        <div style="width: 300px">
                            {#if formatParameters(parameters).length <= parameters_limit}
                                <div class="title-info">{formatParameters(parameters)}</div>
                            {:else}
                                <span class="text">{formatParameters(parameters).slice(0, parameters_limit)}...</span>
                                <span class="see-more" on:click={() => viewParametersModal.open()} aria-hidden="true">See more</span>
                            {/if}
                        </div>
                    {/if}
                    <div style="margin-left: auto; display: flex; align-items: center;">
                        <div on:click={() => isNotificationView = true} aria-hidden="true" style="margin-right: 20px">
                        <span title="Close">
                            <Icon data={faCircleXmarkRegular} scale="2" style="color: #243c6a;"/>
                        </span>
                        </div>
                        <div on:click={() => caseExportModal.open()} aria-hidden="true">
                            <div title="Export">
                                <img src="static/images/exportCurrentResultsIcon.png"
                                     class="export-icon-png-large" alt=""></div>
                        </div>
                    </div>

                </div>
                <div class="pagination">
                    <label for="page-size">Page Size:</label>
                    <select id="page-size" on:change={changePageSize}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="{allCount}">All</option>
                    </select>
                    {#if !isLoading && cases.length > 0}
                        <div class="pagination">
                            {#if cases_summary.pagination.previous}
                                <button class="next-button" on:click={() => changePage(currentPage - 1)}>Previous
                                </button>
                            {/if}
                            <span class="pages-span">
                            Page {currentPage} of {totalPages}
                        </span>
                            {#if cases_summary.pagination.next}
                                <button class="next-button" on:click={() => changePage(currentPage + 1)}>Next</button>
                            {/if}
                        </div>
                    {/if}
                </div>
                <div class="scrollable-table">
                    {#if isLoading}
                        <Loader/>
                    {:else}
                        <table>
                            <thead>
                            <tr>
                                <th on:click={() => sortCases('name')}>
                                    Patient Name
                                    {#if ordering === 'name'}
                                        &#9650; <!-- Upward arrow -->
                                    {:else if ordering === '-name'}
                                        &#9660; <!-- Downward arrow -->
                                    {/if}
                                </th>
                                <th>MRN</th>
                                <th on:click={() => sortCases('physician')}>
                                    Ordering Physician
                                    {#if ordering === 'physician'}
                                        &#9650; <!-- Upward arrow -->
                                    {:else if ordering === '-physician'}
                                        &#9660; <!-- Downward arrow -->
                                    {/if}
                                </th>
                                {#if getPdModOverrideVar().includes('patient_tracking')}
                                    <th on:click={() => sortCasesByTrackingStatus()} style="width: 15%;">
                                        Track Patient
                                    </th>
                                    <th style="width: 20%;">Comments</th>
                                {/if}
                                <th>Test Type</th>
                                <th on:click={() => sortCases('date')}>
                                    Test Result Date
                                    {#if ordering === 'date'}
                                        &#9650; <!-- Upward arrow -->
                                    {:else if ordering === '-date'}
                                        &#9660; <!-- Downward arrow -->
                                    {/if}
                                </th>
                                <th>Matching Parameters</th>
                                <th>Clinical Data</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each cases as c, i}
                                <tr class:highlight={checkIfNeedsMoreReviewRow(c)}>
                                    <td>{c.patient_name}</td>
                                    <td>{c.patient_mrn || "N/A"}</td>
                                    <td>{c.patient_information[0].ordering_physician}</td>
                                    {#if getPdModOverrideVar().includes('patient_tracking')}
                                        {#if patientAlreadyTrackedIds.includes(c.patient_id)}
                                            {#if patient_tracking_summary[c.patient_id].tracked}
                                                <td>
                                                    <div class="icon-container">
                                                        <div>
                                                            <Icon data={faCheckCircle} scale="1.75"
                                                                  style="color: green; margin-right: 10px;"/>
                                                        </div>
                                                        <div class="container-decision">
                                                            <div class="decision-item">Tracked</div>
                                                            <div class="item">{patient_tracking_summary[c.patient_id].tracked_user_name}</div>
                                                            <div class="item">{formatDate(patient_tracking_summary[c.patient_id].created)}</div>
                                                            <div class="item">{formatTime(patient_tracking_summary[c.patient_id].created)}</div>
                                                        </div>
                                                        <div on:click={() => handleOpenEditTrackingModal(c.patient_id)}
                                                             aria-hidden="true">
                                                            <Icon data={faEdit} scale="1.5" style="margin-left: 10px;"/>
                                                        </div>
                                                    </div>
                                                </td>
                                            {:else}
                                                {#if patient_tracking_summary[c.patient_id].needs_more_review}
                                                    <td>
                                                        <div class="icon-container">
                                                            <div class="container-decision">
                                                                <div class="decision-item">Further Review</div>
                                                                <div class="item">{patient_tracking_summary[c.patient_id].tracked_user_name}</div>
                                                                <div class="item">{formatDate(patient_tracking_summary[c.patient_id].created)}</div>
                                                                <div class="item">{formatTime(patient_tracking_summary[c.patient_id].created)}</div>
                                                            </div>
                                                            <div>
                                                                <img src="static/images/needsMoreReviewIcon.png" class="more-review-icon-png" alt="">
                                                            </div>
                                                            <div on:click={() => handleOpenEditTrackingModal(c.patient_id)}
                                                                 aria-hidden="true">
                                                                <Icon data={faEdit} scale="1.5" style="margin-left: 10px;"/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                {:else}
                                                    <td>
                                                        <div class="icon-container">
                                                            <div class="container-decision">
                                                                <div class="decision-item">Not Tracked</div>
                                                                <div class="item">{patient_tracking_summary[c.patient_id].not_tracking_reasons.replaceAll(",", ", ")}</div>
                                                                <div class="item">{patient_tracking_summary[c.patient_id].tracked_user_name}</div>
                                                                <div class="item">{formatDate(patient_tracking_summary[c.patient_id].created)}</div>
                                                                <div class="item">{formatTime(patient_tracking_summary[c.patient_id].created)}</div>
                                                            </div>
                                                            <div>
                                                                <Icon data={faTimesCircle} scale="1.75"
                                                                      style="color: red;"/>
                                                            </div>
                                                            <div on:click={() => handleOpenEditTrackingModal(c.patient_id)}
                                                                 aria-hidden="true">
                                                                <Icon data={faEdit} scale="1.5" style="margin-left: 10px;"/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                {/if}
                                            {/if}
                                        {:else}
                                            <td>
                                                <div class="icon-container">
                                                    <div on:click={() => handleOpenTrackPatientModal(c, i)}
                                                         aria-hidden="true">
                                                        <Icon data={faCheckCircle} scale="1.75"
                                                              style="color: green; margin-right: 10px; cursor: pointer"/>
                                                    </div>
                                                    <div on:click={() => handleOpenNotTrackPatientModal(c)}
                                                         aria-hidden="true">
                                                        <Icon data={faTimesCircle} scale="1.75"
                                                              style="color: red; margin-right: 10px; cursor: pointer"/>
                                                    </div>
                                                    <div on:click={() => handleOpenNeedsMoreReviewPatientModal(c)}
                                                         aria-hidden="true">
                                                        <img src="static/images/needsMoreReviewIcon.png" class="more-review-icon-png" alt="">
                                                    </div>
                                                </div>
                                            </td>
                                        {/if}
                                        <td>
                                            {@html getComments(c.patient_id)}
                                            <button on:click={() => handleCommentModalOpen(c)}
                                                    style="background-color: rgba(144, 195, 76, 1); margin-top: 10px">
                                                Add New Comment
                                            </button>
                                        </td>
                                    {/if}
                                    <td>{c.assay_names.toString().replace("[", "").replace("]", "").replaceAll(",", ", ")}</td>
                                    <td>{Array.from(new Set(c.lab_reported_dates)).toString().replace("[", "").replace("]", "").replaceAll(",", ", ")}</td>
                                    <td>{@html getMatchingParameters(c)}</td>
                                    <td>
                                        <button on:click={() => toggleDropdown(i, c)}>
                                            {cases[i].showDropdown ? 'Close' : 'View'}
                                        </button>
                                    </td>
                                </tr>
                                {#if cases[i].showDropdown}
                                    <tr>
                                        <td colspan="9">
                                            <div class="table">
                                                <AdditionalPatientInfoTable {additionalPatientInfoCase}/>
                                            </div>
                                        </td>
                                    </tr>
                                {/if}
                            {/each}
                            </tbody>
                        </table>
                    {/if}
                </div>
            </div>
        </right>
    </HSplitPane>
</div>

<!--Track Patient Modal-->
<form on:submit|preventDefault={onTrackPatient}>
    <Modal bind:modalFunc={trackPatientModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to track this patient?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="modal-footer-row">
                <button class="modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Confirm</button>
                <button class="modal-button" on:click={() => trackPatientModal.close()}>Cancel</button>
            </div>
        </div>
    </Modal>
</form>

<!--Not Track Patient Modal-->
<form on:submit|preventDefault={onNotTrackPatient}>
    <Modal bind:modalFunc={notTrackPatientModal}>
        <div slot="header">
            <div>
                <h1>Select reason for not tracking patient</h1>
            </div>
        </div>
        <div slot="content">
            {#each presetNonTrackOptions as option}
                <label class="checkbox">
                    <input type="checkbox" value={option.value}
                           on:change={(e) => handleCheckboxChange(e, option.value)}/>
                    {option.label}
                </label>
            {/each}
            <input class="no-track-reason-input" type="text" bind:value={noTrackReason} name="alertName"
                   placeholder="Alternate Reason">
        </div>
        <div slot="footer">
            <div class="modal-footer-row">
                <button class="modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Submit</button>
                <button class="modal-button" on:click={() => handleNotTrackModalClose()}>Cancel</button>
            </div>
        </div>
    </Modal>
</form>

<!--Track Patient Modal-->
<form on:submit|preventDefault={onNeedsMoreReviewPatient}>
    <Modal bind:modalFunc={needsMoreReviewPatientModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to flag this patient for review?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="modal-footer-row">
                <button class="modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Confirm</button>
                <button class="modal-button" on:click={() => needsMoreReviewPatientModal.close()}>Cancel</button>
            </div>
        </div>
    </Modal>
</form>

<!--Edit Track Patient Modal-->
<form on:submit|preventDefault={onEditTrackPatient}>
    <Modal bind:modalFunc={editTrackPatientModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to edit this patients tracking status?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="modal-footer-row">
                <button class="modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Edit</button>
                <button class="modal-button" on:click={() => editTrackPatientModal.close()}>Cancel</button>
            </div>
        </div>
    </Modal>
</form>

<!--Comment Modal-->
<form on:submit|preventDefault={onSaveComment}>
    <Modal bind:modalFunc={commentModal}>
        <div slot="content">
            <textarea bind:value={commentValue} class="big-textbox"></textarea>
        </div>
        <div slot="footer">
            <div class="modal-footer-row">
                <button class="modal-button" style="background-color: rgba(144, 195, 76, 1)" type="submit">Add Comment
                </button>
                <button class="modal-button" style="background-color: lightcoral" on:click={() => commentModal.close()}>
                    Close
                </button>
            </div>
        </div>
    </Modal>
</form>

<!--Case Export Modal-->
<form on:submit|preventDefault={onCaseExport}>
    <Modal bind:modalFunc={caseExportModal}>
        <div slot="header">
            <div>
                <h1>Are you sure you want to export cases?</h1>
            </div>
        </div>
        <div slot="footer">
            <div class="alert-modal-first-row">
                <button on:click={() => caseExportModal.close()}>Cancel</button>
                <button style="background-color: rgba(144, 195, 76, 1)" type="submit">Export</button>
            </div>
        </div>
    </Modal>
</form>

<!--View Parameters Modal-->
<Modal bind:modalFunc={viewParametersModal} top="-200px" left="650px" backdrop="false">
    <div slot="header">
        <div style="display: flex; align-items: center; margin-left: 150px">
            <h3>
                Alert Parameters
            </h3>
            <div on:click={() => viewParametersModal.close()} style="margin-left: 125px" aria-hidden="true">
                <span title="Close">
                    <Icon data={faCircleXmarkRegular} scale="2" style="color: #243c6a;"/>
                </span>
            </div>
        </div>
    </div>
    <div slot="content">
        <div style="width: 500px">
            {formatParameters(parameters)}
        </div>
    </div>
</Modal>