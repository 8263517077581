<script>
    import {searchQueries, inFilterView} from "../../store/notificationSearchQueries";
    import {expandMainHeader} from "../../store/navigation";
    import {Icon} from "svelte-awesome";
    import {faCircleXmark as faCircleXmarkRegular} from "@fortawesome/free-regular-svg-icons";

    let filterData = {};
    let titleHeight = 0;
    let titleRef;
    let isFilterView;

    $: filterData = $searchQueries;

    $: isFilterView = $inFilterView;

    searchQueries.subscribe(value => {
        if (titleRef) {
            setTimeout(() => {
                titleHeight = titleRef.offsetHeight;
                if (titleHeight > 24) {
                    expandMainHeader.set(true);
                } else {
                    expandMainHeader.set(false);
                }
            });
        }
    });

    function getTitle(key) {
        if (key === "full_name") {
            return "Patient Name";
        } else if (key === "markers") {
            return "Alterations";
        } else if (key === "ordering_physician") {
            return "Ordering Physician";
        } else if (key === "genes") {
            return "Genes";
        } else if (key === "notification_start_date") {
            return "Notification Start Date";
        } else if (key === "notification_end_date") {
            return "Notification End Date";
        } else if (key === "data_sets") {
            return "Vendor";
        }
    }

    async function fetchResetNotifications() {
        searchQueries.update(storeValue => {
            storeValue.full_name = '';
            storeValue.markers = '';
            storeValue.notification_start_date = '';
            storeValue.notification_end_date = '';
            storeValue.ordering_physician = '';
            storeValue.genes = '';
            storeValue.data_sets = '';
            return storeValue;
        });
        inFilterView.update(storeValue => {
            storeValue = false;
            return storeValue;
        });
    }

</script>

<style>
    .header {
        display: flex;
        text-align: center;
        font-size: 25px;
        vertical-align: top;
        margin-top: 10px;
        height: 60px;
        justify-content: center;
        margin-left: 35px;
    }

    .filters {
        font-size: 20px;
        display: flex;
        align-items: center;
        width: calc(100vw - 130px);
        margin-bottom: 20px;
        vertical-align: center;
        margin-left: 30px;
    }

    .filter-header {
        display: flex;
        font-weight: bold;
        font-size: 20px;
    }

    .filter-items {
        display: flex;
        flex-wrap: wrap;
        margin-left: 10px;

    }

    .filter-item {
        font-size: 17px;
        margin-right: 10px;
    }

    .filter-key {
        font-weight: bold;
    }

    .center-align {
        flex: 1;
        text-align: center;
    }

    .left-align {
        font-size: 18px;
        margin-top: 33px;
    }

    .filter-view {
        display: flex;
        align-items: center;
    }

</style>

<div class="full-header">
    <div class="header">
        {#if isFilterView}
            <div class="left-align">
                <div class="filter-view" on:click={fetchResetNotifications} aria-hidden="true">
                    <Icon data="{faCircleXmarkRegular}" scale="2" style="margin-right: 10px"/>
                    <span>Close Filter View</span>
                </div>
            </div>
        {/if}
        <h5 class="center-align">View Notifications</h5>
    </div>
    <div class="filters">
        <p class="filter-header" bind:this={titleRef}>Applied Filters: </p>
        <div class="filter-items">
            {#each Object.keys(filterData) as key}
                {#if filterData[key] !== '' && filterData[key].length !== 0}
                    <div class="filter-item">
                        <span class="filter-key">{getTitle(key)}
                            :</span> {filterData[key].toString().replaceAll(",", ", ")}
                    </div>
                {/if}
            {/each}
        </div>
    </div>
</div>