import {callApi} from "../rest.js";

const ROOT_URL = '/analytics/queries';


/**
 * fetch a list of results from Solr given a list of queries
 * @body filters: filters to apply
 * @returns {Promise<Response|any>}
 */
export async function queryCases(filters, add_patient_info, page = 1, pageSize = 20, sortBy = null) {
    let url = `${ROOT_URL}/cases?page=${page}&page_size=${pageSize}&add_patient_info=${add_patient_info}`;
    if (sortBy !== null) {
        url = `${url}&sort_by=${sortBy}`;
    }
    return await callApi(url, "POST", {filters});
}