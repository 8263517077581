<script>
    import {ArrowUpDown} from 'lucide-svelte';
    import {assayMatchResults, selectedCase} from "../../store/patientMatch";
    import {HSplitPane} from "svelte-split-pane";
    import {faXmark} from "@fortawesome/free-solid-svg-icons";
    import {Icon} from "svelte-awesome";
    import {changesDetected} from "../../store/patientSearchQueries";

    export let caseMatch = false; //exported value to determine if the Key Biomarkers should come from $selectedCase or assay match call
    let nextBestConcepts = [];
    let nextBestSummary = [];

    let view = 'assay';
    let sortConfig = {
        key: '',
        direction: 'asc'
    };

    let openView = false;
    let decoratePertAlts = false;

    // Subscribe to store changes and update local variables
    $: if ($assayMatchResults) {
        nextBestConcepts = $assayMatchResults.next_best_concepts || [];
        nextBestSummary = $assayMatchResults.next_best_summary || [];

        // If next best concepts/summary are empty, fallback to matched concepts/summary
        if (nextBestConcepts.length === 0 && nextBestSummary.length === 0) {
            nextBestConcepts = $assayMatchResults.matched_concepts || [];
            nextBestSummary = $assayMatchResults.matched_summary || [];
        }
    } else {
        nextBestConcepts = [];
        nextBestSummary = [];
    }

    function handleSort(key) {
        sortConfig = {
            key,
            direction: sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc'
        };
    }

    $: sortedData = sortData(view === 'assay' ? nextBestSummary :
            nextBestConcepts.filter(item => item.matched_assays && item.matched_assays.length > 0),
        sortConfig.key
    );

    $: if ($changesDetected) {
        changesDetected.set(false);
        openView = false;
        decoratePertAlts = false;
    }

    $: unrelatedAlterations = view === 'assay' ? [] :
        sortData(nextBestConcepts.filter(item => !item.matched_assays || item.matched_assays.length === 0),
            sortConfig.key
        );

    function sortAlphabetically(entries) {
        return [...entries].sort((a, b) => a.localeCompare(b));
    }

    function sortData(data, key) {
        if (!sortConfig.key) return data;

        return [...data].sort((a, b) => {
            if (sortConfig.direction === 'asc') {
                return a[key] > b[key] ? 1 : -1;
            }
            return a[key] < b[key] ? 1 : -1;
        });
    }

    function titleCase(status) {
        return status.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    function getStatusColor(status) {
        switch (status) {
        case 'positive':
            return '#9FCE67';
        case 'negative':
            return '#FC0C0B';
        case 'untested':
            return '#233C6B';
        case 'no-match':
            return '#333333';
        default:
            return '#333333';
        }
    }

    function sortPertAls(pertinentAlterations) {
        const statusOrder = {'positive': 1, 'negative': 2, 'untested': 3};

        return pertinentAlterations.sort((a, b) => {
            if (statusOrder[a.status] < statusOrder[b.status]) return -1;
            if (statusOrder[a.status] > statusOrder[b.status]) return 1;
            return 0;
        });
    }

    function decoratePertAlt(alt) {
        const match = findKeyMarkerMatch(alt);


        return getStatusColor(match);

    }

    function findKeyMarkerMatch(alt) {
        let match;
        if(caseMatch){
            match = $selectedCase?.pertinent_alteration_groups?.pertinent_alterations?.find(
                pertAlt => pertAlt.alteration === alt
            );
        }
        else{
            match = $assayMatchResults?.patient?.pertinent_alterations?.pertinent_alterations?.find(
                pertAlt => pertAlt.alteration === alt
            );
        }
        if (match) {
            return match.status;
        } else {
            return 'no-match';
        }
    }

    function sortConceptByKeyBiomarker(data) {
        return Object.entries(data)
            .sort(([conceptA, countA], [conceptB, countB]) => {
                const statusA = findKeyMarkerMatch(conceptA);
                const statusB = findKeyMarkerMatch(conceptB);

                const statusOrder = {'positive': 3, 'negative': 2, 'untested': 1, 'no-match': 0};
                return statusOrder[statusB] - statusOrder[statusA];
            });
    }


</script>

<style>
    .table-container {
        border: 1px solid #e5e7eb;
        border-radius: 0.5rem;
        margin: 1rem auto;
        width: 100%;
        max-width: 80rem;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }

    thead {
        background-color: #f9fafb;
        width: 100%;
    }

    thead tr {
        width: 100%;
        background-color: #f9fafb;
    }

    .header-cell {
        padding: 1rem;
        border-bottom: 1px solid #e5e7eb;
        border-right: 1px solid #e5e7eb;
        background-color: #f9fafb;
        text-align: left;
        white-space: nowrap;
    }

    .header-cell:last-child {
        border-right: none;
    }

    .row-even {
        background-color: #ffffff;
    }

    .row-odd {
        background-color: #f9fafb;
    }

    .cell {
        padding: 1rem;
        border-bottom: 1px solid #e5e7eb;
        border-right: 1px solid #e5e7eb;
    }

    .cell:last-child {
        border-right: none;
    }

    .comma-list {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .comma-list span:not(:last-child)::after {
        content: ",";
    }

    .key-btn {
        background-color: #9FCE67;
        border: 1px solid #fff;
        border-radius: 8px;
        padding: 6px 10px;
        color: white;
        cursor: pointer;
    }

    .sort-btn {
        background-color: #233C6B;
        border: 1px solid #fff;
        border-radius: 8px;
        padding: 6px 10px;
        margin-right: 10px;
        color: white;
        cursor: pointer;
    }
</style>


{#if (openView)}
    <HSplitPane leftPaneSize="75%" rightPaneSize="25%">
        <left slot="left">
            <div class="w-full px-4 py-2">
                <div class="flex justify-between items-center mb-4">
                    <div style="display: flex; justify-content: space-between; align-items: center; margin: 25px;">
                        <div style="display: flex; flex-direction: column; justify-content: space-bewteen; align-items: start">
                            {#if ($assayMatchResults.patient && $assayMatchResults.patient.full_name)}
                                <span><strong>Name: </strong>{$assayMatchResults.patient.full_name}</span>
                                <span><strong>Diagnosis: </strong></span>
                                {#each $assayMatchResults.patient.diseases as diag}
                                    <span style="margin-left: 10px; margin-top:2px; padding:0">{diag}</span>
                                {/each}
                                <span><strong>Tests Performed: </strong></span>
                                {#each $assayMatchResults.patient.assays as assay}
                                    <span style="margin-left: 10px; margin-top:2px; padding:0">{assay.assay}
                                        - {assay.reported_date}</span>
                                {/each}
                            {/if}
                        </div>
                        <h2 class="text-2xl font-bold">
                            {view === 'assay' ? 'Test Recommendations' : 'Relevant Alterations'} ({sortedData.length})
                        </h2>
                        <span class="key-btn" on:click={() => {openView = !openView}} aria-hidden="true">View Key<br/>Disease Related<br/>Biomarkers</span>
                    </div>

                    <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; max-width: 80rem; margin: 1rem auto;">
                        <span/>
                        <div class="flex gap-8 mr-4">
                            <label class="flex items-center">
                                <input
                                        type="radio"
                                        value="assay"
                                        bind:group={view}
                                        class="mr-2"
                                />
                                Assay View
                            </label>
                            <label class="flex items-center">
                                <input
                                        type="radio"
                                        value="alteration"
                                        bind:group={view}
                                        class="mr-2"
                                />
                                Alteration View
                            </label>
                        </div>
                        <span class="sort-btn" on:click={() => {decoratePertAlts = !decoratePertAlts}}
                              aria-hidden="true">Sort By Key Biomarker</span>
                    </div>
                </div>

                <div class="table-container">
                    {#if view === 'assay'}
                        <table>
                            <thead>
                            <tr>
                                <th class="header-cell font-medium cursor-pointer" style="width: 25%"
                                    on:click={() => handleSort('assay_name')}>
                                    <div class="flex items-center">
                                        Assay
                                        <ArrowUpDown class="ml-2 h-4 w-4"/>
                                    </div>
                                </th>
                                <th class="header-cell font-medium cursor-pointer" style="width: 25%"
                                    on:click={() => handleSort('total_trial_count')}>
                                    <div class="flex items-center">
                                        Total Trials Count
                                        <ArrowUpDown class="ml-2 h-4 w-4"/>
                                    </div>
                                </th>
                                <th class="header-cell font-medium" style="width: 50%">
                                    Relevant Testable Alterations (Trials Count)
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each sortedData as item, i}
                                <tr class={i % 2 === 0 ? 'row-even' : 'row-odd'}>
                                    <td class="cell" style="width: 25%">{item.assay_name}</td>
                                    <td class="cell" style="width: 25%">{item.total_trial_count}</td>
                                    <td class="cell" style="width: 50%">
                                        <div class="comma-list">
                                            {#if (decoratePertAlts)}
                                                {#each sortConceptByKeyBiomarker(item.relevant_concept_count) as [concept, count]}
                                                    <span style="color: {caseMatch ? decoratePertAlt(concept) : ($assayMatchResults.patient && $assayMatchResults.patient.full_name) ? decoratePertAlt(concept) : '#333333'}; font-weight:{decoratePertAlt(concept)==='#333333' ? 'normal':'bold'}">{concept}
                                                        ({count})</span>
                                                {/each}
                                            {:else}
                                                {#each Object.entries(item.relevant_concept_count) as [concept, count]}
                                                    <span>{concept} ({count})</span>
                                                {/each}
                                            {/if}
                                        </div>
                                    </td>
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                    {:else}
                        <table>
                            <thead>
                            <tr>
                                <th class="header-cell font-medium cursor-pointer" style="width: 25%"
                                    on:click={() => handleSort('concept')}>
                                    <div class="flex items-center">
                                        Alteration
                                        <ArrowUpDown class="ml-2 h-4 w-4"/>
                                    </div>
                                </th>
                                <th class="header-cell font-medium cursor-pointer" style="width: 25%"
                                    on:click={() => handleSort('count')}>
                                    <div class="flex items-center">
                                        Trials Count
                                        <ArrowUpDown class="ml-2 h-4 w-4"/>
                                    </div>
                                </th>
                                <th class="header-cell font-medium" style="width: 50%">Assay</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each sortedData as item, i}
                                <tr class={i % 2 === 0 ? 'row-even' : 'row-odd'}>
                                    <td class="cell" style="width: 25%">{item.concept}</td>
                                    <td class="cell" style="width: 25%">{item.count}</td>
                                    <td class="cell" style="width: 50%">
                                        <div class="comma-list">
                                            {#each sortAlphabetically(item.matched_assays) as assay}
                                                <span>{assay}</span>
                                            {/each}
                                        </div>
                                    </td>
                                </tr>
                            {/each}
                            </tbody>
                        </table>

                        {#if unrelatedAlterations.length > 0}
                            <h2 class="text-xl font-bold mt-8 mb-4">
                                Alterations with No Related Assays ({unrelatedAlterations.length})
                            </h2>
                            <table>
                                <thead>
                                <tr>
                                    <th class="header-cell font-medium cursor-pointer" style="width: 25%"
                                        on:click={() => handleSort('concept')}>
                                        <div class="flex items-center">
                                            Alteration
                                            <ArrowUpDown class="ml-2 h-4 w-4"/>
                                        </div>
                                    </th>
                                    <th class="header-cell font-medium cursor-pointer" style="width: 75%"
                                        on:click={() => handleSort('count')}>
                                        <div class="flex items-center">
                                            Trials Count
                                            <ArrowUpDown class="ml-2 h-4 w-4"/>
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {#each unrelatedAlterations as item, i}
                                    <tr class={i % 2 === 0 ? 'row-even' : 'row-odd'}>
                                        <td class="cell" style="width: 25%">{item.concept}</td>
                                        <td class="cell" style="width: 75%">{item.count}</td>
                                    </tr>
                                {/each}
                                </tbody>
                            </table>
                        {/if}
                    {/if}
                </div>
            </div>
        </left>
        <right slot="right">
            <div style="align-items:center; display:flex; margin-left: 15px; text-align: left; flex-direction:row; justify-content: space-between; margin-right: 10px;">
                <span></span>
                <h3>Key Biomarkers</h3>
                <span on:click={()=>{openView=false}} aria-hidden="true"><Icon data="{faXmark}"/></span>
            </div>
            <div style="align-items:start; display:flex; margin-left: 15px; text-align: left; flex-direction:column;">
                {#if (caseMatch)}
                    {#each sortPertAls($selectedCase?.pertinent_alteration_groups?.pertinent_alterations) as pertAlt}
                        <span style="margin-left: 10px; margin-top:5px; padding:0; color: {getStatusColor(pertAlt.status)}">
                            {pertAlt.alteration} - {titleCase(pertAlt.status)}
                        </span>
                    {/each}
                {:else}
                    {#if $assayMatchResults.patient && $assayMatchResults.patient.pertinent_alterations && $assayMatchResults.patient.pertinent_alterations.pertinent_alterations.length > 0}
                        {#if ($assayMatchResults.patient.full_name)}
                            {#each sortPertAls($assayMatchResults.patient.pertinent_alterations.pertinent_alterations) as pertAlt}
                                <span style="margin-left: 10px; margin-top:5px; padding:0; color: {getStatusColor(pertAlt.status)}">
                                    {pertAlt.alteration} - {titleCase(pertAlt.status)}
                                </span>
                            {/each}
                        {:else}
                            {#each ($assayMatchResults.patient.pertinent_alterations.pertinent_alterations) as pertAlt}
                                <span style="margin-left: 10px; margin-top:5px; padding:0">{pertAlt.alteration}</span>
                            {/each}
                        {/if}
                    {/if}
                {/if}
            </div>

        </right>
    </HSplitPane>
{:else}
    <div class="w-full px-4 py-2">
        <div class="flex justify-between items-center mb-4">
            <div style="display: flex; justify-content: space-between; align-items: center; margin: 25px;">
                <div style="display: flex; flex-direction: column; justify-content: space-bewteen; align-items: start">
                    {#if ($assayMatchResults.patient && $assayMatchResults.patient.full_name)}
                        <span><strong>Name: </strong>{$assayMatchResults.patient.full_name}</span>
                        <span><strong>Diagnosis: </strong></span>
                        {#each $assayMatchResults.patient.diseases as diag}
                            <span style="margin-left: 10px; margin-top:2px; padding:0">{diag}</span>
                        {/each}
                        <span><strong>Tests Performed: </strong></span>
                        {#each $assayMatchResults.patient.assays as assay}
                            <span style="margin-left: 10px; margin-top:2px; padding:0">{assay.assay}
                                - {assay.reported_date}</span>
                        {/each}
                    {/if}
                </div>
                <h2 class="text-2xl font-bold">
                    {view === 'assay' ? 'Test Recommendations' : 'Relevant Alterations'} ({sortedData.length})
                </h2>
                <span class="key-btn" on:click={() => {openView = !openView}} aria-hidden="true">View Key<br/>Disease Related<br/>Biomarkers</span>
            </div>

            <div style="display: flex; justify-content: space-between; align-items: center; width: 100%; max-width: 80rem; margin: 1rem auto;">
                <span/>
                <div class="flex gap-8 mr-4">
                    <label class="flex items-center">
                        <input
                                type="radio"
                                value="assay"
                                bind:group={view}
                                class="mr-2"
                        />
                        Assay View
                    </label>
                    <label class="flex items-center">
                        <input
                                type="radio"
                                value="alteration"
                                bind:group={view}
                                class="mr-2"
                        />
                        Alteration View
                    </label>
                </div>
                <span class="sort-btn" on:click={() => {decoratePertAlts = !decoratePertAlts}} aria-hidden="true">Sort By Key Biomarker</span>
            </div>
        </div>

        <div class="table-container">
            {#if view === 'assay'}
                <table>
                    <thead>
                    <tr>
                        <th class="header-cell font-medium cursor-pointer" style="width: 25%"
                            on:click={() => handleSort('assay_name')}>
                            <div class="flex items-center">
                                Assay
                                <ArrowUpDown class="ml-2 h-4 w-4"/>
                            </div>
                        </th>
                        <th class="header-cell font-medium cursor-pointer" style="width: 25%"
                            on:click={() => handleSort('total_trial_count')}>
                            <div class="flex items-center">
                                Total Trials Count
                                <ArrowUpDown class="ml-2 h-4 w-4"/>
                            </div>
                        </th>
                        <th class="header-cell font-medium" style="width: 50%">
                            Relevant Testable Alterations (Trials Count)
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {#each sortedData as item, i}
                        <tr class={i % 2 === 0 ? 'row-even' : 'row-odd'}>
                            <td class="cell" style="width: 25%">{item.assay_name}</td>
                            <td class="cell" style="width: 25%">{item.total_trial_count}</td>
                            <td class="cell" style="width: 50%">
                                <div class="comma-list">
                                    {#if (decoratePertAlts)}
                                        {#each sortConceptByKeyBiomarker(item.relevant_concept_count) as [concept, count]}
                                                    <span style="color: {caseMatch ? decoratePertAlt(concept) : ($assayMatchResults.patient && $assayMatchResults.patient.full_name) ? decoratePertAlt(concept) : '#333333'}; font-weight:{decoratePertAlt(concept)==='#333333' ? 'normal':'bold'}">{concept}
                                                        ({count})</span>
                                        {/each}
                                    {:else}
                                        {#each Object.entries(item.relevant_concept_count) as [concept, count]}
                                            <span>{concept} ({count})</span>
                                        {/each}
                                    {/if}
                                </div>
                            </td>
                        </tr>
                    {/each}
                    </tbody>
                </table>
            {:else}
                <table>
                    <thead>
                    <tr>
                        <th class="header-cell font-medium cursor-pointer" style="width: 25%"
                            on:click={() => handleSort('concept')}>
                            <div class="flex items-center">
                                Alteration
                                <ArrowUpDown class="ml-2 h-4 w-4"/>
                            </div>
                        </th>
                        <th class="header-cell font-medium cursor-pointer" style="width: 25%"
                            on:click={() => handleSort('count')}>
                            <div class="flex items-center">
                                Trials Count
                                <ArrowUpDown class="ml-2 h-4 w-4"/>
                            </div>
                        </th>
                        <th class="header-cell font-medium" style="width: 50%">Assay</th>
                    </tr>
                    </thead>
                    <tbody>
                    {#each sortedData as item, i}
                        <tr class={i % 2 === 0 ? 'row-even' : 'row-odd'}>
                            <td class="cell" style="width: 25%">{item.concept}</td>
                            <td class="cell" style="width: 25%">{item.count}</td>
                            <td class="cell" style="width: 50%">
                                <div class="comma-list">
                                    {#each sortAlphabetically(item.matched_assays) as assay}
                                        <span>{assay}</span>
                                    {/each}
                                </div>
                            </td>
                        </tr>
                    {/each}
                    </tbody>
                </table>

                {#if unrelatedAlterations.length > 0}
                    <h2 class="text-xl font-bold mt-8 mb-4">
                        Alterations with No Related Assays ({unrelatedAlterations.length})
                    </h2>
                    <table>
                        <thead>
                        <tr>
                            <th class="header-cell font-medium cursor-pointer" style="width: 25%"
                                on:click={() => handleSort('concept')}>
                                <div class="flex items-center">
                                    Alteration
                                    <ArrowUpDown class="ml-2 h-4 w-4"/>
                                </div>
                            </th>
                            <th class="header-cell font-medium cursor-pointer" style="width: 75%"
                                on:click={() => handleSort('count')}>
                                <div class="flex items-center">
                                    Trials Count
                                    <ArrowUpDown class="ml-2 h-4 w-4"/>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {#each unrelatedAlterations as item, i}
                            <tr class={i % 2 === 0 ? 'row-even' : 'row-odd'}>
                                <td class="cell" style="width: 25%">{item.concept}</td>
                                <td class="cell" style="width: 75%">{item.count}</td>
                            </tr>
                        {/each}
                        </tbody>
                    </table>
                {/if}
            {/if}
        </div>
    </div>
{/if}
