<script>

    export let filtered_tracking_statuses;
    export let i;

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('en-US', options);
    }

</script>
<style>
    .container {
        display: flex;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        border: 1px solid black;
    }
    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid black;
    }
    th {
        background-color: lightgray;
        color: #ffffff;
    }
    tr {
        background-color: white;
    }

</style>
<div class="container">
    <table>
        <thead>
        <tr>
            <th>Care Plan Category</th>
            <th>Display Name</th>
            <th>Care Plan Date</th>
            <th>Discontinue Reason</th>
            <th>Status</th>
        </tr>
        </thead>
        <tbody>
            {#if Array.isArray(filtered_tracking_statuses[i].patient_case_meta.care_plan_category)}
                {#each filtered_tracking_statuses[i].patient_case_meta.care_plan_category as plan, index}
                    <tr>
                        <td>{filtered_tracking_statuses[i].patient_case_meta.care_plan_category[index] || ""}</td>
                        <td>{filtered_tracking_statuses[i].patient_case_meta.care_plan_name[index] || ""}</td>
                        <td>{formatDate(filtered_tracking_statuses[i].patient_case_meta.care_plan_date[index]) || ""}</td>
                        <td>{filtered_tracking_statuses[i].patient_case_meta.care_plan_discontinue_reason[index] || ""}</td>
                        <td>{filtered_tracking_statuses[i].patient_case_meta.care_plan_status[index] || ""}</td>
                    </tr>
                {/each}
            {:else}
                <tr>
                    <td>{filtered_tracking_statuses[i].patient_case_meta.care_plan_category || ""}</td>
                    <td>{filtered_tracking_statuses[i].patient_case_meta.care_plan_name || ""}</td>
                    <td>{formatDate(filtered_tracking_statuses[i].patient_case_meta.care_plan_date) || ""}</td>
                    <td>{filtered_tracking_statuses[i].patient_case_meta.care_plan_discontinue_reason || ""}</td>
                    <td>{filtered_tracking_statuses[i].patient_case_meta.care_plan_status || ""}</td>
                </tr>
            {/if}
        </tbody>
    </table>
</div>