<script>
    import {Icon} from "svelte-awesome";
    import {faFilter} from "@fortawesome/free-solid-svg-icons";
    import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
    import {HSplitPane} from "svelte-split-pane";
    import MatchResultsTable from "./MatchResultsTable.svelte";
    import {
        allTrialfilters,
        contentsMatches,
        generateTrialsMatches,
        historical_match_params,
        instBioTrials,
        instNonBioTrials,
        instTrialfilters,
        markerMatchesMap,
        matchTableSetting,
        negMarkers,
        openMatchResults,
        outsideTrials,
        pane,
        patientIsLoaded,
        recalculate_match,
        resEvidenceCRCSTherapies,
        resEvidenceCSTherapies,
        resEvidenceCTTherapies,
        resEvidenceMCGTherapies,
        resEvidencePCTherapies,
        resTherapies,
        selectedCase,
        selectedCaseUUID,
        selectedLabResults,
        selectedPatient,
        senEvidenceCRCSTherapies,
        senEvidenceCSTherapies,
        senEvidenceCTTherapies,
        senEvidenceMCGTherapies,
        senEvidencePCTherapies,
        senFTTTherapies,
        senOTTTherapies,
        sigMarkers,
        vusMarkers,
        rnaMarkers,
        leftFilterPaneState,
        tcList,
        zipcodeParam,
        distanceParam,
        assayMatchResults,
        caseAttachments, openAssayExplorer
    } from "../../store/patientMatch";
    import {onDestroy, onMount} from "svelte";
    import {
        evidenceTherapies,
        getAnnotationsTimeCourse,
        getCase,
        getCaseAlterationAnalytics,
        getContentsMatch,
        getInstitutions,
        getPatient,
        getSamples,
        therapies,
        trials,
        getTherapeuticContextList,
        getCasePatientMRN, getPatientAssayMatch,
        getCaseAttachments,
        getSampleAttachment
    } from "../../network/patient_match";
    import Loader from "../shared/Loader.svelte";
    import {getDefaultInstitution} from "../../store/session";
    import {getBaseNavigationUrl} from '../../utils/urls';
    import {addToHistory, selectedOption, mrnParam} from "../../store/navigation";
    import PatientMatchFilterNav from "./PatientMatchFilterNav.svelte";
    import {writable} from "svelte/store";

    function selectOption(option) {
        addToHistory(option);
        selectedOption.set(option);
        window.location.href = `${getBaseNavigationUrl()}#/${option}`;
    }


    let isLoading = true;

    let sortedAlterationGroups = [];

    let biomarkerSummaryPositive = false;

    let untestedBiomarkerCount = 0;


    patientIsLoaded.set(false);

    function fetchMarkers(timeCourseResponse, internalHitsDict, internalDiseasesMap, internalSolidCountMap) {
        let sigMarkersList = {};
        let vusMarkersList = {};
        let negMarkersList = {};
        let rnaMarkersList = {};

        timeCourseResponse.forEach(timeCourseData => {
            timeCourseData.time_course.sort((a, b) => new Date(b.sample_taken) - new Date(a.sample_taken));
            let assays = [];
            timeCourseData.time_course.forEach(tc => {
                assays = [...assays, tc.assay_name];
            });
            let tc = timeCourseData.time_course;
            // Parse the stringified dictionaries
            let variantHgvs = {};
            if (timeCourseData.variant_hgvs) {
                variantHgvs = JSON.parse(timeCourseData.variant_hgvs);
            }
            let annotations = {};
            if (timeCourseData.annotations) {
                annotations = JSON.parse(timeCourseData.annotations);
            }
            let variant_hgvs = {};
            if (timeCourseData.variant_hgvs) {
                variant_hgvs = JSON.parse(timeCourseData.variant_hgvs);
            }
            // Assume we have some functions to extract the relevant data from the parsed dictionaries
            let mutationType = "";
            if ((variantHgvs.canonical_transcript || "") !== "") {
                mutationType = variantHgvs.canonical_transcript.mutation_type;
            }
            let testMode = "";
            if (timeCourseData.test_mode){
                testMode=timeCourseData.test_mode;
            }
            let genieData = annotations.GENIE || [];

            let clinvarData = annotations.clinvar || [];

            let uniprot = annotations.uniprot || [];

            let cancerhotspots = annotations.cancerhotspots || [];

            let jax = {};
            if (timeCourseData.protein_effects.length > 0) {
                timeCourseData.protein_effects.forEach(pe => {
                    if (pe.data_set === "JAX-CKB") {
                        jax["effect"] = pe.effect;
                        jax["description"] = pe.effect;
                    }
                });
            }

            let gene_annotations = {};
            if (timeCourseData.gene_annotations) {
                gene_annotations = JSON.parse(timeCourseData.gene_annotations);
            }

            let gene_type = '';
            if (gene_annotations.gene_type && gene_annotations.gene_type.length > 0) {
                if (gene_annotations.gene_type[0].oncogene__boolean) {
                    gene_type = 'Oncogene';
                } else if (gene_annotations.gene_type[0].tumor_suppressor__boolean) {
                    gene_type = 'Tumor Suppressor';
                }
            }

            let testComparison = {};

            timeCourseData.time_course.forEach(tcEntry => {
                const {assay_name, status} = tcEntry;
                testComparison[assay_name] = status;
            });

            let internalHits = internalHitsDict[timeCourseData.uuid] || 0;
            let internalDiseases = internalDiseasesMap[timeCourseData.uuid] || [];
            let internalSolidCount = internalSolidCountMap[timeCourseData.uuid] || 0;

            let final_interpretation_reason = '';
            let interpretation_path = '';
            let discordant_interpretation = false;
            let vendor_significance = '';
            let vie_significance = '';
            let meta_score = '';

            tc.forEach(tcEntry => {
                if (tcEntry.meta_data && tcEntry.meta_data.discordant_interpretation__boolean && tcEntry.meta_data.discordant_interpretation__boolean === "True") {
                    if (tcEntry.meta_data && tcEntry.meta_data.discordant_interpretation__boolean) {
                        discordant_interpretation = tcEntry.meta_data.discordant_interpretation__boolean;
                    }
                    if (tcEntry.meta_data && tcEntry.meta_data.vendor_significance__string) {
                        vendor_significance = tcEntry.meta_data.vendor_significance__string;
                    }
                    if (tcEntry.meta_data && tcEntry.meta_data.vie_significance__string) {
                        vie_significance = tcEntry.meta_data.vie_significance__string;
                    }
                    if (tcEntry.meta_data && tcEntry.meta_data.interpretation_logic__string) {
                        let interpretationJson = JSON.parse(tcEntry.meta_data.interpretation_logic__string.replace(/'/g, '"'));
                        if (interpretationJson.final_interpretation_reason) {
                            final_interpretation_reason = interpretationJson.final_interpretation_reason;
                        }
                        if (interpretationJson.interpretation_path) {
                            interpretation_path = interpretationJson.interpretation_path;
                        }
                    }
                }
                if (tcEntry.meta_data && tcEntry.meta_data.meta_score__string) {
                    meta_score = tcEntry.meta_data.meta_score__string;
                }
            });


            let markerData = {
                test_mode:testMode,
                time_course: tc,
                marker_display: tc[0].marker_display,
                mutation_type: mutationType,
                genie_data: genieData,
                relevant_alterations: timeCourseData.relevant_alterations,
                alteration: timeCourseData.alteration,
                clinvar: clinvarData,
                uniprot: uniprot,
                cancerhotspots: cancerhotspots,
                representations: timeCourseData.representations,
                jax_ckb: jax,
                gene_type: gene_type,
                testComparison: testComparison,
                internalHits: internalHits,
                internalSolidCount: internalSolidCount,
                internalDiseases: internalDiseases,
                hgvs_g: timeCourseData.hgvs_g || null,
                variant_hgvs: variant_hgvs,
                is_negative_alt: timeCourseData.is_negative_alt,
                is_variant_of_uncertain_significance: timeCourseData.is_calculated_variant_of_uncertain_significance,
                suppress_match: timeCourseData.suppress_match,
                meta_score: meta_score,
                altered_sig: false,
                final_interpretation_reason: final_interpretation_reason,
                interpretation_path: interpretation_path,
                discordant_interpretation: discordant_interpretation,
                vendor_significance: vendor_significance,
                vie_significance: vie_significance
            };
            if (!timeCourseData.suppress_match) {
                if (timeCourseData.is_negative_alt) {
                    negMarkersList[tc[0].marker_display] = markerData;
                } else if (timeCourseData.is_calculated_variant_of_uncertain_significance) {
                    vusMarkersList[tc[0].marker_display] = markerData;

                } else if (!timeCourseData.is_negative_alt && !timeCourseData.is_calculated_variant_of_uncertain_significance) {
                    sigMarkersList[tc[0].marker_display] = markerData;

                }
            } else {
                rnaMarkersList[tc[0].marker_display] = markerData;
            }
        });

        sigMarkers.set(sigMarkersList);
        vusMarkers.set(vusMarkersList);
        negMarkers.set(negMarkersList);
        rnaMarkers.set(rnaMarkersList);
    }

    onMount(async () => loadPatientMatch());

    async function reloadTrialsMatches() {
        if ($selectedCase && $selectedPatient) {


            let selected_markers = [];
            if ($sigMarkers) {
                selected_markers = Object.keys($sigMarkers);
            }

            let therapeutic_context = 'Unspecified';
            if ($selectedPatient.therapeutic_context.length > 0) {
                therapeutic_context = $selectedPatient.therapeutic_context[0] || 'Unspecified';
            }

            let disease_state = ['Unspecified'];
            if (Array.isArray($selectedPatient.disease_state) && $selectedPatient.disease_state.length > 0) {
                disease_state = $selectedPatient.disease_state;
            }

            let matchKwargs = {
                'diseases': $selectedCase.specific_diseases,
                'alterations': selected_markers,
                'distance': $distanceParam === 'unset' ? '' : $distanceParam,
                'zipcode': $zipcodeParam === 'unset' ? '' : $zipcodeParam
            };
            matchKwargs['assay'] = $selectedCase.assay_names;
            matchKwargs['gender'] = $selectedCase.patient_information[0].gender || 'N/A';
            matchKwargs['date_of_birth'] = $selectedCase.patient_information[0].date_of_birth || 'N/A';
            matchKwargs['biomarker_match_type'] = 'BiomarkerOnly';
            matchKwargs['institutional_trials_only'] = false;
            matchKwargs['countries'] = ['United States', 'Canada'];
            matchKwargs['therapeutic_context'] = therapeutic_context;
            matchKwargs['disease_state'] = disease_state;
            matchKwargs['oncologic_treatments'] = $selectedPatient.oncologic_treatments;

            const [trials_matches_outside, trials_matches_instBio, trials_matches_instNonBio] = getTrialsMatches(matchKwargs);

            //
            const [outsideTrialsResult, instBioTrialsResult, instNonBioTrialsResult] = await Promise.all([
                trials_matches_outside,
                trials_matches_instBio,
                trials_matches_instNonBio
            ]);

            outsideTrials.set(outsideTrialsResult);
            instBioTrials.set(instBioTrialsResult);
            instNonBioTrials.set(instNonBioTrialsResult);
            isLoading = false;
        }
    }

    function formatDate(dateString) {
        if(dateString === 'N/A'){return 'N/A'}
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    async function loadPatientMatch() {
        // Check for ehr workflow
        const url = window.location.href;
        const queryString = url.includes('?') ? url.split('?')[1].split('#')[0] : '';
        const searchParams = new URLSearchParams(queryString);

        const searchParam = searchParams.get('mrn');
        mrnParam.set(searchParam);

        if (searchParam !== null) {
            localStorage.setItem('ehrWorkflow', true)
            if ($mrnParam) {
                const caseUUID = await Promise.resolve(getCasePatientMRN($mrnParam));
                selectedCaseUUID.set(caseUUID.length > 0 ? caseUUID[0].uuid : '');
            }
        }

        if (($selectedCaseUUID && $generateTrialsMatches) || $recalculate_match) {

            leftFilterPaneState.set('closed');
            openAssayExplorer.set(false);

            if ($generateTrialsMatches) {
                allTrialfilters.set({
                    "NCTID": [],
                    "ProtocolID": [],
                    "Biomarkers": [],
                    "Diseases": [],
                    "Phase": [],
                    "RecruitingStatus": [],
                    "Drugs": [],
                    "DrugCategory": [],
                    "PriorTreatment": [],
                    "TherapeuticContext": [],
                    "DiseaseState": [],
                    "Distance": []
                });
                instTrialfilters.set({
                    "NCTID": [],
                    "ProtocolID": [],
                    "Biomarkers": [],
                    "Diseases": [],
                    "Phase": [],
                    "RecruitingStatus": [],
                    "Drugs": [],
                    "DrugCategory": [],
                    "PriorTreatment": [],
                    "TherapeuticContext": [],
                    "DiseaseState": [],
                    "Distance": []
                });
            }
            const institutionData = await getInstitutions();

            const caseData = await getCase($selectedCaseUUID);

            const patientData = await getPatient(caseData.patient_uuid);

            const sampleData = await getSamples(caseData.patient_uuid);

            const caseAttachmentData = await getCaseAttachments(caseData.uuid);

            const timeCourse = await getAnnotationsTimeCourse($selectedCaseUUID);

            const tcData = await getTherapeuticContextList();

            const assayMatchJSON = await getPatientAssayMatch(caseData.patient_uuid);

            if (assayMatchJSON != null){
                assayMatchResults.set(assayMatchJSON);
            }

            let internalHitsMap = {};

            let internalDiseasesMap = {};

            let internalSolidCountMap = {};

            for (const timeCourseData of timeCourse) {
                let totalHits = 0;
                let solidCount = 0;
                let faceted_diseases = [];

                if (timeCourseData.alteration && timeCourseData.alteration.length > 0) {
                    for (const alt of timeCourseData.alteration) {
                        if (caseData.alterations.includes(alt)) {
                            try {
                                const response = await getCaseAlterationAnalytics(alt);
                                totalHits += response.count; // Accumulate hits
                                solidCount += response.solid_count;
                                faceted_diseases = response.facet_field_results;
                            } catch (error) {
                                console.error('Error fetching analytics for alteration:', alt, error);
                            }
                        }
                    }
                }

                internalHitsMap[timeCourseData.uuid] = totalHits; // Map UUID to total hits
                internalSolidCountMap[timeCourseData.uuid] = solidCount;
                internalDiseasesMap[timeCourseData.uuid] = faceted_diseases;
            }

            fetchMarkers(timeCourse, internalHitsMap, internalDiseasesMap, internalSolidCountMap);

            let selected_markers = [];
            if ($sigMarkers) {
                selected_markers = Object.keys($sigMarkers);
            }

            let therapeutic_context = 'Unspecified';
            if (patientData.therapeutic_context.length > 0) {
                therapeutic_context = patientData.therapeutic_context[0] || 'Unspecified';
            }

            let disease_state = [
                ...(Array.isArray(patientData.disease_state) ? patientData.disease_state : []),
                ...(Array.isArray(patientData.stages) ? patientData.stages : [])
            ];

            disease_state = disease_state.length > 0
                ? [...new Set(disease_state)]
                : ['Unspecified'];

            let uniqueDrugs = new Set();


            let metaDict =  {}

            if (caseData.meta){
                if (caseData.meta.ICD10__code && caseData.meta.ICD10__description) {
                    metaDict.ICD10 = caseData.meta.ICD10__code.map((code, index) => {
                        let description = caseData.meta.ICD10__description[index] || '';
                        return `${code} - ${description}`;
                    });
                }

                Object.keys(caseData.meta).forEach(key => {
                    metaDict[key] = caseData.meta[key];
                });
            }

            let historicalMetaDict =  {}

            if (caseData.meta){
                if (caseData.meta.ICD10__code && caseData.meta.ICD10__description) {
                    historicalMetaDict.ICD10 = caseData.meta.ICD10__code.map((code, index) => {
                        let description = caseData.meta.ICD10__description[index] || '';
                        return `${code} - ${description}`;
                    });
                }

                Object.keys(caseData.meta).forEach(key => {
                    historicalMetaDict[key] = caseData.meta[key];
                });
            }

            let patientDiagnoses = []

            patientData.diagnosis.forEach(diagnosis => {
                patientDiagnoses.push({
                    diagnosis: diagnosis.name,
                    date_of_diagnosis: formatDate(diagnosis.date_of_diagnosis ? diagnosis.date_of_diagnosis || 'N/A' : 'N/A'),
                    stage: diagnosis.stage ? diagnosis.stage || 'N/A' : 'N/A'
                });
            })

            //reset therapies
            resTherapies.set([])
            senFTTTherapies.set([])
            senOTTTherapies.set([])


            historical_match_params.set({
                'recalculate_match': false,
                'last_match_performed': getCurrentTime(),
                'historical_fields': {
                    'patient_fields':{
                        'patient_name': caseData.patient_name,
                        'patient_mrn': caseData.patient_mrn,
                        'patient_meta_data': historicalMetaDict,
                        'gender': caseData.patient_information[0].gender || 'N/A',
                        'date_of_birth': formatDate(caseData.patient_information[0].date_of_birth || 'N/A'),
                        'cancer_icd10_codes': caseData.meta ? caseData.meta.ICD10__Cancer__code || [] : [],
                        'diagnoses': patientDiagnoses
                    },
                    'assays': caseData.assay_names,
                    'selected_alterations': selected_markers,
                    'therapeutic_context': therapeutic_context,
                    'prior_interventions': patientData.oncologic_treatments.filter(item => {
                        if (uniqueDrugs.has(item.drugs)) {
                            return false;
                        } else {
                            uniqueDrugs.add(item.drugs);
                            return true;
                        }
                    })
                },
                'patient_fields':{
                    'patient_name': caseData.patient_name,
                    'patient_mrn': caseData.patient_mrn,
                    'patient_meta_data': metaDict,
                    'gender': caseData.patient_information[0].gender || 'N/A',
                    'date_of_birth': formatDate(caseData.patient_information[0].date_of_birth || 'N/A'),
                    'cancer_icd10_codes': caseData.meta ? caseData.meta.ICD10__Cancer__code || [] : [],
                    'diagnoses': patientDiagnoses
                },
                'updated_fields': {},
                'unsaved_updated_fields': {
                    'assays': false,
                    'selected_alterations': false,
                    'therapeutic_context': false,
                    'patient_fields': false,
                }
            });

            let matchKwargs = {
                'diseases': caseData.specific_diseases,
                'alterations': caseData.alterations,
                'distance': $distanceParam === 'unset' ? '' : $distanceParam,
                'zipcode': $zipcodeParam === 'unset' ? '' : $zipcodeParam
            };

            const contentsMatch = await getContentsMatch(matchKwargs);

            matchKwargs['alterations'] = selected_markers;
            matchKwargs['assay'] = caseData.assay_names.length > 0 ? caseData.assay_names : ['Unspecified'];

            const therapies_matches = therapies(matchKwargs);

            const evidence_matches = evidenceTherapies($selectedCaseUUID, matchKwargs);

            matchKwargs['gender'] = caseData.patient_information[0].gender || 'N/A';
            matchKwargs['date_of_birth'] = caseData.patient_information[0].date_of_birth || 'N/A';
            matchKwargs['biomarker_match_type'] = 'BiomarkerOnly';
            matchKwargs['institutional_trials_only'] = false;
            matchKwargs['countries'] = ['United States', 'Canada'];
            matchKwargs['therapeutic_context'] = therapeutic_context;
            matchKwargs['disease_state'] = disease_state;
            matchKwargs['oncologic_treatments'] = patientData.oncologic_treatments;

            const [trials_matches_outside, trials_matches_instBio, trials_matches_instNonBio] = getTrialsMatches(matchKwargs);

            //
            const [outsideTrialsResult, instBioTrialsResult, instNonBioTrialsResult, therapiesResult, evidenceResult] = await Promise.all([
                trials_matches_outside,
                trials_matches_instBio,
                trials_matches_instNonBio,
                therapies_matches,
                evidence_matches
            ]);

            refreshMarkersMap(outsideTrialsResult, instBioTrialsResult, selected_markers);


            isLoading = false;
            patientIsLoaded.set(true);
            generateTrialsMatches.set(false);
            selectedCase.set(caseData);
            selectedPatient.set(patientData);
            caseAttachments.set(caseAttachmentData);
            selectedLabResults.set(sampleData);
            outsideTrials.set(outsideTrialsResult);
            instBioTrials.set(instBioTrialsResult);
            instNonBioTrials.set(instNonBioTrialsResult);
            tcList.set(tcData);
            contentsMatches.set({
                "geneDescription": [],
                "pathway": [],
                "mutationDescription": [],
                "mutationLocation": [],
                "geneFrequency": [],
                "mutationFrequency": []
            });


            therapiesResult.results.forEach(therapy => {
                if (therapy.consolidated.response_is_positive) {
                    if (therapy.consolidated.for_other_tumor_type) {
                        $senOTTTherapies = [...$senOTTTherapies, therapy];
                    } else {
                        $senFTTTherapies = [...$senFTTTherapies, therapy];
                    }
                } else {
                    $resTherapies = [...$resTherapies, therapy];
                }
            });

            const CRCS_LABELS = ["Case Reports/Case Series"];
            const CS_LABELS = ["Clinical Study", "Clinical Study - Cohort"];
            const CT_LABELS = ["Phase 0", "Phase 1", "Phase 1B/2", "Phase 2", "Phase 3"];
            const PC_LABELS = ["Preclinical", "Preclinical - Cell Culture", "Preclinical - Cell Line Xenograft", "Preclinical - Patient Cell Culture", "Preclinical - Pdx", "Preclinical - Pdx & cell culture"];

            let senCRCSEvidenceTherapies = evidenceResult.results.filter(therapy => therapy.response_is_positive).filter(therapy => therapy.evidence_references.some(er => CRCS_LABELS.includes(er.evidence_category)));
            let senCSEvidenceTherapies = evidenceResult.results.filter(therapy => therapy.response_is_positive).filter(therapy => therapy.evidence_references.some(er => CS_LABELS.includes(er.evidence_category)));
            let senCTEvidenceTherapies = evidenceResult.results.filter(therapy => therapy.response_is_positive).filter(therapy => therapy.evidence_references.some(er => CT_LABELS.includes(er.evidence_category)));
            let senPCEvidenceTherapies = evidenceResult.results.filter(therapy => therapy.response_is_positive).filter(therapy => therapy.evidence_references.some(er => PC_LABELS.includes(er.evidence_category)));
            let senMCGEvidenceTherapies = evidenceResult.results.filter(therapy => therapy.response_is_positive).filter(therapy => therapy.data_set === "MCG");

            let resCRCSEvidenceTherapies = evidenceResult.results.filter(therapy => !therapy.response_is_positive).filter(therapy => therapy.evidence_references.some(er => CRCS_LABELS.includes(er.evidence_category)));
            let resCSEvidenceTherapies = evidenceResult.results.filter(therapy => !therapy.response_is_positive).filter(therapy => therapy.evidence_references.some(er => CS_LABELS.includes(er.evidence_category)));
            let resCTEvidenceTherapies = evidenceResult.results.filter(therapy => !therapy.response_is_positive).filter(therapy => therapy.evidence_references.some(er => CT_LABELS.includes(er.evidence_category)));
            let resPCEvidenceTherapies = evidenceResult.results.filter(therapy => !therapy.response_is_positive).filter(therapy => therapy.evidence_references.some(er => PC_LABELS.includes(er.evidence_category)));
            let resMCGEvidenceTherapies = evidenceResult.results.filter(therapy => !therapy.response_is_positive).filter(therapy => therapy.data_set === "MCG");


            senEvidenceCRCSTherapies.set(senCRCSEvidenceTherapies);
            senEvidenceCSTherapies.set(senCSEvidenceTherapies);
            senEvidenceCTTherapies.set(senCTEvidenceTherapies);
            senEvidencePCTherapies.set(senPCEvidenceTherapies);
            senEvidenceMCGTherapies.set(senMCGEvidenceTherapies);

            resEvidenceCRCSTherapies.set(resCRCSEvidenceTherapies);
            resEvidenceCSTherapies.set(resCSEvidenceTherapies);
            resEvidenceCTTherapies.set(resCTEvidenceTherapies);
            resEvidencePCTherapies.set(resPCEvidenceTherapies);
            resEvidenceMCGTherapies.set(resMCGEvidenceTherapies);


            const alterationMatchMap = {};

            contentsMatch.results.forEach(match => {
                match.trigger_alterations?.forEach(alt => {
                    if (!alterationMatchMap[alt]) {
                        alterationMatchMap[alt] = {};
                    }
                    alterationMatchMap[alt][match.type] = match.body;
                });
            });

            contentsMatches.set(alterationMatchMap);


        } else if (!$selectedCaseUUID) {
            console.warn("No case selected");
            isLoading = false;
            patientIsLoaded.set(false);
            pane.set('backToSummary');
        } else {
            isLoading = false;
            patientIsLoaded.set(true);
        }

        sortedAlterationGroups = $selectedCase.pertinent_alteration_groups?.pertinent_alteration_groups
            ? sortAlterationGroups($selectedCase.pertinent_alteration_groups.pertinent_alteration_groups)
            : [];

        biomarkerSummaryPositive = sortedAlterationGroups.some(group => group.group_status === 'positive');

        $selectedCase.pertinent_alteration_groups?.pertinent_alterations?.forEach(pertAlt => {if (pertAlt.status === 'untested'){untestedBiomarkerCount+=1}})
    }

    function getTrialsMatches(matchKwargs) {
        const trials_matches_outside = trials(matchKwargs);


        matchKwargs['institutional_trials_only'] = true;
        const trials_matches_instBio = trials(matchKwargs);

        matchKwargs['biomarker_match_type'] = 'NonBiomarkerOnly';
        const trials_matches_instNonBio = trials(matchKwargs);

        return [trials_matches_outside, trials_matches_instBio, trials_matches_instNonBio];
    }

    $: if ($recalculate_match) {
        isLoading = true;
        // Re-run the onMount logic when refresh_flag is set to true
        loadPatientMatch();
        recalculate_match.set(false);  // Reset the flag
    }

    $: if ($zipcodeParam || $distanceParam){
        isLoading = true;
        reloadTrialsMatches();
    }

    function refreshMarkersMap(outsideTrials, instBioTrials, selectedMarkers) {
        let updatedMarkerMap = [];

        for (let marker of selectedMarkers) {
            updatedMarkerMap[marker] = {
                outsideTrials: [],
                instTrials: [],
            };

            updatedMarkerMap[marker].outsideTrials = outsideTrials.results.filter(trial => {
                for (let detectedAlteration of trial.detected_alterations) {
                    for (let triggerAlt of detectedAlteration.trigger_alterations) {
                        if (triggerAlt === marker) {
                            return true; // Include trial if the condition is met
                        }
                    }
                }
                return false; // Exclude trial if none of the detected alterations match
            });

            updatedMarkerMap[marker].instTrials = instBioTrials.results.filter(trial => {
                for (let detectedAlteration of trial.detected_alterations) {
                    for (let triggerAlt of detectedAlteration.trigger_alterations) {
                        if (triggerAlt === marker) {
                            return true; // Include trial if the condition is met
                        }
                    }
                }
                return false; // Exclude trial if none of the detected alterations match
            });
        }
        markerMatchesMap.set(updatedMarkerMap);
    }

    let allFilters = [];
    let instFilters = [];
    let filteredInstBioTrials = [];
    let filteredInstNonBioTrials = [];
    let filteredOutsideTrials = [];
    let filteredSenOTTTherapies = [];
    let filteredSenFTTTherapies = [];
    let filteredResTherapies = [];
    let filteredNCCNCount = 0;
    let filteredFDACount = 0;

    let activeTab = '';

    $: {
        // Reset filter lists
        allFilters = [];
        instFilters = [];

        // Update allFilters list
        Object.keys($allTrialfilters).forEach(key => {
            $allTrialfilters[key].forEach(value => {
                allFilters.push({label: key, value: value, source: 'all'});
            });
        });

        // Update instFilters list
        Object.keys($instTrialfilters).forEach(key => {
            $instTrialfilters[key].forEach(value => {
                // Check if value exists in allFilters
                const existsInAllFilters = allFilters.find(filter => filter.label === key && filter.value === value);
                if (existsInAllFilters) {
                    existsInAllFilters.source = 'all';
                } else {
                    instFilters.push({label: key, value: value, source: 'inst'});
                }
            });
        });

        // Reset filtered trial lists
        filteredOutsideTrials = [];
        filteredInstNonBioTrials = [];
        filteredInstBioTrials = [];

        // Check if any filters are applied
        const filtersApplied = allFilters.length > 0 || instFilters.length > 0;

        // If filters are applied, filter the trials; otherwise, assign original trial results
        if (filtersApplied) {
            // Filter outside trials
            filteredOutsideTrials = filterTrials($outsideTrials.results, allFilters, []);

            // Filter institutional non-bio trials
            filteredInstNonBioTrials = filterTrials($instNonBioTrials.results, allFilters, instFilters);

            // Filter institutional bio trials
            filteredInstBioTrials = filterTrials($instBioTrials.results, allFilters, instFilters);
        } else {
            // Assign original trial results to filtered lists
            filteredOutsideTrials = $outsideTrials.results;
            filteredInstNonBioTrials = $instNonBioTrials.results;
            filteredInstBioTrials = $instBioTrials.results;
        }
        // Apply Filtering for Therapies
        filteredSenOTTTherapies = filterTherapies($senOTTTherapies, allFilters, filtersApplied);
        filteredSenFTTTherapies = filterTherapies($senFTTTherapies, allFilters, filtersApplied);
        filteredResTherapies = filterTherapies($resTherapies, allFilters, filtersApplied);
        filteredNCCNCount = getNCCNCount();
        filteredFDACount = getFDACount();
    }

    // Function to filter trials based on filters
    function filterTrials(trials, allFilters, instFilters) {
        return trials.filter(trial => {
            // Check if the trial complies with all filters in allFilters
            for (const filter of allFilters) {
                if (!trialMatchesFilter(trial, filter)) {
                    return false; // Trial doesn't comply with this filter
                }
            }

            // Check if the trial complies with all filters in instFilters
            for (const filter of instFilters) {
                if (!trialMatchesFilter(trial, filter)) {
                    return false; // Trial doesn't comply with this filter
                }
            }

            return true; // Trial complies with all filters
        });
    }

    function trialMatchesFilter(trial, filter) {
        switch (filter.label) {
        case 'NCTID':
            return trial.nct_id === filter.value;
        case 'ProtocolID':
            return trial.inst_protocol_ids && trial.inst_protocol_ids.includes(filter.value);
        case 'Biomarkers':
            return trial.detected_alterations && trial.detected_alterations.some(da => da.trigger_alterations.includes(filter.value));
        case 'Diseases':
            return trial.matched_diseases && trial.matched_diseases.some(disease => disease.matched_disease === filter.value);
        case 'Phase':
            return trial.phase === filter.value;
        case 'Drugs':
            return trial.match_results && trial.match_results.some(therapy => {
                return therapy.treatment_contexts && therapy.treatment_contexts.some(treatment => {
                    const drugsInTherapyDisplay = treatment.therapy_display.split(' + ');
                    return drugsInTherapyDisplay.includes(filter.value);
                });
            });
        case 'DrugCategory':
            const drugs = Object.values(filter.value);
            return drugs[0].some(drug => {
                return trial.match_results && trial.match_results.some(therapy => {
                    return therapy.treatment_contexts && therapy.treatment_contexts.some(treatment => {
                        const drugsInTherapyDisplay = treatment.therapy_display.split(' + ');
                        return drugsInTherapyDisplay.includes(drug);
                    });
                });
            });
        case 'PriorTreatment':
            if (filter.value === "Full Match") {
                return trial.match_results && trial.match_results.some(therapy => {
                    return therapy.treatment_contexts && therapy.treatment_contexts.some(tc => {
                        return tc.pi_matches && tc.pi_matches.length > 0;
                    });
                });
            } else if (filter.value === "Qualified") {
                return trial.match_results && trial.match_results.some(therapy => {
                    return therapy.treatment_contexts && therapy.treatment_contexts.some(tc => {
                        return tc.pi_matches && tc.pi_matches.some(pi => pi.qualified)
                    });
                });
            }
            else {
                return trial.match_results && trial.match_results.every(therapy => {
                    // Check if treatment_contexts is either missing or empty
                    return !therapy.treatment_contexts || therapy.treatment_contexts.length === 0 ||
                        therapy.treatment_contexts.every(tc => {
                            return !tc.pi_matches || tc.pi_matches.length === 0;
                        });
                });
            }
        case 'RecruitingStatus':
            return (trial.inst_statuses && trial.inst_statuses.includes(filter.value)) || (trial.recruiting_status && trial.recruiting_status === filter.value);
        case 'TherapeuticContext':
            return trial.match_results &&
                trial.match_results.some(mr =>
                    mr.treatment_contexts &&
                    mr.treatment_contexts.some(tc =>
                        tc.therapeutic_context &&
                        tc.therapeutic_context.length > 0 &&
                        tc.therapeutic_context.some(context =>
                            context.toLowerCase() === filter.value.toLowerCase())
                    )
                );
        case 'DiseaseState':
            return trial.match_results &&
                trial.match_results.some(mr =>
                    mr.disease_states &&
                    mr.disease_states.length > 0 &&
                    mr.disease_states.some(ds =>
                        ds.toLowerCase() === filter.value.toLowerCase()))
        case 'Distance':
            //this is going to require an updated match call
            return true;

        default:
            return false;
        }
    }

    function filterTherapies(therapies, allFilters, filtersApplied) {
        if (filtersApplied) {
            return therapies.filter(therapy => {
                for (const filter of allFilters) {
                    return therapyMatchesFilter(therapy, filter);
                }
            });
        } else {
            return therapies;
        }

    }

    function therapyMatchesFilter(therapy, filter, isEvidence){
        switch (filter.label) {
        case 'NCTID':
            return true;
        case 'ProtocolID':
            return true;
        case 'Biomarkers':
            if (isEvidence) {
                return therapy.detected_alterations && therapy.detected_alterations.some(da => da.display === filter.value);
            }
            else {
                return therapy.trigger_alteration_display.includes(filter.value);
            }
        case 'Diseases':
            if (isEvidence){
                return therapy.matched_diseases && therapy.matched_diseases.some(disease => disease.matched_disease === filter.value);
            }
            else {
                return therapy.consolidated.matched_diseases && therapy.consolidated.matched_diseases.some(disease => disease.matched_disease === filter.value);
            }        case 'Drugs':
            return therapy.therapy_display.includes(filter.value);
        case 'DrugCategory':
            const drugs = Object.values(filter.value);
            return drugs[0].some(drug => {
                const drugsInTherapyDisplay = therapy.therapy_display.split(' + ');
                return drugsInTherapyDisplay.includes(drug);
            });
        case 'PriorTreatment':
            return true;
        case 'RecruitingStatus':
            return true;
        case 'TherapeuticContext':
            return true;
        case 'DiseaseState':
            return true;
        case 'Distance':
            return true;
        default:
            return false;
        }
    }

    // Function to sort the alteration groups and alterations
    function sortAlterationGroups(groups) {
        const statusOrder = {'positive': 1, 'negative': 2, 'untested': 3};

        return groups
            .sort((a, b) => statusOrder[a.group_status] - statusOrder[b.group_status])
            .map(group => {
                group.alterations = group.alterations.sort((a, b) => {
                    const altA = $selectedCase.pertinent_alteration_groups.pertinent_alterations.find(alt => alt.alteration === a);
                    const altB = $selectedCase.pertinent_alteration_groups.pertinent_alterations.find(alt => alt.alteration === b);
                    return statusOrder[altA.status] - statusOrder[altB.status];
                });
                return group;
            });
    }

    function getStatusColor(status) {
        switch (status) {
        case 'positive':
            return '#9FCE67';
        case 'negative':
            return '#FC0C0B';
        case 'untested':
            return '#233C6B';
        default:
            return '#000000';
        }
    }

    function titleCase(status) {
        return status.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    function getCurrentTime() {
        return new Date().toLocaleTimeString();
    }

    function getNCCNCount() {
        return filteredSenOTTTherapies.filter(therapy => {
                return therapy.consolidated.sources_list.includes('NCCN');
            }).length +
            filteredSenFTTTherapies.filter(therapy => {
                return therapy.consolidated.sources_list.includes('NCCN');
            }).length +
            filteredResTherapies.filter(therapy => {
                return therapy.consolidated.sources_list.includes('NCCN');
            }).length || 0;
    }

    function getFDACount() {
        return filteredSenOTTTherapies.filter(therapy => {
                return therapy.consolidated.sources_list.includes('FDA');
            }).length +
            filteredSenFTTTherapies.filter(therapy => {
                return therapy.consolidated.sources_list.includes('FDA');
            }).length +
            filteredResTherapies.filter(therapy => {
                return therapy.consolidated.sources_list.includes('FDA');
            }).length || 0;
    }

</script>

<style>
    .hover-underline {
        cursor: pointer;
        text-decoration: none;
        color: black;
        font-weight: bold;
        font-size: 16px;
        text-align: left;
    }

    .hover-underline:hover {
        text-decoration: underline;
        color: #233C6B;
    }

    .no-patient-selected-message {
        background-color: #f2f2f2;
        border: 1px solid #ccc;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        width: 70%;
        margin: 0 auto;
    }

    .icon-button {
        cursor: pointer;
        width: 10%;
    }

    table {
        width: 100%;
    }

    .td-pertAlt {
        text-align: start;
        vertical-align: center;
        padding: 10px 10px 10px 0;
    }

    table th {
        text-align: start;
    }

    .td-headers {
        cursor: pointer;
        text-decoration: none;
        color: black;
        font-weight: bold;
        font-size: 16px;
        text-align: left;
    }

    .vertical-tabs {
        display: flex;
        font-size: 18px;
        text-align: right;
        justify-content: right;
    }

    .tab-menu {
        display: flex;
        flex-direction: column;
        width: 15em;
        background-color: transparent;
        border-right: 1px solid #ccc;

    }

    .tab-menu button {
        font-size: 18px;
        background-color: transparent;
        border: None;
        border-radius: 0;
        padding: 10px 0 10px 10px;
        text-align: left;
        cursor: pointer;
        transition: background 0.3s;
    }

    .tab-menu button:hover {
        background: transparent;
    }

    .tab-menu button.active {
        border-right: 3px solid #000;
        background: transparent;
        font-weight: bold;
    }

    .tab-content {
        flex-grow: 1;
        padding: 20px;
    }

    .tab-pane {
        display: none;
        max-height: 54vh;
        height: 54vh;
        overflow-x: scroll;
    }

    .tab-pane.active {
        display: block;
    }

    .caption {
        position: sticky;
        z-index: 1;
        top: 0;
        font-weight: bold;
        background-color: white;
        font-size: 20px;
        text-align: center;
        padding-bottom: 15px;
    }

    .biomarker-th {
        position: sticky;
        z-index: 1;
        top: 30px;
        background-color: white;
    }

</style>
{#if isLoading}
    <div style="width:80vw; height:50vh; align-items: center; align-content: center;">
        <Loader/>
    </div>

{:else}
    {#if ($pane === 'backToSummary')}
        {#if ($leftFilterPaneState === 'open')}
            <HSplitPane leftPaneSize='20%' rightPaneSize='80%' minLeftPaneSize='20%'>
                <left slot="left">
                    {#if ($leftFilterPaneState === 'open')}
                        <svelte:component this={PatientMatchFilterNav}/>
                    {/if}
                </left>
                <right slot="right">
                    <div style="margin-bottom: 10px; display:flex; justify-content:flex-start; align-items: center;">
                        <div class="icon-button"
                             on:click={() => {leftFilterPaneState.set($leftFilterPaneState === "closed" ? "open" : "closed")}}>
                            {#if ($leftFilterPaneState === 'closed')}
                                <p style="margin-bottom: 0">Apply Filters</p>
                                <Icon data="{faFilter}" scale="1.5" style="color: #233C6B; margin-top: 0"/>
                            {:else}
                                <p style="margin-bottom: 0">Close Filter Pane</p>
                                <Icon data="{faCircleXmark}" scale="1.5" style="color: #c5c5c7; margin-top: 0"/>
                            {/if}

                        </div>
                        <h1 style="width: 80vw;">Case Summary</h1>
                    </div>
                    {#if $selectedCase}
                        {#if (activeTab === "")}
                            {#if ($instBioTrials.results.length + $instNonBioTrials.results.length + $outsideTrials.results.length > 0)}
                                <h3 style="text-align: left; margin-left: 10px; cursor:pointer;"
                                    on:click={()=>activeTab='actionability'} aria-hidden="true">
                                    <span style="color: #9FCE67;">Actionability </span>- The patient Qualifies for
                                    biomarker
                                    directed treatment options in the GO Knowledgebase
                                </h3>
                            {:else}
                                <h3 style="text-align: left; margin-left: 10px; cursor:pointer;"
                                    on:click={()=>activeTab='actionability'} aria-hidden="true">
                                    <span style="color: #FC0C0B;">Actionability </span>- The patient Does Not Qualify
                                    for
                                    biomarker directed treatment options in the GO Knowledgebase
                                </h3>
                            {/if}
                            {#if (biomarkerSummaryPositive)}
                                <h3 style="text-align: left; margin-left: 10px; cursor:pointer;"
                                    on:click={()=>activeTab='biomarker'} aria-hidden="true">
                                    <span style="color: #9FCE67;">Biomarker Summary </span>- The patient is positive for
                                    one
                                    or more key alterations in their disease type
                                    {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                        <p style="margin-left: 11em; font-weight:normal">The patient has one or more
                                            biomarkers that have discordant interpretations</p>
                                    {/if}
                                    {#if (untestedBiomarkerCount > 0)}
                                        <p style="margin-left: 11em; font-weight:normal">There are {untestedBiomarkerCount} untested key biomarkers for the patients disease type</p>
                                    {/if}
                                </h3>
                            {:else}
                                <h3 style="text-align: left; margin-left: 10px; cursor:pointer;"
                                    on:click={()=>activeTab='biomarker'} aria-hidden="true">
                                    <span style="color: #FC0C0B;">Biomarker Summary </span>- The patient is negative for
                                    all key biomarkers in their disease type.
                                    {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                        <p style="margin-left: 11em; font-weight:normal">The patient has one or more
                                            biomarkers that have discordant interpretations</p>
                                    {/if}
                                    {#if  (untestedBiomarkerCount > 0)}
                                        <p style="margin-left: 11em; font-weight:normal">There are {untestedBiomarkerCount} untested key biomarkers for the patients disease type</p>
                                    {/if}
                                </h3>
                            {/if}
                            {#if (($selectedCase.lab_results?.length ?? 0 > 0) || ($selectedPatient.events?.length ?? 0 > 0) || ($selectedPatient.oncologic_treatments?.length ?? 0 > 0))}
                                <h3 style="text-align: left; margin-left: 10px; cursor:pointer;"
                                    on:click={()=>activeTab='clinical'} aria-hidden="true">
                                    <span style="color: #9FCE67;">Clinical History Summary </span>- The case has
                                    associated
                                    clinical information
                                </h3>
                            {:else}
                                <h3 style="text-align: left; margin-left: 10px; cursor:pointer;"
                                    on:click={()=>activeTab='clinical'} aria-hidden="true">
                                    <span style="color: #FC0C0B;">Clinical History Summary </span>- The case does not
                                    have
                                    associated clinical information
                                </h3>
                            {/if}
                        {:else}
                            <div class="vertical-tabs">
                                <div class="tab-menu">
                                    <button class="tab-link {activeTab === 'actionability' ? 'active' : ''}"
                                            on:click={() => activeTab = activeTab === 'actionability' ? '' : 'actionability'}
                                            style="color: {($instBioTrials.results.length + $instNonBioTrials.results.length + $outsideTrials.results.length > 0) ? '#9FCE67' : '#FC0C0B'}">
                                        Actionability
                                    </button>
                                    <button class="tab-link {activeTab === 'biomarker' ? 'active' : ''}"
                                            on:click={() => activeTab = activeTab === 'biomarker' ? '' : 'biomarker'}
                                            style="color: {biomarkerSummaryPositive ? '#9FCE67' : '#FC0C0B'}">
                                        Biomarker Summary
                                    </button>
                                    <button class="tab-link {activeTab === 'clinical' ? 'active' : ''}"
                                            on:click={() => activeTab = activeTab === 'clinical' ? '' : 'clinical'}
                                            style="color: {(($selectedCase.lab_results?.length ?? 0 > 0) || ($selectedPatient.events?.length ?? 0 > 0) || ($selectedPatient.oncologic_treatments?.length ?? 0 > 0)) ? '#9FCE67' : '#FC0C0B'}">
                                        Clinical History Summary
                                    </button>
                                </div>
                                <div class="tab-content">
                                    <div id="actionability"
                                         class="tab-pane {activeTab === 'actionability' ? 'active' : ''}">
                                        <p class="td-headers"
                                           style="margin-left: 3em; margin-top:10px; margin-bottom: 0;">
                                            Clinical Trials</p>
                                        <p class="hover-underline"
                                           style="margin-left: 6em; margin-top:10px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('allTrials')}}
                                           aria-hidden="true">
                                            {filteredInstNonBioTrials.length + filteredInstBioTrials.length + filteredOutsideTrials.length}
                                            Total Clinical Trials
                                        </p>

                                        <p class="hover-underline"
                                           style="margin-left: 9em; margin-top:2px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('instTrialsOnly')}}
                                           aria-hidden="true">
                                            {(filteredInstBioTrials.length || 0) + (filteredInstNonBioTrials.length || 0)}
                                            at {getDefaultInstitution()}
                                        </p>

                                        <p class="hover-underline"
                                           style="margin-left: 12em; margin-top:2px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('instTrialsOnly')}}
                                           aria-hidden="true">
                                            {(filteredInstBioTrials.length || 0)} biomarker driven
                                        </p>

                                        <p class="hover-underline"
                                           style="margin-left: 12em; margin-top:2px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('instTrialsOnly')}}
                                           aria-hidden="true">
                                            {(filteredInstNonBioTrials.length || 0)} non-biomarker driven
                                        </p>

                                        <p class="hover-underline"
                                           style="margin-left: 9em; margin-top:2px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('outsideTrialsOnly')}}
                                           aria-hidden="true">
                                            {filteredOutsideTrials.length} outside
                                        </p>

                                        <p class="td-headers"
                                           style="margin-left: 3em; margin-top:10px; margin-bottom: 0;">
                                            Therapy Recommendations</p>

                                        <p class="hover-underline"
                                           style="margin-left: 6em; margin-top:2px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('allTherapies')}}
                                           aria-hidden="true">
                                            {filteredSenOTTTherapies.length + filteredSenFTTTherapies.length + filteredResTherapies.length}
                                            Tier
                                            1
                                            Therapy Options
                                        </p>

                                        <p class="hover-underline"
                                           style="margin-left: 9em; margin-top:2px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('fdaTherapies')}}
                                           aria-hidden="true">
                                            {filteredFDACount} FDA Approvals
                                        </p>

                                        <p class="hover-underline"
                                           style="margin-left: 9em; margin-top:2px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('nccnTherapies');}}
                                           aria-hidden="true">
                                            {filteredNCCNCount} NCCN Recommended
                                        </p>

                                        <p class="hover-underline" style="margin-left: 3em; margin-top:10px;">
                                            There is No Prognostic or Diagnostic Information available
                                        </p>
                                    </div>
                                    <div id="biomarker" class="tab-pane {activeTab === 'biomarker' ? 'active' : ''}">
                                        <h3 class="caption">Key Biomarkers</h3>
                                        <table>
                                            <thead class="biomarker-th">
                                            <tr>
                                                <th>Pertinent Biomarkers</th>
                                                <th>Status</th>
                                                <th>Components (status)</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {#each sortedAlterationGroups as altGroup}
                                                <tr>
                                                    <td class="td-pertAlt">{altGroup.name}</td>
                                                    <td class="td-pertAlt"
                                                        style="color: {getStatusColor(altGroup.group_status)};">
                                                        {titleCase(altGroup.group_status)}
                                                    </td>
                                                    <td class="td-pertAlt">
                                                        {#each altGroup.alterations as alt}
                                                            {#each $selectedCase.pertinent_alteration_groups.pertinent_alterations as pertinentAlt}
                                                                {#if pertinentAlt.alteration === alt}
                                                                    <div>
                                                                        {alt} (<span
                                                                            style="color: {getStatusColor(pertinentAlt.status)};">{titleCase(pertinentAlt.status)}</span>)
                                                                    </div>
                                                                {/if}
                                                            {/each}
                                                        {/each}
                                                    </td>
                                                </tr>
                                            {/each}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div id="clinical" class="tab-pane {activeTab === 'clinical' ? 'active' : ''}">
                                        <!--                                    Timeline-->
                                    </div>
                                </div>
                            </div>
                        {/if}

                    {:else}
                        {#if (localStorage.getItem('ehrWorkflow'))}
                            <div class="no-patient-selected-message">
                                <h3>No Patient Found with provided MRN</h3>
                                <p>
                                    Please contact an administrator
                                </p>
                            </div>
                        {:else}
                            <div class="no-patient-selected-message">
                                <h3>No Patient Selected</h3>
                                <p>
                                    Please <span class="hover-underline"
                                                 on:click={() => selectOption('patient-search')}>select a patient</span>
                                    to explore matching clinical trials and therapies, manage patient, clinical, and
                                    test
                                    information, and view biomarker details.
                                </p>
                            </div>
                        {/if}
                    {/if}
                </right>
            </HSplitPane>
        {:else}
            <div style="display: flex; flex-direction: column">
                <div style="display: flex; flex-direction: row">
                    <div class="icon-button"
                         on:click={() => {leftFilterPaneState.set($leftFilterPaneState === "closed" ? "open" : "closed")}}>
                        {#if ($leftFilterPaneState === 'closed')}
                            <p style="margin-bottom: 0">Apply Filters</p>
                            <Icon data="{faFilter}" scale="1.5" style="color: #233C6B; margin-top: 0"/>
                        {:else}
                            <p style="margin-bottom: 0">Close Filter Pane</p>
                            <Icon data="{faCircleXmark}" scale="1.5" style="color: #c5c5c7; margin-top: 0"/>
                        {/if}

                    </div>
                    <h1 style="width: 80vw;">Case Summary</h1>
                </div>
                <div>
                    {#if $selectedCase}
                        {#if (activeTab === "")}
                            {#if ($instBioTrials.results.length + $instNonBioTrials.results.length + $outsideTrials.results.length > 0)}
                                <h3 style="text-align: left; margin-left: 10px; cursor:pointer;"
                                    on:click={()=>activeTab='actionability'} aria-hidden="true">
                                    <span style="color: #9FCE67;">Actionability </span>- The patient Qualifies for
                                    biomarker
                                    directed treatment options in the GO Knowledgebase
                                </h3>
                            {:else}
                                <h3 style="text-align: left; margin-left: 10px; cursor:pointer;"
                                    on:click={()=>activeTab='actionability'} aria-hidden="true">
                                    <span style="color: #FC0C0B;">Actionability </span>- The patient Does Not Qualify
                                    for
                                    biomarker directed treatment options in the GO Knowledgebase
                                </h3>
                            {/if}
                            {#if (biomarkerSummaryPositive)}
                                <h3 style="text-align: left; margin-left: 10px; cursor:pointer;"
                                    on:click={()=>activeTab='biomarker'} aria-hidden="true">
                                    <span style="color: #9FCE67;">Biomarker Summary </span>- The patient is positive for
                                    one
                                    or more key alterations in their disease type
                                    {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                        <p style="margin-left: 11em; font-weight:normal">The patient has one or more
                                            biomarkers that have discordant interpretations</p>
                                    {/if}
                                    {#if (untestedBiomarkerCount > 0)}
                                        <p style="margin-left: 11em; font-weight:normal">There are {untestedBiomarkerCount} untested key biomarkers for the patients disease type</p>
                                    {/if}
                                </h3>
                            {:else}
                                <h3 style="text-align: left; margin-left: 10px; cursor:pointer;"
                                    on:click={()=>activeTab='biomarker'} aria-hidden="true">
                                    <span style="color: #FC0C0B;">Biomarker Summary </span>- The patient is negative for
                                    all key biomarkers in their disease type
                                    {#if ($selectedCase.case_glance.markers.discordant_markers_count > 0)}
                                        <p style="margin-left: 11em; font-weight:normal">The patient has one or more
                                            biomarkers that have discordant interpretations</p>
                                    {/if}
                                    {#if (untestedBiomarkerCount > 0)}
                                        <p style="margin-left: 11em; font-weight:normal">There are {untestedBiomarkerCount} untested key biomarkers for the patients disease type</p>
                                    {/if}
                                </h3>
                            {/if}
                            {#if (($selectedCase.lab_results?.length ?? 0 > 0) || ($selectedPatient.events?.length ?? 0 > 0) || ($selectedPatient.oncologic_treatments?.length ?? 0 > 0))}
                                <h3 style="text-align: left; margin-left: 10px; cursor:pointer;"
                                    on:click={()=>activeTab='clinical'} aria-hidden="true">
                                    <span style="color: #9FCE67;">Clinical History Summary </span>- The case has
                                    associated
                                    clinical information
                                </h3>
                            {:else}
                                <h3 style="text-align: left; margin-left: 10px; cursor:pointer;"
                                    on:click={()=>activeTab='clinical'} aria-hidden="true">
                                    <span style="color: #FC0C0B;">Clinical History Summary </span>- The case does not
                                    have
                                    associated clinical information
                                </h3>
                            {/if}
                        {:else}
                            <div class="vertical-tabs">
                                <div class="tab-menu">
                                    <button class="tab-link {activeTab === 'actionability' ? 'active' : ''}"
                                            on:click={() => activeTab = activeTab === 'actionability' ? '' : 'actionability'}
                                            style="color: {($instBioTrials.results.length + $instNonBioTrials.results.length + $outsideTrials.results.length > 0) ? '#9FCE67' : '#FC0C0B'}">
                                        Actionability
                                    </button>
                                    <button class="tab-link {activeTab === 'biomarker' ? 'active' : ''}"
                                            on:click={() => activeTab = activeTab === 'biomarker' ? '' : 'biomarker'}
                                            style="color: {biomarkerSummaryPositive ? '#9FCE67' : '#FC0C0B'}">
                                        Biomarker Summary
                                    </button>
                                    <button class="tab-link {activeTab === 'clinical' ? 'active' : ''}"
                                            on:click={() => activeTab = activeTab === 'clinical' ? '' : 'clinical'}
                                            style="color: {(($selectedCase.lab_results?.length ?? 0 > 0) || ($selectedPatient.events?.length ?? 0 > 0) || ($selectedPatient.oncologic_treatments?.length ?? 0 > 0)) ? '#9FCE67' : '#FC0C0B'}">
                                        Clinical History Summary
                                    </button>
                                </div>
                                <div class="tab-content">
                                    <div id="actionability"
                                         class="tab-pane {activeTab === 'actionability' ? 'active' : ''}">
                                        <p class="td-headers"
                                           style="margin-left: 3em; margin-top:10px; margin-bottom: 0;">
                                            Clinical Trials</p>
                                        <p class="hover-underline"
                                           style="margin-left: 6em; margin-top:10px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('allTrials')}}
                                           aria-hidden="true">
                                            {filteredInstNonBioTrials.length + filteredInstBioTrials.length + filteredOutsideTrials.length}
                                            Total Clinical Trials
                                        </p>

                                        <p class="hover-underline"
                                           style="margin-left: 9em; margin-top:2px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('instTrialsOnly')}}
                                           aria-hidden="true">
                                            {(filteredInstBioTrials.length || 0) + (filteredInstNonBioTrials.length || 0)}
                                            at {getDefaultInstitution()}
                                        </p>

                                        <p class="hover-underline"
                                           style="margin-left: 12em; margin-top:2px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('instTrialsOnly')}}
                                           aria-hidden="true">
                                            {(filteredInstBioTrials.length || 0)} biomarker driven
                                        </p>

                                        <p class="hover-underline"
                                           style="margin-left: 12em; margin-top:2px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('instTrialsOnly')}}
                                           aria-hidden="true">
                                            {(filteredInstNonBioTrials.length || 0)} non-biomarker driven
                                        </p>

                                        <p class="hover-underline"
                                           style="margin-left: 9em; margin-top:2px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('outsideTrialsOnly')}}
                                           aria-hidden="true">
                                            {filteredOutsideTrials.length} outside
                                        </p>

                                        <p class="td-headers"
                                           style="margin-left: 3em; margin-top:10px; margin-bottom: 0;">
                                            Therapy Recommendations</p>

                                        <p class="hover-underline"
                                           style="margin-left: 6em; margin-top:2px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('allTherapies')}}
                                           aria-hidden="true">
                                            {filteredSenOTTTherapies.length + filteredSenFTTTherapies.length + filteredResTherapies.length}
                                            Tier
                                            1
                                            Therapy Options
                                        </p>

                                        <p class="hover-underline"
                                           style="margin-left: 9em; margin-top:2px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('fdaTherapies')}}
                                           aria-hidden="true">
                                            {filteredFDACount} FDA Approvals
                                        </p>

                                        <p class="hover-underline"
                                           style="margin-left: 9em; margin-top:2px; margin-bottom: 0;"
                                           on:click={() => {openMatchResults.set(true); pane.set('main'); matchTableSetting.set('nccnTherapies');}}
                                           aria-hidden="true">
                                            {filteredNCCNCount} NCCN Recommended
                                        </p>

                                        <p class="hover-underline" style="margin-left: 3em; margin-top:10px;">
                                            There is No Prognostic or Diagnostic Information available
                                        </p>
                                    </div>
                                    <div id="biomarker" class="tab-pane {activeTab === 'biomarker' ? 'active' : ''}">
                                        <h3 class="caption">Key Biomarkers</h3>
                                        <table>
                                            <thead class="biomarker-th">
                                            <tr>
                                                <th>Pertinent Biomarkers</th>
                                                <th>Status</th>
                                                <th>Components (status)</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {#each sortedAlterationGroups as altGroup}
                                                <tr>
                                                    <td class="td-pertAlt">{altGroup.name}</td>
                                                    <td class="td-pertAlt"
                                                        style="color: {getStatusColor(altGroup.group_status)};">
                                                        {titleCase(altGroup.group_status)}
                                                    </td>
                                                    <td class="td-pertAlt">
                                                        {#each altGroup.alterations as alt}
                                                            {#each $selectedCase.pertinent_alteration_groups.pertinent_alterations as pertinentAlt}
                                                                {#if pertinentAlt.alteration === alt}
                                                                    <div>
                                                                        {alt} (<span
                                                                            style="color: {getStatusColor(pertinentAlt.status)};">{titleCase(pertinentAlt.status)}</span>)
                                                                    </div>
                                                                {/if}
                                                            {/each}
                                                        {/each}
                                                    </td>
                                                </tr>
                                            {/each}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div id="clinical" class="tab-pane {activeTab === 'clinical' ? 'active' : ''}">
                                        <!--                                    Timeline-->
                                    </div>
                                </div>
                            </div>
                        {/if}

                    {:else}
                        {#if (localStorage.getItem('ehrWorkflow'))}
                            <div class="no-patient-selected-message">
                                <h3>No Patient Found with provided MRN</h3>
                                <p>
                                    Please contact an administrator
                                </p>
                            </div>
                        {:else}
                            <div class="no-patient-selected-message">
                                <h3>No Patient Selected</h3>
                                <p>
                                    Please <span class="hover-underline"
                                                 on:click={() => selectOption('patient-search')}>select a patient</span>
                                    to explore matching clinical trials and therapies, manage patient, clinical, and
                                    test
                                    information, and view biomarker details.
                                </p>
                            </div>
                        {/if}
                    {/if}
                </div>
            </div>
        {/if}
    {:else if ($openMatchResults)}
        <svelte:component this="{MatchResultsTable}"/>
    {/if}
{/if}

