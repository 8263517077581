<script>
    import { selectedLabResults, selectedCase, caseAttachments, selectedFileID } from "../../store/patientMatch";
    import {
        getCaseAttachments,
        getLabResultAttachment,
        getSampleAttachment,
        uploadCaseAttachment,
    } from "../../network/patient_match";
    import {onDestroy, onMount} from "svelte";
    import Loader from "../shared/Loader.svelte";
    export let tab;

    let file;
    let selectedFile;
    let loading=false;
    let iframeSrc = "";
    let imageSrc = "";
    let textContent = "";


    $: {
        if($selectedFileID){
            selectedFile = $selectedFileID
            handleFileSelection();
        }
    }
    onDestroy(()=> {
        imageSrc='';
        iframeSrc='';
        selectedFileID.set('');
        textContent = ""
    })
    $: attachmentMap = [];

    $: {
        attachmentMap = [];
        $selectedLabResults.forEach((sample) => {
            sample.attachment_file_names.forEach((name, index) => {
                attachmentMap.push({
                    attachment_type: "sample",
                    id: sample.attachment_files[index],
                    attachment_file_name: name
                });
            });

            sample.lab_results.forEach((lr) => {
                lr.attachment_file_names.forEach((name, index) => {
                    attachmentMap.push({
                        attachment_type: "lab_result",
                        id: lr.attachment_files[index],
                        attachment_file_name: name
                    });
                });
            });
        });
        $caseAttachments.forEach(caseAttachment => {
            attachmentMap.push({
                attachment_type: "case",
                id: caseAttachment.id,
                attachment_file_name: caseAttachment.url_path.split("/").pop()
            });
        });
    }

    async function handleFileSelection() {
        if (!selectedFile) {
            iframeSrc = "";
            return;
        }

        const [attachment_type, id] = selectedFile.split("-");

        if (attachment_type === "sample") {
            try {
                iframeSrc = await getSampleAttachment(id);
                imageSrc = ""
                textContent = ""
            } catch (error) {
                console.error("Error fetching the PDF:", error);
                iframeSrc = "";
            }
        } else if (attachment_type === "lab_result") {
            try {
                iframeSrc = await getLabResultAttachment(id);
                imageSrc=""
                textContent = ""
            } catch (error) {
                console.error("Error fetching the PDF:", error);
                iframeSrc = "";
            }
        } else if (attachment_type === "case"){
            const selectedAttachment = $caseAttachments.find(att => att.id.toString() === id);
            iframeSrc = ""
            textContent = ""
            const BASE_URL = configureConnectionURLs();
            const url = BASE_URL + selectedAttachment.url_path
            if(isTextFile(url)){
                try {
                    const response = await fetch(url);
                    textContent = await response.text();
                    iframeSrc = "";
                    imageSrc = "";
                } catch (error) {
                    console.error("Error fetching the text file:", error);
                    textContent = "Unable to load file.";
                }
            }
            else {
                imageSrc = url;
            }
        }
    }

    function configureConnectionURLs() {
        let hostInformation = window.location.host;
        let host = hostInformation;
        let sPROTOCOL = "https";
        if (hostInformation.includes("localhost") ||
            hostInformation.includes("127.0.0.1") ||
            hostInformation.includes("192.168") ||
            hostInformation.includes("10.0.1")) {
            host = host.replace("8059", "8000");
            sPROTOCOL = "http";
        }
        return `${sPROTOCOL}://${host}`;
    }

    function handleFileChange(event) {
        file = event.target.files[0];
    }
    async function upload() {
        loading = true;
        let result = await uploadCaseAttachment($selectedCase, file);
        const caseAttachmentData = await getCaseAttachments($selectedCase.uuid);
        caseAttachments.set(caseAttachmentData);
        loading = false;
    }

    function cancel(){

    }

    function isTextFile(url) {
        const imageFileExtensions = ['.png', '.jpeg', '.jpg'];
        return !imageFileExtensions.some(ext => url.toLowerCase().endsWith(ext));
    }

    document.addEventListener('mousedown', function() {
        const iframe = document.querySelector('iframe');
        if (iframe) {
            iframe.style.pointerEvents = 'none';
        }
    });

    document.addEventListener('mouseup', function() {
        const iframe = document.querySelector('iframe');
        if (iframe) {
            iframe.style.pointerEvents = 'auto';
        }
    });

</script>

{#if tab === 'view'}
    <div style="display:flex; flex-direction: column;">
        <h3>Select File to View</h3>
        <select bind:value={selectedFile} on:change={handleFileSelection}>
            <option value="">-- Select a File --</option>
            {#each attachmentMap as attachment}
                <option value="{attachment.attachment_type}-{attachment.id}">
                    {attachment.attachment_file_name}
                </option>
            {/each}
        </select>

        {#if iframeSrc}
            <iframe src={iframeSrc} title="upload-viewer" style="width: 100%; height: 65vh;" />
        {/if}
        {#if imageSrc}
            <img src="{imageSrc}" title="Case Attachment Image" alt=""/>
        {/if}
        {#if textContent}
            <pre>
                {textContent}
            </pre>
        {/if}
    </div>
{:else if tab === 'upload'}
    {#if (loading)}
        <Loader/>
    {:else}
        <div style="display:flex; flex-direction: column;">
            <h3>Select File to Upload</h3>
            <div class="modal-input">
                <input style="margin:5px" class="upload-case" type="file" on:change={handleFileChange}/>
            </div>
            <div class="modal-buttons">
                <button class="cancel-button" on:click={cancel}>Cancel</button>
                <button class="upload-button" on:click={upload}>Upload</button>
            </div>
        </div>
    {/if}

{:else}
    REPORT VIEW
{/if}

<style>
    iframe {
        width: 100%;
        height: 65vh;
    }

    img {
        pointer-events: none;
        max-height: 65vh;
    }

    pre {
        max-height: 65vh;
        font-family: Poppins, sans-serif;
        font-size: 1rem;
        line-height: 1.5;
        text-align: left;
        background-color: #f9f9f9;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        overflow: auto;
        white-space: pre-wrap;
        color: #333;
    }

    .upload-button {
        margin-top: 10px;
        background-color: #233C6B;
        color: #fff;
        padding: 10px 25px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .cancel-button {
        margin-top: 10px;
        background-color: #9d9d9f;
        color: #fff;
        margin-right: 0.5em;
        padding: 10px 25px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
</style>
