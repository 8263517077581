<script>
    import {searchQueries, totalTrials, matchedTrials, inFilterView} from "../../store/instTrialSearchQueries";
    import {expandMainHeader} from "../../store/navigation";
    import {faCircleXmark as faCircleXmarkRegular} from "@fortawesome/free-regular-svg-icons";
    import {Icon} from "svelte-awesome";
    import {pane} from "../../store/instInsights";

    let filterData = {};
    let total = '';
    let matched = '';
    let titleHeight = 0;
    let titleRef;
    let isFilterView;

    $: filterData = $searchQueries;

    $: total = $totalTrials;

    $: matched = $matchedTrials;

    $: isFilterView = $inFilterView;

    searchQueries.subscribe(value => {
        if (titleRef) {
            setTimeout(() => {
                titleHeight = titleRef.offsetHeight;
                if (titleHeight > 24) {
                    expandMainHeader.set(true);
                } else {
                    expandMainHeader.set(false);
                }
            });
        }
    });

    function getTitle(key) {
        if (key === "patient_name") {
            return "Patient Name";
        } else if (key === "patient_mrn") {
            return "MRN";
        } else if (key === "diseases") {
            return "Diseases";
        } else if (key === "disease_states") {
            return "Disease States";
        } else if (key === "drugs") {
            return "Drugs";
        } else if (key === "genes") {
            return "Genes";
        } else if (key === "alterations") {
            return "Alterations";
        } else if (key === "recruiting_status") {
            return "Recruiting Status";
        } else if (key === "eligibility_name") {
            return "Eligibility Name";
        } else if (key === "protocol_id") {
            return "Protocol ID";
        } else if (key === "nct_id") {
            return "NCT ID";
        }
    }

    async function fetchResetTrials() {
        searchQueries.update(storeValue => {
            storeValue.patient_name = '';
            storeValue.patient_mrn = '';
            storeValue.diseases = '';
            storeValue.disease_states = '';
            storeValue.drugs = '';
            storeValue.genes = '';
            storeValue.alterations = '';
            storeValue.recruiting_status = '';
            storeValue.protocol_id = '';
            storeValue.nct_id = '';
            storeValue.eligibility_name = '';
            return storeValue;
        });
        pane.set("trial_list");
        inFilterView.update(storeValue => {
            storeValue = false;
            return storeValue;
        });
    }
</script>

<style>
    .header {
        display: flex;
        text-align: center;
        font-size: 25px;
        vertical-align: top;
        margin-top: 10px;
        height: 60px;
        justify-content: center;
        margin-left: 40px;
    }
    .filters {
        font-size: 20px;
        display: flex;
        align-items: center;
        width: calc(100vw - 130px);
        margin-bottom: 20px;
        vertical-align: center;
        margin-left: 30px;
    }
    .filter-header {
        display: flex;
        font-weight: bold;
        font-size: 20px;
    }
    .filter-items {
        display: flex;
        flex-wrap: wrap;
        margin-left: 10px;

    }
    .filter-item {
        font-size: 17px;
        margin-right: 10px;
    }
    .filter-key {
        font-weight: bold;
    }
    .center-align {
        flex: 1;
        text-align: center;
    }
    .right-align {
        margin-left: auto;
        font-size: 18px;
        margin-top: 33px;
        margin-right: 80px;
    }
    .left-align {
        font-size: 18px;
        margin-top: 33px;
    }

    .filter-view {
        display: flex;
        align-items: center;
    }
</style>

<div class="full-header">
    <div class="header">
        {#if isFilterView}
            <div class="left-align">
                <div class="filter-view" on:click={() => fetchResetTrials()} aria-hidden="true">
                    <Icon data="{faCircleXmarkRegular}" scale="2" style="margin-right: 10px"/>
                    <span>Close Filter View</span>
                </div>
            </div>
        {/if}
        <h5 class="center-align">Institutional Insights</h5>
        <div class="right-align">
            {#if total !== ""}
                <div>
                    <span style="font-weight: bold;">Total Trial Count:</span> {total}
                </div>
            {/if}
            {#if matched !== "" && total.toString() !== matched.toString()}
                <div>
                    <span style="font-weight: bold;">Matching Trial Count:</span> {matched}
                </div>
            {/if}
        </div>
    </div>
    <div class="filters">
        <p class="filter-header" bind:this={titleRef}>Applied Filters: </p>
        <div class="filter-items">
            {#each Object.keys(filterData) as key}
                {#if filterData[key] !== '' && filterData[key].length !== 0}
                    <div class="filter-item">
                        <span class="filter-key">{getTitle(key)}:</span> {filterData[key].toString().replaceAll(",", ", ")}
                    </div>
                {/if}
            {/each}
        </div>
    </div>
</div>