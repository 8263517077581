<script>
    import {Icon} from "svelte-awesome";
    import {faCircleXmark} from "@fortawesome/free-regular-svg-icons";
    import  {openAssayExplorer} from "../../store/patientMatch";
    import {onMount} from "svelte";

    let openGene = false;
    let searchResults = false;

    let  searchGene = '';
    let  searchGeneInput = '';

    let openPanel = [];
    let openPanelConceptMap = {}
    let panelTypeLabel = {"SNV":"Mutation", "CNV":"Copy Number", "CTX":"Fusions", "PRX":"Protein Expression", "Biomarkers":"Biomarker","Karyotype":"Karyotype", "PGX":"Pharmacogenomics"}
    export let filteredSamples;


    function calculateConceptCount(key, panelConcepts){
            let totalConcepts = 0;

            panelConcepts.filter(entry => entry.type === key).forEach(entry => {
                totalConcepts += entry.concepts.length;
            });

            return totalConcepts;
    }

    function gatherPanelConcepts(key, panelConcepts){
            let allConcepts = [];

            panelConcepts.filter(entry => entry.type === key).forEach(entry => {
                allConcepts = [...allConcepts, ...entry.concepts];
            });

            return allConcepts;
    }

    function togglePanelView(labResult, panelType){
        if (openPanel.includes(labResult.id+panelType)) {
            openPanel = openPanel.filter(id => !id.includes(labResult.id+panelType))
        }
        else{
            if (openPanel.some(panel => panel.includes(labResult.id))) {
                openPanel = openPanel.filter(id => !id.includes(labResult.id))
            }
            openPanel = [...openPanel, labResult.id+panelType]
        }
        openPanelConceptMap[labResult.id] = gatherPanelConcepts(panelType, labResult.panel_concept_map)

    }

    function filterSamplesByConcept(searchConcept) {
        return filteredSamples.map(sample => {
            const filteredLabResults = sample.lab_results.map(lr => {
                const filteredPanels = lr.panel_concept_map.filter(panel =>
                    panel.concepts.some(concept =>
                        concept.toLowerCase() === searchConcept.toLowerCase() ||
                        (concept.toLowerCase().includes(searchConcept.toLowerCase()))
                    )
                );
                return {...lr, panel_concept_map: filteredPanels};
            }).filter(lr => lr.panel_concept_map.length > 0);
            return {...sample, lab_results: filteredLabResults};
        }).filter(sample => sample.lab_results.length > 0);
    }


</script>

<div style="height: 100%; overflow: scroll;">
    <div style="display:flex; justify-content:space-evenly; align-items: center;">
        <span/>
        <h3 style="margin-right: 10px;font-weight: bold; font-size: 20px; text-decoration: underline;">
            Assay Explorer
        </h3>
        <div on:click={() => openAssayExplorer.set(false)} aria-hidden="true">
            <Icon data="{faCircleXmark}" scale="1.5" style="color: #233C6B;"/>
        </div>
    </div>
    <div style=" display: flex; flex-direction: column; align-items: start;">
        {#each filteredSamples as sample}
            {#each sample.lab_results as lr}
                <span style="margin-left: 15px;"><strong>{lr.assay_display}</strong></span>
            {/each}
        {/each}
        <span style="margin-left: 20px; margin-bottom: 20px">
            <strong>Search Gene:</strong>
            <input type="text"  bind:value={searchGeneInput}/>
            <span class="search-button" on:click={()=>{searchResults=true; searchGene=searchGeneInput; filterSamplesByConcept(searchGene)}}>Search</span>
            <span class="clear-button" on:click={()=>{searchResults=false; searchGene=''; searchGeneInput='';}}>Clear</span>
        </span>
        {#if (searchResults)}
            {#if (filterSamplesByConcept(searchGene).length > 0)}
                <strong style="margin-left: 35px;">{searchGene} is present on the following Panels:</strong>
                {#each filterSamplesByConcept(searchGene) as sample}
                    {#each sample.lab_results as lr}
                        <span style="margin-left: 35px; margin-bottom: 20px"><strong>{lr.assay_display}</strong></span>
                        <div style="display: flex; flex-direction: row; align-items: flex-start">
                            <table>
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Count</th>
                                </tr>
                                </thead>
                                <tbody>
                                {#each Object.entries(panelTypeLabel) as [key, value]}
                                    {#if (calculateConceptCount(key, lr.panel_concept_map) !== 0)}
                                        <tr class="panel-row"
                                            on:click={()=>{togglePanelView(lr, key)}}>
                                            <td style="width:150px;">{value}</td>
                                            <td>{calculateConceptCount(key, lr.panel_concept_map)}</td>
                                        </tr>
                                    {/if}
                                {/each}
                                </tbody>
                            </table>
                            {#if openPanel.some(panel => panel.includes(lr.id))}
                                <div style="margin-left: 15px; width: 250px; background-color: #ccc; font-family: Arial, sans-serif; display: flex; flex-direction: column; align-items: center; padding: 10px;">
                                    <div style="font-size: 12px; font-weight: bold; text-align: center; margin-bottom: 5px; display: flex; justify-content: space-between; align-items: center; width: 100%;">
                                        <span style="margin: auto;">Added Genes </span>
                                        <span style="cursor: pointer; font-size: 14px; font-weight: bold;" on:click={() => openPanel = openPanel.filter(panel => !panel.includes(lr.id))} aria-hidden="true">
                                            X
                                        </span>
                                    </div>
                                    <div style="height: 150px; width: 95%; overflow-y: scroll; padding: 5px; background-color: white; border: 1px solid #bbb;">
                                        {#each openPanelConceptMap[lr.id] as concept}
                                            <p style="text-align: left; margin: 2px 0; font-size: 12px;">{concept}</p>
                                        {/each}
                                    </div>
                                </div>
                            {/if}
                        </div>
                    {/each}
                {/each}
            {:else}
                <strong style="margin-left: 35px;">{searchGene} is not present on any of the panels.</strong>
            {/if}
        {:else}
            <span style="margin-left: 25px; margin-bottom: 20px; cursor:pointer;" on:click={()=>{openGene=!openGene;}} aria-hidden="true" ><strong>View Gene: <span>{openGene ? '▲' : '▼'}</span></strong></span>
            {#if (openGene)}
                {#each filteredSamples as sample}
                    {#each sample.lab_results as lr}
                        <span style="margin-left: 35px; margin-bottom: 20px"><strong>{lr.assay_display}</strong></span>
                        <div style="display: flex; flex-direction: row; align-items: flex-start">
                            <table>
                                <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Count</th>
                                </tr>
                                </thead>
                                <tbody>
                                {#each Object.entries(panelTypeLabel) as [key, value]}
                                    {#if (calculateConceptCount(key, lr.panel_concept_map) !== 0)}
                                        <tr class="panel-row"
                                            on:click={()=>{togglePanelView(lr, key)}}>
                                            <td style="width:150px;">{value}</td>
                                            <td>{calculateConceptCount(key, lr.panel_concept_map)}</td>
                                        </tr>
                                    {/if}
                                {/each}
                                </tbody>
                            </table>
                            {#if openPanel.some(panel => panel.includes(lr.id))}
                                <div style="margin-left: 15px; width: 250px; background-color: #ccc; font-family: Arial, sans-serif; display: flex; flex-direction: column; align-items: center; padding: 10px;">
                                    <div style="font-size: 12px; font-weight: bold; text-align: center; margin-bottom: 5px; display: flex; justify-content: space-between; align-items: center; width: 100%;">
                                        <span style="margin: auto;">Added Genes </span>
                                        <span style="cursor: pointer; font-size: 14px; font-weight: bold;" on:click={() => openPanel = openPanel.filter(panel => !panel.includes(lr.id))} aria-hidden="true">
                                            X
                                        </span>
                                    </div>
                                    <div style="height: 150px; width: 95%; overflow-y: scroll; padding: 5px; background-color: white; border: 1px solid #bbb;">
                                        {#each openPanelConceptMap[lr.id] as concept}
                                            <p style="text-align: left; margin: 2px 0; font-size: 12px;">{concept}</p>
                                        {/each}
                                    </div>
                                </div>
                            {/if}
                        </div>
                    {/each}
                {/each}
            {/if}
        {/if}
    </div>
</div>


<style>
    table {
        margin-left: 35px;
        margin-bottom: 20px;
        color:#233C6B;
        text-align: left;
        border-collapse: collapse;
    }

    th, td {
        cursor: pointer;
    }

    .panel-row:hover{
        background-color: #ffecb3;
    }

    .search-button {
        margin-top: 10px;
        background-color: #98B249;
        color: #fff;
        padding: 10px 25px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }

    .clear-button {
        margin-top: 10px;
        background-color: #d98b95;
        color: #fff;
        margin-right: 0.5em;
        padding: 10px 25px;
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        cursor: pointer;
    }
</style>