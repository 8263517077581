<script>
    import {selectedProtocolID, patientLevel, selectedEligName, selectedEligID, pane} from "../../store/instInsights";
    import {faCircleXmark as faCircleXmarkRegular} from "@fortawesome/free-regular-svg-icons";
    import {Icon} from "svelte-awesome";
    import {onMount} from "svelte";
    import {queryCases} from "../../network/analytics/queries";
    import {faCircleXmark} from "@fortawesome/free-solid-svg-icons";
    import Modal from "../Modal.svelte";

    let patientDetails = null;
    let isPanelVisible = false;
    let checkedRows = new Map();
    let selectedPageSize = 10;
    let currentPage = 1;
    let allCount = '';
    let selectedPatients = [];
    let patientExportModal;
    let selectedExportOption = '';
    let all = false;
    let selected = false;
    let mostRecentMacthedDate = '';

    async function fetchCases() {
        let filters = [];
        if ($patientLevel === "trial") {
            filters = [
                {
                    "core": "recommendations",
                    "type": "term",
                    "field": "inst_protocol_ids",
                    "values": [{"value": $selectedProtocolID}],
                    "display": "Protocol ID: " + $selectedProtocolID,
                    "operator": "Any"
                }
            ];
        } else {
            filters = [
                {
                    "core": "recommendations",
                    "type": "term",
                    "field": "eligibility_id",
                    "values": [{"value": $selectedEligID}],
                    "display": "Eligibility ID: " + $selectedEligID,
                    "operator": "Any"
                }
            ];
        }
        let response = await queryCases(filters, true, currentPage, selectedPageSize);
        if (response.ok) {
            const results = await response.json();
            selectedPatients = results.results;
            allCount = results.hits;
            checkedRows = new Map(selectedPatients.map(data => [data.uuid, false]));
        } else {
            pushFail('Unable to fetch query');
        }
    }

    async function setMostRecentDate() {
        let filters = [];
        if ($patientLevel === "trial") {
            filters = [
                {
                    "core": "recommendations",
                    "type": "term",
                    "field": "inst_protocol_ids",
                    "values": [{"value": $selectedProtocolID}],
                    "display": "Protocol ID: " + $selectedProtocolID,
                    "operator": "Any"
                }
            ];
        } else {
            filters = [
                {
                    "core": "recommendations",
                    "type": "term",
                    "field": "eligibility_id",
                    "values": [{"value": $selectedEligID}],
                    "display": "Eligibility ID: " + $selectedEligID,
                    "operator": "Any"
                }
            ];
        }
        let response = await queryCases(filters, true, 1, 999999);
        if (response.ok) {
            const results = await response.json();
            mostRecentMacthedDate = getMostRecentDate(results.results);
        } else {
            pushFail('Unable to fetch query');
        }
    }

    function handleViewDetails(patient) {
        patientDetails = patient;
        isPanelVisible = true;
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const finalDate = date.toLocaleDateString('en-US', options);
        if (finalDate === "Invalid Date") {
            return "";
        } else {
            return finalDate;
        }
    }

    function getMatchingParameters(diseases, alterations, genes) {
        let finalString = "";
        if (diseases) {
            finalString = finalString + "<b>Diseases:</b> " + diseases.join(", ");
        }
        if (alterations) {
            finalString = finalString + "<br><b>Alterations:</b> " + alterations.join(", ");
        }
        if (genes) {
            finalString = finalString + "<br><b>Genes:</b> " + genes.join(", ");
        }
        return finalString;
    }

    function formatParametersForExport(diseases, alts, genes) {
        let finalString = "";
        if (diseases) {
            finalString = finalString + "Diseases: " + diseases.join(", ");
        }
        if (alts) {
            if (finalString !== "") {
                finalString = finalString + ", Alterations: " + alts.join(", ");
            } else {
                finalString = finalString + "Alterations: " + alts.join(", ");
            }
        }
        if (genes) {
            if (finalString !== "") {
                finalString = finalString + ", Genes: " + genes.join(", ");
            } else {
                finalString = finalString + "Genes: " + genes.join(", ");
            }
        }
        return finalString;
    }

    function getMostRecentDate(results) {
        let allRecommendations = results
            .flatMap(result => result.recommendations || [])
            .filter(rec => rec && rec.created);

        // If no recommendations with a date exist, return null
        if (allRecommendations.length === 0) {
            return "";
        }


        // Find the most recent recommendation by date
        let mostRecentRecommendation = allRecommendations.reduce((latest, current) => {
            return new Date(current.created) > new Date(latest.created) ? current : latest;
        }, allRecommendations[0]);

        return formatDate(mostRecentRecommendation.created);
    }

    async function changePageSize(event) {
        selectedPageSize = event.target.value;
        currentPage = 1;
        await fetchCases();
    }

    async function changePage(page) {
        currentPage = page;
        await fetchCases();
    }

    function exitPage() {
        if ($patientLevel === "trial") {
            pane.set("trial_list");
        } else {
            pane.set("eligibility_list");
        }
    }

    function togglePanel() {
        if (isPanelVisible) {
            patientDetails = null;
        }
        isPanelVisible = !isPanelVisible;
    }

    function toggleCheckbox(id) {
        checkedRows.set(id, !checkedRows.get(id));
        checkedRows = new Map(checkedRows);
    }

    function anyRowsChecked() {
        return Array.from(checkedRows.values()).some(checked => checked);
    }

    function getCheckedRows() {
        return Array.from(checkedRows.entries()).filter(([id, checked]) => checked).map(([id]) => id);
    }

    function handleExportCheckboxChange(event, option) {
        selectedExportOption = option;
        if (option === 'all') {
            all = event.target.checked;
            selected = false;
        } else if (option === 'selected') {
            all = false;
            selected = event.target.checked;
        }
    }

    const onPatientExport = async () => {
        if (selectedExportOption !== "") {
            await exportToCSV();
            all = false;
            selected = false;
            patientExportModal.close();
        }
    };

    async function exportToCSV() {
        const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(generateCSVContent())}`;
        const link = document.createElement("a");
        link.href = csvContent;
        link.download = selectedExportOption + "_patients.csv";
        link.click();
    }

    function generateCSVContent() {
        // Define the headers for the CSV
        let headers = [];
        if ($patientLevel === "trial") {
            headers = [
                "Protocol ID",
                "Patient Name",
                "MRN",
                "Matching Parameters",
                "Eligibility Name",
                "Match Date"
            ];
        } else {
            headers = [
                "Protocol ID",
                "Eligibility Name",
                "Patient Name",
                "MRN",
                "Matching Parameters",
                "Match Date"
            ];
        }

        // Convert each patient's lab results into CSV rows
        const rows = [];
        let exportPatients = selectedPatients;
        if (selectedExportOption === "selected") {
            let selectedUUIDs = getCheckedRows();
            exportPatients = selectedPatients.filter(obj => selectedUUIDs.includes(obj.uuid));
        }
        exportPatients.forEach((p) => {
            let protocolID = $selectedProtocolID;
            let eligName = $selectedEligName;
            let patient_name = p.patient_name;
            let patient_mrn = p.patient_mrn;
            let matching_parameters = "";
            let eligibility_names = "";
            for (let recommendation in p.recommendations) {
                let rec = p.recommendations[recommendation];
                if (matching_parameters === "") {
                    matching_parameters = matching_parameters + formatParametersForExport(rec.trigger_diseases, rec.trigger_alterations, rec.trigger_genes);
                } else {
                    matching_parameters = matching_parameters + " | " + formatParametersForExport(rec.trigger_diseases, rec.trigger_alterations, rec.trigger_genes);
                }
                if (rec.eligibility_name !== undefined) {
                    if (eligibility_names === "") {
                        eligibility_names = eligibility_names + rec.eligibility_name;
                    } else {
                        eligibility_names = eligibility_names + " | " + rec.eligibility_name;
                    }
                }
            }
            let match_date = formatDate(p.recommendations[0].created);
            let row_info = [];
            if ($patientLevel === "trial") {
                row_info = [
                    protocolID || "",
                    patient_name || "",
                    patient_mrn || "",
                    matching_parameters || "",
                    eligibility_names || "",
                    match_date || ""];
            } else {
                row_info = [
                    protocolID || "",
                    eligName || "",
                    patient_name || "",
                    patient_mrn || "",
                    matching_parameters || "",
                    match_date || ""];
            }
            rows.push(row_info);
        });

        return [headers, ...rows].map((row) =>
            row.map(escapeCSVValue).join(",")
        ).join("\n");
    }

    function escapeCSVValue(value) {
        // Escape double quotes and wrap the value in double quotes if necessary
        if (value.includes(",")) {
            return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
    }

    function closeExportModal() {
        all = false;
        selected = false;
        patientExportModal.close()
    }

    onMount(() => {
        fetchCases();
        setMostRecentDate();
    });

    $: totalPages = Math.ceil(allCount / selectedPageSize);

</script>

<style>
    .container {
        display: flex;
        height: calc(74vh - 70px);
        width: 100%;
        gap: 1em;
        overflow: hidden; /* Add this */
    }

    .summary {
        margin: 1em 1em;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1em;
        flex: 1; /* Changed from flex-grow: 1 */
        transition: width 0.3s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        min-width: 0; /* Add this */
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 12px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    thead th {
        position: sticky;
        top: 0;
    }

    th {
        background-color: #233C6B;
        color: #ffffff;
    }

    tr:nth-child(even) {
        background-color: #f9f9f9;
    }

    .scrollable-table {
        overflow-y: auto;
        max-height: calc(58vh - 4em);
        border-radius: 10px;
    }

    /* When hidden */
    .hidden {
        flex: 0;
        width: 0;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }

    .details-pane-container {
        flex: 0 0 30%; /* Add this - don't grow, don't shrink, start at 30% */
        transition: all 0.3s ease;
        position: relative;
        width: 0;
    }

    .details-pane {
        height: calc(74vh - 135px); /* Match container height */
        margin: 1em 1em 1em 0;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 1em;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        overflow: hidden; /* Add this */
    }

    /* Add styles for the main operator displays in the table */
    :global(.operator-any) {
        color: #16a34a;
        font-weight: 700;
    }

    :global(.operator-none) {
        color: #dc2626;
        font-weight: 700;
    }

    :global(.operator-all) {
        color: #002aff;
        font-weight: 700;
    }
    .icon-png {
        width: 35px;
        cursor: pointer;
    }
    .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .title-info {
        margin-right: 20px;
    }

    .title-name {
        font-weight: bold;
        margin-right: 10px;
    }
    .pagination button {
        margin-left: 0.5em;
    }

    .pages-span {
        margin-left: 0.5em;
        margin-right: 0.2em;
    }
    .pagination {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 5px;
    }
    .export-icon-png-large {
        width: 50px;
        cursor: pointer;
    }
    .next-button {
        background-color: rgba(7, 7, 7, 0.19);
        color: #fff;
    }
    button {
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
    }
    .toggle-alert-panel-button {
        margin-left: 25px;
    }
    .checkbox {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
</style>

<div class="container">
    <div class="summary">
        <div class="title">
            {#if $selectedEligName}
                <div class="title-name">Eligibility Name:</div>
                <div class="title-info">{$selectedEligName}</div>
            {/if}
            {#if $selectedProtocolID}
                <div class="title-name">Protocol ID:</div>
                <div class="title-info">{$selectedProtocolID}</div>
            {/if}
            {#if allCount}
                <div class="title-name">Matched Patients:</div>
                <div class="title-info">{allCount}</div>
            {/if}
            <div class="title-name">Most Recent Matched Date:</div>
            <div class="title-info">{mostRecentMacthedDate}</div>
            <div style="margin-left: auto; display: flex; align-items: center;">
                <div on:click={() => exitPage()} aria-hidden="true" style="margin-right: 20px">
                        <span title="Close">
                            <Icon data={faCircleXmarkRegular} scale="2" style="color: #243c6a;"/>
                        </span>
                </div>
                <div on:click={() => patientExportModal.open()} aria-hidden="true">
                    <div title="Export">
                        <img src="static/images/exportCurrentResultsIcon.png"
                             class="export-icon-png-large" alt=""></div>
                </div>
            </div>

        </div>
        <div class="pagination">
            <label for="page-size">Page Size:</label>
            <select id="page-size" on:change={changePageSize}>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="{allCount}">All</option>
            </select>
            {#if selectedPatients.length > 0}
                <div class="pagination">
                    {#if currentPage !== 1}
                        <button class="next-button" on:click={() => changePage(currentPage - 1)}>Previous
                        </button>
                    {/if}
                    <span class="pages-span">
                            Page {currentPage} of {totalPages}
                        </span>
                    {#if currentPage !== totalPages}
                        <button class="next-button" on:click={() => changePage(currentPage + 1)}>Next</button>
                    {/if}
                </div>
            {/if}
        </div>
        <div class="scrollable-table">
            <table>
                <thead>
                <tr>
                    <th>Select</th>
                    <th>Full Name</th>
                    <th>MRN</th>
                    <th>Matching Parameters</th>
                    {#if $patientLevel === "trial"}
                        <th>Eligibility Name</th>
                    {/if}
                    <th>Match Date</th>
                    <th>Patient Details</th>
                </tr>
                </thead>
                <tbody>
                {#each selectedPatients as patient, i}
                    <tr class:even={i % 2 === 1} style="background-color: {patientDetails  && patientDetails.uuid === patient.uuid? 'lightyellow' : ''}">
                        <td>
                            <input type="checkbox" checked={checkedRows.get(patient.uuid) || false} on:change={() => toggleCheckbox(patient.uuid)}>
                        </td>
                        <td>{patient.patient_name}</td>
                        <td>{patient.patient_mrn || "N/A"}</td>
                        <td>
                            {#each patient.recommendations as rec, i}
                                <div style="margin-bottom: {i < patient.recommendations.length - 1 ? '30px' : '0'}">
                                    {@html getMatchingParameters(rec.trigger_diseases, rec.trigger_alterations, rec.trigger_genes)}
                                </div>
                            {/each}
                        </td>
                        {#if $patientLevel === "trial"}
                            <td>
                                {#if patient.recommendations.length === 1}
                                    <div>{patient.recommendations[0].eligibility_name || ""}</div>
                                {:else}
                                    {#each patient.recommendations as rec}
                                        <div style="margin-bottom: 30px">{rec.eligibility_name || ""}</div>
                                    {/each}
                                {/if}
                            </td>
                        {/if}
                        <td>
                            {formatDate(patient.recommendations[0].created) || ""}
                        </td>
                        <td>
                            <div on:click={() => handleViewDetails(patient)} aria-hidden="true" style="margin-right: 30px">
                                <img src="static/images/viewCurrentResultsIcon.png"
                                     class="icon-png" alt="">
                            </div>
                        </td>
                    </tr>
                {/each}
                </tbody>
            </table>
        </div>
    </div>

    {#if patientDetails}
        <div class="details-pane-container" class:hidden={!isPanelVisible}>
            <div class="details-pane">
                <div style="display:flex; justify-content:center; align-items: center;">
                    <h3 class="text-xl font-bold mb-4" style="font-size: 25px">{patientDetails.patient_name}</h3>
                    <div class="toggle-alert-panel-button" on:click={togglePanel} aria-hidden="true">
                        <div>
                            <Icon data="{faCircleXmark}" style="scale: 1.25"/>
                        </div>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column; align-items: flex-start; text-align: left;">
                    <div class="text-xl font-bold mb-4" style="margin-top: 50px; margin-bottom: 20px; font-size: 25px">Key Information</div>
                    <div>DOB: {formatDate(patientDetails.date_of_birth)}</div>
                    <div>Diagnoses: {patientDetails.specific_diseases}</div>
                    <div>Ordering Physician: {patientDetails.patient_ordering_physician}</div>
                    <div class="text-xl font-bold mb-4" style="margin-top: 50px; margin-bottom: 20px; font-size: 25px">Genomic Test Details</div>
                    {#each patientDetails.test_types as test, i}
                        <div>{test + " (" + patientDetails.case__lab_reported_dates__mstring[i]
                        + ") " + patientDetails.lab_report_report_ids[i]}</div>
                    {/each}
                </div>
            </div>
        </div>
    {/if}
</div>

<!--Patient Export Modal-->
<form on:submit|preventDefault={onPatientExport}>
    <Modal bind:modalFunc={patientExportModal}>
        <div slot="header">
            <div>
                <h1>Which Patients would you like to export?</h1>
            </div>
        </div>
        <div slot="content" style="display: flex">
            <div style="flex: 6">
                <label class="checkbox">
                    <input type="checkbox" bind:checked={all} on:change={(event) => handleExportCheckboxChange(event, 'all')} />
                    Export All
                </label>
                {#if anyRowsChecked()}
                    <label class="checkbox">
                        <input type="checkbox" bind:checked={selected} on:change={(event) => handleExportCheckboxChange(event, 'selected')} />
                        Export Selected
                    </label>
                {/if}
            </div>
        </div>
        <div slot="footer">
            <div class="alert-modal-first-row">
                <button on:click={() => closeExportModal()}>Cancel</button>
                <button style="background-color: rgba(144, 195, 76, 1)" type="submit">Export</button>
            </div>
        </div>
    </Modal>
</form>